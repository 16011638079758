import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// import NAVI_ETH from "img/NAVI-ETH.svg";
// import opBackdrop from "img/home/opBackdrop.svg";

import { ARBITRUM, SONIC_TESTNET, FANTOM } from "config/chains";
import { formatAmountFree, formatNumber } from "lib/numbers";

import { useThemeContext } from "contexts/ThemeProvider";
import useProcessedData from "hooks/useProcessedData";
import useSwitchChain from "hooks/useSwitchChain";
import { caculateAPYHourly } from "lib/helper";
import Tokens from "./Tokens";

// const NAVI_WETH_Address = "0x104d0312f95a1b3056435d19668f2272ab1e7df2"; // fix me later

const YieldVault = ({ chainId, active, library }) => {
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const [switchChain] = useSwitchChain(chainId);

  // const processedDataFANTOM = useProcessedData(FANTOM);
  // const processedDataOP = useProcessedData(OP);
  const processedDataSONIC = useProcessedData(SONIC_TESTNET);

  const [statsNAVIWFTM, setStatsNAVIWFTM] = useState({
    price: 0,
    apr: 10,
  });

  // const [statsNAVIWETH, setStatsNAVIWETH] = useState({
  //   price: 0,
  //   apr: 1,
  // });

  const totalApyNAVI_FTM = statsNAVIWFTM.apr
    ? formatNumber(String(caculateAPYHourly(Number(statsNAVIWFTM.apr), 4080)), 2)
    : "--";

  let aprTotalNavi;
  let apyNavi;
  // let dailyApyNavi;
  aprTotalNavi = parseFloat(formatAmountFree(processedDataSONIC?.naviAprTotal, 2));
  apyNavi = aprTotalNavi ? formatNumber(caculateAPYHourly(aprTotalNavi, 4080), 2) : null;
  // dailyApyNavi = aprTotalNavi ? formatNumber(caculateAPYDaily(aprTotalNavi, 1), 4) : null;

  let aprTotalNlp;
  let apyNlp;
  // let dailyApyNlp;
  aprTotalNlp = parseFloat(formatAmountFree(processedDataSONIC?.nlpAprTotal, 2));
  apyNlp = aprTotalNlp ? formatNumber(caculateAPYHourly(aprTotalNlp, 4080), 2) : null;

  // let aprTotalNaviARB;
  // let apyNaviARB;
  // // let dailyApyNaviARB;
  // aprTotalNaviARB = parseFloat(formatAmountFree(processedDataARB?.naviAprTotal, 2));
  // apyNaviARB = aprTotalNaviARB ? formatNumber(caculateAPYHourly(aprTotalNaviARB, 4080), 2) : null;
  // // dailyApyNaviARB = aprTotalNaviARB ? formatNumber(caculateAPYDaily(aprTotalNaviARB, 1), 4) : null;

  // let aprTotalNlpARB;
  // let apyNlpARB;
  // // let dailyApyNlpARB;
  // aprTotalNlpARB = parseFloat(formatAmountFree(processedDataARB?.nlpAprTotal, 2));
  // apyNlpARB = aprTotalNlpARB ? formatNumber(caculateAPYHourly(aprTotalNlpARB, 4080), 2) : null;
  // // dailyApyNlpARB = aprTotalNlpARB ? formatNumber(caculateAPYDaily(aprTotalNlpARB, 1), 4) : null;

  const apy = {
    apyNavi: {
      [SONIC_TESTNET]: apyNavi,
      // [ARBITRUM]: apyNaviARB,
    },
    apyNlp: {
      [SONIC_TESTNET]: apyNlp,
      // [ARBITRUM]: apyNlpARB,
    },
    apyNAVI_FTM: {
      [SONIC_TESTNET]: totalApyNAVI_FTM,
    },
    priceNAVI_FTM: {
      [SONIC_TESTNET]: statsNAVIWFTM.price,
    },
  };

  return (
    <Wrapper>
      <Tokens active={active} chainId={chainId} library={library} apy={apy} />
    </Wrapper>
  );
};

const TokenCardContainer = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 767px) {
    margin-top: 16px;
    gap: 16px;
  }

  .content-nlp {
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--base-unit-xs-8, 8px);
  align-self: stretch;

  & > span {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
  }

  .desc {
    color: var(--white-60, rgba(255, 255, 255, 0.6));
    font-size: 16px;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }
`;

const TokenItem = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid #ffffff1a;
  border-radius: 16px;
  flex: 1;
  background: linear-gradient(90deg, rgba(19, 181, 236, 0.1) 0%, rgba(19, 181, 236, 0) 100%);

  &.op {
    background: linear-gradient(90deg, rgba(255, 4, 32, 0.1) 0%, rgba(255, 4, 32, 0) 100%);
  }

  .info {
    .symbol {
      display: flex;
      align-items: center;
      gap: 12px;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;

      img[alt="navi_icon"] {
        width: 36px;
        height: 36px;
      }
      img:not([alt="navi_icon"]) {
        width: 54px;
      }
    }

    .backdrop {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 16px;
    }

    .stats {
      display: flex;
      gap: 16px;
      margin-top: 16px;

      .stat-row {
        color: #ffffff99;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;

        .value {
          color: #90fb75;

          &.skeleton-box {
            width: 50px !important;
          }
        }

        &:last-child {
          padding-left: 16px;
          border-left: 1px solid #ffffff1a;
        }
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const ViewPoolBtn = styled(Link)`
  width: 32px;
  height: 32px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #080715;
  background-color: #90fb75;
  padding: 4px;
  border-radius: 8px;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 12px;
  &:hover {
    background: #2ecf06;
    color: #080715 !important;
  }
`;

const Wrapper = styled.div`
  margin: 48px 0 0;

  @media (max-width: 767px) {
    margin: 36px 0 0;

    div.Tooltip-popup:has(.customTooltip) {
      transform: translateX(-100px);
    }
  }

  &.theme--light {
    .desc {
      color: #0d1a16 !important;
    }

    ${TokenItem} {
      border-color: var(--Border, rgba(13, 26, 22, 0.07));
      background: linear-gradient(90deg, rgba(19, 181, 236, 0.1) 0%, rgba(19, 181, 236, 0) 100%);

      &.arb {
        background: linear-gradient(90deg, rgba(18, 140, 255, 0.1) 0%, rgba(4, 134, 255, 0) 100%);
      }

      .stat-row {
        color: var(--Text-Text_Primary, #0d1a16);

        .value {
          color: var(--Primary, #02b27f);
        }
      }
    }

    ${ViewPoolBtn} {
      background: #02b27f;
    }
  }
`;

export default YieldVault;
