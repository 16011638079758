import { useState, useCallback } from "react";
import { BigNumber } from "ethers";
import cx from "classnames";
import SettingsManager from "abis/SettingsManager.json";
import { USD_DECIMALS, getLiqPrice, importImage } from "lib/legacy";

import { getContract } from "config/contracts";

import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";

import "./PositionSeller.css";
import { contractFetcher } from "lib/contracts";
import { formatAmount, formatNumber, parseValue } from "lib/numbers";
import PercentageButtons from "./PercentageButtons";
import useSWR from "swr";
import { preventSpecialCharacters } from "utils/helpers";
import { useMedia } from "react-use";
import Tooltip from "components/Tooltip/Tooltip";
import GreenArraowIcon from "components/Icon/GreenArraowIcon";
import { useThemeContext } from "contexts/ThemeProvider";
import styled from "styled-components";
export default function AddToPosition(props) {
  const {
    active,
    library,
    onConfirm,
    userTokenBalances,
    isVisible,
    position,
    setIsVisible,
    chainId,
    marketTokensInfo,
    isConfirm,
  } = props;
  const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
  const markPrice = BigNumber.from(position?.isLong ? currentMarket?.askPrice : currentMarket?.bidPrice || 0);

  const [closeQuantity, setCloseQuantity] = useState("");

  const handleSelectPercentageCloseQuantity = (percentage) => {
    if (!userTokenBalances?.gusdBalance || userTokenBalances?.gusdBalance.eq(0)) {
      return;
    }
    const temptValue = userTokenBalances?.gusdBalance.mul(parseValue(percentage, 30)).div(parseValue(1, 30));
    const tempFee = temptValue.mul(parseValue(position.leverage, 30)).mul(8).div(parseValue(10000, 30));
    if (temptValue.add(tempFee).lt(userTokenBalances?.gusdBalance)) {
      setCloseQuantity(formatAmount(temptValue, 30, 6, false));
    } else {
      const maxColateral = userTokenBalances?.gusdBalance
        .mul(parseValue(1, 30))
        .div(parseValue(1 + (Number(position.leverage) * 8) / 10000, 30));
      setCloseQuantity(formatAmount(maxColateral, 30, 6, false));
    }
  };

  const handleConfirm = () => {
    if (parseValue(closeQuantity, 30).eq(position.quantity)) {
      const sizeDelta = parseValue(position.leverage, 30).mul(userTokenBalances?.gusdBalance).div(parseValue(1, 30));
      onConfirm && onConfirm(position, userTokenBalances?.gusdBalance, sizeDelta);
    } else {
      const collateralDelta = newCollateral.sub(parseValue(position.collateral, 30));
      const sizeDelta = newSize.sub(parseValue(position.size, 30));
      onConfirm && onConfirm(position, collateralDelta, sizeDelta);
    }
  };
  const collateral = parseValue(position.collateral, 30);
  const newCollateral = closeQuantity ? collateral.add(parseValue(closeQuantity, 30)) : null;
  const newSize = newCollateral ? newCollateral.mul(parseValue(position.leverage, 30)).div(parseValue(1, 30)) : null;

  const newQuantityDelta =
    closeQuantity && markPrice
      ? parseValue(closeQuantity, 30).mul(parseValue(position.leverage, 30)).div(markPrice)
      : null;
  const newQuantity = newQuantityDelta ? newQuantityDelta.add(position.quantity) : null;
  // console.log("????", {newQuantity,markPrice, position });
  const settingsManagerAddress = getContract(chainId, "SettingsManager");
  const { data: tradingFee } = useSWR(
    newQuantity && [`Exchange:tradingFee:${[active, newQuantity]}`, chainId, settingsManagerAddress, "tradingFee"],
    {
      fetcher: contractFetcher(library, SettingsManager, [position.tokenId, position.isLong]),
    }
  );
  // console.log("????", tradingFee);
  const { data: minCollateral } = useSWR(["ExChange:minCollateral", chainId, settingsManagerAddress, "minCollateral"], {
    fetcher: contractFetcher(library, SettingsManager),
  });
  const { data: liquidateThreshold } = useSWR(
    position.tokenId && [
      `ExChange:liquidateThreshold-${position.posId}`,
      chainId,
      settingsManagerAddress,
      "liquidateThreshold",
      position.tokenId,
    ],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const getErrorCollateral = () => {
    if (!Number(closeQuantity)) {
      return "";
    }

    if (minCollateral && parseValue(closeQuantity, 30).lt(minCollateral)) {
      return `Min order: ${formatAmount(minCollateral, 30, 2)} USD`;
    }
    if (posSize && posFees) {
      const remain = userTokenBalances?.gusdBalance?.sub(parseValue(closeQuantity || 0, 30));
      if (remain && remain.lt(posFees.mul(parseValue(1, 20)))) {
        return "Amount exceeds balance";
        // return `An additional amount of $${formatAmount(
        //   parseValue(closeQuantity || 0, 30)
        //     .add(posFees.mul(parseValue(1, 20)))
        //     .sub(userTokenBalances?.gusdBalance),
        //   30,
        //   2,
        //   true
        // )} is required in your exchange balance to place the trade`;
      }
    }
    return false;
  };
  let posSize;
  if (closeQuantity) posSize = parseValue(formatAmount(newSize.sub(parseValue(position.size, 30)), 30, 2, false), 5);
  let posFees;
  if (tradingFee && newSize) {
    posFees = tradingFee?.mul(posSize) || 0;
  }

  const isDisableButton = () => {
    if (!Number(closeQuantity) || parseValue(closeQuantity, 30).lt(minCollateral)) return true;
    if (posSize && posFees) {
      const remain = userTokenBalances?.gusdBalance?.sub(parseValue(closeQuantity || 0, 30));
      if (remain && remain.lt(posFees.mul(parseValue(1, 20)))) {
        return true;
      }
    }
    return false;
  };
  const renderPrimaryText = () => {
    if (!Number(closeQuantity)) return "Enter an amount";
    if (closeQuantity && isDisableButton()) return getErrorCollateral();
    // if (parseValue(closeQuantity, 30).lt(minCollateral)) return `Min amount: ${formatAmount(minCollateral, 30, 0, true)} USD`
    return isConfirm ? "Increasing..." : "Increase Position";
  };

  const isMobile = useMedia("(max-width: 600px)");

  const handleFormatValue = useCallback(
    (isEURSymbol, value) => {
      const resultVal =
        isMobile && isEURSymbol
          ? formatNumber(formatAmount(value, 30, 2, false), 2)
          : formatNumber(formatAmount(value, 30, 4, false), 4);
      return resultVal;
    },
    [isMobile]
  );
  const newAveragePrice =
    newSize && markPrice
      ? parseValue(position.size, 30)
          .mul(parseValue(position.averagePrice, 30))
          .add(newSize.sub(parseValue(position.size, 30)).mul(markPrice))
          .div(parseValue(position.size, 30).add(newSize.sub(parseValue(position.size, 30))))
      : null;
  const accruedFees = parseValue(position.accruedPositionFee, 30)
    .add(parseValue(position.accruedFundingFee, 30))
    .add(parseValue(position.accruedBorrowFee, 30));

  const newLiquidPrice =
    newSize && markPrice && newAveragePrice
      ? getLiqPrice(liquidateThreshold, newCollateral, newSize, accruedFees, position.isLong, newAveragePrice)
      : null;
  // console.log("?????", closeQuantity && isDisableButton());
  const theme = useThemeContext();
  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal confirmation-modal add-to-position-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Increase Position"}
          allowContentTouchMove
          maxWidth="480px"
        >
          <HeadWrap className="order-title-line order-title-line-details">
            <div className="token">
              <img src={importImage("ic_" + position?.symbol?.toLowerCase() + "_24.svg")} alt="" />{" "}
              <div className="title">{position.symbol}</div>
              <div className="position-id">#{position?.posId}</div>
            </div>

            <div className="position-info-container">
              <div className="position-info">
                <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                  {position?.isLong ? "LONG" : "SHORT"}
                </div>
                <div className="side">
                  {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                </div>
                <div className="leverage-box">{formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X</div>
              </div>
              {/* */}
            </div>
          </HeadWrap>
          <div className="content-container">
            <div className="Exchange-swap-section" style={{ marginBottom: "8px", marginTop: "0px" }}>
              <div className="Exchange-swap-section-top">
                <div className="">
                  <Tooltip
                    handle={`Add Amount`}
                    position="left-bottom"
                    renderContent={() => {
                      return (
                        <span>
                          When you click on the 100% option, your max collateral amount will be estimated based on your
                          free collateral and Open pos. fees
                        </span>
                      );
                    }}
                  />
                </div>
                <div className="muted align-right clickable">
                  <span>
                    Free Collateral: $
                    {userTokenBalances?.gusdBalance
                      ? formatAmount(userTokenBalances?.gusdBalance, USD_DECIMALS, 2, true)
                      : "0.00"}
                  </span>
                </div>
                {/* <div className="muted align-right clickable">
                  <span>Max: {formatAmount(freeQuantity, 30, 4, true)}</span>
                </div> */}
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={closeQuantity}
                    onChange={(e) => setCloseQuantity(e.target.value)}
                    onKeyDown={preventSpecialCharacters}
                  />
                </div>
                <div className="PositionEditor-token-symbol">USD</div>
              </div>
            </div>
            {/* {closeQuantity && isDisableButton() && <div className="validate-error">{getErrorCollateral()}</div>} */}
            <PercentageButtons
              onChangePercentage={handleSelectPercentageCloseQuantity}
              balance={Number(formatAmount(userTokenBalances?.gusdBalance, 30, 6, false))}
              value={closeQuantity}
              decimals={6}
              isUsd={true}
              fullBalance={userTokenBalances?.gusdBalance}
            />
            <div className="PositionEditor-info-box" style={{ marginTop: "16px" }}>
              <div className="square-container square-fee-container">
                <ExchangeInfoRow label={`Mark Price`}>
                  ${formatAmount(markPrice, 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Collateral In`}>USD</ExchangeInfoRow>
                <ExchangeInfoRow label={`Fees`}>
                  <Tooltip
                    handle={<>${posFees ? formatAmount(posFees, 10, 2, true) : "0.00"}</>}
                    position="right-top"
                    className="fit-content custom"
                    renderContent={() => {
                      return (
                        <span>
                          Position Fee (0.08% of position size):{" "}
                          <span className="bold text-white">
                            ${posFees ? formatAmount(posFees, 10, 2, true) : "0.00"}
                          </span>
                        </span>
                      );
                    }}
                  />
                </ExchangeInfoRow>
                {/* <ExchangeInfoRow label={`Fee Discount`}>$--</ExchangeInfoRow> */}
                {/* <ExchangeInfoRow label={`Avg. Entry Price`}>
                  ${formatAmount(parseValue(position.averagePrice, 30), 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow> */}

                <Divider />
                <div className="position-change-line">
                  <div className="position-change-info">Leverage</div>
                  <div className="position-change-info change-value">
                    {formatAmount(parseValue(position.leverage, 30), 30, 2, true)}x
                  </div>
                </div>
                <div className="position-change-line">
                  <div className="position-change-info">Collateral</div>
                  <div className="position-change-info change-value">
                    {newCollateral ? (
                      <span>
                        <span style={{ textDecoration: "line-through", color: "#828899" }}>
                          ${formatNumber(formatAmount(collateral, 30, 2, false), 2)}
                        </span>{" "}
                        <span style={{ position: "relative", top: "2px" }}>
                          {" "}
                          <GreenArraowIcon fill={"#828899"} />
                        </span>{" "}
                        ${formatNumber(formatAmount(newCollateral, 30, 2, false), 2)}
                        {/* <span
                          className={cx("Exchange-list-info-label", {
                            positive: newCollateral.sub(collateral).gt(0),
                            negative: newCollateral.sub(collateral).lt(0),
                          })}
                          style={{ marginLeft: "4px", fontSize: "14px" }}
                        >
                          ({newCollateral.sub(collateral).gte(0) ? "+" : "-"}$
                          {formatNumber(formatAmount(newCollateral.sub(collateral), 30, 2, false))})
                        </span> */}
                      </span>
                    ) : (
                      `$${formatNumber(formatAmount(collateral, 30, 2, false), 2)}`
                    )}
                  </div>
                </div>
                <div className="position-change-line">
                  <div className="position-change-info">Pos. Size</div>
                  <div className="position-change-info change-value">
                    {newSize ? (
                      <span>
                        <span style={{ textDecoration: "line-through", color: "#828899" }}>
                          ${formatNumber(formatAmount(parseValue(position.size, 30), 30, 2, false))}
                        </span>{" "}
                        <span style={{ position: "relative", top: "2px" }}>
                          {" "}
                          <GreenArraowIcon fill={"#828899"} />
                        </span>{" "}
                        ${formatNumber(formatAmount(newSize, 30, 2, false))}
                        {/* <span
                          className={cx("Exchange-list-info-label", {
                            positive: newSize.sub(parseValue(position.size, 30)).gt(0),
                            negative: newSize.sub(parseValue(position.size, 30)).lt(0),
                          })}
                          style={{ marginLeft: "4px", fontSize: "14px" }}
                        >
                          ({newSize.sub(parseValue(position.size, 30)).gte(0) ? "+" : "-"}$
                          {formatNumber(formatAmount(newSize.sub(parseValue(position.size, 30)), 30, 2, false))})
                        </span> */}
                      </span>
                    ) : (
                      `$${formatNumber(formatAmount(parseValue(position.size, 30), 30, 2, false))}`
                    )}
                  </div>
                </div>

                <div className="position-change-line">
                  <div className="position-change-info">
                    <span>Pos. Qty ({position.symbol})</span>
                  </div>
                  <div className="position-change-info change-value">
                    {newQuantityDelta ? (
                      <span>
                        <span style={{ textDecoration: "line-through", color: "#828899" }}>
                          {handleFormatValue(position.symbol === "EUR", position.quantity)}{" "}
                        </span>
                        <span style={{ position: "relative", top: "2px" }}>
                          {" "}
                          <GreenArraowIcon fill={"#828899"} />
                        </span>{" "}
                        {handleFormatValue(position.symbol === "EUR", newQuantity)}
                        {/* <span
                          className={cx("Exchange-list-info-label", {
                            positive: newQuantityDelta.gt(0),
                            negative: newQuantityDelta.lt(0),
                          })}
                          style={{ marginLeft: "4px", fontSize: "14px" }}
                        >
                          ({newQuantityDelta.gte(0) ? "+" : "-"}
                          {handleFormatValue(position.symbol === "EUR", newQuantityDelta)})
                        </span> */}
                      </span>
                    ) : (
                      `${handleFormatValue(position.symbol === "EUR", position.quantity)}`
                    )}
                  </div>
                </div>
                <div className="position-change-line">
                  <div className="position-change-info">Entry Price</div>
                  <div className="position-change-info change-value">
                    {newAveragePrice ? (
                      <span>
                        <span style={{ textDecoration: "line-through", color: "#828899" }}>
                          ${formatAmount(parseValue(position.averagePrice, 30), 30, currentMarket?.decimals || 2, true)}
                        </span>{" "}
                        <span style={{ position: "relative", top: "2px" }}>
                          {" "}
                          <GreenArraowIcon fill={"#828899"} />
                        </span>{" "}
                        ${formatAmount(newAveragePrice, 30, currentMarket?.decimals || 2, true)}
                        {/* <span
                          className={cx("Exchange-list-info-label", {
                            positive: newAveragePrice.sub(parseValue(position.averagePrice, 30)).gt(0),
                            negative: newAveragePrice.sub(parseValue(position.averagePrice, 30)).lt(0),
                          })}
                          style={{ marginLeft: "4px", fontSize: "14px" }}
                        >
                          ({newAveragePrice.sub(parseValue(position.averagePrice, 30)).gte(0) ? "+" : "-"}$
                          {formatAmount(
                            newAveragePrice.sub(parseValue(position.averagePrice, 30)),
                            30,
                            currentMarket?.decimals || 2,
                            true
                          ).replace("-", "")}
                          )
                        </span> */}
                      </span>
                    ) : (
                      `$${formatAmount(parseValue(position.averagePrice, 30), 30, currentMarket?.decimals || 2, true)}`
                    )}
                  </div>
                </div>
                <div className="position-change-line">
                  <div className="position-change-info">Liq. Price</div>
                  <div className="position-change-info change-value">
                    {newLiquidPrice ? (
                      <span>
                        <span style={{ textDecoration: "line-through", color: "#828899" }}>
                          $
                          {formatAmount(
                            parseValue(position.liquidationPrice, 30),
                            30,
                            currentMarket?.decimals || 2,
                            true
                          )}
                        </span>{" "}
                        <span style={{ position: "relative", top: "2px" }}>
                          {" "}
                          <GreenArraowIcon fill={"#828899"} />
                        </span>{" "}
                        ${formatAmount(newLiquidPrice, 30, currentMarket?.decimals || 2, true)}
                        {/* <span
                          className={cx("Exchange-list-info-label", {
                            positive: newLiquidPrice.sub(parseValue(position.liquidationPrice, 30)).gt(0),
                            negative: newLiquidPrice.sub(parseValue(position.liquidationPrice, 30)).lt(0),
                          })}
                          style={{ marginLeft: "4px", fontSize: "14px" }}
                        >
                          ({newLiquidPrice.sub(parseValue(position.liquidationPrice, 30)).gte(0) ? "+" : "-"}$
                          {formatAmount(
                            newLiquidPrice.sub(parseValue(position.liquidationPrice, 30)),
                            30,
                            currentMarket?.decimals || 2,
                            true
                          ).replace("-", "")}
                          )
                        </span> */}
                      </span>
                    ) : (
                      `$${formatAmount(
                        parseValue(position.liquidationPrice, 30),
                        30,
                        currentMarket?.decimals || 2,
                        true
                      )}`
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button
              className="default-btn Exchange-swap-button"
              onClick={handleConfirm}
              disabled={isDisableButton() || isConfirm}
            >
              {renderPrimaryText()}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}

const StyledTag = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: var(--Leverage-Text, #ffc700);
  line-height: 140%;
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Leverage-Background, rgba(255, 199, 0, 0.2));
`;
const Divider = styled.div`
  background: var(--Border, rgba(239, 236, 235, 0.08));
  height: 1px;
  width: 100%;
  margin: 8px 0 12px 0;
`;
const HeadWrap = styled.div`
  flex-direction: column;
  align-items: flex-start;
  .token {
    display: flex;
    gap: 4px;
    align-items: center;
    width: 100%;
  }
`;
