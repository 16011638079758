import { ImageFaucet } from "assets";
import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import Token from "abis/Token.json";
import { callContract, contractFetcher } from "lib/contracts";
import { useChainId } from "lib/chains";
import { getWhitelistedTokens } from "config/tokens";
import { useInfoTokens } from "domain/tokens";
import useSWR from "swr";
import { getContract } from "config/contracts";
import { PLACEHOLDER_ACCOUNT } from "lib/legacy";
import ReaderV2 from "abis/ReaderV2.json";
import { formatAmount } from "lib/numbers";

const tokenListData = [
  {
    symbol: "USDC",
    amount: 200,
    method: "faucet",
  },
  {
    symbol: "BTC",
    amount: 1,
    method: "mint",
  },
  {
    symbol: "USDT",
    amount: 200,
    method: "mint",
  },
  {
    symbol: "ETH",
    amount: 1,
    method: "mint",
  },
];
const Faucet = ({ setWalletModalVisible }: { setWalletModalVisible: (visible: boolean) => void }) => {
  const [isDisable, setIsDisable] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();
  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped && !t.isNative);
  const handleSuscessCapcha = () => {
    setIsDisable(false);
  };
  const readerAddress = getContract(chainId, "Reader");
  const tokenAddresses = whitelistedTokens.map((token) => token.address);

  const { data: tokenBalances }: any = useSWR(
    [`NlpSwap:getTokenBalances:${active}`, chainId, readerAddress, "getTokenBalances", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokenAddresses]),
    }
  );
  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined);

  const dataTokenListMap = useMemo(() => {
    return tokenList.map((token) => {
      const tokenData = tokenListData.find((data) => data.symbol === token.symbol);
      const tokenInfo = infoTokens[token.address];

      return {
        ...token,
        method: tokenData ? tokenData.method : "mint",
        amount: tokenData ? tokenData.amount : "mint",
        balance: tokenInfo.balance,
      };
    });
  }, [tokenListData, tokenList, infoTokens]);

  const onFaucet = (token: typeof dataTokenListMap[0]) => {
    if (!active || !library) {
      console.error("Web3 is not connected");
      return;
    }

    setIsSubmitting(true);
    const contract = new ethers.Contract(token.address, Token.abi, library.getSigner());
    const method = token.symbol === "USDC" ? "faucet" : "mint"; //faucet";

    const params =
      token.symbol === "USDC" ? [] : [account, ethers.utils.parseUnits(token.amount.toString(), token.decimals)];

    callContract(chainId, contract, method, params, {
      sentMsg: `Faucet submitted!`,
      failMsg: `Faucet failed.`,
      successMsg: `Faucet success!`,
    })
      .then(async () => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  useEffect(() => {
    if (active) {
      setWalletModalVisible(false);
    }
  }, [active, setWalletModalVisible]);

  return (
    <Wrapper>
      <div className="wrapper-img">
        <img src={ImageFaucet} alt="" />
        <div className="connect"></div>
      </div>
      <div className="wrapper-box">
        <div className="head">
          <div className="title">Sonic Testnet Faucets</div>
          <div className="desc">Fast and reliable.</div>
        </div>

        {account ? (
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: "left",
                  }}
                >
                  Asset
                </th>
                <th
                  style={{
                    textAlign: "left",
                  }}
                >
                  Wallet Balance
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {dataTokenListMap.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td className="name-faucet">{item.symbol}</td>
                    <td
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {formatAmount(item.balance, item.decimals)}
                    </td>
                    <td>
                      <button onClick={() => onFaucet(item)} className="default-btn">
                        Faucet
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="btn-connect">
            <button className="App-cta Exchange-swap-button" onClick={() => setWalletModalVisible(true)}>
              <span>Connect Wallet</span>
            </button>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default Faucet;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 95px;
  gap: 79px;
  .wrapper-img {
    position: relative;
    height: fit-content;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .connect {
    position: absolute;
    width: 78.152px;
    height: 22.881px;
    flex-shrink: 0;
    border: 2px solid #06002b;
    background: #29d0f0;
    top: 26%;
    right: -33%;
  }
  .wrapper-box {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 24px;
    border: 2px solid #29d0f0;
    min-width: 512px;
    @media screen and (max-width: 1024px) {
      min-width: unset;
    }
  }
  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 36px */
  }
  .desc {
    margin-top: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }
  .box-faucet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .name-faucet {
    color: white;
    text-align: left;
    font-weight: 700;
  }
  .btn-connect {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  table {
    width: 100%;
    margin-top: 20px;
  }

  th,
  td {
    padding: 8px;
    text-align: center;
  }

  th {
    text-align: center;
  }
`;
