import styled from "styled-components";

const StyledWrapperSection = styled.div`
  display: flex;
  gap: 24px;

  /* height: 600px; */

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;

    height: fit-content;

    /* margin-bottom: 80px; */

    flex-direction: column;
    width: 100%;

    .left {
      max-width: 100% !important;
    }
  }

  @media (max-width: 767px) {
    grid-template-rows: 204px 1fr 1fr;

    margin-bottom: 0;
  }

  .left {
    flex: 1;
    max-width: 648px;
  }
`;

const StyledWrapperSectionIpad = styled.div`
  display: flex;

  /* height: 600px; */

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;

    height: fit-content;

    margin-bottom: 80px;

    flex-direction: column;
    width: 100%;

    .left {
      max-width: 100% !important;
    }
  }

  @media (max-width: 767px) {
    grid-template-rows: 204px 1fr 1fr;

    margin-bottom: 0;
  }

  .left {
    flex: 1;
    max-width: 648px;
  }
`;

const StyledVaultAction = styled.div`
  width: 424px;

  @media (max-width: 1023px) {
    width: 100%;

    .btn-deposit {
      width: 100% !important;
    }
  }

  .total-wrapper-action {
    background: #11121b;
    border-radius: 12px;

    padding: 12px;
    margin-bottom: 16px;

    .Available {
      margin-bottom: 13px;
    }
  }

  .Exchange-swap-box-inner {
    padding: 24px;

    @media (max-width: 767px) {
      padding: 16px;
    }

    .group-tabs {
      display: flex;

      overflow: hidden;

      border-radius: 12px;

      .tab {
        cursor: pointer;

        display: flex;
        justify-content: center;

        flex: 1;

        padding: 12px 16px;

        font-weight: 400;
        font-size: 14px;
        line-height: 140%;

        background: #101233;
        color: rgba(255, 255, 255, 0.6);

        &.active {
          background: #90FB75;

          color: #101233;
        }
      }
    }

    .your-balance {
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;

        gap: 4px;

        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: rgba(255, 255, 255, 0.6);

        span {
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;

          color: #ffffff;
        }
      }

      .right {
        a {
          display: flex;
          align-items: center;

          gap: 4px;

          text-decoration: none;

          font-weight: 500;
          font-size: 14px;
          line-height: 140%;

          color: #90FB75;
        }
      }
    }

    .wrapper-input {
      display: flex;
      align-items: center;
      gap: 4px;

      img {
        width: auto;
        width: 24px;
        height: 24px;
      }

      .App-card-title-mark-icon {
        position: relative;
        margin-right: 0.8rem;
        display: flex;
      }

      .App-card-title-mark-icon .selected-network-symbol {
        width: 12px;
        height: 12px;
        position: absolute;
        bottom: 0;
        right: 0;
        border: 1px solid #1e1f37;
        border-radius: 50%;
      }

      .input-balance {
        flex: 1;
        margin-top: 1px;
        gap: 12.5px;

        input {
          font-family: "Roboto";
          width: 100%;
          padding: 0;

          font-size: 16px;

          &::placeholder {
            font-family: "Roboto";

            font-weight: 500;
            font-size: 16px;
            line-height: 140%;

            color: #ffffff;
          }
        }

        div {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;

          color: #ffffff;
        }
      }

      .max-btn {
        cursor: pointer;

        display: flex;
        align-items: center;
        padding-left: 8px;
        padding-right: 8px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;

        /* width: 34px; */
        height: 24px;

        background: #90FB75;
        border-radius: 6px;

        font-weight: 400;
        font-size: 12px;
        line-height: 140%;

        color: #080715;
      }
    }

    .right {
      a {
        color: rgb(52, 245, 174);
      }
    }

    .btn-deposit {
      display: flex;
      justify-content: center;
      align-items: center;

      border: none;

      padding: 8px 16px;
      gap: 4px;
      margin-top: 20px;

      width: 376px;
      height: 40px;

      /* background: #90FB75; */
      /* border-radius: 12px; */

      font-weight: 500;
      font-size: 14px;
      line-height: 140%;

      /* color: #080715; */

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .perfomance-fee {
    margin-top: 10px;

    padding: 24px;
    gap: 16px;

    /* border: 1px solid rgba(255, 255, 255, 0.1); */
    background: #191b2e;
    border-radius: 16px;

    @media (max-width: 767px) {
      padding: 16px;
    }

    .deposit-withdraw-fee {
      display: flex;

      margin-bottom: 16px;

      div {
        display: flex;
        flex-direction: column;

        flex: 1;

        gap: 4px;

        font-weight: 400;
        font-size: 14px;
        line-height: 140%;

        color: rgba(255, 255, 255, 0.6);

        span {
          font-weight: 700;
          font-size: 14px;
          line-height: 140%;

          color: #ffffff;
        }
      }
    }

    .label {
      display: flex;
      align-items: center;

      gap: 4px;

      font-weight: 400;
      font-size: 14px;
      line-height: 140%;

      color: rgba(255, 255, 255, 0.6);

      img {
        margin-top: 3px;
      }
    }

    .percent {
      margin: 4px 0 16px 0;

      font-weight: 700;
      font-size: 14px;
      line-height: 140%;

      color: #ffffff;
    }

    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;

      color: rgba(255, 255, 255, 0.6);
    }

    img {
      opacity: 0.6;
      cursor: pointer;
      pointer-events: all;
    }
  }

  &.theme--light {
    .Exchange-swap-box-inner.App-box-highlight.vault-v2 {
      background: #fff !important;
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

      .Tab {
        border-radius: 12px;
        .Tab-option:not(.active) {
          color: #0d1a16 !important;
        }
      }

      .est {
        color: #0d1a16b2 !important;

        span {
          color: #0d1a16 !important;
        }

        & + div:has(.Tooltip) {
          .Tooltip-handle {
            span {
              color: #0d1a16b2 !important;
            }
          }
        }
      }
    }

    a:has(.redirect-icon) {
      &:hover {
        path {
          fill: #03805b;
        }
      }
    }

    a.link-underline {
      color: var(--Primary, #02b27f);
    }

    div.max-btn {
      background: var(--Primary, #02b27f) !important;
      color: var(--Text-Text_Button, #fff) !important;

      &:hover {
        background: #03805b !important;
      }
    }

    .perfomance-fee {
      background: #fff !important;
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

      .percent {
        color: #0d1a16 !important;
      }

      .deposit-withdraw-fee {
        & > div {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;

          span {
            color: var(--Text-Text_Primary, #0d1a16) !important;
          }
        }
      }

      & > div {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;

        span {
          color: var(--Text-Text_Primary, #0d1a16) !important;
        }
      }
    }

    .link-underline {
      &:hover {
        color: #03805b !important;
      }
    }

    .total-wrapper-action {
      background: var(--Background-Input_Background, rgba(38, 77, 65, 0.07)) !important;

      *,
      input::placeholder {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
      }

      input {
        color: var(--Text-Text_Primary, #0d1a16) !important;
      }

      .wrapper-input {
        span {
          color: var(--Text-Text_Primary, #0d1a16) !important;
        }
      }
    }
  }
`;

const StyledVaultContent = styled.div`
  border: 1px solid #ffffff1a;
  padding: 24px;
  border-radius: 16px;

  @media (max-width: 767px) {
    padding: 16px;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 8px;

    font-size: 16px;
    line-height: 22.4px;

    img {
      width: 40px;
      height: 40px;
    }

    .App-card-title-mark-icon {
      position: relative;
      margin-right: 0;
      display: flex;
    }

    .App-card-title-mark-icon .selected-network-symbol {
      width: 18px;
      height: 18px;
      position: absolute;
      bottom: 0;
      right: 0;
      border: 1px solid #1e1f37;
      border-radius: 50%;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 20px;
    font-weight: 700;

    margin-bottom: 16px;

    .txt-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      color: #90FB75;
      text-decoration: none;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .content {
    color: rgba(255, 255, 255, 0.6);
    line-height: 19.6px;
  }
`;

const StyledVaultInfoSection = styled.div`
  padding: 24px;
  /* background-color: #ffffff1a;
  border: 1px solid #ffffff1a; */
  /* border-radius: 4px; */
  min-width: 320px;
  max-width: unset !important;
  width: 100%;
  background: #191b2e;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  gap: 24px;

  margin-bottom: 24px;

  @media (max-width: 767px) {
    padding: 16px;
  }

  &:nth-child(1) {
    max-width: 536px;
    flex: 1;

    @media (max-width: 767px) {
      flex: unset;
    }
  }

  & > * {
    flex: 1;

    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
  }

  span {
    display: block;
    margin-top: 12px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
  }

  @media (max-width: 1023px) {
    min-width: 264px;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    .TVL,
    .APY,
    .Daily,
    .your-deposit,
    .last-harvest {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        margin-top: 0;
        font-size: 14px;
      }

      .left-bottom {
        left: -125px;
      }
    }
  }
`;

const StyledVaultInfo = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 768px) {
    margin-top: 24px;
  }

  &.theme--light {
    ${StyledVaultContent} {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));

      .header {
        border: none !important;

        a {
          color: var(--Primary, #02b27f) !important;

          &:hover {
            color: #03805b !important;
          }
        }
      }

      .content {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
      }
    }
  }
`;

export {
  StyledWrapperSection,
  StyledWrapperSectionIpad,
  StyledVaultContent,
  StyledVaultInfoSection,
  StyledVaultInfo,
  Wrapper,
  StyledVaultAction,
};
