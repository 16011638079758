import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { createContext, useMemo } from "react";
import useSWR from "swr";

import { getServerUrl } from "config/backendV2";
import { useChainId } from "lib/chains";
import { ACTIVE_CHAIN_IDS, ACTIVE_CHAIN_IDS_V2, arrayURLFetcher, getStatsInfoV2, getStatsInfoV3 } from "lib/legacy";
import { BASE, SONIC_TESTNET, FANTOM, FANTOM_TESTNET, OP, OP_TESTNET } from "config/chains";

const { AddressZero } = ethers.constants;

export const StatsContext = createContext();

const StatsProvider = ({ children }) => {
  const { chainId } = useChainId();
  const { active, library } = useWeb3React();

  // const { data: dataStatsAll } = useSWR(
  //   ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/app-stats")),
  //   {
  //     fetcher: arrayURLFetcher,
  //     refreshInterval: 10000,
  //   },
  // );
  const { data: dataStatsAll } = useSWR(
    ACTIVE_CHAIN_IDS_V2.map((chainId) => getServerUrl(chainId, "/public/app-stats")),
    {
      fetcher: arrayURLFetcher,
      refreshInterval: 10000,
    }
  );

  // const { naviPrice } = useNaviPrice(chainId, {}, active);
  // const { naviPriceFromFantom, naviPriceFromOP, naviPriceFromArbitrum } = useNaviPrice(
  //   chainId,
  //   { arbitrum: chainId === ARBITRUM ? library : undefined, op: chainId === OP ? library : undefined },
  //   active
  // );
  const currentStats = getStatsInfoV3(dataStatsAll);
  // console.log("?????", currentStats);
  // const whitelistedTokens = getWhitelistedTokens(chainId);
  // const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  // const nlpManagerAddress = getContract(chainId, "NlpManager");
  // const nlpManagerAddressFANTOM = getContract(FANTOM, "NlpManager");
  // const nlpManagerAddressOP = getContract(OP, "NlpManager");
  // const nlpManagerAddressARB = getContract(ARBITRUM, "NlpManager");

  // const readerAddress = getContract(chainId, "Reader");
  // const naviAddress = getContract(chainId, "NAVI");
  // const nlpAddress = getContract(chainId, "NLP");
  // const usdgAddress = getContract(chainId, "USDN");
  // const nlpVesterAddress = getContract(chainId, "NlpVester");
  // const naviVesterAddress = getContract(chainId, "NaviVester");
  // const tokensForSupplyQuery = [naviAddress, nlpAddress, usdgAddress];

  // const { data: aums } = useSWR([`Dashboard:getAums`, chainId, nlpManagerAddress, "getAums"], {
  //   fetcher: contractFetcher(library, NlpManager),
  // });

  // const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);

  // let aum;
  // if (aums && aums.length > 0) {
  //   aum = aums[0].add(aums[1]).div(2);
  // }

  // let adjustedUsdgSupply = bigNumberify(0);

  // for (let i = 0; i < tokenList.length; i++) {
  //   const token = tokenList[i];
  //   const tokenInfo = infoTokens[token.address];
  //   if (tokenInfo && tokenInfo.usdgAmount) {
  //     adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
  //   }
  // }

  // const { data: totalSupplies } = useSWR(
  //   [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
  //   {
  //     fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
  //     refreshInterval: 5000,
  //   }
  // );

  // const { data: naviSupplyOfNaviVester } = useSWR(
  //   [`Dashboard:naviSupplyOfNaviVester:${active}`, chainId, naviAddress, "balanceOf", naviVesterAddress],
  //   {
  //     fetcher: contractFetcher(library, Token),
  //     refreshInterval: 5000,
  //   }
  // );

  // const { data: naviSupplyOfNlpVester } = useSWR(
  //   [`Dashboard:naviSupplyOfNlpVester:${active}`, chainId, naviAddress, "balanceOf", nlpVesterAddress],
  //   {
  //     fetcher: contractFetcher(library, Token),
  //     refreshInterval: 5000,
  //   }
  // );

  // const {
  //   total: totalNaviStaked,
  //   fantom: fantomNaviStaked,
  //   fantomlegacy: fantomNaviStakedLegacy,
  //   op: opNaviStaked,
  //   oplegacy: opNaviStakedLegacy,
  //   arbitrum: arbitrumStakedNavi,
  // } = useTotalNaviStaked();
  // let currentChainNaviStaked = fantomNaviStaked;
  // if (chainId === OP) currentChainNaviStaked = opNaviStaked;
  // if (chainId === ARBITRUM) currentChainNaviStaked = arbitrumStakedNavi;

  // const { data: aumsOP } = useSWR([`Dashboard:getAumsOP`, OP, nlpManagerAddressOP, "getAums"], {
  //   fetcher: contractFetcher(library, NlpManager),
  //   refreshInterval: 5000,
  // });

  // const { data: aumsFANTOM } = useSWR([`Dashboard:getAumsFANTOM`, FANTOM, nlpManagerAddressFANTOM, "getAums"], {
  //   fetcher: contractFetcher(library, NlpManager),
  //   refreshInterval: 5000,
  // });
  // const { data: aumsFANTOMlegacy } = useSWR([`Dashboard:getAumsFANTOMlegacy`, FANTOM, "0x304951d7172bCAdA54ccAC1E4674862b3d5b3d5b", "getAums"], {
  //   fetcher: contractFetcher(library, NlpManager),
  //   refreshInterval: 5000,
  // });

  // const { data: aumsArb } = useSWR([`Dashboard:getAumsARB`, ARBITRUM, nlpManagerAddressARB, "getAums"], {
  //   fetcher: contractFetcher(library, NlpManager),
  //   refreshInterval: 5000,
  // });

  // let aumOP;
  // if (aumsOP && aumsOP.length > 0) {
  //   aumOP = aumsOP[0].add(aumsOP[1]).div(2);
  // }

  // let aumFANTOM;
  // if (aumsFANTOM && aumsFANTOM.length > 0) {
  //   aumFANTOM = aumsFANTOM[0].add(aumsFANTOM[1]).div(2);
  // }

  // let aumFANTOMlegacy;
  // if (aumsFANTOMlegacy && aumsFANTOMlegacy.length > 0) {
  //   aumFANTOMlegacy = aumsFANTOMlegacy[0].add(aumsFANTOMlegacy[1]).div(2);
  // }

  // let aumArb;
  // if (aumsArb && aumsArb.length > 0) {
  //   aumArb = aumsArb[0].add(aumsArb[1]).div(2);
  // }

  // let nlpPrice;
  // let nlpSupply;
  // let nlpMarketCap;
  // if (aum && totalSupplies && totalSupplies[3]) {
  //   nlpSupply = totalSupplies[3];
  //   if (naviSupplyOfNaviVester && naviSupplyOfNlpVester) {
  //     nlpSupply = nlpSupply.sub(naviSupplyOfNaviVester).sub(naviSupplyOfNlpVester);
  //   }

  //   nlpPrice =
  //     aum && aum.gt(0) && nlpSupply.gt(0)
  //       ? aum.mul(expandDecimals(1, NLP_DECIMALS)).div(nlpSupply)
  //       : expandDecimals(1, USD_DECIMALS);
  //   nlpMarketCap = nlpPrice.mul(nlpSupply).div(expandDecimals(1, NLP_DECIMALS));
  // }

  // let tvl;
  // if (currentChainNaviStaked && aum && naviPrice) {
  //   tvl = naviPrice.mul(currentChainNaviStaked).div(expandDecimals(1, NAVI_DECIMALS)).add(aum);
  // }

  // let fantomTvl;
  // if (fantomNaviStaked && aumFANTOM && naviPriceFromFantom) {
  //   fantomTvl = naviPriceFromFantom.mul(fantomNaviStaked).div(expandDecimals(1, NAVI_DECIMALS)).add(aumFANTOM);
  // }

  // let fantomLegacyTvl;
  // if (fantomNaviStakedLegacy && aumFANTOMlegacy && naviPriceFromFantom) {
  //   fantomLegacyTvl = naviPriceFromFantom.mul(fantomNaviStakedLegacy).div(expandDecimals(1, NAVI_DECIMALS)).add(aumFANTOMlegacy);
  // }
  // let opTvl;
  // if (opNaviStaked && opNaviStakedLegacy && aumOP && naviPriceFromOP) {
  //   opTvl = naviPriceFromOP.mul(opNaviStaked.add(opNaviStakedLegacy)).div(expandDecimals(1, NAVI_DECIMALS)).add(aumOP);
  // }

  // let arbTvl;
  // if (arbitrumStakedNavi && aumArb && naviPriceFromArbitrum) {
  //   arbTvl = naviPriceFromArbitrum.mul(arbitrumStakedNavi).div(expandDecimals(1, NAVI_DECIMALS)).add(aumArb);
  // }
  // let totalTvl;
  // if (nlpMarketCap && naviPrice && totalNaviStaked && aumOP && aumFANTOM && aumArb && fantomTvl && opTvl && arbTvl && fantomLegacyTvl) {
  //   totalTvl = fantomTvl.add(opTvl).add(arbTvl).add(fantomLegacyTvl)
  // }

  const valueProvider = useMemo(() => {
    return {
      // totalVolume: {
      //   total: currentStats.totalVolume,
      //   [FANTOM_TESTNET]: currentStats[FANTOM_TESTNET]?.totalVolume,
      //   [OP_TESTNET]: currentStats[OP_TESTNET]?.totalVolume,
      // },
      // total24hVolume: {
      //   total: currentStats.totalFees,
      //   [FANTOM_TESTNET]: currentStats[FANTOM_TESTNET]?.totalFees,
      //   [OP_TESTNET]: currentStats[OP_TESTNET]?.totalFees,
      // },
      // openInterest: {
      //   total: currentStats.longOpenInterest,
      //   [FANTOM_TESTNET]: currentStats[FANTOM_TESTNET]?.longOpenInterest,
      //   [OP_TESTNET]: currentStats[OP_TESTNET]?.longOpenInterest,
      // },
      trades: {
        total: currentStats?.trades,
        [SONIC_TESTNET]: currentStats?.[SONIC_TESTNET]?.data?.trades,
      },
      trades24H: {
        total: currentStats.trades24H,
        [SONIC_TESTNET]: currentStats?.[SONIC_TESTNET]?.data?.trades24H,
      },
      volume: {
        total: currentStats?.volume,
        [SONIC_TESTNET]: currentStats?.[SONIC_TESTNET]?.data?.volume,
      },
      volume24H: {
        total: currentStats.volume24H,
        [SONIC_TESTNET]: currentStats?.[SONIC_TESTNET]?.data?.volume24H,
      },
      fees: {
        total: currentStats.fees,
        [SONIC_TESTNET]: currentStats?.[SONIC_TESTNET]?.data?.fees,
      },
      fees24H: {
        total: currentStats.fees24H,
        [SONIC_TESTNET]: currentStats?.[SONIC_TESTNET]?.data?.fees24H,
      },
      realizedPnl24H: {
        total: currentStats.realizedPnl24H,
        [SONIC_TESTNET]: currentStats?.[SONIC_TESTNET]?.data?.realizedPnl24H,
      },
      openInterest: {
        total: currentStats?.openInterest,
        [SONIC_TESTNET]: currentStats?.[SONIC_TESTNET]?.data?.openInterest,
      },
      nslpPrice: {
        total: currentStats?.nslpPrice,
        [SONIC_TESTNET]: currentStats?.[SONIC_TESTNET]?.data?.nslpPrice,
      },
      nslpTvl: {
        total: currentStats?.nslpTvl,
        [SONIC_TESTNET]: currentStats?.[SONIC_TESTNET]?.data?.nslpTvl,
      },
      nslpStaked: {
        total: currentStats?.nslpStaked,
        [SONIC_TESTNET]: currentStats?.[SONIC_TESTNET]?.data?.nslpStaked,
      },
      totalUsers: {
        total: currentStats?.users,
        [SONIC_TESTNET]: currentStats?.[SONIC_TESTNET]?.data?.users,
      },
    };
  }, [currentStats]);
  return <StatsContext.Provider value={valueProvider}>{children}</StatsContext.Provider>;
};

export default StatsProvider;
