import BannerLanding from "containers/Landing/Banner";
import Contact from "containers/Landing/Contact";
import Network from "containers/Landing/Network";
import Protocol from "containers/Landing/Protocol";
import Tokens from "containers/Landing/Tokens";
import VaultLanding from "containers/Landing/Vault";
import styled from "styled-components";

const Landing = () => {
  return (
    <Wrapper>
      <BannerLanding />
      <Protocol />
      {/* <VaultLanding /> */}
      <Network />
      <Tokens />
      <Contact />
    </Wrapper>
  );
};

export default Landing;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 120px;
  width: 100%;
  @media screen and (max-width: 768px) {
    gap: 80px;
  }
`;
