function Icon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? "36"}
      height={props.size ?? "36"}
      fill="none"
      viewBox="0 0 36 36"
    >
      <path fill="#000" d="M4.154 12.461H6.923V33.23H4.154z"></path>
      <path fill="#000" d="M12.462 16.615H15.231V33.23H12.462z"></path>
      <path fill="#000" d="M20.77 9.692H23.539V33.230000000000004H20.77z"></path>
      <path fill="#000" d="M29.077 6.923H31.846000000000004V33.231H29.077z"></path>
    </svg>
  );
}

export default Icon;
