import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import styled from "styled-components";

import "./RouterTab.scss";

import Vest from "pages/Vest";

import nlp from "img/routerTab/nlp.svg";
import navi_esnavi from "img/routerTab/navi_esnavi.svg";
import overview from "img/routerTab/overview.svg";
import transfer_account from "img/routerTab/transfer_account.svg";
import nslp from "img/routerTab/nslp.svg";
import vest from "img/routerTab/vest.svg";
import nlpLight from "img/routerTab/nlpLight.svg";
import navi_esnaviLight from "img/routerTab/navi_esnaviLight.svg";
import overviewLight from "img/routerTab/overviewLight.svg";
import transfer_accountLight from "img/routerTab/transfer_accountLight.svg";
import nslpLight from "img/routerTab/nslpLight.svg";
import vestLight from "img/routerTab/vestLight.svg";

import NlpManager from "abis/NlpManager.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import Vault from "abis/Vault.json";
import Footer from "components/Footer/Footer";
import { getServerUrl } from "config/backend";
import { ARBITRUM, BASE, SONIC_TESTNET, FANTOM, FANTOM_TESTNET, OP, getConstant } from "config/chains";
import { getContract } from "config/contracts";
import { useNaviPrice, useTotalNaviStaked, useTotalEsNaviStaked } from "domain/legacy";
import { BigNumber, ethers } from "ethers";
import { contractFetcher } from "lib/contracts";
import {
  ESNAVI_DECIMALS,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getProcessedData,
  getStakingData,
  getVestingData,
  NAVI_DECIMALS,
  PLACEHOLDER_ACCOUNT,
} from "lib/legacy";
import { bigNumberify, expandDecimals, formatAmount } from "lib/numbers";
import BeginAccountTransfer from "pages/BeginAccountTransfer/BeginAccountTransfer";
import EarnNLP from "pages/Stake/EarnNLP";
import NAVIandESNAVI from "pages/Stake/NAVIandESNAVI";
import EarnOverview from "pages/Stake/Overview";
import { Link, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import useSWR from "swr";
import bg from "img/earn/bg.svg";
import bgLight from "img/earn/bg-light.png";
import Nslp from "pages/Stake/Nslp";
import moment from "moment";
import useTokensPrice from "hooks/useTokensPrice";
import EarnOverviewV2 from "pages/Stake/OverviewV2";
import { useThemeContext } from "contexts/ThemeProvider";

const { AddressZero } = ethers.constants;
const EarnTabs = [
  {
    name: "Overview",
    params: null,
    img: overview,
    imgLight: overviewLight,
    chains: [FANTOM, SONIC_TESTNET, ARBITRUM, BASE, FANTOM_TESTNET],
  },
  {
    name: "NSLP",
    params: "nslp",
    img: nslp,
    imgLight: nslpLight,
    chains: [FANTOM, SONIC_TESTNET, ARBITRUM, BASE, FANTOM_TESTNET],
    newTag: true,
  },
  {
    name: "NAVI & EsNAVI",
    params: "navi-esnavi",
    img: navi_esnavi,
    imgLight: navi_esnaviLight,
    chains: [FANTOM, SONIC_TESTNET, ARBITRUM, BASE, FANTOM_TESTNET],
  },
  {
    name: "NLP",
    params: "nlp",
    img: nlp,
    imgLight: nlpLight,
    chains: [FANTOM, SONIC_TESTNET, ARBITRUM, BASE, FANTOM_TESTNET],
  },
  {
    name: "Vest",
    params: "vest",
    img: vest,
    imgLight: vestLight,
    chains: [FANTOM, SONIC_TESTNET, ARBITRUM, BASE],
  },
  // {
  //   name: "Vault",
  //   params: "vault",
  //   img: vault,
  //   chains: [FANTOM, ARBITRUM] //TODO legacy
  // },
  {
    name: "Transfer account",
    params: "transfer-account",
    img: transfer_account,
    imgLight: transfer_accountLight,
    chains: [FANTOM, SONIC_TESTNET, ARBITRUM, BASE, FANTOM_TESTNET],
  },
];

const now = moment.utc().valueOf() / 1000;

export default function RouterTab({ setPendingTxns, connectWallet }) {
  const { lightThemeClassName, isLightTheme } = useThemeContext();

  let match = useRouteMatch();
  const location = useLocation();
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const rewardRouterAddress = getContract(chainId, "RewardRouter");
  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const naviAddress = getContract(chainId, "NAVI");
  const esNaviAddress = getContract(chainId, "ES_NAVI");
  const bnNaviAddress = getContract(chainId, "BN_NAVI");
  const nlpAddress = getContract(chainId, "NLP");
  const nslpAddress = getContract(chainId, "NSLP");
  const usdgAddress = getContract(chainId, "USDN");

  const stakedNaviTrackerAddress = getContract(chainId, "StakedNaviTracker");
  const bonusNaviTrackerAddress = getContract(chainId, "BonusNaviTracker");
  const feeNaviTrackerAddress = getContract(chainId, "FeeNaviTracker");

  const stakedNlpTrackerAddress = getContract(chainId, "StakedNlpTracker");
  const feeNlpTrackerAddress = getContract(chainId, "FeeNlpTracker");

  const stakedNslpTrackerAddress = getContract(chainId, "StakedNslpTracker");
  const feeNslpTrackerAddress = getContract(chainId, "FeeNslpTracker");

  const nlpManagerAddress = getContract(chainId, "NlpManager");

  const stakedNaviDistributorAddress = getContract(chainId, "StakedNaviDistributor");
  const stakedNlpDistributorAddress = getContract(chainId, "StakedNlpDistributor");
  const stakedNslpDistributorAddress = getContract(chainId, "StakedNslpDistributor");

  const naviVesterAddress = getContract(chainId, "NaviVester");
  const nlpVesterAddress = getContract(chainId, "NlpVester");
  const nslpVesterAddress = getContract(chainId, "NslpVester");

  const vesterAddresses = [naviVesterAddress, nlpVesterAddress, nslpVesterAddress];

  const excludedEsNaviAccounts = [stakedNaviDistributorAddress, stakedNlpDistributorAddress, stakedNslpDistributorAddress];

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");

  const walletTokens = [naviAddress, esNaviAddress, nlpAddress, stakedNaviTrackerAddress, nslpAddress];
  const depositTokens = [
    naviAddress,
    esNaviAddress,
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    bnNaviAddress,
    nlpAddress,
    nslpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedNaviTrackerAddress,
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    feeNaviTrackerAddress,
    feeNaviTrackerAddress,
    feeNlpTrackerAddress,
    feeNslpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    feeNaviTrackerAddress,
    stakedNlpTrackerAddress,
    feeNlpTrackerAddress,
    stakedNslpTrackerAddress,
    feeNslpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}:${walletTokens}:${account}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
      refreshInterval: 10000,
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}:${depositTokens}:${rewardTrackersForDepositBalances}:${account}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
      refreshInterval: 10000,
    }
  );

  const { data: stakingInfo } = useSWR(
    [
      `StakeV2:stakingInfo:${active}:${rewardTrackersForStakingInfo}:${account}`,
      chainId,
      rewardReaderAddress,
      "getStakingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
      refreshInterval: 10000,
    }
  );

  const { data: stakedNaviSupply } = useSWR(
    [
      `StakeV2:stakedNaviSupply:${active}:${stakedNaviTrackerAddress}`,
      chainId,
      naviAddress,
      "balanceOf",
      stakedNaviTrackerAddress,
    ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, nlpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, NlpManager),
    refreshInterval: 10000,
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, Vault),
      refreshInterval: 10000,
    }
  );

  const { data: esNaviSupply } = useSWR(
    [
      `StakeV2:esNaviSupply:${active}:${excludedEsNaviAccounts}:${esNaviAddress}`,
      chainId,
      readerAddress,
      "getTokenSupply",
      esNaviAddress,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [excludedEsNaviAccounts]),
      refreshInterval: 10000,
    }
  );

  const { data: vestingInfo } = useSWR(
    [
      `StakeV2:vestingInfo:${active}:${vesterAddresses}:${account}`,
      chainId,
      readerAddress,
      "getVestingInfo",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
      refreshInterval: 10000,
    }
  );
  const tokensForSupplyQuery = [naviAddress, nlpAddress, usdgAddress];

  const { data: totalSupplies } = useSWR(
    [
      `StakeV2:totalSupplies:${active}:${tokensForSupplyQuery}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
      refreshInterval: 10000,
    }
  );
  const { naviPrice, naviPriceFromFantom } = useNaviPrice(
    chainId,
    { arbitrum: chainId === FANTOM ? library : undefined },
    active
  );

  // const { totalNaviSupply, totalSupply } = useNAVIInfo();
  let totalNaviSupply, totalSupply;
  if (totalSupplies && totalSupplies[1]) {
    totalNaviSupply = totalSupplies[1];
    totalSupply = totalSupplies[1];
  }
  // const naviPrice = BigNumber.from("1000000000000000000000000000000")

  // let { total: totalNaviSupply } = useTotalNaviSupply();
  // let totalNaviSupply = BigNumber.from("10000000000000000000000000");

  let { op: opNaviStaked, fantom: fantomNaviStaked, total: totalNaviStaked } = useTotalNaviStaked();
  let { op: opEsNaviStaked, fantom: fantomEsNaviStaked, total: totalEsNaviStaked } = useTotalEsNaviStaked();

  // const naviSupplyUrl = getServerUrl(chainId, "/navi_supply");
  // const { data: naviSupply } = useSWR([naviSupplyUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.text()),
  // });
  let naviSupply = totalSupply;

  // const isNaviTransferEnabled = true;

  let esNaviSupplyUsd;
  if (esNaviSupply && naviPrice) {
    esNaviSupplyUsd = esNaviSupply.mul(naviPrice).div(expandDecimals(1, 18));
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedNaviSupply,
    naviPrice,
    naviSupply
  );

  let hasMultiplierPoints = false;
  let multiplierPointsAmount;
  if (processedData && processedData.bonusNaviTrackerRewards && processedData.bnNaviInFeeNavi) {
    multiplierPointsAmount = processedData.bonusNaviTrackerRewards.add(processedData.bnNaviInFeeNavi);
    if (multiplierPointsAmount.gt(0)) {
      hasMultiplierPoints = true;
    }
  }
  let totalRewardTokens;
  if (processedData && processedData.bnNaviInFeeNavi && processedData.bonusNaviInFeeNavi) {
    totalRewardTokens = processedData.bnNaviInFeeNavi.add(processedData.bonusNaviInFeeNavi);
  }

  let totalRewardTokensAndNlp;
  if (totalRewardTokens && processedData && processedData.nlpBalance) {
    totalRewardTokensAndNlp = totalRewardTokens.add(processedData.nlpBalance);
  }

  const bonusNaviInFeeNavi = processedData ? processedData.bonusNaviInFeeNavi : undefined;

  let stakedNaviSupplyUsd;
  if (!totalNaviStaked.isZero() && naviPrice) {
    stakedNaviSupplyUsd = totalNaviStaked.mul(naviPrice).div(expandDecimals(1, 18));
  }

  let stakedEsNaviSupplyUsd;
  if (!totalEsNaviStaked.isZero() && naviPrice) {
    stakedEsNaviSupplyUsd = totalEsNaviStaked.mul(naviPrice).div(expandDecimals(1, 18));
  }

  let totalSupplyUsd;
  if (totalNaviSupply && !totalNaviSupply.isZero() && naviPrice) {
    totalSupplyUsd = totalNaviSupply.mul(naviPrice).div(expandDecimals(1, 18));
  }

  let maxUnstakeableNavi = bigNumberify(0);
  if (
    totalRewardTokens &&
    vestingData &&
    vestingData.naviVesterPairAmount &&
    multiplierPointsAmount &&
    processedData.bonusNaviInFeeNavi
  ) {
    const availableTokens = totalRewardTokens.sub(vestingData.naviVesterPairAmount);
    const stakedTokens = processedData.bonusNaviInFeeNavi;
    const divisor = multiplierPointsAmount.add(stakedTokens);
    if (divisor.gt(0)) {
      maxUnstakeableNavi = availableTokens.mul(stakedTokens).div(divisor);
    }
  }
  let earnMsg;
  if (totalRewardTokensAndNlp && totalRewardTokensAndNlp.gt(0)) {
    let naviAmountStr;
    if (processedData.naviInStakedNavi && processedData.naviInStakedNavi.gt(0)) {
      naviAmountStr = formatAmount(processedData.naviInStakedNavi, 18, 2, true) + " NAVI";
    }
    let esNaviAmountStr;
    if (processedData.esNaviInStakedNavi && processedData.esNaviInStakedNavi.gt(0)) {
      esNaviAmountStr = formatAmount(processedData.esNaviInStakedNavi, 18, 2, true) + " EsNAVI";
    }
    let mpAmountStr;
    if (processedData.bonusNaviInFeeNavi && processedData.bnNaviInFeeNavi.gt(0)) {
      mpAmountStr = formatAmount(processedData.bnNaviInFeeNavi, 18, 2, true) + " MP";
    }
    let nlpStr;
    if (processedData.nlpBalance && processedData.nlpBalance.gt(0)) {
      nlpStr = formatAmount(processedData.nlpBalance, 18, 2, true) + " NLP";
    }
    const amountStr = [naviAmountStr, esNaviAmountStr, mpAmountStr, nlpStr].filter((s) => s).join(", ");
    earnMsg = (
      <div>
        <span>
          You are earning {nativeTokenSymbol} rewards with {formatAmount(totalRewardTokensAndNlp, 18, 2, true)} tokens.
          <br />
          Tokens: {amountStr}.
        </span>
      </div>
    );
  }

  //NSLP only for FANTOM
  const rewardsPrice = useTokensPrice({
    naviPrice: naviPriceFromFantom,
    chainId,
  });

  return (
    <Wrapper
      style={
        isLightTheme
          ? { backgroundImage: `url(${bgLight})`, backgroundSize: "contain", backgroundRepeat: "no-repeat" }
          : {}
      }
      className={`RouterTab ${lightThemeClassName}`}
    >
      <div className="total-wrapper-content">
        <div className="router">
          {EarnTabs.filter((x) => x.chains.includes(chainId)).map((item) => (
            <div
              key={item.params}
              className={`earn-tab ${
                (item.params && location.pathname.includes(item.params)) ||
                (!item.params && location.pathname === "/earn-v2/")
                  ? "earn-tab-active"
                  : ""
              } ${item.newTag ? "new-tag" : ""}`}
            >
              <Link to={`/earn-v2/${item.params || ""}`}>
                <img src={isLightTheme ? item.imgLight : item.img} alt={item.params} />
                <span>{item.name}</span>
              </Link>
            </div>
          ))}
        </div>
        <Switch>
          <Route path={`/earn-v2/navi-esnavi`} exact>
            <NAVIandESNAVI
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              processedData={processedData}
              naviPrice={naviPrice}
              stakedNaviSupplyUsd={stakedNaviSupplyUsd}
              stakedEsNaviSupplyUsd={stakedEsNaviSupplyUsd}
              totalNaviSupply={totalNaviSupply}
              totalSupplyUsd={totalSupplyUsd}
              esNaviSupplyUsd={esNaviSupplyUsd}
              esNaviSupply={esNaviSupply}
              hasMultiplierPoints={hasMultiplierPoints}
              vestingData={vestingData}
              maxUnstakeableNavi={maxUnstakeableNavi}
              multiplierPointsAmount={multiplierPointsAmount}
              bonusNaviInFeeNavi={bonusNaviInFeeNavi}
            />
          </Route>
          <Route path={`/earn-v2/nlp`} exact>
            <EarnNLP setPendingTxns={setPendingTxns} connectWallet={connectWallet} processedData={processedData} />
          </Route>
          <Route path={`/earn-v2/vest`} exact>
            <Vest
              totalRewardTokens={totalRewardTokens}
              vestingData={vestingData}
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              processedData={processedData}
            />
          </Route>
          <Route path={match.path} exact>
            {/* <EarnOverview
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              processedData={processedData}
              vestingData={vestingData}
            /> */}
            <EarnOverviewV2
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              processedData={processedData}
              vestingData={vestingData}
              totalNaviSupply={totalNaviSupply}
              stakedNaviSupplyUsd={stakedNaviSupplyUsd}
              totalSupplyUsd={totalSupplyUsd}
              stakedEsNaviSupplyUsd={stakedEsNaviSupplyUsd}
              esNaviSupplyUsd={esNaviSupplyUsd}
              esNaviSupply={esNaviSupply}
              rewardsPrice={rewardsPrice}
            />
          </Route>

          <Route path={`/earn-v2/nslp`} exact>
            <Nslp
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              // isBootstrapPhase={isBootstrapPhase}
              // endTimeBootstrapPhase={endTimeBootstrapPhase}
              rewardsPrice={rewardsPrice}
            />
          </Route>
        </Switch>
      </div>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;

  min-height: 900px;

  background-image: url(${bg});
  background-repeat: no-repeat;
  padding-bottom: 286px;
  background-size: contain;

  .earn-tab {
    opacity: 0.6 !important;

    a span {
      font-weight: 700;
    }

    &.new-tag {
      display: flex;
      align-items: center;
      gap: var(--base-unit-xs-8, 8px);
      position: relative;

      &::after {
        content: "New";
        display: flex;
        padding: 2px var(--Border_radius, 4px);
        justify-content: center;
        align-items: center;
        gap: var(--base-unit-xs-8, 8px);
        border-radius: var(--Border_radius, 4px);
        border: 1px solid var(--Warning, #ffdf76);
        color: var(--Warning, #ffdf76);
        font-size: 12px;
        font-weight: 700;
        line-height: 140%; /* 16.8px */

        @media screen and (max-width: 767px) {
          position: absolute;
          right: -0.953px;
          top: -4px;
          transform: rotate(29.671deg);
        }
      }
    }
  }
  .earn-tab-active {
    span {
      font-weight: 700;
    }
    opacity: 1 !important;
  }
  @media (max-width: 1024px) {
    /* min-height: 1200px; */
    background-size: initial;
    gap: 0;
  }

  @media (max-width: 600px) {
    gap: 0;
  }

  .total-wrapper-content {
    display: flex;

    gap: 24px;

    width: 1320px;
    /* height: fit-content; */

    /* padding: 0 24px; */

    @media (max-width: 1024px) {
      width: 100%;
      padding: 0 24px;
    }

    @media screen and (max-width: 767px) {
      gap: 40px;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      padding: 0;
    }

    .router {
      margin-top: 65px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      width: 163px;

      a {
        cursor: pointer;
        display: flex;
        align-items: center;

        gap: 10px;

        font-weight: 700;
        font-size: 14px;

        color: rgba(255, 255, 255, 0.6);
        text-decoration: none;
      }

      .earn-tab-active {
        a {
          color: white !important;
        }
      }

      @media (max-width: 1023px) {
        margin-top: 64px;
      }

      @media (max-width: 600px) {
        flex-direction: row;
        align-items: center;

        width: 100%;
        height: 95px;
        background: #191b2e;
        padding: 12px;
        margin-top: 0px;
        gap: unset;
        justify-content: space-between;
        .earn-tab {
          padding: 12px;
          opacity: 1 !important;
        }
        .earn-tab-active {
          span {
            font-weight: 700;
          }
          background: rgba(255, 255, 255, 0.1);
          border-radius: 12px;
        }
        div {
          flex: 1;
          a {
            display: flex;

            flex-direction: column;
            gap: 6px;
            opacity: 1;

            img {
              width: 32px;
              height: 32px;
            }

            font-weight: 500;
            font-size: 8px;

            color: #ffffff;

            &:nth-child(2) {
              span {
                max-width: 30px;
              }
            }

            span {
              max-width: 38px;

              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 2048px) {
    height: 100%;
  }
`;
const Warning = styled.div`
  margin: 8px 0;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
`;
