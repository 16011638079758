import { useState, FC, memo, useEffect, useCallback, useMemo, useRef } from "react";
import styled from "styled-components";
import { BigNumber, BigNumberish, ethers } from "ethers";
import { t } from "@lingui/macro";

import Tab from "components/Tab/Tab";
import TokenInput from "components/TokenInput";

import TokenIcon from "img/icons/ic_nslp.svg";
import lock_ic from "img/earn/lock.svg";
import lockLight_ic from "img/earn/lockLight.svg";
import check_ic from "img/earn/check.svg";
import "./Nslp.scss";
import "./NslpTheme.scss";
import AmountAndDollar from "components/AmountAndDollar/AmountAndDollar";
import useSWR from "swr";
import { callContract, contractFetcher } from "lib/contracts";
import { useWeb3React } from "@web3-react/core";
import { getContract } from "config/contracts";
import { DEFAULT_CHAIN_ID, OP, SUPPORTED_CHAIN_ID_NSLP } from "config/chains";
import { expandDecimals, formatAmount, formatNumber, parseValue } from "lib/numbers";

import { ACTIVE_CHAIN_IDS_V2, ACTIVE_CHAIN_IDS_VNAVI, SECONDS_PER_DAY } from "lib/legacy";

import NAVIFarm from "abis/NAVIFarm.json";
import VaultNavi from "abis/VaultNavi.json";
import PriceManager from "abis/PriceManager.json";

import { ClaimVNAVIModal, DepositvNAVIModal, NslpStakeModal, NslpUnstakeModal, WithdrawVNAVIModal } from "./modals/Stakes";
import useNslpContract from "hooks/contracts/useNslpContract";
import useTokenContract from "hooks/contracts/useTokenContract";
import useUsdPrice from "hooks/useUsdPrice";
import useValidateAction from "hooks/useValidateAction";
import useSettingsManagerContract from "hooks/contracts/useSettingsManagerContract";
import moment from "moment";
import { useNaviPrice } from "domain/legacy";
import Tooltip from "components/Tooltip/Tooltip";
import { useMedia } from "react-use";
import { Link } from "react-router-dom";
import useNslpPrice from "hooks/useNslpPrice";
import useNAVIFarmContractMulticall from "hooks/contracts/useNAVIFarmContractMulticall";
import { switchNetwork } from "lib/wallets";
import { useChainIdWithDefault } from "lib/chains";
import useVNAVIContract from "hooks/contracts/usevNAVIContract";
import { omit } from "lodash";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import usePoolRewardersVNAVI from "hooks/contracts/usePoolRewardersVNAVI";
import Countdown from "react-countdown";
import { useThemeContext } from "contexts/ThemeProvider";
import useNslpApr, { SYMBOL_BY_ADDRESS_REWARDS } from "hooks/useNslpApr";
import { getNativeToken } from "config/tokens";
import useInfoNslp from "hooks/useInfoNslp";

type Reward = {
  amount: BigNumber;
  inUsd: BigNumber;
  symbol: string;
  decimal: number;
  tooltip: string;
};

export const MINT_NLP = "Mint NSLP";
export const REDEEM_NLP = "Redeem NSLP";
interface Props {
  setPendingTxns: React.Dispatch<React.SetStateAction<never[]>>;
  connectWallet;
  // isBootstrapPhase: boolean;
  // endTimeBootstrapPhase: BigNumber | undefined;
  rewardsPrice: any;
}

// const now = moment.utc().valueOf() / 1000;

const Nslp: FC<Props> = ({
  setPendingTxns,
  connectWallet,
  // isBootstrapPhase,
  // endTimeBootstrapPhase,
  rewardsPrice,
}) => {
  const { account, active, library } = useWeb3React();
  const chainId = useChainIdWithDefault({
    chains: SUPPORTED_CHAIN_ID_NSLP,
    isUseDefaultChain: false,
  });

  const now = Date.now() / 1000;
  const { lightThemeClassName, isLightTheme } = useThemeContext() as any;
  //countdown show content Pending rewards
  const [isCountdownComplete, setIsCountdownComplete] = useState<boolean>(false);
  const countdownDate = 1716092995991;
  const handleCountdownComplete = () => {
    setIsCountdownComplete(true);
  };

  //Modal Show State
  const [isModalStakeOpen, setIsModalStakeOpen] = useState<boolean>(false);
  const [isModalUnstakeOpen, setIsModalUnstakeOpen] = useState<boolean>(false);

  //Use Contracts
  const { balanceOf: balanceOfNslp, decimals: nslpDecimal } = useNslpContract(chainId, active, account);

  const { price: nslpPrice, totalSupply: totalSupplyNslp } = useInfoNslp(chainId);

  const {
    pendingTokens,
    stakedNlp,
    nlpPoolInfo: [totalStaked],
    poolRewardsPerSec,
    cooldownDuration: [cooldownDuration],
    getUnlockedVestingAmount: [unlockedVestingAmount],
    lockedVestingAmounts: [lockedVestingAmount],
    getTotalVested: [totalVested],
    claimable: [claimable],
    vestingDuration: [vestingDuration],
    poolRewarders: [poolRewardersAddresses],
  } = useNAVIFarmContractMulticall(chainId, active, account, library);

  const [amountStaked, lastClaimTimestamp] = stakedNlp;

  // const nslpPrice = useMemo(() => {
  //   //Calculate NSLP Price
  //   if (totalSupplyNslp && !totalSupplyNslp.eq(0) && vaultUSDBalance) {
  //     const totalSupplyParsed = totalSupplyNslp.mul(parseValue("1", 12)!);

  //     return vaultUSDBalance.mul(parseValue("1", 30)!).div(totalSupplyParsed);
  //   }

  //   // if can not calculate, get price from contract
  //   return nslpPriceFromContract ? nslpPriceFromContract.mul(parseValue("1", 25)!) : undefined;
  // }, [nslpPriceFromContract, totalSupplyNslp, vaultUSDBalance]);

  const amountStakedUsd = useUsdPrice(amountStaked, nslpPrice, 30);
  const balanceOfNslpUsd = useUsdPrice(balanceOfNslp, nslpPrice, 30);
  const totalStakedUsd = useUsdPrice(totalStaked, nslpPrice, 30);
  const totalSupplyNslpUsd = useUsdPrice(totalSupplyNslp, nslpPrice, 30);

  //Memo values
  const nslpTotal = useMemo(() => {
    const amount = amountStaked && balanceOfNslp && amountStaked.add(balanceOfNslp);

    return {
      amount,
      amountUsd: nslpPrice && amount && amount.mul(nslpPrice).div(expandDecimals(1, 30)),
    };
  }, [amountStaked, balanceOfNslp, nslpPrice]);

  // const naviPrice = parseValue(rewardsPrice["NAVI"]?.price, NAVI_DECIMALS)?.mul(parseValue(1, 12)!);

  //APR = ((rewardPrice * rewardPerSec * 365246060) / totalStakedNslpInDollar) * 100
  // const apr = useMemo(() => {
  //   if (totalStakedUsd) {
  //     const [, rewardSymbols, RewardDecimals, rewardPerSecs] = poolRewardsPerSec;
  //     const _totalStakedInDollar = parseFloat(formatAmountFree(totalStakedUsd, Number(18), Number(18))) || 1;

  //     return rewardPerSecs.reduce((acc, cur, index) => {
  //       const rewardPrice = rewardsPrice[rewardSymbols[index]]?.price;

  //       const _rewardPerSec = parseFloat(
  //         formatAmountFree(cur, Number(RewardDecimals[index]), Number(RewardDecimals[index]))
  //       );

  //       const apr = ((+rewardPrice * _rewardPerSec * SECONDS_PER_YEAR) / _totalStakedInDollar) * 100;
  //       return acc + apr;
  //     }, 0);
  //   }
  // }, [poolRewardsPerSec, rewardsPrice, totalStakedUsd]);

  const apr = useNslpApr({
    nslpPrice: nslpPrice,
    rewardsPrice,
    totalStaked,
    poolRewardsPerSec,
  });

  useNaviPrice(chainId, library, active);
  const nativeToken = getNativeToken(chainId);

  // const apr = useMemo(() => {
  //   if (totalStakedUsd) {
  //     const _rewardPerSec = parseFloat(
  //       formatAmountFree(
  //         rewardPerSec,
  //         Number(rewardDecimal || NSLP_DECIMALS),
  //         Number(rewardDecimal || NSLP_DECIMALS),
  //       ),
  //     );
  //     const _totalStakedInDollar =
  //       parseFloat(
  //         formatAmountFree(
  //           totalStakedUsd,
  //           Number(rewardDecimal || NSLP_DECIMALS),
  //           Number(rewardDecimal || NSLP_DECIMALS),
  //         ),
  //       ) || 1;
  //     const result = ((NAVI_PRICE * _rewardPerSec * SECONDS_PER_YEAR) / _totalStakedInDollar) * 100;
  //     return result;
  //   }
  // }, [rewardDecimal, rewardPerSec, totalStakedUsd]);

  // const timelockUnstake =
  //   startTimestamp && cooldownDuration ? (Number(startTimestamp) + Number(cooldownDuration)) * 1000 : 0;

  // const endTimeBootstrapPhaseAndCooldownDuration =
  //   endTimeBootstrapPhase && cooldownDuration ? Number(endTimeBootstrapPhase) + Number(cooldownDuration) : 0;

  const renderTimeText = () => {
    if (!lastClaimTimestamp || lastClaimTimestamp.eq(0) || !cooldownDuration || cooldownDuration.eq(0)) return null;

    const endLockTimestamp = Number(lastClaimTimestamp) + Number(cooldownDuration);
    if (!lastClaimTimestamp.eq(0) && amountStaked.gt(0) && !cooldownDuration.eq(0) && now < endLockTimestamp) {
      return (
        <div className="lock" style={{ backgroundColor: isLightTheme ? "#0D1A1612" : "" }}>
          <img src={isLightTheme ? lockLight_ic : lock_ic} alt="lock_ic" className="lock-icon-styled" />
          <div className="lock-content">
            <span className="label"> Your staked tokens will be unlocked at: </span>
            <span>{moment(endLockTimestamp * 1000).format("HH:mm, ddd, MMM DD YYYY")}.</span>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={`earn-content-container custom ${lightThemeClassName}`}>
      {/* <div className="tab-content-title">NSLP {isBootstrapPhase && <span className="tag">Bootstrap</span>}</div> */}
      <div className="tab-content-title">NSLP</div>
      <div className="tab-content-desc">
        {/* {isBootstrapPhase
          ? "NSLP is liquidity provider token. Earn NAVI directly without vesting."
          : "NSLP is liquidity provider token. Earn NAVI directly without vesting."} */}
        {`NSLP is Navigator Stable Liquidity Provider. Stake NSLP to earn protocol fees and bonus vNAVI (which could be vested without reserve).`}
      </div>
      <div className="tab-main-content">
        <div className="main-content left-content">
          <div className="left-content-border-box">
            <div className="box-main-price">
              <div className="price-info">
                <div className="title">NSLP Price</div>
                <div className="value">
                  {ACTIVE_CHAIN_IDS_VNAVI.includes(chainId) ? (
                    `$${formatAmount(nslpPrice, 30, 3)}`
                  ) : (
                    <div className="skeleton-box" style={{ width: 60 }}></div>
                  )}
                </div>
              </div>
              <img src={TokenIcon} alt="nslp-tokens" />
            </div>
            <div
              className={`btns ${!ACTIVE_CHAIN_IDS_VNAVI.includes(chainId) ? "no-chain" : ""}`}
              style={{ marginTop: 8 }}
            >
              {ACTIVE_CHAIN_IDS_VNAVI.includes(chainId) && (
                <>
                  <Link
                    to="/buy/mint"
                    className="default-btn full"
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    Mint NSLP
                  </Link>
                  <button className="border-btn full">
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "unset",
                        fontWeight: 700,
                      }}
                      to="/buy/redeem"
                    >
                      Redeem NSLP
                    </Link>
                  </button>
                </>
              )}
              {!ACTIVE_CHAIN_IDS_VNAVI.includes(chainId) && (
                <button className="default-btn" onClick={() => switchNetwork(DEFAULT_CHAIN_ID, active)}>
                  Switch to FANTOM
                </button>
              )}
            </div>
            <div className="box-devider">
              <div className="divider-title">NSLP APR</div>
              <div className="divider-line" />
            </div>
            <div className="box-apr">
              {apr && apr.total !== undefined && !isNaN(apr.total) ? (
                <>
                  {/* {apr.total === 0 ? "0.000" : `${formatNumber(apr.total, 2)}`}% */}
                  {Object.keys(omit(apr, "total")).length === 1 ? (
                    <>{apr.total === 0 ? "0.000" : `${formatNumber(apr.total, 2)}%`}</>
                  ) : (
                    <Tooltip
                      handle={`${apr.total === 0 ? "0.000" : `${formatNumber(apr.total, 2)}`}%`}
                      renderContent={() =>
                        Object.entries(apr).map(
                          ([key, value]) =>
                            key !== "total" && (
                              <StatsTooltipRow
                                key={key}
                                label={SYMBOL_BY_ADDRESS_REWARDS[chainId][key.toLowerCase()] + " APR"}
                                showDollar={false}
                                value={`${value === 0 ? "0.000" : `${formatNumber(value, 2)}`}%`}
                              />
                            )
                        )
                      }
                    />
                  )}
                </>
              ) : (
                <div className="skeleton-box" style={{ width: "60px" }} />
              )}
            </div>
            <div className="box-token-info">
              <div className="title">Total Supply</div>
              <div className="value">
                {formatAmount(totalSupplyNslp, nslpDecimal, 0, true)} NSLP{" "}
                <span>${formatAmount(totalSupplyNslpUsd, nslpDecimal, 2, true)}</span>
              </div>
            </div>
            <div className="box-token-info">
              <div className="title">Total Staked</div>
              <div className="value">
                {formatAmount(totalStaked, nslpDecimal, 0, true)} NSLP{" "}
                <span>${formatAmount(totalStakedUsd, nslpDecimal, 2, true)}</span>
              </div>
            </div>
          </div>
          <div className="left-box-content">
            <div className="main-title-container">
              <div className="main-title">NSLP</div>
            </div>
            <div className="box-desc">Stake NSLP to earn {nativeToken?.symbol || "FTM"} and vNAVI.</div>
            {/* <div className="line-divider" /> */}
            <div className="rewards-es-container">
              <div className="account-info">
                <div className="reward-info">
                  <div className="title">Wallet</div>
                  <AmountAndDollar
                    symbol="NSLP"
                    amount={formatAmount(balanceOfNslp, nslpDecimal, 2, true)}
                    dollar={`$${formatAmount(balanceOfNslpUsd, nslpDecimal, 2, true)}`}
                  />
                </div>
                <div className="reward-info">
                  <div className="title">Staked</div>
                  <AmountAndDollar
                    symbol="NSLP"
                    amount={`${formatAmount(amountStaked, nslpDecimal, 2, true)}`}
                    dollar={`$${formatAmount(amountStakedUsd, nslpDecimal, 2, true)}`}
                  />
                </div>
              </div>
              <div className="button-actions">
                {account && (
                  <button onClick={() => setIsModalStakeOpen(true)} className="default-btn">
                    <div>Stake</div>
                  </button>
                )}
                {account && (
                  <button
                    disabled={
                      amountStaked?.eq(0) ||
                      !lastClaimTimestamp ||
                      !cooldownDuration ||
                      now <= Number(lastClaimTimestamp) + Number(cooldownDuration)
                    }
                    onClick={() => setIsModalUnstakeOpen(true)}
                    className="border-btn"
                  >
                    Unstake
                  </button>
                )}
                {!account && (
                  <button className="default-btn" onClick={() => connectWallet()}>
                    Connect Wallet
                  </button>
                )}
              </div>
            </div>
            {renderTimeText()}
          </div>
        </div>
        {/* && !isCountdownComplete */}
        {/* {chainId === OP && !isCountdownComplete ? (
          <CountdownNSLP>
            <div className="title-countdown">Rewards start in </div>
            <div className="content-countdown">
              <Countdown date={countdownDate} onComplete={handleCountdownComplete} />
            </div>
          </CountdownNSLP>
        ) : ( */}
        <div className="main-content right-content no-tooltip">
          {/* <div className={`right-content-border-box ${isBootstrapPhase ? "golden-border" : ""}`}> */}
          <ClaimBox
            chainId={chainId}
            // bootstrapFarmAddress={bootstrapFarmAddress}
            setPendingTxns={setPendingTxns}
            library={library}
            pendingToken={pendingTokens}
            account={account}
            connectWallet={connectWallet}
            // naviPrice={naviPrice}
            // isInLockTime={isBootstrapPhase}
            rewardsPrice={rewardsPrice}
            unlockedVestingAmount={unlockedVestingAmount}
            lockedVestingAmount={lockedVestingAmount}
            totalVested={totalVested}
            claimable={claimable}
            active={active}
            cooldownDuration={cooldownDuration}
            vestingDuration={vestingDuration}
          />
        </div>
        {/* )} */}
      </div>
      <NslpStakeModal
        isVisible={isModalStakeOpen}
        setIsVisible={setIsModalStakeOpen}
        setPendingTxns={setPendingTxns}
        chainId={chainId}
        library={library}
        balanceOf={balanceOfNslp}
        nslpDecimal={nslpDecimal}
        cooldownDuration={cooldownDuration}
        // isBootstrapPhase={isBootstrapPhase}
        // endTimeBootstrapPhase={endTimeBootstrapPhase}
        amountStaked={amountStaked}
      />
      <NslpUnstakeModal
        isVisible={isModalUnstakeOpen}
        setIsVisible={setIsModalUnstakeOpen}
        setPendingTxns={setPendingTxns}
        chainId={chainId}
        library={library}
        amountStaked={amountStaked}
        nslpDecimal={nslpDecimal}
      />
    </div>
  );
};

interface ClaimBoxProps {
  chainId: number;
  // bootstrapFarmAddress: string;
  setPendingTxns: React.Dispatch<React.SetStateAction<never[]>>;
  library: any;
  pendingToken: any;
  account: string | null | undefined;
  connectWallet: () => void;
  // naviPrice: BigNumber | undefined;
  // isInLockTime: boolean;
  rewardsPrice: any;
  unlockedVestingAmount: BigNumber | undefined;
  lockedVestingAmount: BigNumber | undefined;
  totalVested: BigNumber | undefined;
  claimable: BigNumber | undefined;
  active: boolean;
  cooldownDuration: BigNumber | undefined;
  vestingDuration: BigNumber | undefined;
}

const tooltipTexts = {
  vNAVI: "vNAVI from NSLP staking.",
  WFTM: "FTM (WFTM) from NSLP staking.",
  WETH: "ETH (WETH) from NSLP staking.",
};

const symbolRender = {
  WFTM: "FTM (WFTM)",
  WETH: "ETH (WETH)",
};

function ClaimBox({
  chainId,
  // bootstrapFarmAddress,
  setPendingTxns,
  library,
  pendingToken,
  account,
  connectWallet,
  // naviPrice,
  // isInLockTime,
  rewardsPrice,
  unlockedVestingAmount,
  lockedVestingAmount,
  totalVested,
  claimable,
  active,
  cooldownDuration,
  vestingDuration,
}: ClaimBoxProps) {
  const now = useRef(moment.utc().valueOf() / 1000);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const NAVIFarmAddress = getContract(chainId, "NAVIFarm");
  const isMobile = useMedia("(max-width: 767px)");

  const [isModalDepositOpen, setIsModalDepositOpen] = useState<boolean>(false);
  const [isModalWithdrawOpen, setIsModalWithdrawOpen] = useState<boolean>(false);
  const [isModalClaimOpen, setIsModalClaimOpen] = useState<boolean>(false);

  const { balanceOf: balanceOfVNAVI, decimals: vNAVIDecimal } = useVNAVIContract(chainId, active, account);
  const { poolInfo } = usePoolRewardersVNAVI(chainId, active, account, library);

  const [, symbols, decimals, amounts] = pendingToken;

  const rewards = useMemo<Reward[]>(() => {
    if (!symbols || !symbols.length || !decimals || !decimals.length || !amounts || !amounts.length) {
      return [];
    }

    const _rewards = symbols.reduce((acc, cur, index) => {
      const inUsd = rewardsPrice?.[symbols[index]]?.price
        ? amounts[index]
            ?.mul(parseValue(rewardsPrice?.[symbols[index]]?.price, rewardsPrice?.[symbols[index]]?.decimal))
            ?.div(expandDecimals(1, rewardsPrice[symbols[index]]?.decimal))
        : undefined;
      return [
        ...acc,
        {
          symbol: symbolRender[cur] || cur,
          decimal: Number(decimals[index]),
          amount: amounts[index],
          inUsd: amounts[index].eq(0) ? 0 : inUsd,
          tooltip: tooltipTexts[cur] || "",
        },
      ];
    }, []);

    //Check if have duplicate reward
    const rewards = {};

    for (const reward of _rewards) {
      if (rewards.hasOwnProperty(reward.symbol)) {
        const inUsd =
          rewards[reward.symbol].inUsd !== undefined && reward.inUsd !== undefined
            ? BigNumber.from(rewards[reward.symbol].inUsd).add(reward.inUsd)
            : undefined;

        rewards[reward.symbol] = {
          amount: rewards[reward.symbol].amount.add(reward.amount),
          inUsd,
          symbol: reward.symbol,
          decimal: reward.decimal,
          tooltip: reward.tooltip,
        };
      } else {
        rewards[reward.symbol] = {
          amount: reward.amount,
          inUsd: reward.inUsd,
          symbol: reward.symbol,
          decimal: reward.decimal,
          tooltip: reward.tooltip,
        };
      }
    }

    return Object.values(rewards);
  }, [amounts, decimals, rewardsPrice, symbols]);

  const totalRewardUsd = useMemo(() => {
    if (!!rewards.length) {
      return rewards.reduce((acc, cur) => {
        const inUsdByNumber = parseFloat(formatAmount(cur.inUsd, cur.decimal, cur.decimal));
        return acc + inUsdByNumber;
      }, 0);
    }

    return 0;
  }, [rewards]);

  /**
   * Claim Rewards Function
   */
  const claim = useCallback(async () => {
    const _nlp = true;
    const _vesting = false;

    setIsSubmitting(true);

    const contract = new ethers.Contract(NAVIFarmAddress, NAVIFarm.abi, library.getSigner());
    callContract(chainId, contract, "harvestMany", [_nlp, _vesting], {
      sentMsg: t`Claim submitted!`,
      failMsg: t`Claim failed.`,
      successMsg: t`Claimed successfully!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  }, [chainId, library, setIsSubmitting, setPendingTxns, NAVIFarmAddress]);

  //dailyAvgReward = vNAVI user vested / durationVesting (day unit)
  const dailyAvgReward = useMemo(() => {
    if (vestingDuration && lockedVestingAmount) {
      const vestingDurationByDayUnit = Number(vestingDuration) / SECONDS_PER_DAY;
      const result = vestingDurationByDayUnit
        ? lockedVestingAmount.mul(parseValue(1, 18)!).div(parseValue(vestingDurationByDayUnit, 18)!)
        : undefined;
      return result;
    }
  }, [lockedVestingAmount, vestingDuration]);

  const willWithdraw = totalVested && unlockedVestingAmount ? totalVested?.sub(unlockedVestingAmount) : undefined;

  const { naviPrice } = useNaviPrice(chainId, {}, active);

  const unlockedVestingAmountUsd = useUsdPrice(unlockedVestingAmount, naviPrice, 30);
  const claimableUsd = useUsdPrice(claimable, naviPrice, 30);
  const balanceOfVNAVIUsd = useUsdPrice(balanceOfVNAVI, naviPrice, 30);
  const willWithdrawUsd = useUsdPrice(willWithdraw, naviPrice, 30);

  const isVestFinished =
    unlockedVestingAmount && totalVested && unlockedVestingAmount.eq(totalVested) && unlockedVestingAmount.gt(0);

  return (
    <>
      <div className="left-box-content">
        <div className="main-title-container">
          <div className="main-title">
            Pending rewards
            <div className="green-value">${formatAmount(parseValue(totalRewardUsd * 1e18, 12), 30, 2, true)}</div>
          </div>
        </div>
        <div className="rewards-container grid">
          {rewards.length > 0 &&
            rewards.map((rw, index) => {
              return (
                <div className="reward-info" key={rw.symbol}>
                  <div className="title">
                    <Tooltip
                      className="fit-content"
                      handle={rw.symbol || "..."}
                      renderContent={() => rw.tooltip}
                      position={isMobile && (index + 1) % 2 === 0 ? "right-bottom" : "left-top"}
                    />
                  </div>
                  <AmountAndDollar
                    amount={formatAmount(rw.amount, rw.decimal, 4, true)}
                    dollar={`$${formatAmount(rw.inUsd, rw.decimal, 2, true)}`}
                  />
                </div>
              );
            })}
          {rewards.length === 0 &&
            Array(2)
              .fill(null)
              .map((_, i) => (
                <div className="reward-info" key={i} style={{ display: "flex", flexDirection: "column" }}>
                  <div className="title" style={{ display: "flex" }}>
                    <div className="skeleton-box" style={{ width: "65px", height: "19.6px" }} />
                  </div>
                  <div className="skeleton-box" style={{ width: "130px", height: "19.6px" }} />
                </div>
              ))}
        </div>
        <div className={`btns no-chain`}>
          {account && (
            <>
              <div className="actions alone" style={{ marginTop: 0 }}>
                {poolInfo && poolInfo.startTimestamp && now.current < poolInfo.startTimestamp ? (
                  <button className="default-btn full" disabled={true}>
                    Rewards start in{" "}
                    <Countdown
                      key={poolInfo.startTimestamp}
                      date={new Date(Number(poolInfo.startTimestamp) * 1000)}
                      renderer={countdownRenderer}
                      daysInHours
                    />
                  </button>
                ) : (
                  <button
                    className="default-btn full"
                    disabled={totalRewardUsd === 0 || !totalRewardUsd || !poolInfo}
                    onClick={claim}
                  >
                    {isSubmitting ? "Claiming..." : "Claim"}
                  </button>
                )}
              </div>
            </>
          )}

          {!account && (
            <button className="default-btn" onClick={() => connectWallet()}>
              Connect Wallet
            </button>
          )}
        </div>
        {account && (
          <div className="desc">
            Claim your vNAVI in advance then you can vest them. Claiming rewards will reset your lock time to the{" "}
            <>{cooldownDuration ? `${(Number(cooldownDuration) / 60 / 60 / 24).toFixed()}-day` : "--"} </>
            period.
          </div>
        )}
      </div>
      <div className="left-box-content">
        <div className="main-title-container">
          <div className="main-title">
            Vesting overview
            <div className="main-desc">
              Convert your vNAVI to NAVI linearly over a period of{" "}
              <>{vestingDuration ? `${Number(vestingDuration) / 60 / 60 / 24} days` : "--"}.</>
            </div>
          </div>
        </div>
        <div className="vest-container">
          <div className="vest-box">
            <div className="vest-info">
              <div className="vest-box-title">Ready to vest</div>
              <div className="vest-box-amount">{formatAmount(balanceOfVNAVI, 18, 2, true)}</div>
              <div className="vest-box-inUsd">${formatAmount(balanceOfVNAVIUsd, 18, 2, true)}</div>
            </div>
            <button disabled={!account} className="default-btn" onClick={() => setIsModalDepositOpen(true)}>
              Deposit
            </button>
          </div>
          <div className="box-divider" />
          <div className="vest-box">
            <div className="vest-info">
              <div className="vest-box-title">
                <Tooltip
                  handle="Vesting status"
                  position="center-top"
                  renderContent={() => (
                    <>
                      <span className="bold white">{formatAmount(unlockedVestingAmount, 18, 2, true)}</span> tokens have
                      been converted to NAVI from total{" "}
                      <span className="bold white">{formatAmount(totalVested, 18, 2, true)}</span> vNAVI deposited for
                      vesting.
                    </>
                  )}
                />
              </div>
              <div className="vest-box-amount">
                {formatAmount(unlockedVestingAmount, 18, 2, true)}/{formatAmount(totalVested, 18, 2, true)}{" "}
                {isVestFinished && <img src={check_ic} alt="check_ic" />}
              </div>
              <div className="vest-box-inUsd">${formatAmount(unlockedVestingAmountUsd, 18, 2, true)}</div>
            </div>
            <button
              disabled={
                !account ||
                !unlockedVestingAmount ||
                unlockedVestingAmount.eq(0) ||
                !totalVested ||
                unlockedVestingAmount.eq(totalVested)
              }
              className="border-btn"
              onClick={() => setIsModalWithdrawOpen(true)}
            >
              Withdraw
            </button>
          </div>
          <div className="box-divider" />
          <div className="vest-box">
            <div className="vest-info">
              <div className="vest-box-title">
                <Tooltip
                  handle="Claimable NAVI"
                  position="right-top"
                  renderContent={() => (
                    <>
                      <span className="bold white">{formatAmount(claimable, 18, 2, true)}</span> NAVI tokens vested from
                      vNAVI can be claimed.
                    </>
                  )}
                />
              </div>
              <div className="vest-box-amount">{formatAmount(claimable, 18, 2, true)}</div>
              <div className="vest-box-inUsd">${formatAmount(claimableUsd, 18, 2, true)}</div>
            </div>
            <button
              disabled={!account || !claimable || claimable.eq(0)}
              className="default-btn"
              onClick={() => setIsModalClaimOpen(true)}
            >
              Claim
            </button>
          </div>
        </div>
      </div>
      <DepositvNAVIModal
        isVisible={isModalDepositOpen}
        setIsVisible={setIsModalDepositOpen}
        setPendingTxns={setPendingTxns}
        chainId={chainId}
        library={library}
        decimal={vNAVIDecimal}
        balanceOf={balanceOfVNAVI}
        dailyAvgReward={dailyAvgReward}
        totalVested={totalVested}
        isVestFinished={isVestFinished}
      />
      <WithdrawVNAVIModal
        isVisible={isModalWithdrawOpen}
        setIsVisible={setIsModalWithdrawOpen}
        setPendingTxns={setPendingTxns}
        chainId={chainId}
        library={library}
        willWithdraw={willWithdraw}
        willWithdrawUsd={willWithdrawUsd}
      />
      <ClaimVNAVIModal
        isVisible={isModalClaimOpen}
        setIsVisible={setIsModalClaimOpen}
        setPendingTxns={setPendingTxns}
        chainId={chainId}
        library={library}
        claimable={claimable}
        claimableUsd={claimableUsd}
      />
    </>
  );
}

interface ActionBoxProps {
  nslpPrice: BigNumber | undefined;
  chainId: number;
  active: boolean;
  account: string | null | undefined;
  library: any;
  totalMinted: BigNumber;
  setPendingTxns: React.Dispatch<React.SetStateAction<never[]>>;
  connectWallet: () => void;
  nslpDecimal: number;
  balanceOfNslp: BigNumber | undefined;
  totalSupplyNslp: BigNumber | undefined;
  nslpSymbol: string | undefined;
}

function ActionBox({
  nslpPrice,
  chainId,
  active,
  account,
  library,
  totalMinted,
  connectWallet,
  setPendingTxns,
  nslpDecimal,
  balanceOfNslp,
  totalSupplyNslp,
  nslpSymbol,
}: ActionBoxProps) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const vaultNaviAddress = getContract(chainId, "VaultNslp");
  const usdcAddress = getContract(chainId, "USDC");
  const nslpAddress = getContract(chainId, "NSLP");

  const priceManagerAddress = getContract(chainId, "PriceManager");

  // TABS: MINT & REDEEM
  const [activeTab, setActiveTab] = useState(MINT_NLP);

  const TAB_OPTIONS = [MINT_NLP, REDEEM_NLP];
  const TAB_OPTION_LABELS = {
    [MINT_NLP]: t`Mint NSLP`,
    [REDEEM_NLP]: t`Redeem NSLP`,
  };

  // INPUT:
  const [fromValue, setFromValue] = useState<string>("");
  const [toValue, setToValue] = useState<string>("");

  const onFromValueChange = (e) => {
    setFromValue(e.target.value);
  };

  const onToValueChange = (e) => {
    setToValue(e.target.value);
  };

  // DATAS:
  const usdcToken = useTokenContract(chainId, active, account, usdcAddress, vaultNaviAddress);
  const { stakingFee, maxTotalNslp, isStakingEnabled, isUnstakingEnabled } = useSettingsManagerContract(
    chainId,
    active,
    account
  );

  const { data: usdcTokenToUsd } = useSWR<BigNumber>([`Nslp:tokenToUsd`, chainId, priceManagerAddress, "tokenToUsd"], {
    fetcher: contractFetcher(undefined, PriceManager, [usdcAddress, expandDecimals(1, usdcToken.decimals || 18)]),
  });

  //Swap fee = (usdAmount * settingsManager.stakingFee(_token)) / BASIS_POINTS_DIVISOR;
  const usdcSwapFee = useMemo(() => {
    const BASIS_POINTS_DIVISOR = 100000;
    const fromTokenDecimal = activeTab === MINT_NLP ? usdcToken.decimals : nslpDecimal;
    if (fromValue && fromValue !== "0" && stakingFee) {
      const fromAmount = parseValue(fromValue, fromTokenDecimal)!;
      return fromAmount.mul(stakingFee).div(BASIS_POINTS_DIVISOR);
    }
  }, [activeTab, fromValue, stakingFee, usdcToken.decimals, nslpDecimal]);

  useEffect(() => {
    //THIS CALCULATE OUTPUT AMOUNT
    (() => {
      if (!fromValue || fromValue === "0") {
        setToValue("");
        return;
      }
      if (nslpPrice && !nslpPrice.eq(0) && usdcTokenToUsd && usdcSwapFee) {
        const fromValueParsed = parseValue(fromValue, 30);
        const usdcSwapFeeParsed = usdcSwapFee.mul(parseValue(1, 12)!); //to decimal 30
        const feeAmount = usdcSwapFeeParsed.mul(usdcTokenToUsd).div(nslpPrice);

        let toVal;
        let toTokenDecimal = activeTab === REDEEM_NLP ? usdcToken.decimals : nslpDecimal;

        if (activeTab === REDEEM_NLP) {
          toVal = fromValueParsed?.mul(nslpPrice).div(usdcTokenToUsd).sub(feeAmount);
        } else {
          toVal = fromValueParsed?.mul(usdcTokenToUsd).div(nslpPrice).sub(feeAmount);
        }
        setToValue(formatAmount(toVal, 30, toTokenDecimal));
      }
    })();
  }, [nslpPrice, fromValue, activeTab, usdcTokenToUsd, usdcSwapFee, usdcToken.decimals, nslpDecimal]);

  useEffect(() => {
    //THIS RESET FORM WHILE CHANGE TAB
    (() => {
      setFromValue("");
    })();
  }, [activeTab]);

  const nslpAvailableToMint = useMemo(() => {
    if (maxTotalNslp && totalSupplyNslp) {
      return maxTotalNslp.sub(totalSupplyNslp);
    }
  }, [maxTotalNslp, totalSupplyNslp]);

  //If balance > maxUsdcCanUse ==> use maxUsdcCanUse
  const handledMaxUsdcCanUse = useMemo(() => {
    if (nslpAvailableToMint && nslpPrice && usdcTokenToUsd) {
      //maxUsdcCanUse: (maxTotalNslp - totalSupplyNslp) * nslpPrice / usdcPrice
      const parsedUsdcTokenToUsd = usdcTokenToUsd.div(parseValue(1, 12)!);
      const parsedNslpPrice = nslpPrice.div(parseValue(1, 12)!);

      const maxUsdcCanUse = nslpAvailableToMint.mul(parsedNslpPrice).div(parsedUsdcTokenToUsd).div(parseValue(1, 12)!);

      return maxUsdcCanUse && usdcToken.balance.lt(maxUsdcCanUse) ? usdcToken.balance : maxUsdcCanUse;
    }
  }, [nslpAvailableToMint, nslpPrice, usdcToken.balance, usdcTokenToUsd]);

  //actions: mint & redeem

  const { approve, getButtonText, isButtonEnabled, isNeedApproval } = useValidateAction({
    tokenAddress: usdcAddress,
    spender: vaultNaviAddress,
    fromValue,
    maxAmount: usdcToken.balance,
    isSubmitting,
    actionText: ["Mint", "Mint"],
    disabled: [
      [!nslpPrice || nslpPrice.eq(0) || !usdcTokenToUsd, "Fetching price..."],
      [!nslpAvailableToMint || !!parseValue(toValue, nslpDecimal)?.gt(nslpAvailableToMint), "Limit minted amount exceeded"],
      [!isStakingEnabled, "Token not enabled"],
    ],
    fromValueDecimal: usdcToken.decimals,
  });

  const {
    approve: approveRedeem,
    getButtonText: getButtonTextRedeem,
    isButtonEnabled: isButtonEnabledRedeem,
    isNeedApproval: isNeedApprovalRedeem,
  } = useValidateAction({
    tokenAddress: nslpAddress,
    spender: vaultNaviAddress,
    fromValue,
    maxAmount: totalMinted,
    isSubmitting,
    actionText: ["Redeem", "Redeem"],
    fromValueDecimal: nslpDecimal,
    disabled: [
      [!nslpPrice || nslpPrice.eq(0) || !usdcTokenToUsd, "Fetching price..."],
      [!isUnstakingEnabled, "Token not enabled"],
    ],
  });

  const mint = useCallback(async () => {
    if (isNeedApproval) {
      approve();
      return;
    }
    setIsSubmitting(true);

    const contract = new ethers.Contract(vaultNaviAddress, VaultNavi.abi, library.getSigner());
    callContract(chainId, contract, "stake", [account, usdcAddress, parseValue(fromValue, usdcToken.decimals)], {
      sentMsg: `Mint submitted!`,
      failMsg: `Mint failed.`,
      successMsg: `Minted successfully!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsSubmitting(false);
        setFromValue("");
      });
  }, [
    isNeedApproval,
    vaultNaviAddress,
    library,
    chainId,
    account,
    usdcAddress,
    fromValue,
    usdcToken.decimals,
    setPendingTxns,
    approve,
  ]);

  const redeem = useCallback(async () => {
    if (isNeedApprovalRedeem) {
      approveRedeem();
      return;
    }
    setIsSubmitting(true);

    const contract = new ethers.Contract(vaultNaviAddress, VaultNavi.abi, library.getSigner());
    callContract(chainId, contract, "unstake", [usdcAddress, parseValue(fromValue, nslpDecimal)], {
      sentMsg: `Redeem submitted!`,
      failMsg: `Redeem failed.`,
      successMsg: `Redeemed successfully!`,
      setPendingTxns,
    })
      .then(async (res) => {})
      .finally(() => {
        setIsSubmitting(false);
        setFromValue("");
      });
  }, [
    isNeedApprovalRedeem,
    vaultNaviAddress,
    library,
    chainId,
    usdcAddress,
    fromValue,
    nslpDecimal,
    setPendingTxns,
    approveRedeem,
  ]);

  return (
    <div className="left-box-content">
      <Tab1
        options={TAB_OPTIONS}
        optionLabels={TAB_OPTION_LABELS}
        option={activeTab}
        setOption={setActiveTab}
        onChange={setActiveTab}
      />
      {activeTab === MINT_NLP && (
        <>
          <div className="total-amount">
            <div className="title">Total Amount ({usdcToken.symbol || "..."})</div>
            <div className="value">{account ? formatAmount(usdcToken.balance, usdcToken.decimals, 2, true) : "--"}</div>
          </div>
          <TokenInput
            //This balance will be depend on `max mint`
            balance={handledMaxUsdcCanUse}
            defaultTokenSymbol={usdcToken.symbol}
            tokenDecimal={usdcToken.decimals}
            leftLabel="From"
            value={fromValue}
            onChange={onFromValueChange}
            setValue={setFromValue}
            withPercentSelect
            rightLabel={`Balance: ${formatAmount(usdcToken.balance, usdcToken.decimals, 2, true)}`}
          />
          <TokenInput
            defaultTokenSymbol={nslpSymbol || "..."}
            leftLabel="To"
            value={toValue}
            onChange={onToValueChange}
            tokenDecimal={nslpDecimal}
            rightLabel={`Balance: ${formatAmount(balanceOfNslp, nslpDecimal, 2, true)}`}
            disabled
          />
        </>
      )}
      {activeTab === REDEEM_NLP && (
        <>
          <div className="total-amount">
            <div className="title">Total Amount ({nslpSymbol || "..."})</div>
            <div className="value">{account ? formatAmount(totalMinted, nslpDecimal, 2, true) : "--"}</div>
          </div>
          <TokenInput
            defaultTokenSymbol={nslpSymbol || "..."}
            leftLabel="From"
            value={fromValue}
            setValue={setFromValue}
            onChange={onFromValueChange}
            withPercentSelect
            balance={account ? totalMinted : undefined}
            tokenDecimal={nslpDecimal}
            rightLabel={`Balance: ${formatAmount(balanceOfNslp, nslpDecimal, 2, true)}`}
          />
          <TokenInput
            token={usdcToken}
            leftLabel="To"
            value={toValue}
            onChange={onToValueChange}
            rightLabel={`Balance: ${formatAmount(usdcToken.balance, usdcToken.decimals, 2, true)}`}
            disabled
          />
        </>
      )}
      <div className="stats">
        <div className="row">
          <div className="title">NSLP Price</div>
          <div className="value">${formatAmount(nslpPrice, 30, 4)}</div>
        </div>
        <div className="row">
          <div className="title">Swap Fee</div>
          <div className="value">
            {formatAmount(usdcSwapFee, usdcToken.decimals)} {usdcToken.symbol || "..."}
          </div>
        </div>
        {activeTab === MINT_NLP && (
          <div className="row">
            <div className="title">Remaining Mintable Amount</div>
            <div className="value">
              {formatNumber(formatAmount(nslpAvailableToMint, nslpDecimal, 2, false), 4)} {nslpSymbol || "..."}
            </div>
          </div>
        )}
      </div>
      {!account && (
        <button className="default-btn full" style={{ marginTop: 24 }} onClick={() => connectWallet()}>
          Connect Wallet
        </button>
      )}
      {account && activeTab === MINT_NLP && (
        <button onClick={mint} className="default-btn action-btn" disabled={!isButtonEnabled()}>
          {getButtonText()}
        </button>
      )}
      {account && activeTab === REDEEM_NLP && (
        <button onClick={redeem} className="default-btn action-btn" disabled={!isButtonEnabledRedeem()}>
          {getButtonTextRedeem()}
        </button>
      )}
    </div>
  );
}

const Tab1 = styled(Tab)`
  height: 42px;
  text-align: center;
  color: var(--white-100, #fff);
  font-size: 14px;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  border-radius: 12px;
  width: fit-content;
  display: inline-flex;
  border-radius: 12px;
  border: 1px solid var(--white-10, rgba(255, 255, 255, 0.1));
  background: var(--natural-1, #18191a);
  grid-template-columns: 120px 120px;
  user-select: none;
  overflow: hidden;
  box-sizing: content-box;

  * {
    user-select: none;
  }

  > div {
    height: 42px;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box !important;
    border-radius: 10px;

    &:hover {
      all: unset;
    }
  }
`;

const CountdownNSLP = styled.div`
  @media screen and (max-width: 1033px) {
    flex: none;
    width: 100%;
  }
  display: flex;
  flex: 1 1;
  height: 565px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--Nature-1, #191b2e);
  gap: 8px;
  .title-countdown {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
  .content-countdown {
    color: var(--Primary, #90FB75);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`;

const countdownRenderer = (countdownProps) => {
  const {
    formatted: { hours, minutes, seconds },
  } = countdownProps;
  return <>{`${hours}:${minutes}:${seconds}`}</>;
};

export default memo(Nslp);
