import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { memo, useEffect, useState } from "react";
import Countdown from "react-countdown";
import styled from "styled-components";

// import gray_arrow_ic from "img/gray_arrow.svg";
// import gray_arrow_icLight from "img/gray_arrowLight.svg";
import contest from "img/analytics/contest.svg";
import topPnL from "img/analytics/topPnL.svg";
import topPnLLight from "img/analytics/topPnLLight.svg";
import topVolume from "img/analytics/topVolume.svg";
import topVolumeLight from "img/analytics/topVolumeLight.svg";

import downIc from "img/downArrow.svg";
import top1 from "img/top1.svg";
import top2 from "img/top2.svg";
import top2Light from "img/top2Light.svg";
import top3 from "img/top3.svg";
import top3Light from "img/top3Light.svg";

import { commify } from "@ethersproject/units";
import { ADDRESS_ZERO } from "@uniswap/v3-sdk";
import { Select } from "antd";
import ExternalLink from "components/ExternalLink/ExternalLink";
import LinkIcon from "components/Icon/LinkIcon";
import Tooltip from "components/Tooltip/Tooltip";
import { getLocalTokenIcon } from "config/aggregators/tokens";
import { useThemeContext } from "contexts/ThemeProvider";
import useContest from "hooks/useContest";
import { configContest } from "hooks/useLeaderboard";
import useYourNFTId from "hooks/useYourNFTId";
import { PLACEHOLDER_ACCOUNT, shortenAddress } from "lib/legacy";
import { formatAmount, formatNumber, parseValue } from "lib/numbers";
import moment from "moment";
import NoItemFound from "pages/Analytics/components/NoItemFound";
import { useMediaQuery } from "react-responsive";
import { DataTable, SkeletonLoadingTable, TableRow, renderArrows } from "../helper/table";
import PaginationV2 from "../../AnalyticsV3/components/PaginationV2";

const topsImg = [top1, top2, top3];
const topsImgLight = [top1, top2Light, top3Light];
// const TOP_REWARDS = ["2K", "1K", "700", "650", "650"];
const countdownRenderer = (countdownProps) => {
  const { days, hours, minutes, seconds } = countdownProps;
  const d = String(days);
  const h = String(hours);
  const m = String(minutes);
  const s = String(seconds);
  return (
    <div className="time-countdown">
      <div className="time-left">
        {d.padStart(2, "0")}
        {/* <span>d </span> */}
      </div>{" "}
      <div className="space">: </div>
      <div className="time-left">
        {h.padStart(2, "0")}
        {/* <span>h </span> */}
      </div>{" "}
      <div className="space">: </div>
      <div className="time-left">
        {m.padStart(2, "0")}
        {/* <span>m</span>{" "} */}
      </div>{" "}
      <div className="space">: </div>
      <div className="time-left">
        {s.padStart(2, "0")}
        {/* <span>s</span> */}
      </div>
    </div>
  );
};

const PNL_DESC = "pnl DESC";
const PNL_ASC = "pnl ASC";
const VOLUME_DESC = "tradingVolume DESC";
const VOLUME_ASC = "tradingVolume ASC";

const ORDER_BY_OPTIONS = [
  {
    value: PNL_DESC,
    label: "Highest to lowest in Total PnL",
  },
  {
    value: PNL_ASC,
    label: "Lowest to highest in Total PnL",
  },
  {
    value: VOLUME_DESC,
    label: "Highest to lowest in Total Volume",
  },
  {
    value: VOLUME_ASC,
    label: "Lowest to highest in Total volume",
  },
];

const Contest = ({ chainId, isValidChain }) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1023px)" });
  const { account } = useWeb3React();
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const { sort, handleSort, isLoading, data, currentPage, setCurrentPage, rowsPerPage, setRowsPerPage } =
    useContest(chainId);

  // const renderArrows = (field) => {
  //   return (
  //     <div className="group-arrow">
  //       <span>
  //         {sort.field === field && sort.type === "ASC" ? (
  //           <UpArrowIcon fill={isLightTheme ? "#0D1A16" : "#fff"} className="up" />
  //         ) : (
  //           <UpArrowIcon fill={isLightTheme ? "#0D1A16B2" : "#ADABAB"} className="up" />
  //         )}
  //       </span>

  //       <span>
  //         {sort.field === field && sort.type === "DESC" ? (
  //           <UpArrowIcon fill={isLightTheme ? "#0D1A16" : "#fff"} className="down" />
  //         ) : (
  //           <UpArrowIcon fill={isLightTheme ? "#0D1A16B2" : "#ADABAB"} className="down" />
  //         )}
  //       </span>
  //     </div>
  //   );
  // };
  return (
    <Wrapper className={lightThemeClassName}>
      {isValidChain && (
        <>
          <SummaryInfos account={account} chainId={chainId} />
          {isSmallScreen && (
            <div className="filter-block">
              Ordered by:{" "}
              <StyledSelect
                showArrow
                suffixIcon={<img className="downIc" src={downIc} alt="" />}
                onChange={(value) => {
                  const [field, type] = value.split(" ");
                  handleSort(field, type);
                }}
                defaultValue={ORDER_BY_OPTIONS[0]}
                bordered={false}
                options={ORDER_BY_OPTIONS}
                popupClassName={lightThemeClassName}
              />
            </div>
          )}
          <DataTable $noIndex className={`${lightThemeClassName} table-wrapper`}>
            <div className="head">
              <div className="col-name">
                <Tooltip
                  position="left-top"
                  handle={`Wallet`}
                  renderContent={() => (sort.field === "pnl" ? `Ranked by PnL` : `Ranked by Trading Volume`)}
                />
              </div>
              <div className="col-name">Number of Trades</div>
              <div className="col-name" onClick={() => handleSort("tradingVolume")}>
                Total Volume
                {renderArrows("tradingVolume", sort, isLightTheme)}
              </div>
              <div className="col-name" onClick={() => handleSort("pnl")}>
                Total PnL
                {renderArrows("pnl", sort, isLightTheme)}
              </div>
            </div>
            <div className="body">
              {!isLoading &&
                data?.rows?.map((row, i) => {
                  const rank = sort.field === "pnl" ? row.topPnl : row.topVolume;
                  const isPnlPositive = row.pnl > 0;

                  return (
                    <TableRow key={i} noScaleOnHover>
                      {/* <div d-label="Top" className={`col-body top-${rank}`}>
                    {!isLightTheme && (
                      <>{topsImg[rank - 1] ? <img src={topsImg[rank - 1]} alt={`top-${rank}`} /> : rank}</>
                    )}
                    {isLightTheme && (
                      <>{topsImgLight[rank - 1] ? <img src={topsImgLight[rank - 1]} alt={`top-${rank}`} /> : rank}</>
                    )}
                  </div> */}

                      <div d-label="Wallet" className="col-body user">
                        {/* <Avatar address={row.account} size="24px" chainId={chainId} NFTid={undefined} /> */}
                        {shortenAddress(row.account, 13)}
                      </div>
                      <div d-label="Number of Trades" className="col-body">
                        {row.trades}
                      </div>
                      <div d-label="Total Volume" className="col-body">
                        ${formatAmount(parseValue(row.tradingVolume, 30), 30, 2, true)}{" "}
                        <span className="top">#{row.topVolume}</span>
                      </div>
                      <div d-label="PnL" className={`col-body ${isPnlPositive ? "positive" : "negative"}`}>
                        {isPnlPositive ? "+" : "-"}$
                        {formatAmount(parseValue(row.pnl, 30), 30, 2, true).replace("-", "")}
                        <span className="top">#{row.topPnl}</span>
                      </div>
                      {/* <img className="icon-view" src={icView} alt="view_ic" /> */}
                      {rank <= 5 && (
                        <div className={`ribbon ribbon-${rank}`}>
                          {formatNumber(configContest[chainId].TOP_REWARDS[rank - 1])}{" "}
                          {configContest[chainId].REWARD_TOKEN}
                        </div>
                      )}
                    </TableRow>
                  );
                })}
              {!isLoading && data?.rows && data?.rows?.length === 0 && (
                <div className="no-data-card">Contest ranking is updating...</div>
              )}
            </div>
            {isLoading && <SkeletonLoadingTable noHeading />}{" "}
            {!isLoading && data?.rows && data?.rows?.length > 0 && (
              <PaginationV2
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                // setRowsPerPage={setRowsPerPage}
                setCurrentPage={setCurrentPage}
                rows={data?.count}
              />
            )}
          </DataTable>
        </>
      )}
      {!isValidChain && <NoItemFound />}
    </Wrapper>
  );
};

const SummaryInfos = ({ account, chainId }) => {
  const { isLightTheme } = useThemeContext();
  const NFTid = useYourNFTId(PLACEHOLDER_ACCOUNT);
  const [isLoading, setIsLoading] = useState(true);
  const tokenImg = getLocalTokenIcon(configContest[chainId].REWARD_TOKEN);
  const [data, setData] = useState({
    currentAccount: {
      account,
      tradingVolume: undefined,
      pnl: undefined,
      topPnl: undefined,
      topVolume: undefined,
    },
    topVolume: {
      account: undefined,
      tradingVolume: undefined,
      pnl: undefined,
    },
    topPnl: {
      account: undefined,
      tradingVolume: undefined,
      pnl: undefined,
    },
  });

  useEffect(() => {
    (async function fetch() {
      try {
        const { data: response } = await axios(`https://api.navigator.exchange/stats/sonic/contests`, {
          params: {
            account: account ? account.toLowerCase() : ADDRESS_ZERO,
            from: configContest[chainId].START_TIMESTAMP,
            to: configContest[chainId].END_TIMESTAMP,
          },
        });
        setIsLoading(false);
        setData(response.data);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    })();
  }, [account, chainId]);

  return (
    <div className="sumary">
      <div className="timer">
        <div className="timer-heading">
          Trading ConTest
          <ExternalLink
            href={
              configContest[chainId].CONTEST_LINK ||
              "https://medium.com/@navigator/navigatorblitz-is-back-let-the-race-to-the-pyramid-begin-0e4fca75a4b1"
            }
          >
            {/* <img src={isLightTheme ? gray_arrow_icLight : gray_arrow_ic} alt="gray_arrow_ic" /> */}
            <LinkIcon fill={isLightTheme && "#0D1A16"} />
          </ExternalLink>
        </div>
        <div className="timer-desc">
          Secure a position among the top 5 in either TOP TRADING VOLUME or TOP P&L to be eligible for prizes. Forex
          volume counts as 1/3.
        </div>
        <div className="timer-countdown">
          <div className="timer-date">
            Time:{" "}
            <span>
              {moment(configContest[chainId].START_TIMESTAMP * 1000)
                .utc()
                .format("MMM DD, YYYY")}{" "}
              -{" "}
              {moment(configContest[chainId].END_TIMESTAMP * 1000)
                .utc()
                .format("MMM DD, YYYY")}
            </span>
          </div>
          <div className="timer-ends">
            {Math.round(new Date().getTime() / 1000) > configContest[chainId].START_TIMESTAMP ? (
              <>
                Ends in:{" "}
                {Math.round(new Date().getTime() / 1000) > configContest[chainId].END_TIMESTAMP ? (
                  <span style={{ fontWeight: 700 }} className="ended-status">
                    Ended
                  </span>
                ) : (
                  <Countdown
                    date={new Date(configContest[chainId].END_TIMESTAMP * 1000)}
                    renderer={countdownRenderer}
                  />
                )}
              </>
            ) : (
              <>
                Starts in:{" "}
                <Countdown
                  date={new Date(configContest[chainId].START_TIMESTAMP * 1000)}
                  renderer={countdownRenderer}
                />
              </>
            )}
          </div>
        </div>
        <div className="timer-prize">
          <div className="inner">
            <img src={contest} alt="contest" />{" "}
            <div>
              Prize:{" "}
              <span>
                {commify(configContest[chainId].REWARD_AMOUNT)} {configContest[chainId].REWARD_TOKEN}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="contest-info">
        <div className="contest-info-box">
          <div className="contest-info-box-top">
            <div className="contest-info-box-heading">Your Ranking</div>
            <div className="contest-info-box-user">
              {/* <img width={24} height={24} src={isLightTheme ? userLight : user} alt="user" /> */}
              {account ? shortenAddress(account, 13) : "--"}
            </div>
          </div>
          {/* <div className="contest-info-box-row">
            Rank: <span className="green">{account ? `#${data.currentAccount?.topVolume}` : "#--"}</span>
          </div> */}
          <div className="contest-info-box-row">
            Volume:{" "}
            <span>
              {" "}
              {account ? (
                data.currentAccount?.tradingVolume ? (
                  <>${formatAmount(parseValue(data.currentAccount?.tradingVolume, 30), 30, 0, true).replace("-", "")}</>
                ) : (
                  <>
                    {data.currentAccount === null ? (
                      "$0"
                    ) : (
                      <>{isLoading ? <span className="skeleton-box" style={{ width: 80, height: 19.59 }} /> : "$0"}</>
                    )}
                  </>
                )
              ) : (
                "$0"
              )}
            </span>{" "}
            {data.currentAccount?.topVolume && <span className="top">#{data.currentAccount?.topVolume}</span>}
          </div>
          <div className="contest-info-box-row">
            {/* {isPnlPositive ? "+" : "-"}${formatAmount(row.pnl
, 30, 0, true).replace("-", "")} */}
            PnL:{" "}
            <span>
              {account ? (
                data.currentAccount?.pnl ? (
                  <>
                    {data.currentAccount?.pnl > 0 ? "+" : "-"}$
                    {formatAmount(parseValue(data.currentAccount?.pnl, 30), 30, 0, true).replace("-", "")}
                  </>
                ) : (
                  <>
                    {data.currentAccount === null ? (
                      "$0"
                    ) : (
                      <>{isLoading ? <span className="skeleton-box" style={{ width: 80, height: 19.59 }} /> : "$0"}</>
                    )}
                  </>
                )
              ) : (
                "$0"
              )}
            </span>{" "}
            {data.currentAccount?.topPnl && <span className="top">#{data.currentAccount?.topPnl}</span>}
          </div>
        </div>
        <div className="contest-info-box pnl">
          <img src={isLightTheme ? topPnLLight : topPnL} alt="topPnL" />
          <div className="contest-info-box-top">
            <div className="contest-info-box-heading">Top PnL</div>
            <div className="contest-info-box-user">
              {/* <img src={isLightTheme ? userLight : user} alt="user" /> */}
              {/* <Avatar
                address={data.topPnl?.account || PLACEHOLDER_ACCOUNT}
                size="20px"
                chainId={chainId}
                NFTid={NFTid}
              /> */}

              {data.topPnl?.account ? (
                shortenAddress(data.topPnl?.account, 13)
              ) : (
                <>{isLoading ? <span className="skeleton-box" style={{ width: 80, height: 19.59 }} /> : "--"}</>
              )}
            </div>
          </div>
          <div className="contest-info-box-row">
            Reward:{" "}
            <span className="green">
              {commify(configContest[chainId].TOP_REWARDS[0])} <img src={tokenImg} alt="tokenImg" width="24" />
            </span>
          </div>
          <div className="contest-info-box-row">
            Volume:{" "}
            <span>
              {data.topPnl?.tradingVolume ? (
                <>${formatAmount(parseValue(data.topPnl?.tradingVolume, 30), 30, 0, true).replace("-", "")}</>
              ) : (
                <>{isLoading ? <span className="skeleton-box" style={{ width: 80, height: 19.59 }} /> : "$0"}</>
              )}
            </span>
          </div>
          <div className="contest-info-box-row">
            PnL:{" "}
            <span>
              {data.topPnl?.pnl ? (
                <>
                  {data.topPnl?.pnl > 0 ? "+" : "-"}$
                  {formatAmount(parseValue(data.topPnl?.pnl, 30), 30, 0, true).replace("-", "")}
                </>
              ) : (
                <>{isLoading ? <span className="skeleton-box" style={{ width: 80, height: 19.59 }} /> : "$0"}</>
              )}
            </span>
          </div>
        </div>
        <div className="contest-info-box volume">
          <img src={isLightTheme ? topVolumeLight : topVolume} alt="topVolume" />
          <div className="contest-info-box-top">
            <div className="contest-info-box-heading">Top Volume</div>
            <div className="contest-info-box-user">
              {/* <img src={isLightTheme ? userLight : user} alt="user" /> */}
              {/* <Avatar
                address={data.topVolume?.account || PLACEHOLDER_ACCOUNT}
                size="20px"
                chainId={chainId}
                NFTid={NFTid}
              /> */}

              {data.topVolume?.account ? (
                shortenAddress(data.topVolume?.account, 13)
              ) : (
                <>{isLoading ? <span className="skeleton-box" style={{ width: 80, height: 19.59 }} /> : "--"}</>
              )}
            </div>
          </div>
          <div className="contest-info-box-row">
            Reward:{" "}
            <span className="green">
              {commify(configContest[chainId].TOP_REWARDS[0])} <img src={tokenImg} alt="tokenImg" width="24" />
            </span>
          </div>
          <div className="contest-info-box-row">
            Volume:{" "}
            <span>
              {data.topVolume?.tradingVolume ? (
                <>${formatAmount(parseValue(data.topVolume?.tradingVolume, 30), 30, 0, true).replace("-", "")}</>
              ) : (
                <>{isLoading ? <span className="skeleton-box" style={{ width: 80, height: 19.59 }} /> : "$0"}</>
              )}
            </span>
          </div>
          <div className="contest-info-box-row">
            PnL:{" "}
            <span>
              {data.topVolume?.pnl ? (
                <>
                  {data.topVolume?.pnl > 0 ? "+" : "-"}$
                  {formatAmount(parseValue(data.topVolume?.pnl, 30), 30, 0, true).replace("-", "")}
                </>
              ) : (
                <>{isLoading ? <span className="skeleton-box" style={{ width: 80, height: 19.59 }} /> : "$0"}</>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const StyledSelect = styled(Select)`
  width: fit-content;
`;

const Wrapper = styled.div`
  /* max-width: calc(1320px + 48px); */
  /* padding-left: 24px;
  padding-right: 24px; */
  width: 100%;
  margin: 0 auto;
  /* margin-bottom: 286px; */

  /* @media (max-width: 1023px) {
    padding: 0 20px;
    max-width: unset;
  } */
  .col-name {
    font-size: 14px !important;
    color: var(--Text-Text_Secondary, #999794);
    .Tooltip-handle {
      color: var(--Text-Text_Secondary, #999794) !important;
    }
  }
  .table-wrapper {
    border-radius: 16px;
    background: var(--Nature-1, #1a1919);
    padding: 24px;
    @media screen and (max-width: 900px) {
      padding: 0;
      background: transparent;
    }
  }
  .sumary {
    margin-bottom: 24px;
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    .timer {
      width: 536px;
      .ended-status {
        border-radius: var(--Base-Unit-XS-8, 8px);
        display: flex;
        height: 36px;
        padding: 0px 8px;
        background: rgba(239, 236, 235, 0.08);
        flex-direction: column;
        justify-content: center;
        display: flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        color: #fff;
      }
      .timer-heading {
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        line-height: 140%; /* 33.6px */

        display: flex;
        align-items: center;
        gap: var(--Base-Unit-XS-8, 8px);
        margin-bottom: 8px;

        img {
          width: 32px;
          height: 32px;
        }

        a {
          &:hover {
            g {
              opacity: 1;
            }
            path {
              fill: #c1ff99;
            }
          }
        }
      }
      .timer-desc {
        color: #999794;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
      }
      .timer-countdown {
        display: flex;
        align-items: center;
        gap: 32px;
        margin: 16px 0;
      }
      .timer-date {
        color: #999794;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%; /* 19.6px */

        span {
          color: #fff;
          font-size: 14px;
          font-weight: 700;
          line-height: 140%;
        }
      }
      .timer-ends {
        color: #999794;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
        display: flex;
        align-items: center;
        gap: var(--Base-Unit-XS-8, 8px);

        .time-countdown {
          color: #fff;
          font-size: 14px;
          font-weight: 700;
          line-height: 140%; /* 19.6px */

          display: flex;
          justify-content: center;
          align-items: center;
          gap: var(--Border_radius, 4px);

          .time-left {
            display: flex;
            width: 36px;
            height: 36px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: var(--Base-Unit-XS-8, 8px);
            border-radius: var(--Base-Unit-XS-8, 8px);
            background: var(--Border, rgba(255, 255, 255, 0.1));
          }
        }
      }
      .timer-prize {
        display: inline-flex;
        border-radius: var(--Base-Unit-S-12, 12px);
        border: 2px solid #72cc37;

        .inner {
          border-radius: var(--Base-Unit-S-12, 10px);

          padding: var(--Base-Unit-XS-8, 8px) 16px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          gap: var(--Base-Unit-XS-8, 8px);
          color: #999794;
          font-size: 16px;
          font-weight: 700;
          line-height: 140%; /* 22.4px */

          span {
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            line-height: 140%;
          }
        }
      }
    }

    .contest-info {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      flex: 1 0 0;
      align-self: stretch;

      .contest-info-box {
        flex: 1;
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 16px;
        position: relative;
        background: #191919;

        & > img {
          position: absolute;
          right: 0.333px;
          top: -18px;
        }

        &.pnl {
          background: #33301f;
        }

        &.volume {
          background: #342e4d;
        }

        .contest-info-box-top {
          /* flex: 1; */
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--Base-Unit-XS-8, 8px);
          align-self: stretch;

          .contest-info-box-heading {
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            line-height: 140%; /* 19.6px */
          }

          .contest-info-box-user {
            display: flex;
            align-items: center;
            gap: var(--Base-Unit-XS-8, 8px);
            align-self: stretch;
            color: #999794;
            font-size: 14px;
            font-weight: 500;
            line-height: 140%; /* 19.6px */
          }
        }

        .contest-info-box-row {
          color: #999794;
          font-size: 14px;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
          display: flex;
          align-items: center;

          .skeleton-box {
            display: flex;
          }

          .top {
            display: inline-flex;
            padding: 2px var(--Border_radius, 4px);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: var(--Base-Unit-XS-8, 8px);
            border-radius: 6px;
            background: var(--HighLight, rgba(255, 255, 255, 0.1));
            color: #999794;
            font-size: 12px;
            font-weight: 700;
            line-height: 140%; /* 16.8px */
            margin-left: 8px;
          }
        }

        span {
          color: #fff;
          font-size: 14px;
          font-weight: 500;
          line-height: 140%;

          &:not(.top) {
            margin-left: 4px;
          }

          &.green {
            color: #90FB75;
          }

          &:has(img) {
            display: inline-flex;
            align-items: center;
            gap: var(--Border_radius, 4px);
          }
        }
      }
    }
  }

  @media screen and (max-width: 1023px) {
    .sumary {
      margin-bottom: 36px;
      flex-direction: column;
      align-items: stretch;

      .timer {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .sumary {
      gap: 16px;
      margin-bottom: 24px;
      .timer {
        .timer-countdown {
          flex-direction: column;
          align-items: stretch;
          gap: 16px;
        }
      }

      .contest-info {
        flex-direction: column;
        align-items: stretch;
        /* gap: 16px; */

        .contest-info-box {
          padding: 16px;

          & > img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    ${DataTable} {
      .col-body {
        &.user {
          font-size: 14px;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }
      }
    }

    .pagination-analytics {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &.theme--light {
    .ended-status {
      background: rgba(13, 26, 22, 0.07) !important;
      color: #0d1a16 !important;
    }
    *:not(.rowsPerPage *, .movePage *) {
      color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
    }
    .timer-prize .inner {
      background: #f5fcfc !important;
    }

    .timer-heading,
    .timer-date span,
    .time-left,
    .timer-prize span {
      color: #0d1a16 !important;
    }

    .timer-heading {
      a {
        &:hover {
          path {
            fill: #02b27f !important;
          }
        }
      }
    }

    .time-left {
      background: var(--Border, rgba(13, 26, 22, 0.07)) !important;
    }

    .contest-info-box {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;

      .contest-info-box-heading {
        color: #0d1a16 !important;
      }

      &.pnl {
        border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
        background: #fff9d8 !important;
      }

      &.volume {
        border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
        background: #ded6ff !important;
      }
    }

    .contest-info-box-row {
      span {
        color: #0d1a16 !important;

        &.green {
          color: var(--Functional-Green, #02b27f) !important;
        }
      }

      .top {
        background: var(--HighLight, rgba(2, 178, 127, 0.15)) !important;
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
      }
    }

    .ribbon {
      background: #ffcc57 !important;
      box-shadow: 0 0 0 999px #ffcc57 !important;
    }
    .ribbon-2 {
      background: #8fffc3 !important;
      box-shadow: 0 0 0 999px #8fffc3 !important;
    }
    .ribbon-3 {
      background: #92ebff !important;
      box-shadow: 0 0 0 999px #92ebff !important;
    }
    .ribbon-4 {
      background: #fef3bc !important;
      box-shadow: 0 0 0 999px #fef3bc !important;
    }
    .ribbon-5 {
      background: #fef3bc !important;
      box-shadow: 0 0 0 999px #fef3bc !important;
    }
  }
`;

export default memo(Contest);
