import { Select } from "antd";
import Tooltip from "components/Tooltip/Tooltip";
import useLeaderboard, { DEFAULT_SORT, configContest } from "hooks/useLeaderboard";
import downIc from "img/downTriangleIc.svg";
import Pagination from "pages/Analytics/components/Pagination";
import styled from "styled-components";
import "../styles/AnalyticsV2.styled";

import Avatar from "components/Avatar";
import { useThemeContext } from "contexts/ThemeProvider";
import { BigNumber } from "ethers";
import { SECONDS_PER_DAY, shortenAddress } from "lib/legacy";
import { useMediaQuery } from "react-responsive";

import icView from "img/ic-view.svg";
import top1 from "img/top1.svg";
import top2 from "img/top2.svg";
import top2Light from "img/top2Light.svg";
import top3 from "img/top3.svg";
import top3Light from "img/top3Light.svg";

import { formatAmount } from "lib/numbers";
import moment from "moment";
import NoItemFound from "pages/Analytics/components/NoItemFound";
import { useMemo, useState } from "react";
import { DataTable, SkeletonLoadingTable, TableRow, renderArrows } from "../helper/table";
import useTopTrader from "hooks/useTopTrader";
import { commify } from "@ethersproject/units";
import PaginationV2 from "../../AnalyticsV3/components/PaginationV2";

const topsImg = [top1, top2, top3];
const topsImgLight = [top1, top2Light, top3Light];

const PNL_DESC = "pnl DESC";
const PNL_ASC = "pnl ASC";
const VOLUME_DESC = "tradingVolume DESC";
const VOLUME_ASC = "tradingVolume ASC";

const ORDER_BY_OPTIONS = [
  {
    value: PNL_DESC,
    label: "Highest to lowest in PnL",
  },
  {
    value: PNL_ASC,
    label: "Lowest to highest in PnL",
  },
  {
    value: VOLUME_DESC,
    label: "Highest to lowest in Trading Volume",
  },
  {
    value: VOLUME_ASC,
    label: "Lowest to highest in Trading Volume",
  },
];

const MAPPED_BY_KEY = {
  tradingVolume: "Trading Volume",
  pnl: "PnL",
};

const TopTraders = ({ chainId, isValidChain }) => {
  const isSmallDevice = useMediaQuery({ query: "(max-width: 1023px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [from, setFrom] = useState();
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const { sort, handleSort, isLoading, data, currentPage, setCurrentPage, rowsPerPage, setRowsPerPage } = useTopTrader(
    chainId,
    {
      toNow: true,
      from: from || 1698278400,
    }
  );
  // const { sort, handleSort, isLoading, data, currentPage, setCurrentPage, rowsPerPage, setRowsPerPage } =
  //   useLeaderboard(chainId, {
  //     toNow: true,
  //     from: from || 1698278400,
  //   });
  const FILTER_BY_OPTIONS = useMemo(() => {
    return [
      {
        label: "All time",
        value: configContest?.[chainId]?.START_TIMESTAMP,
      },
      {
        label: "24 Hours",
        value: moment().unix() - SECONDS_PER_DAY,
      },
      {
        label: "7 Days",
        value: moment().unix() - SECONDS_PER_DAY * 7,
      },
      {
        label: "1 Month",
        value: moment().unix() - SECONDS_PER_DAY * 30,
      },
    ];
  }, []);

  function getRank(index) {
    if (sort.type === "ASC") {
      const total = data.count;
      return total - index - (currentPage - 1) * rowsPerPage;
    }
    return currentPage * rowsPerPage - rowsPerPage + index + 1;
  }
  const isEmpty = !isLoading && data?.rows?.length === 0;

  return (
    <Wrapper className={lightThemeClassName}>
      {isValidChain && (
        <>
          {!isSmallDevice && (
            <div className="top-filter">
              <StyledSelect1
                showArrow
                suffixIcon={<img className="downIc" src={downIc} alt="" />}
                onChange={(value) => {
                  setFrom(value);
                }}
                defaultValue={FILTER_BY_OPTIONS[0]}
                bordered={false}
                options={FILTER_BY_OPTIONS}
                popupClassName={lightThemeClassName}
                className={lightThemeClassName}
              />
              <div className="last-update">
                Last update: {moment(data.lastUpdateTime ? data.lastUpdateTime * 1000 : Date.now()).fromNow()}
              </div>
            </div>
          )}
          {isSmallDevice && (
            <>
              <div className="filter-block">
                Ordered by:{" "}
                <StyledSelect
                  showArrow
                  suffixIcon={<img className="downIc" src={downIc} alt="" />}
                  onChange={(value) => {
                    const [field, type] = value.split(" ");
                    handleSort(field, type);
                  }}
                  defaultValue={ORDER_BY_OPTIONS.find((opt) => opt.value === DEFAULT_SORT)}
                  bordered={false}
                  options={ORDER_BY_OPTIONS}
                  popupClassName={lightThemeClassName}
                  className={lightThemeClassName}
                />
              </div>
              <div className="filter-block">
                <div className="filter-child">
                  Filtered by:{" "}
                  <StyledSelect1
                    showArrow
                    suffixIcon={<img className="downIc" src={downIc} alt="" />}
                    onChange={(value) => {
                      setFrom(value);
                    }}
                    defaultValue={FILTER_BY_OPTIONS[0]}
                    bordered={false}
                    options={FILTER_BY_OPTIONS}
                    popupClassName={lightThemeClassName}
                    className={lightThemeClassName}
                  />
                </div>
                <div className="last-update">
                  Last update: {moment(data.lastUpdateTime ? data.lastUpdateTime * 1000 : Date.now()).fromNow()}
                </div>
              </div>
            </>
          )}
          {isEmpty ? (
            <NoItemFound />
          ) : (
            <DataTable $rowHeightSkeletonMobile="146.38px" className={`${lightThemeClassName} table-wrapper`}>
              <div className="head">
                <div className="col-name">
                  {/* <Tooltip
                    handle={`Top`}
                    position="center-top"
                    className="fit-content"
                    renderContent={() => {
                      return <div>Sort by {MAPPED_BY_KEY[sort.field]}</div>;
                    }}
                  /> */}
                  Top
                </div>
                <div
                  className="col-name"
                  style={{
                    minWidth: 540,
                  }}
                >
                  Wallet
                </div>
                <div className="col-name">Win/Loss</div>
                <div className="col-name" onClick={() => handleSort("tradingVolume")}>
                  Trading Volume
                  {renderArrows("tradingVolume", sort, isLightTheme)}
                </div>
                <div className="col-name" onClick={() => handleSort("pnl")}>
                  PnL
                  {renderArrows("pnl", sort, isLightTheme)}
                </div>
              </div>
              <div className="body">
                {!isLoading &&
                  data?.rows &&
                  data?.rows?.map((row, i) => {
                    const rank = getRank(i);

                    const isPnlPositive = row.pnl > 0;
                    return (
                      <TableRow
                        key={i}
                        // noScaleOnHover
                        to={`/analytics/trader/${row.account}`}
                      >
                        <div d-label="Top" className={`col-body top-${rank}`}>
                          {!isLightTheme && (
                            <>{topsImg[rank - 1] ? <img src={topsImg[rank - 1]} alt={`top-${rank}`} /> : rank}</>
                          )}
                          {isLightTheme && (
                            <>
                              {topsImgLight[rank - 1] ? <img src={topsImgLight[rank - 1]} alt={`top-${rank}`} /> : rank}
                            </>
                          )}
                        </div>
                        <div
                          d-label="Wallet"
                          className="col-body user col-min-width"
                          style={{
                            minWidth: 540,
                          }}
                        >
                          {/* <Avatar address={row.account} size="24px" chainId={chainId} NFTid={undefined} /> */}
                          {isMobile ? shortenAddress(row.account, 13) : row.account}
                        </div>
                        <div d-label="Win/Loss" className="col-body">
                          {row.win}/{row.loss}
                        </div>
                        <div d-label="Trading Volume" className="col-body">
                          ${commify(row.tradingVolume?.toFixed(2))}
                        </div>
                        <div d-label="PnL" className={`col-body ${isPnlPositive ? "positive" : "negative"}`}>
                          {isPnlPositive ? "+" : "-"}${commify(row.pnl?.toFixed(2).replace("-", ""))}
                        </div>
                        {/* <img className="icon-view" src={icView} alt="view_ic" /> */}
                      </TableRow>
                    );
                  })}
              </div>
              {isLoading && <SkeletonLoadingTable noHeading />}{" "}
              {!isEmpty && (
                <PaginationV2
                  currentPage={currentPage}
                  rowsPerPage={rowsPerPage}
                  // setRowsPerPage={setRowsPerPage}
                  setCurrentPage={setCurrentPage}
                  rows={data?.count}
                />
              )}
            </DataTable>
          )}
        </>
      )}
      {!isValidChain && <NoItemFound />}
    </Wrapper>
  );
};

const StyledSelect1 = styled(Select)`
  &:not(.ant-select-customize-input) .ant-select-selector {
    width: fit-content;
    display: flex;
    height: 40px !important;
    padding: 0px var(--Base-Unit-S-12, 12px) !important;
    justify-content: center;
    align-items: center;
    gap: var(--Base-Unit-XS-8, 8px);
    border-radius: 12px;
    border: 1px solid var(--Border, rgba(255, 255, 255, 0.1)) !important;
    background-color: var(--Nature-1, #191919) !important;
  }

  .ant-select-arrow {
    rotate: 180deg;
  }
`;

const StyledSelect = styled(StyledSelect1)`
  &:not(.ant-select-customize-input) .ant-select-selector {
    height: 44px !important;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  .table-wrapper {
    border-radius: 16px;
    background: var(--Nature-1, #1a1919);
    padding: 24px;

    @media screen and (max-width: 900px) {
      background: transparent;
      padding: 0;
    }
  }
  .top-filter {
    position: absolute;
    right: 0;
    z-index: 20;
    margin: -88px auto 48px;
    gap: 24px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    transform: translateY(50%);
    @media screen and (max-width: 1023px) {
      position: static;
      margin-top: 0;
    }
    @media screen and (max-width: 700px) {
      margin-top: -0px;
      margin-bottom: 24px;
    }
    .ant-select {
      border-radius: 8px;
    }

    .last-update {
      color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
      font-size: 14px;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
    }
  }

  .pagination-analytics {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .ant-select {
      & > .ant-select-selector {
        border-radius: 4px;
      }
    }

    .btn.btn-primary {
      border-radius: var(--Border_radius, 4px);
      border: 1px solid var(--Border, rgba(255, 255, 255, 0.1));
      width: 24px;
      height: 24px;
    }

    .btn-label {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1023px) {
    .filter-block {
      color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
      margin-bottom: 16px;

      &:has(.filter-child) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 24px;

    .top-filter {
      flex-direction: column;
      align-items: flex-start;
    }

    .filter-block {
      display: flex;
      /* flex-direction: column; */
      gap: 8px;
      align-items: center;

      .ant-select-selector {
        padding-left: 8px;
      }
    }
  }

  &.theme--light {
    .ant-select {
    }
    .top-filter {
      .ant-select {
        background: #fff;
      }

      .last-update {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
      }
    }
  }
`;

export default TopTraders;
