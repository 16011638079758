import { useChainIdWithDefault } from "lib/chains";
import { useEffect, useState } from "react";
import styled from "styled-components";
import cx from "classnames";

import ExternalLink from "components/ExternalLink/ExternalLink";
import Footer from "components/Footer/Footer";
import { ARBITRUM, BASE, SONIC_TESTNET, FANTOM, OP, SUPPORTED_V2_CHAINS } from "config/chains";
import { useThemeContext } from "contexts/ThemeProvider.js";
import imgHeader from "img/analytics/img-header.svg";
import rightImg from "img/analytics/right-img.svg";
import { TXT } from "pages/NFT/multichainTxt.js";
import { Link, Route, useLocation, useRouteMatch } from "react-router-dom";
import "styles/AnalyticV1.scss";
import SearchAddress from "./SearchAddress";
import LeaderBoard from "./components/LeaderBoard";
import Oracle from "./components/Oracle.jsx";
import Overview from "./components/Overview.js";
import TopReferrals from "./components/TopReferrals";
import { StyledAnalyticsContent } from "./styles/AnalyticsV3.styled";
import { getV1SubgraphData } from "utils/analyticsV3";
import useAnalyticsOverview from "hooks/analyticsV3/useAnalyticsOverview";
import { THEME_LIGHT } from "lib/legacy";
import { PositionDetail, TopPositionTab, TopTradersTab, TraderDetail } from "pages/AnalyticsNavigator/components";
import { ContestTab } from "pages/AnalyticsNavigator/components";
import TopPositionTabWithLiq from "../AnalyticsNavigator/components/TopPositionTabWithLiq";
import Account from "../AnalyticsNavigator/components/Account";
const NOW_TS = Math.floor(Date.now() / 1000);
const FIRST_DATE_TS = Math.floor(+new Date(2021, 7, 31) / 1000);
export default function AnalyticsV3() {
  const { isLightTheme } = useThemeContext() as any;
  const location = useLocation();
  const { path } = useRouteMatch();
  // const { chainId } = useChainId();
  const chainId = useChainIdWithDefault({
    isUseDefaultChain: false,
  });
  const isValidChain = SUPPORTED_V2_CHAINS.includes(chainId);
  const [activedTab, setActivedTab] = useState(location.pathname);

  useEffect(() => {
    const splited = location.pathname.split("/").filter(Boolean);
    //details page
    if (splited.length > 2) {
      setActivedTab("/" + splited.slice(0, 2).join("/"));
    } else {
      setActivedTab(location.pathname);
    }
  }, [location.pathname]);

  const TABS = [
    {
      label: "Overview",
      networks: [SONIC_TESTNET],
      link: ["/analytics/overview", "/analytics", "/analytics/"],
    },
    {
      label: "Top Traders",
      networks: [SONIC_TESTNET],
      link: ["/analytics/top-traders", "/analytics/trader"],
      hideOn: [],
    },
    {
      label: "Top Positions",
      // networks: [FANTOM],
      link: ["/analytics/top-positions", "/analytics/position"],
      disabled: false,
    },
    // {
    //   label: "Contest",
    //   networks: [SONIC_TESTNET],
    //   hideOn: [ARBITRUM, BASE],
    //   link: ["/analytics/contest"],
    // },
    // {
    //   label: "Search",
    //   // networks: [FANTOM],
    //   link: ["/analytics/search"],
    // },
  ];

  return (
    <div className={`analytic-v1-wrapper ${isLightTheme ? `analytic-v1-wrapper--light ${THEME_LIGHT}` : ""}`}>
      <HeadWraper $isThemeLight={isLightTheme}>
        <div className="analytic-container header">
          <Title>
            <div className="title-container">
              <span className="title-head">Analytics</span>
              {/* <img src={TXT[chainId].icon} alt="img" />{" "} */}
            </div>
            <p className="desc">The comprehensive statistics and data visualization.</p>
          </Title>
        </div>
      </HeadWraper>
      <Wrapper>
        <TabWrapper
          className={cx({
            "wrong-chain": !isValidChain,
          })}
        >
          <div className="tab-container">
            {TABS.map((item) => {
              const hide = item.hideOn && item.hideOn.some((chain) => chainId === chain);
              if (hide) return null;

              if (item.disabled) {
                return (
                  <div className="tab--item tab--disabled">
                    <div>{item.label}</div>
                  </div>
                );
              }

              return (
                <Link
                  onClick={() => setActivedTab(item.link[0])}
                  to={item.link[0]}
                  className={`${item.link.includes(activedTab) ? "tab--item tab--actived" : "tab--item"}`}
                >
                  <div>{item.label}</div>
                </Link>
              );
            })}
          </div>
        </TabWrapper>
        <StyledAnalyticsContent>
          <Route path={`${path}/overview`}>
            <Overview />
          </Route>
          <Route path={`${path}/top-traders`}>
            <TopTradersTab chainId={chainId} isValidChain={isValidChain} />
          </Route>
          <Route path={`${path}/trader/:account`}>
            <Account chainId={chainId} isValidChain={isValidChain} />
          </Route>
          <Route path={`${path}/top-positions`}>
            <TopPositionTab chainId={chainId} isValidChain={isValidChain} />
          </Route>
          <Route path={`${path}/top-positions-liq`}>
            <TopPositionTabWithLiq chainId={chainId} isValidChain={isValidChain} />
          </Route>
          <Route path={`${path}/position/:posId`}>
            <PositionDetail chainId={chainId} isValidChain={isValidChain} />
          </Route>
          {/* <Route path={`${path}/contest`}>
            <ContestTab chainId={chainId} isValidChain={isValidChain} />
          </Route> */}
          {/* <Route path={`${path}/top-positions`}>
            <TopPositionTab chainId={chainId} isThemeLight={isLightTheme} />
          </Route>
          <Route path={`${path}/top-referrals`}>
            <TopReferrals chainId={chainId} isThemeLight={isLightTheme} />
          </Route>
          <Route path={`${path}/loyalty`}>
            <LeaderBoard chainId={chainId} />
          </Route>
          <Route path={`${path}/account/:account`}>
            <Account chainId={chainId} isThemeLight={isLightTheme} />
          </Route>
          <Route path={`${path}/search`}>
            <SearchAddress />
          </Route>
          <Route path={`${path}/oracle`}>
            <Oracle chainId={chainId} />
          </Route> */}
          {/* <Route path={`${path}/contest`}>
            <Contest chainId={chainId} />
          </Route> */}
          {/* <Route path={`${path}/`}>
            <Overview />
          </Route> */}
          <Route exact path={`${path}/`}>
            <Overview />
          </Route>
        </StyledAnalyticsContent>

        <Footer />
      </Wrapper>
    </div>
  );
}
const Wrapper = styled.div`
  .analytic-container {
    width: 100%;
    max-width: calc(1320px + 48px);
    /* padding-left: 24px;
    padding-right: 24px; */
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* @media screen and (max-width: 700px) {
      padding-left: 16px;
      padding-right: 16px;
    } */
  }
`;
const TabWrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: calc(1320px + 48px);
  padding-left: 24px;
  padding-right: 24px;
  margin: 48px auto;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1023px) {
    flex-direction: column;
    margin-block: 0px 24px;
  }

  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 0;

    > .tab-container {
      width: 100%;
    }

    > span {
      padding-left: 20px;
      margin-top: 10px;
      width: 100% !important;
    }
  }

  > span {
    flex: 1 45%;
    text-align: right;
    align-self: flex-end;
    color: #ffffff99 !important;

    @media (max-width: 1023px) {
      margin-top: 16px;
      padding-right: 20px;
    }
  }

  .tab-container {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    background-color: #212224;
    border-radius: var(--Border-Radius-Medium, 12px);
    height: 44px;

    @media screen and (max-width: 1023px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-top: 16px;

      a > div {
        text-align: center;
      }
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    &::-webkit-scrollbar {
      height: 2px;
    }

    &::-webkit-scrollbar-track-piece:end {
      margin-right: 10px;
    }

    &::-webkit-scrollbar-track-piece:start {
      margin-left: 10px;
    }

    @media (max-width: 1023px) {
      justify-content: space-between;
    }
  }

  .tab--item {
    padding: 12px 16px;
    white-space: nowrap;

    font-size: 14px;
    text-decoration: none;
    font-weight: 500 !important;
    color: rgb(255, 255, 255);
    border: 2px solid transparent;
    color: #828899;

    &.tab--disabled {
      cursor: not-allowed;
    }

    cursor: pointer;
    &:not(.tab--disabled):hover {
      color: rgb(255, 255, 255);
    }
    &.tab--actived {
      color: rgb(255, 255, 255);
      border-radius: var(--Border-Radius-Medium, 12px);
      border: 2px solid #41454d;
      background: var(--Nature-3, #2e3033);
    }
  }
`;
const TabItem = styled(Link)``;
const Title = styled.div`
  width: 1218px;
  max-width: 100%;
  text-transform: uppercase;
  align-self: flex-start;
  margin-top: 48px;

  @media (max-width: 767px) {
    padding-bottom: 24px;
  }

  .title-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    @media (max-width: 1023px) {
      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  span {
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;
    color: rgb(255, 255, 255);

    @media (max-width: 1023px) {
      font-size: 24px;
      line-height: 140%;
    }
  }
  .version-styled {
    color: #03f5ae !important;
  }

  img {
    width: 48px;
    height: 48px;
  }

  .desc {
    text-transform: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.6) !important;
    margin-top: 4px;
    margin-bottom: 0;
  }
`;
const HeadWraper = styled.div<{ $isThemeLight: Boolean }>`
  width: 100%;
  overflow: hidden;
  position: relative;
  @media (max-width: 767px) {
    height: unset;
  }

  .header {
    width: 100%;
    max-width: calc(1320px + 48px);
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;
    display: flex;
    position: relative;

    justify-content: space-between;
    align-items: flex-end;
    height: 100%;

    @media (max-width: 767px) {
      flex-direction: column;
      padding-inline: 16px;
    }
  }
`;
const ImgContainer = styled.div`
  width: 376px;
  height: 225px;
  overflow: hidden;
  object-fit: contain;
  margin-left: auto;
  flex-shrink: 0;

  @media screen and (max-width: 767px) {
    height: 160px;

    img {
      height: 100%;
    }
  }
`;
