import { useWeb3React } from "@web3-react/core";
import cx from "classnames";
import { BigNumber, ethers } from "ethers";
import PositionRouter from "abis/PositionRouter.json";
import icHistory from "img/trade/ic-history.svg";
import { renderRisk, renderRiskV1 } from "lib/helper";
import {
  BASIS_POINTS_DIVISOR,
  calculatePositionDelta,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  getLiqRisk,
  getLiquidationPrice,
  getOrderError,
  getProfitPrice,
  importImage,
  INCREASE,
  isAddressZero,
  MIN_PROFIT_TIME,
  USD_DECIMALS,
} from "lib/legacy";
import { bigNumberify, formatAmount, parseValue } from "lib/numbers";
import moment from "moment";
import styled from "styled-components";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import PositionHistory from "./PositionHistory";
import "./PositionSeller.css";
import RowsDropdown, { RowsDropdownHandler } from "./RowsDropdown";
import { useMemo, useState } from "react";
import { getNextAveragePrice } from "components/Exchange/SwapBox";
import { useLocalStorageByChainId } from "lib/localStorage";
import { CLOSE_POSITION_RECEIVE_TOKEN_KEY } from "config/localStorage";
import { getTokens, getWrappedToken } from "config/tokens";
import { getContract } from "config/contracts";
import { callContract } from "lib/contracts";
import useUserPositionActivitiesV1 from "hooks/useUserPositionActivitiesV1";
import PositionHistoryV1 from "./PositionHistoryV1";

export const MAPPED_ACTION_TYPES = {
  STOP_LOSS: "Stop Loss",
  TAKE_PROFIT: "Take Profit",
  TRAILING_STOP: "Trailing Stop",
  INCREASE_POSITION: "Increase Position",
};
const { AddressZero } = ethers.constants;
export default function PositionDetailsV1(props) {
  const {
    account,
    isVisible,
    setIsVisible,
    chainId,
    marketTokensInfo,
    isConfirm,
    hideClose,
    positionsMap,
    positionKey,
    showPnlAfterFees,
    orders,
    selectedPosition,
    nativeTokenAddress,
    isHigherSlippageAllowed,
    savedSlippageAmount,
    isContractAccount,
    minExecutionFee,
    setPendingTxns,
    pendingPositions,
    setPendingPositions,
  } = props;
  const { library } = useWeb3React();
  const [isOpenPositionHistory, setIsOpenPositionHistory] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { positions: positionActivities, details } = useUserPositionActivitiesV1(account, selectedPosition.id);
  const isDisableCondition = () => {};
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";
  const position = positionsMap && positionKey ? positionsMap[positionKey] : undefined;
  const positionRouterAddress = getContract(chainId, "PositionRouter");
  const hasPositionProfit = position[showPnlAfterFees ? "hasProfitAfterFees" : "hasProfit"];
  const positionDelta = position[showPnlAfterFees ? "pendingDeltaAfterFees" : "pendingDelta"] || bigNumberify(0);
  const liquidationPrice = getLiquidationPrice(position) || bigNumberify(0);
  const posQty =
    position?.averagePrice && position?.averagePrice.gt(0)
      ? position.size.mul(parseValue(1, USD_DECIMALS)).div(position?.averagePrice)
      : 0;
  const isLong = position.isLong;
  const pnlWithoutFee_LiqPrice =
    position.markPrice && position.averagePrice
      ? isLong
        ? liquidationPrice.sub(position.averagePrice).mul(position.size).div(position.averagePrice)
        : position.averagePrice.sub(liquidationPrice).mul(position.size).div(position.averagePrice)
      : bigNumberify(0);

  const pnlWithoutFee_MarkPrice =
    position.markPrice && position.averagePrice
      ? isLong
        ? position.markPrice.sub(position.averagePrice).mul(position.size).div(position.averagePrice)
        : position.averagePrice.sub(position.markPrice).mul(position.size).div(position.averagePrice)
      : bigNumberify(0);

  const LiqRisk =
    position.markPrice && position.averagePrice
      ? pnlWithoutFee_MarkPrice.mul(parseValue(100, USD_DECIMALS)).div(pnlWithoutFee_LiqPrice)
      : 0;

  const posOrders = useMemo(() => {
    return (
      orders
        ?.filter(
          (x) =>
            (x.isLong && position.isLong && position.indexToken.address === x.indexToken) ||
            (!x.isLong &&
              !position.isLong &&
              position.indexToken.address === x.indexToken &&
              position.collateralToken.address === x.collateralToken)
        )
        .map((i) => ({ ...i, isV1: true })) || []
    );
  }, [orders, position]);

  const mappedPosOrders = useMemo(() => {
    let data = {};
    if (posOrders && posOrders.length > 0) {
      posOrders
        .map((o) => {
          if (o.type === "Increase") {
            return {
              ...o,
              actionType: "INCREASE_POSITION",
            };
          }
          if (o.type === "Decrease") {
            if (o.isLong) {
              return {
                ...o,
                actionType: o.triggerAboveThreshold ? "TAKE_PROFIT" : "STOP_LOSS",
              };
            } else {
              return {
                ...o,
                actionType: o.triggerAboveThreshold ? "STOP_LOSS" : "TAKE_PROFIT",
              };
            }
          }
          return {
            ...o,
            actionType: "STOP_LOSS",
          };
        })
        .forEach((order) => {
          if (!Array.isArray(data[order.actionType])) {
            data[order.actionType] = [order];
          } else {
            data[order.actionType].push(order);
          }
        });
    }
    return data;
  }, [posOrders]);

  const [nextDelta, nextHasProfit = bigNumberify(0)] = useMemo(() => {
    if (!position) {
      return [bigNumberify(0), false];
    }
    return [position.delta, position.hasProfit, position.deltaPercentage];
  }, [position]);
  const profitPrice = getProfitPrice(position.markPrice, position);
  const [savedRecieveTokenAddress, setSavedRecieveTokenAddress] = useLocalStorageByChainId(
    chainId,
    `${CLOSE_POSITION_RECEIVE_TOKEN_KEY}-${position.indexToken.symbol}-${position?.isLong ? "long" : "short"}`
  );
  const toTokens = isContractAccount ? getTokens(chainId).filter((t) => !t.isNative) : getTokens(chainId);
  const [swapToToken, setSwapToToken] = useState(() =>
    savedRecieveTokenAddress ? toTokens.find((token) => token.address === savedRecieveTokenAddress) : undefined
  );
  const wrappedToken = getWrappedToken(chainId);
  const handleConfirm = () => {
    setIsSubmitting(true);
    const collateralTokenAddress = position.collateralToken.isNative
      ? nativeTokenAddress
      : position.collateralToken.address;
    const indexTokenAddress = position.indexToken.isNative ? nativeTokenAddress : position.indexToken.address;
    let allowedSlippage = savedSlippageAmount;
    if (isHigherSlippageAllowed) {
      allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
    }
    const priceBasisPoints = position.isLong
      ? BASIS_POINTS_DIVISOR - allowedSlippage
      : BASIS_POINTS_DIVISOR + allowedSlippage;
    const refPrice = position.isLong ? position.indexToken.minPrice : position.indexToken.maxPrice;
    let priceLimit = refPrice.mul(priceBasisPoints).div(BASIS_POINTS_DIVISOR);
    const minProfitExpiration = position.lastIncreasedTime + MIN_PROFIT_TIME;
    const minProfitTimeExpired = parseInt(Date.now() / 1000) > minProfitExpiration;

    if (nextHasProfit && !minProfitTimeExpired) {
      if ((position.isLong && priceLimit.lt(profitPrice)) || (!position.isLong && priceLimit.gt(profitPrice))) {
        priceLimit = profitPrice;
      }
    }

    const tokenAddress0 = collateralTokenAddress === AddressZero ? nativeTokenAddress : collateralTokenAddress;
    const path = [tokenAddress0];

    let collateralToken;
    let receiveToken;
    collateralToken = position.collateralToken;
    receiveToken = swapToToken ? swapToToken : collateralToken;

    if (isContractAccount && isAddressZero(receiveToken.address)) {
      receiveToken = wrappedToken;
    }

    const isUnwrap = receiveToken.address === AddressZero;
    const isSwap = receiveToken.address !== tokenAddress0;
    if (isSwap) {
      if (isUnwrap && tokenAddress0 !== nativeTokenAddress) {
        path.push(nativeTokenAddress);
      } else if (!isUnwrap) {
        path.push(receiveToken.address);
      }
    }
    const withdrawETH = isUnwrap && !isContractAccount;
    const params = [
      path, // _path
      indexTokenAddress, // _indexToken
      bigNumberify(0), // _collateralDelta
      position.size, // _sizeDelta
      position.isLong, // _isLong
      account, // _receiver
      priceLimit, // _acceptablePrice
      0, // _minOut
      minExecutionFee, // _executionFee
      withdrawETH, // _withdrawETH
      AddressZero, // _callbackTarget
    ];
    const sizeDeltaUsd = formatAmount(position.size, USD_DECIMALS, 2);
    const successMsg = `Requested decrease of ${position.indexToken.symbol} ${
      position.isLong ? "Long" : "SHort"
    } by ${sizeDeltaUsd} USD.`;

    const contract = new ethers.Contract(positionRouterAddress, PositionRouter.abi, library.getSigner());
    callContract(chainId, contract, "createDecreasePosition", params, {
      value: minExecutionFee,
      sentMsg: `Close submitted!`,
      successMsg,
      failMsg: `Close failed.`,
      setPendingTxns,
      // for Arbitrum, sometimes the successMsg shows after the position has already been executed
      // hide the success message for Arbitrum as a workaround
      hideSuccessMsg: false,
    })
      .then(async (res) => {
        setIsVisible(false);

        let nextSize = position.size.sub(position.size);

        pendingPositions[position.key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            size: nextSize,
          },
        };

        setPendingPositions({ ...pendingPositions });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  const renderContent = (order, id) => {
    if (order.type !== "Increase") {
      const orderError = getOrderError(account, order, positionsMap, position);
      const triggerPricePrefix = order.triggerAboveThreshold ? "≥" : "≤";
      return (
        <>
          {id >= 1 && <Divider className="medium" />}
          <ContentWrap style={{ opacity: orderError ? 0.5 : 1 }}>
            <ExchangeInfoRow label={"Size Delta"}>${formatAmount(order.sizeDelta, 30, 2, true)}</ExchangeInfoRow>
            {/* <ExchangeInfoRow label={"Pos.Qty"}>
              {formatAmount(orderQuantity, 30, 4, true)} {position.indexToken.symbol}
            </ExchangeInfoRow> */}
            <ExchangeInfoRow label={"Trigger Price"}>
              {triggerPricePrefix} ${formatAmount(order.triggerPrice, 30, 2, true)}
            </ExchangeInfoRow>
          </ContentWrap>
          {orderError && (
            <div className="negative" style={{ marginTop: "12px", fontWeight: 400 }}>
              {orderError}
            </div>
          )}
        </>
      );
    } else {
      const triggerPricePrefix = order.triggerAboveThreshold ? "≥" : "≤";

      return (
        <>
          {id >= 1 && <Divider className="medium" />}
          <ContentWrap>
            <ExchangeInfoRow label={"Size Delta"}>${formatAmount(order.sizeDelta, 30, 2, true)}</ExchangeInfoRow>
            {/* <ExchangeInfoRow label={"Pos.Qty"}>
              {formatAmount(orderQuantity, 30, 4, true)} {position.indexToken.symbol}
            </ExchangeInfoRow> */}
            <ExchangeInfoRow label={"Trigger Price"}>
              {triggerPricePrefix} ${formatAmount(order.triggerPrice, 30, 2, true)}
            </ExchangeInfoRow>
          </ContentWrap>
        </>
      );
    }
  };
  // console.log("???", position.createdAt);

  return (
    <Wrapper className="PositionEditor">
      {true && (
        <Modal
          className={`PositionSeller-modal  confirmation-modal details-position-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Open Position"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details" style={{ paddingBottom: "8px" }}>
            <div className="position-info-container" style={{ width: "100%" }}>
              {" "}
              <StyledWrap className="head">
                <div className="header">
                  <img src={importImage("ic_" + position.indexToken.symbol?.toLowerCase() + "_24.svg")} alt="tk" />
                  <div className="position-info">
                    <div className="title">{position.indexToken.symbol}/USD</div>{" "}
                    <div className="position-id">#{selectedPosition.posId}</div>
                  </div>
                </div>
                <div className="postion-history-ic" onClick={() => setIsOpenPositionHistory(true)}>
                  <img alt="history-ic" className="ic-history" src={icHistory} />
                </div>
              </StyledWrap>
              <StyledWrap>
                <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                  {position?.isLong ? "LONG" : "SHORT"}
                </div>
                <div className="side">MARKET</div>
                <div className="leverage-box">{formatAmount(position.leverage, 4, 2, true)}X</div>
              </StyledWrap>
            </div>
          </div>{" "}
          <div className="content-container">
            {" "}
            <Divider />
            <div className="order-details-container">
              <RowsDropdown
                show={true}
                handler={<ExchangeInfoRow label={<div className="title">Position Details</div>}></ExchangeInfoRow>}
              >
                <ExchangeInfoRow label={`Unrealized PnL`}>
                  <div
                    className={cx("", {
                      positive: position.deltaAfterFeesStr.includes("+"),
                      negative: position.deltaAfterFeesStr.includes("-"),
                      muted: !position.deltaAfterFeesStr.includes("+") && !position.deltaAfterFeesStr.includes("-"),
                    })}
                    style={{ gap: 0 }}
                  >
                    {position.deltaAfterFeesStr} ({position.deltaAfterFeesPercentageStr})
                  </div>
                </ExchangeInfoRow>
                {/* <ExchangeInfoRow label={`Realized PnL`}>
                  <div className={cx({ positive: true, negative: false })}>
                    {true ? (true ? "+" : "-") : ""}${"--"}{" "}
                  </div>
                </ExchangeInfoRow> */}
                <ExchangeInfoRow label={`Net Value`}>
                  ${formatAmount(position.netValue, USD_DECIMALS, 2, true)}
                </ExchangeInfoRow>{" "}
                <ExchangeInfoRow label={`Pos.Size`}>
                  ${formatAmount(position.size, USD_DECIMALS, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos.Qty`}>
                  {formatAmount(posQty, 30, 4, true)} {position.indexToken.symbol}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Liq. Price`}>
                  ${formatAmount(liquidationPrice, USD_DECIMALS, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Mark Price`}>
                  ${formatAmount(position.markPrice, USD_DECIMALS, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Avg. Entry Price`}>
                  ${formatAmount(position.averagePrice, USD_DECIMALS, 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Liq. Risk`}>{renderRiskV1(LiqRisk, false)}</ExchangeInfoRow>
                <ExchangeInfoRow label={`Collateral`}>
                  ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                </ExchangeInfoRow>
                {/* <ExchangeInfoRow label={`Pending Collateral`}>$--</ExchangeInfoRow> */}
                {!selectedPosition.isLong && (
                  <ExchangeInfoRow label={`Collateral in`}>{selectedPosition.collateralToken.symbol}</ExchangeInfoRow>
                )}
                <ExchangeInfoRow label={`Date/Time Opened`}>
                  {moment(new Date(selectedPosition?.createdAt * 1000)).format(dateFormat)}&nbsp;
                  {moment(new Date(selectedPosition?.createdAt * 1000)).format(timeFormat)}
                </ExchangeInfoRow>
              </RowsDropdown>
              {posOrders.length > 0 && <Divider className="toggle-margin " />}

              {posOrders.length > 0 && (
                <RowsDropdown
                  handler={<ExchangeInfoRow label={<div className="title">Linked Orders</div>}></ExchangeInfoRow>}
                >
                  {Object.keys(mappedPosOrders).map((type) => (
                    <div className="square-container square-fee-container">
                      <RowsDropdown
                        key={type}
                        handler={
                          <RowsDropdownHandler>
                            {MAPPED_ACTION_TYPES[type]} ({mappedPosOrders[type].length})
                          </RowsDropdownHandler>
                        }
                      >
                        <div className="title">
                          {mappedPosOrders[type].map((order, id) => renderContent(order, id))}
                        </div>
                      </RowsDropdown>
                    </div>
                  ))}
                </RowsDropdown>
              )}
              <Divider className="toggle-margin " />
              <RowsDropdown
                handler={
                  <ExchangeInfoRow label={<div className="title">Fees</div>} isPositive={true}></ExchangeInfoRow>
                }
              >
                <div className="square-container square-fee-container">
                  <ExchangeInfoRow label={`Paid`}>
                    {selectedPosition?.paidPosFee ? "$" + formatAmount(selectedPosition.paidPosFee, 30, 4, true) : "-"}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Position`}>
                    {selectedPosition?.paidPosFee ? "$" + formatAmount(selectedPosition.paidPosFee, 30, 4, true) : "-"}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Borrow`}>-</ExchangeInfoRow>
                </div>
                <div className="square-container square-fee-container">
                  <ExchangeInfoRow label={`Accrued`}>
                    ${formatAmount(position.closingFee.add(position.fundingFee), 30, 4, true)}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Position`}>
                    {" "}
                    ${formatAmount(position.closingFee, 30, 4, true)}
                  </ExchangeInfoRow>
                  {/* <ExchangeInfoRow label={`Funding`}>{true && "-"}$--</ExchangeInfoRow> */}
                  <ExchangeInfoRow label={`Borrow`}>${formatAmount(position.fundingFee, 30, 4, true)}</ExchangeInfoRow>
                </div>
              </RowsDropdown>
            </div>
          </div>
          {!hideClose && (
            <div className="Exchange-swap-button-container">
              <button
                className="App-cta Exchange-swap-button"
                onClick={handleConfirm}
                disabled={isSubmitting || isDisableCondition()}
              >
                {isSubmitting ? "Closing..." : "Close Position"}
              </button>
            </div>
          )}
          {details && (
            <PositionHistoryV1
              isVisible={isOpenPositionHistory}
              setIsVisible={setIsOpenPositionHistory}
              rows={positionActivities}
              position={details}
              chainId={chainId}
              optionalHeader={
                <div className="order-title-line order-title-line-details">
                  <div className="position-info-container">
                    <div className="position-info">
                      <img src={importImage("ic_" + position?.indexToken.symbol?.toLowerCase() + "_24.svg")} alt="" />
                      <div className="title">{position.indexToken.symbol}</div>
                      {/* <div className="position-id">#{position?.posId}</div> */}
                      <div className="position-tags">
                        <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                          {position?.isLong ? "LONG" : "SHORT"}
                        </div>
                        <div className="side">MARKET</div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          )}
        </Modal>
      )}
    </Wrapper>
  );
}

const StyledWrap = styled.div`
  &.head {
    display: flex;
    justify-content: space-between;
  }
  .header {
    display: flex;
    gap: 4px;
  }
  display: flex;
  gap: 4px;
  .position-info {
    gap: 4px;
  }

  .position-info .title {
    font-size: 14px;
  }
  .position-info .position-id {
    font-weight: 500;
  }
`;
const Wrapper = styled.div`
  .postion-history-ic {
    cursor: pointer;
    &:hover {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(354deg) brightness(106%)
        contrast(101%);
    }
  }
  .Exchange-swap-button-container {
    margin-top: -8px;
  }
  .order-title-line .position-info-container {
    gap: 8px;
  }

  .content-container {
    padding-top: 0 !important;
    .title {
      font-weight: 700;
      color: #fff;
    }
  }
  .Exchange-info-label {
    margin-bottom: 0 !important;
  }

  .square-fee-container {
    border-radius: var(--Border-Radius-Medium, 12px);
    background: var(--Nature-1, #18191a);
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;
const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin: 16px 0;
  background: var(--Border, rgba(239, 236, 235, 0.08));
  &.toggle-margin {
    margin: -8px 0 0 0;
  }
  &.medium {
    margin: 12px 0;
  }
`;
const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
