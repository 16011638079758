import { useWeb3React } from "@web3-react/core";
import { getPageTitle } from "lib/legacy";
import styled from "styled-components";

import { getWhitelistedTokens } from "config/tokens";
import { useInfoTokens } from "domain/tokens";
import { useChainId, useChainIdWithDefault } from "lib/chains";
import { bigNumberify } from "lib/numbers";

import Footer from "components/Footer/Footer";
import SEO from "components/Common/SEO";
import Markets from "./components/Markets";
import Stats from "./components/Stats";
import Tokens from "./components/Tokens";

import patternImg from "img/dashboard/pattern.png";
import YieldVault from "./components/YieldVault";
import { useThemeContext } from "contexts/ThemeProvider";

import "./DashboardTheme.scss";

export default function DashboardV3({ setTradeVersion }) {
  const { lightThemeClassName } = useThemeContext();
  const { active, library } = useWeb3React();
  const chainId = useChainIdWithDefault({
    isUseDefaultChain: false,
  });

  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  let adjustedUsdgSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount);
    }
  }

  return (
    <SEO title={getPageTitle("Dashboard")}>
      <Wrapper className={lightThemeClassName}>
        <div className={`default-container page-layout DashboardV2 ${lightThemeClassName}`}>
          <Stats chainId={chainId} active={active} />
          <YieldVault chainId={chainId} active={active} library={library} />
          {/* <Tokens chainId={chainId} active={active} library={library} /> */}
          <Markets chainId={chainId} setTradeVersion={setTradeVersion} />
        </div>
        <Footer />
      </Wrapper>
    </SEO>
  );
}

const Wrapper = styled.div`
  /* background-image: url(${patternImg});
  background-repeat: no-repeat;
  background-size: contain; */

  &.theme--light {
    background: linear-gradient(180deg, #f7fffd 0%, #eef1f7 100%);
  }

  @media screen and (max-width: 700px) {
    .default-container {
      padding: 16px 16px 268px 16px;
    }
  }
`;
