function Icon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? "36"}
      height={props.size ?? "36"}
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#000"
        d="M27.345 6v4.033h-2.052V6h2.052zm-.25 22.323V32h-2.052v-3.677h2.052zm1.448-4.484c0-.52-.092-.961-.277-1.326-.175-.364-.465-.688-.868-.97-.395-.283-.93-.556-1.605-.82a22.278 22.278 0 01-3.014-1.45c-.86-.52-1.53-1.162-2.013-1.927-.482-.775-.723-1.754-.723-2.94 0-1.13.258-2.109.776-2.938a5.124 5.124 0 012.145-1.914c.92-.456 1.99-.683 3.21-.683.93 0 1.772.145 2.527.437a5.273 5.273 0 011.947 1.258c.544.546.96 1.216 1.25 2.01.29.792.434 1.699.434 2.72h-3.776c0-.547-.057-1.03-.171-1.45-.114-.419-.281-.77-.5-1.052a1.968 1.968 0 00-.764-.629 2.216 2.216 0 00-.986-.219c-.535 0-.974.11-1.316.328a1.918 1.918 0 00-.75.889 3.3 3.3 0 00-.224 1.258c0 .464.08.87.237 1.216.167.346.452.665.855.957.404.283.956.574 1.658.875 1.14.456 2.14.948 3 1.476.86.529 1.53 1.176 2.013 1.941.483.766.724 1.741.724 2.926 0 1.175-.263 2.178-.79 3.007-.526.82-1.263 1.45-2.21 1.887-.947.428-2.044.642-3.29.642-.807 0-1.61-.11-2.407-.328a6.388 6.388 0 01-2.171-1.094c-.65-.5-1.167-1.166-1.553-1.995-.386-.839-.579-1.869-.579-3.09h3.79c0 .665.083 1.221.25 1.668.166.437.386.788.657 1.052.281.256.597.438.948.547.35.11.706.164 1.066.164.561 0 1.021-.104 1.381-.314.369-.21.645-.497.83-.861a2.7 2.7 0 00.289-1.258z"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M5.122 5.706l-2.12 2.119 7.248 7.247-1.698 1.697 7.529 2.017-2.018-7.528-1.695 1.695-7.246-7.247zM5.113 17.007l-2.107 2.107 7.247 7.247-1.702 1.703 7.528 2.017-2.017-7.529-1.702 1.702-7.247-7.247z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
