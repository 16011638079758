import { useEffect, useState } from "react";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import styled from "styled-components";
import useSWR from "swr";
import { t } from "@lingui/macro";

import Tab from "components/Tab/Tab";
import Footer from "components/Footer/Footer";
import { useNaviPrice } from "domain/legacy";
import TooltipComponent from "components/Tooltip/Tooltip";
import ftmIcon from "img/ic_ftm_24.svg";
import opIcon from "img/ic_op_24.svg";
import {
  PLACEHOLDER_ACCOUNT,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
  USD_DECIMALS,
  NLP_DECIMALS,
} from "lib/legacy";

import { StyledWrapperSection, StyledWrapperSectionIpad, StyledVaultInfoSection, StyledVaultInfo } from "./index.style";

import naviIcon from "img/ic_navi_custom.svg";
import NAVI_FTM from "img/NAVI-FTM.svg";
import NAVI_ETH from "img/NAVI-ETH.svg";
import tokensIcon from "img/tokens.svg";

import VaultJson from "abis/Vault.json";
import NewVaultJson from "abis/NewVault.json";
import ReaderV2 from "abis/ReaderV2.json";
import RewardReader from "abis/RewardReader.json";
import Token from "abis/Token.json";
import NlpManager from "abis/NlpManager.json";
import VaultStrategyNAVI from "abis/VaultStrategyNAVI.json";
import VaultStrategyNLP from "abis/VaultStrategyNLP.json";
import VaultStrategyNAVI_WFTM_OLD from "abis/VaultStrategyNAVI_WFTM_OLD.json";
import VaultStrategyNAVI_WFTM from "abis/VaultStrategyNAVI_WFTM.json";

import { getContract } from "config/contracts";
import { contractFetcher } from "lib/contracts";
import { formatAmount, formatKeyAmount, formatNumber, formatAmountFree, expandDecimals } from "lib/numbers";
import VaultAction from "./VaultAction";
import moment, { now } from "moment";
import VaultTab from "./VaultTab";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import { useChainId } from "lib/chains";
import { FANTOM } from "config/chains";
import { Trans } from "@lingui/react";
import RouterTab from "components/RouterTab";
import { useMedia } from "react-use";
import { useThemeContext } from "contexts/ThemeProvider";

export const NAVI_VAULT = "NAVI vault";
export const NLP_VAULT = "NLP vault";
export const NAVI_WFTM_OLD_VAULT = "NAVI_WFTM_OLD vault";
export const NAVI_WFTM_VAULT = "NAVI_WFTM vault";
export const NAVI_WETH_VAULT = "NAVI_WETH vault";

const TAB_ICONS = {
  [NAVI_VAULT]: naviIcon,
  [NLP_VAULT]: tokensIcon,
  [NAVI_WFTM_OLD_VAULT]: NAVI_FTM,
  [NAVI_WFTM_VAULT]: NAVI_FTM,
  [NAVI_WETH_VAULT]: NAVI_ETH,
};

const StyledTab = styled(Tab)`
  background-color: #191b2e !important;
  height: 60px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 22.5px;
  background: #11121b;
  border-radius: 16px;
  width: fit-content;
  display: inline-flex;
  padding: 8px;

  @media (max-width: 768px) {
    width: 100%;

    display: flex !important;
    flex-wrap: wrap;

    height: fit-content;

    .Tab-option {
      width: 50%;

      padding: 8px !important;
    }
  }

  > div {
    height: 45px;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
    box-sizing: border-box !important;

    img {
      filter: none !important;
      width: auto;
      height: 24px;
    }

    &:nth-child(2) {
      img {
        width: 36px;
      }
    }
  }
`;

const NAVI_WFTM_Address = "0xdc935ffe9f9c972b7b304e0b7e61eb774037e394";

export default function Vault(props) {
  // const { chainId } = useChainId();
  const isMobile = useMedia("(max-width: 600px)");
  const { lightThemeClassName, isLightTheme } = useThemeContext();
  const chainId = FANTOM;

  const [activeTab, setActiveTab] = useState(chainId === FANTOM ? NAVI_VAULT : NAVI_WETH_VAULT);
  const TAB_OPTION_LABELS = {
    [NAVI_VAULT]: `NAVI`,
    [NLP_VAULT]: `NLP`,
    [NAVI_WFTM_OLD_VAULT]: `NAVI-WFTM V1`,
    [NAVI_WFTM_VAULT]: `NAVI-WFTM`,
    [NAVI_WETH_VAULT]: `NAVI-WETH`,
  };

  const { connectWallet, setPendingTxns } = props;

  const { active, library, account } = useWeb3React();

  const [value, setValue] = useState("");
  const [statsNAVIWFTM, setStatsNAVIWFTM] = useState({
    price: 0,
    apr: 10,
  });

  const fetchStatsNAVIWFTM = async () => {
    const res = await axios.get(`https://api.navigator.exchange/api/tokens/info?symbols=NAVI/FTM&chain=FANTOM`, {});
    // const resAPR = await axios.get(`https://api.grim.finance/apy/breakdown`, {});

    if (res && res.data) {
      const { price } = res.data["NAVI/FTM"];
      // const apr = resAPR.data["equalizerv2-navi-ftm"]?.vaultApr ? resAPR.data["equalizerv2-navi-ftm"]?.vaultApr * 100 : 0;
      setStatsNAVIWFTM({
        apr: 10,
        price,
      });
    }
  };

  useEffect(() => {
    fetchStatsNAVIWFTM();

    const interval = setInterval(async () => {
      fetchStatsNAVIWFTM();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const TAB_OPTIONS = chainId === FANTOM ? [NAVI_VAULT, NLP_VAULT, NAVI_WFTM_VAULT] : [NAVI_WETH_VAULT];

  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const vaultNAVIAddress = getContract(chainId, "VaultNAVI");
  const vaultNLPAddress = getContract(chainId, "VaultNLP");
  const vaultNAVI_WFTM_OLDAddress = getContract(chainId, "VaultNAVI_WFTM_OLD");
  const vaultNAVI_WFTMAddress = getContract(chainId, "VaultNAVI_WFTM");

  const currentVaultAddress =
    activeTab === NAVI_VAULT
      ? vaultNAVIAddress
      : activeTab === NLP_VAULT
      ? vaultNLPAddress
      : activeTab === NAVI_WFTM_VAULT
      ? vaultNAVI_WFTMAddress
      : vaultNAVI_WFTM_OLDAddress; // 3 vault

  const positionRouterAddress = getContract(chainId, "PositionRouter");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const naviAddress = getContract(chainId, "NAVI");
  const esNaviAddress = getContract(chainId, "ES_NAVI");
  const bnNaviAddress = getContract(chainId, "BN_NAVI");
  const nlpAddress = getContract(chainId, "NLP");
  const usdgAddress = getContract(chainId, "USDN");

  const stakedNaviTrackerAddress = getContract(chainId, "StakedNaviTracker");
  const bonusNaviTrackerAddress = getContract(chainId, "BonusNaviTracker");
  const feeNaviTrackerAddress = getContract(chainId, "FeeNaviTracker");

  const stakedNlpTrackerAddress = getContract(chainId, "StakedNlpTracker");
  const feeNlpTrackerAddress = getContract(chainId, "FeeNlpTracker");

  const nlpManagerAddress = getContract(chainId, "NlpManager");

  const naviVesterAddress = getContract(chainId, "NaviVester");
  const nlpVesterAddress = getContract(chainId, "NlpVester");

  const vesterAddresses = [naviVesterAddress, nlpVesterAddress];

  const walletTokens = [naviAddress, esNaviAddress, nlpAddress, stakedNaviTrackerAddress, NAVI_WFTM_Address];
  const depositTokens = [
    naviAddress,
    esNaviAddress,
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    bnNaviAddress,
    nlpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedNaviTrackerAddress,
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    feeNaviTrackerAddress,
    feeNaviTrackerAddress,
    feeNlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedNaviTrackerAddress,
    bonusNaviTrackerAddress,
    feeNaviTrackerAddress,
    stakedNlpTrackerAddress,
    feeNlpTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `StakeV2:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, ReaderV2, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: depositBalancesESNAVI } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      "0x4fedf117114Eb32518cCa913F8a9FECF04719547" || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: depositBalancesNLP } = useSWR(
    [
      `StakeV2:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      "0x12E2611b357EE749D3C202dF1342108598365497" || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: contractFetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedNaviSupply } = useSWR(
    [`StakeV2:stakedNaviSupply:${active}`, chainId, naviAddress, "balanceOf", stakedNaviTrackerAddress],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, chainId, nlpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, NlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(library, VaultJson),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [vesterAddresses]),
    }
  );

  //NAVI
  const { data: strategyAddressNAVI } = useSWR([`StakeV2:strategy:${active}`, chainId, vaultNAVIAddress, "strategy"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });
  const { data: lastHarvestNAVI } = useSWR(
    [`StakeV2:lastHarvestNAVI:${active}`, chainId, strategyAddressNAVI, "lastHarvest"],
    {
      fetcher: contractFetcher(library, VaultStrategyNAVI),
    }
  );
  const { data: balanceOfNAVI } = useSWR(
    () => [`StakeV2:balanceOfNAVI:${active}:${account}`, chainId, vaultNAVIAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );

  const { data: pricePerFullShareNAVI } = useSWR(
    () => [`StakeV2:getPricePerFullShareNAVI:${active}:${account}`, chainId, vaultNAVIAddress, "getPricePerFullShare"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const { data: totalSupplyNAVI } = useSWR([`StakeV2:totalSupplyNAVI:${active}`, chainId, vaultNAVIAddress, "balance"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });

  //NLP
  const { data: strategyAddressNLP } = useSWR([`StakeV2:strategy:${active}`, chainId, vaultNLPAddress, "strategy"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });

  const { data: lastHarvestNLP } = useSWR(
    [`StakeV2:lastHarvestNLP:${active}`, chainId, strategyAddressNLP, "lastHarvest"],
    {
      fetcher: contractFetcher(library, VaultStrategyNLP),
    }
  );
  const { data: balanceOfNLP } = useSWR(
    [`StakeV2:balanceOfNLP:${active}:${account}`, chainId, vaultNLPAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );

  const { data: pricePerFullShareNLP } = useSWR(
    () => [`StakeV2:getPricePerFullShareNLP:${active}:${account}`, chainId, vaultNLPAddress, "getPricePerFullShare"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const { data: totalSupplyNLP } = useSWR([`StakeV2:totalSupplyNLP:${active}`, chainId, vaultNLPAddress, "balance"], {
    fetcher: contractFetcher(library, NewVaultJson),
  });

  // NAVI_WFTM

  const { data: strategyAddressNAVI_WFTM } = useSWR(
    [`StakeV2:strategy:${active}`, chainId, vaultNAVI_WFTMAddress, "strategy"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  // const { data: lastHarvestNAVI_WFTM } = useSWR(
  //   [`StakeV2:lastHarvestNAVI:${active}`, chainId, strategyAddressNAVI_WFTM, "lastHarvest"],
  //   {
  //     fetcher: contractFetcher(library, VaultStrategyNAVI_WFTM),
  //   }
  // );
  const { data: lastHarvestNAVI_WFTM } = useSWR("https://api.navigator.exchange/fantom/api/last-compound", {
    refreshInterval: 10000,
  });

  const { data: balanceOfNAVI_WFTM } = useSWR(
    () => [`StakeV2:balanceOfNAVI_WFTM:${active}:${account}`, chainId, vaultNAVI_WFTMAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );

  const { data: pricePerFullShareNAVI_WFTM } = useSWR(
    () => [
      `StakeV2:getPricePerFullShareNAVI_WFTM:${active}:${account}`,
      chainId,
      vaultNAVI_WFTMAddress,
      "getPricePerFullShare",
    ],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const { data: totalSupplyNAVI_WFTM } = useSWR(
    [`StakeV2:totalSupplyNAVI_WFTM:${active}`, chainId, vaultNAVI_WFTMAddress, "balance"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  // NAVI_WFTM_OLD

  // const { data: strategyAddressNAVI_WFTM_OLD } = useSWR(
  //   [`StakeV2:strategy:${active}`, chainId, vaultNAVI_WFTM_OLDAddress, "strategy"],
  //   {
  //     fetcher: contractFetcher(library, NewVaultJson),
  //   }
  // );

  // const { data: lastHarvestNAVI_WFTM_OLD } = useSWR(
  //   [`StakeV2:lastHarvestNAVI:${active}`, chainId, strategyAddressNAVI_WFTM_OLD, "lastHarvest"],
  //   {
  //     fetcher: contractFetcher(library, VaultStrategyNAVI_WFTM),
  //   }
  // );

  const { data: balanceOfNAVI_WFTM_OLD } = useSWR(
    () => [`StakeV2:balanceOfNAVI_WFTM_OLD:${active}:${account}`, chainId, vaultNAVI_WFTM_OLDAddress, "balanceOf"],
    {
      fetcher: contractFetcher(library, NewVaultJson, [account || PLACEHOLDER_ACCOUNT]),
    }
  );

  const { data: pricePerFullShareNAVI_WFTM_OLD } = useSWR(
    () => [
      `StakeV2:getPricePerFullShareNAVI_WFTM:${active}:${account}`,
      chainId,
      vaultNAVI_WFTM_OLDAddress,
      "getPricePerFullShare",
    ],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const { data: totalSupplyNAVI_WFTM_OLD } = useSWR(
    [`StakeV2:totalSupplyNAVI_WFTM_OLD:${active}`, chainId, vaultNAVI_WFTM_OLDAddress, "balance"],
    {
      fetcher: contractFetcher(library, NewVaultJson),
    }
  );

  const tokensForSupplyQuery = [naviAddress, nlpAddress, usdgAddress];

  const { data: totalSupplies } = useSWR(
    [`StakeV2:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
    }
  );
  const { naviPrice } = useNaviPrice(chainId, {}, active);

  let naviSupply;
  if (totalSupplies && totalSupplies[1]) {
    naviSupply = totalSupplies[1];
  }

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);

  const depositBalanceData = getDepositBalanceData(depositBalances);
  const depositBalanceDataESNAVI = getDepositBalanceData(depositBalancesESNAVI);
  const depositBalanceDataNLP = getDepositBalanceData(depositBalancesNLP);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);
  const amountTokenDeposit =
    activeTab === NAVI_VAULT
      ? formatAmountFree(balanceOfNAVI, 18)
      : activeTab === NLP_VAULT
      ? formatAmountFree(balanceOfNLP, 18)
      : activeTab === NAVI_WFTM_VAULT
      ? formatAmountFree(balanceOfNAVI_WFTM, 18)
      : formatAmountFree(balanceOfNAVI_WFTM_OLD, 18); // 3 vault
  const rateWithdraw =
    activeTab === NAVI_VAULT
      ? formatAmountFree(pricePerFullShareNAVI, 18)
      : activeTab === NLP_VAULT
      ? formatAmountFree(pricePerFullShareNLP, 18)
      : activeTab === NAVI_WFTM_VAULT
      ? formatAmountFree(pricePerFullShareNAVI_WFTM, 18)
      : formatAmountFree(pricePerFullShareNAVI_WFTM_OLD, 18); // 3 vault
  const amountTVL =
    activeTab === NAVI_VAULT
      ? formatAmount(totalSupplyNAVI, 18)
      : activeTab === NLP_VAULT
      ? formatAmount(totalSupplyNLP, 18)
      : activeTab === NAVI_WFTM_VAULT
      ? formatAmount(totalSupplyNAVI_WFTM, 18)
      : formatAmount(totalSupplyNAVI_WFTM_OLD, 18); // 3 vault
  const amountLastHarvest =
    activeTab === NAVI_VAULT
      ? lastHarvestNAVI
        ? moment(Number(lastHarvestNAVI?.toString() || "") * 1000).fromNow()
        : "0"
      : activeTab === NLP_VAULT
      ? lastHarvestNLP
        ? moment(Number(lastHarvestNLP?.toString() || "") * 1000).fromNow()
        : "0"
      : lastHarvestNAVI_WFTM
      ? moment(Number(lastHarvestNAVI_WFTM?.toString() || "") * 1000).fromNow()
      : "0"; // 3 vault
  //   lastHarvestNLP
  // ? moment(Number(lastHarvestNLP?.toString() || "") * 1000).fromNow()
  // : "0"; // 3 vault
  const caculateAPYHourly = (apr, time) => {
    const aprTimeFrame = (apr / 4080) * time;
    const A = 1 + aprTimeFrame / (time * 100);
    return (Math.pow(A, time) - 1) * 100;
  };

  const caculateAPYDaily = (apr, time) => {
    const aprTimeFrame = (apr / 365) * time;
    const A = 1 + aprTimeFrame / (time * 100);
    return (Math.pow(A, time) - 1) * 100;
  };

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedNaviSupply,
    naviPrice,
    naviSupply
  );

  let nameToken = "naviBalance";

  switch (activeTab) {
    case NAVI_VAULT:
      nameToken = "naviBalance";
      break;

    case NLP_VAULT:
      nameToken = "nlpBalance";
      break;

    case NAVI_WFTM_OLD_VAULT:
      nameToken = "navi_wftmBalance";
      break;

    case NAVI_WFTM_VAULT:
      nameToken = "navi_wftmBalance";
      break;

    case NAVI_WETH_VAULT:
      nameToken = "navi_wethBalance";
      break;

    default:
      nameToken = "naviBalance";
      break;
  }

  let balance = formatAmountFree(processedData[nameToken], 18);

  if (processedData && vestingData && processedData?.nlpBalance) {
    if (activeTab === NLP_VAULT) {
      balance = formatAmountFree(processedData?.nlpBalance.sub(vestingData?.nlpVesterPairAmount), 18); // watch later
    } else {
    }
  }

  const priceToken =
    activeTab === NAVI_VAULT
      ? Number(formatAmount(naviPrice, USD_DECIMALS))
      : activeTab === NLP_VAULT
      ? Number(formatKeyAmount(processedData, "nlpPrice", USD_DECIMALS, 3, true))
      : statsNAVIWFTM.price;

  const esNaviInStakedESNAVI =
    depositBalanceDataESNAVI &&
    naviPrice &&
    depositBalanceDataESNAVI["esNaviInStakedNavi"].mul(naviPrice).div(expandDecimals(1, NLP_DECIMALS));

  const esNaviInStakedNLP =
    depositBalanceDataNLP &&
    naviPrice &&
    depositBalanceDataNLP["esNaviInStakedNavi"].mul(naviPrice).div(expandDecimals(1, NLP_DECIMALS));

  const aprFarm = Number(
    formatKeyAmount(
      processedData,
      activeTab === NAVI_VAULT ? "naviAprForNativeToken" : "nlpAprForNativeToken",
      2,
      2,
      true
    )
  );

  const aprFarmEsNAVI = Number(formatKeyAmount(processedData, "naviAprForNativeToken", 2, 2, true));

  const totalStakedWithOutEsNAVI = Number(amountTVL * priceToken);
  const totalEsNAVI = Number(formatAmount(activeTab === NAVI_VAULT ? esNaviInStakedESNAVI : esNaviInStakedNLP, 30, 2));

  const aprVault = aprFarm + aprFarmEsNAVI * (totalEsNAVI / totalStakedWithOutEsNAVI);

  const apr = [NAVI_WFTM_OLD_VAULT, NAVI_WFTM_VAULT].includes(activeTab) ? statsNAVIWFTM.apr : aprVault;

  const apy = !isNaN(formatNumber(String(caculateAPYHourly(Number(apr), 4080)), 2))
    ? formatNumber(String(caculateAPYHourly(Number(apr), 4080)), 2)
    : "--";

  const totalApr = [NAVI_WFTM_OLD_VAULT, NAVI_WFTM_VAULT].includes(activeTab)
    ? statsNAVIWFTM.apr
    : formatKeyAmount(processedData, activeTab === NAVI_VAULT ? "naviAprTotal" : "nlpAprTotal", 2, 2, true); // 3 vault

  const totalApy = totalApr ? formatNumber(String(caculateAPYHourly(Number(totalApr), 4080)), 2) : "--";
  const [daily, setDaily] = useState("0.0000");
  const [dailyFTM, setDailyFTM] = useState("0.0000");
  const [dailyEsNAVI, setDailyEsNAVI] = useState("0.0000");
  useEffect(() => {
    if (!isNaN(totalApy)) {
      setDaily(formatNumber(caculateAPYDaily(Number(totalApr), 1), 4));
      setDailyFTM(formatNumber(caculateAPYDaily(Number(apr), 1), 4));
      setDailyEsNAVI(formatNumber(caculateAPYDaily(Number(totalApr), 1) - caculateAPYDaily(Number(apr), 1), 4));
    }
  }, [totalApy, totalApr]);

  function renderHeader() {
    return (
      <StyledVaultInfo>
        <StyledVaultInfoSection>
          <div className="TVL">
            <div>TVL</div>
            {[NAVI_WFTM_OLD_VAULT, NAVI_WFTM_VAULT].includes(activeTab) ? (
              <span className="APY-value">${priceToken === 0 ? "--" : formatNumber(amountTVL * priceToken, 2)}</span>
            ) : amountTVL !== "--" && priceToken ? (
              <StyledTooltipComponent
                handle={`$${
                  amountTVL !== "--" && priceToken ? (
                    formatNumber(
                      amountTVL * priceToken +
                        Number(formatAmount(activeTab === NAVI_VAULT ? esNaviInStakedESNAVI : esNaviInStakedNLP, 30)),
                      2
                    )
                  ) : (
                    <div className="skeleton-box" />
                  )
                }`}
                position={isMobile ? "right-bottom" : undefined}
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow
                        label={`Staked ${activeTab === NAVI_VAULT ? "NAVI" : "NLP"}`}
                        value={amountTVL !== "--" && priceToken ? formatNumber(amountTVL * priceToken, 2) : "--"}
                        showDollar={true}
                      />
                      <StatsTooltipRow
                        label={`Staked EsNAVI`}
                        value={formatNumber(
                          Number(formatAmount(activeTab === NAVI_VAULT ? esNaviInStakedESNAVI : esNaviInStakedNLP, 30, 2)),
                          2
                        )}
                        showDollar={true}
                      />
                    </>
                  );
                }}
              />
            ) : (
              <div className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "10px" }} />
            )}
          </div>
          <div className="APY">
            <div>APY</div>
            {[NAVI_WFTM_OLD_VAULT, NAVI_WFTM_VAULT].includes(activeTab) ? (
              <span className="APY-value">{activeTab === NAVI_WFTM_OLD_VAULT ? 0 : totalApy}%</span>
            ) : totalApy !== "--" ? (
              <StyledTooltipComponent
                handle={
                  <span
                    style={{
                      marginTop: 0,
                    }}
                    className="APY-value"
                  >
                    {totalApy}%
                  </span>
                }
                position={isMobile ? "right-bottom" : undefined}
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow
                        label={`Escrowed NAVI APY`}
                        value={`${formatNumber(totalApy - apy, 2)}%`}
                        showDollar={false}
                      />
                      <StatsTooltipRow label={`FTM APY`} value={`${formatNumber(apy, 2)}%`} showDollar={false} />
                    </>
                  );
                }}
              />
            ) : (
              <div className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "10px" }} />
            )}
          </div>
          <div className="Daily">
            <div>Daily</div>
            {[NAVI_WFTM_OLD_VAULT, NAVI_WFTM_VAULT].includes(activeTab) ? (
              <span className="Daily-value">{activeTab === NAVI_WFTM_OLD_VAULT ? 0 : daily}%</span>
            ) : daily !== "0.0000" ? (
              <StyledTooltipComponent
                handle={
                  <span
                    style={{
                      marginTop: 0,
                    }}
                    className="APY-value"
                  >
                    {daily}%
                  </span>
                }
                position="right-bottom"
                renderContent={() => {
                  return (
                    <>
                      <StatsTooltipRow label={`Daily Escrowed NAVI`} value={`${dailyEsNAVI}%`} showDollar={false} />
                      <StatsTooltipRow label={`Daily FTM`} value={`${dailyFTM}%`} showDollar={false} />
                    </>
                  );
                }}
              />
            ) : (
              <div className="skeleton-box" style={{ width: "60px", height: "20px", marginTop: "10px" }} />
            )}
          </div>
        </StyledVaultInfoSection>
      </StyledVaultInfo>
    );
  }

  const isIpad = useMedia("(max-width: 768px)");

  return (
    <Wrapper className={`Vault default-container page-layout ${lightThemeClassName}`}>
      <div
        style={{
          maxWidth: "100%",
        }}
        className="section-title-block"
      >
        <div className="section-title-icon"></div>
        <div className="section-title-content">
          <div
            style={{
              marginBottom: 8,
            }}
            className="Page-title"
          >
            <div>
              Vault <img alt="" src={chainId === FANTOM ? ftmIcon : opIcon} />
            </div>
          </div>

          <div
            style={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(255, 255, 255, 0.6)",
            }}
            className="description"
          >
            Our cutting-edge auto-compound Yield Farming strategy, designed to empower investors to earn rewards
            effortlessly.
          </div>
        </div>
      </div>
      <StyledTab
        icons={TAB_ICONS}
        options={TAB_OPTIONS}
        optionLabels={TAB_OPTION_LABELS}
        option={activeTab}
        setOption={setActiveTab}
        onChange={setActiveTab}
        isIconFirst={true}
      />

      {activeTab === NAVI_WFTM_OLD_VAULT && (
        <div className="old_vault">
          We have deployed a new contract to V2. Please move ALL of your LP Tokens to the new NAVI-WFTM vault.
        </div>
      )}

      {isIpad ? (
        <StyledWrapperSectionIpad>
          <div className="left">
            {renderHeader()}

            <StyledVaultInfoSection>
              <div className="your-deposit">
                Your deposit
                {amountTokenDeposit !== "--" && priceToken ? (
                  <span className="your-deposit-value">
                    {amountTokenDeposit !== "--" && priceToken ? (
                      "$" + formatNumber(amountTokenDeposit * priceToken * rateWithdraw, 2)
                    ) : (
                      <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                    )}
                  </span>
                ) : (
                  <span className="your-deposit-value">
                    <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                  </span>
                )}
              </div>

              <div className="last-harvest">
                Last harvest
                <span className="last-harve-value">
                  {amountLastHarvest != "0" ? (
                    amountLastHarvest
                  ) : (
                    <span className="skeleton-box" style={{ width: "100px", height: "25px" }} />
                  )}
                </span>
              </div>
            </StyledVaultInfoSection>
          </div>

          <div className="right">
            <VaultAction
              active={active}
              library={library}
              balance={balance}
              value={value}
              setValue={setValue}
              account={account}
              connectWallet={connectWallet}
              currentActiveTab={activeTab}
              chainId={chainId}
              Token={Token}
              currentVaultAddress={currentVaultAddress}
              NewVaultJson={NewVaultJson}
              contractFetcher={contractFetcher}
              amountTokenDeposit={amountTokenDeposit}
              rateWithdraw={rateWithdraw}
              setPendingTxns={setPendingTxns}
            />

            <VaultTab currentActiveTab={activeTab} chainId={chainId} />
          </div>
        </StyledWrapperSectionIpad>
      ) : (
        <StyledWrapperSection>
          <div className="left">
            {renderHeader()}
            <VaultTab currentActiveTab={activeTab} chainId={chainId} />
          </div>

          <div className="right">
            <StyledVaultInfoSection>
              <div className="your-deposit">
                Your deposit
                {amountTokenDeposit !== "--" && priceToken ? (
                  <span className="your-deposit-value">
                    {amountTokenDeposit !== "--" && priceToken ? (
                      "$" + formatNumber(amountTokenDeposit * priceToken * rateWithdraw, 2)
                    ) : (
                      <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                    )}
                  </span>
                ) : (
                  <span className="your-deposit-value">
                    <div className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                  </span>
                )}
              </div>

              <div className="last-harvest">
                Last harvest
                <span className="last-harve-value">
                  {amountLastHarvest != "0" ? (
                    amountLastHarvest
                  ) : (
                    <span className="skeleton-box" style={{ width: "60px", height: "20px" }} />
                  )}
                </span>
              </div>
            </StyledVaultInfoSection>

            <VaultAction
              active={active}
              library={library}
              balance={balance}
              value={value}
              setValue={setValue}
              account={account}
              connectWallet={connectWallet}
              currentActiveTab={activeTab}
              chainId={chainId}
              Token={Token}
              currentVaultAddress={currentVaultAddress}
              NewVaultJson={NewVaultJson}
              contractFetcher={contractFetcher}
              amountTokenDeposit={amountTokenDeposit}
              rateWithdraw={rateWithdraw}
              setPendingTxns={setPendingTxns}
            />
          </div>
        </StyledWrapperSection>
      )}
      <Footer />
    </Wrapper>
  );
}

const StyledTooltipComponent = styled(TooltipComponent)`
  .Tooltip-row-value {
    margin-top: 0 !important;
  }
  .Tooltip-row > span {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  .Tooltip {
    margin-top: 0 !important;
    margin-bottom: 18px;
  }
  display: inline-block !important;

  .Tooltip-handle {
    display: inline-block;
    margin-top: 0;
  }

  /* .Tooltip-popup {
    top: 150%;
    left: 100%;
    transform: translateX(-50%);
  } */
`;

const Wrapper = styled.div`
  max-width: unset;
  width: fit-content;

  padding: 64px 0 286px 0;

  .Page-title {
    div {
      display: flex;
      align-items: center;

      gap: 6px;

      font-size: 24px;

      img {
        width: 16px;
      }
    }
  }

  @media (max-width: 1023px) {
    /* padding-bottom: 0 !important; */
    min-height: unset !important;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: 600px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .old_vault {
    margin-bottom: 24px;

    padding: 12px 24px;
    gap: 10px;

    background: #f6475d;
    border-radius: 12px;
  }
  &.theme--light {
    background: linear-gradient(180deg, #f7fffd 0%, #eef1f7 100%);

    & > .Tab.block {
      .Tab-option {
        color: var(--Text-Text_Primary, #0d1a16);

        &.active {
          background: var(--Border, rgba(13, 26, 22, 0.07));
        }
      }
    }

    .section-title-content {
      .description {
        color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
      }
    }

    .Tab.block {
      background: #fff !important;
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
      border-radius: 16px;

      /* .Tab-option {
        &:hover,
        &.active {
          background: var(--Border, rgba(13, 26, 22, 0.07));
          color: var(--Text-Text_Primary, #0d1a16);
        }
      } */
    }

    ${StyledVaultInfoSection} {
      background-color: #fff !important;
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07));
    }
  }
`;
