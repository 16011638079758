import cx from "classnames";
import { BigNumber } from "ethers";
import { useEffect, useMemo, useState } from "react";

import { SLIPPAGE_BPS_KEY } from "config/localStorage";
import {
  DEFAULT_SLIPPAGE_AMOUNT,
  STOP_LOSS,
  TAKE_PROFIT,
  USD_DECIMALS,
  getLiqPrice,
  getLiquidPriceV2,
  importImage,
} from "lib/legacy";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { formatAmount, parseValue } from "lib/numbers";
import styled from "styled-components";
import { preventSpecialCharacters } from "utils/helpers";
import Modal from "../Modal/Modal";
import Tab from "../Tab/Tab";
import ExchangeInfoRow from "./ExchangeInfoRow";
import PercentageButtons from "./PercentageButtons";
import "./PositionSeller.css";
import TakeProfitLongTriggerButton from "./TakeProfitLongTriggerButton";
import TakeProfitShortTriggerButton from "./TakeProfitShortTriggerButton";
import { formatShortNumber } from "utils/formatPrice";
import GreenArraowIcon from "components/Icon/GreenArraowIcon";
import useSWR from "swr";
import { getContract } from "config/contracts";
import { contractFetcher } from "lib/contracts";
import SettingsManager from "abis/SettingsManager.json";
import Checkbox from "components/Checkbox/Checkbox";
// const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "grey" }} spin />;
// const { AddressZero } = ethers.constants;
// const ORDER_SIZE_DUST_USD = expandDecimals(1, USD_DECIMALS - 1); // $0.10

export default function AddTPSL(props) {
  const {
    onConfirm,
    isVisible,
    position,
    setIsVisible,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    chainId,
    marketTokensInfo,
    isConfirm,
    library,
  } = props;
  const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
  const markPrice = BigNumber.from(currentMarket.askPrice || 1);
  let [orderOption, setOrderOption] = useState(TAKE_PROFIT);
  const [triggerPriceInput, setTriggerPriceInput] = useState("");
  const [closeQuantity, setCloseQuantity] = useState("");
  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);
  const ORDER_OPTIONS = [TAKE_PROFIT, STOP_LOSS];
  const ORDER_OPTION_LABELS = {
    [TAKE_PROFIT]: TAKE_PROFIT,
    [STOP_LOSS]: STOP_LOSS,
  };
  const settingsManagerAddress = getContract(chainId, "SettingsManager");
  const { data: liquidateThreshold } = useSWR(
    position.tokenId && [
      `ExChange:liquidateThreshold-${position.posId}`,
      chainId,
      settingsManagerAddress,
      "liquidateThreshold",
      position.tokenId,
    ],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const onOrderOptionChange = (option) => {
    setOrderOption(option);
  };
  const [takeProfitPrice, setTakeProfitPrice] = useState(0);
  const [takeProfitQuantity, setTakeProfitQuantity] = useState(0);
  const [stopLossPrice, setStopLossPrice] = useState(0);
  const [stopLossQuantity, setStopLossQuantity] = useState(0);
  const setTPSLTrigerPriceValue = (value) => {
    if (orderOption === TAKE_PROFIT) {
      setTakeProfitPrice(value);
    } else {
      setStopLossPrice(value);
    }
  };
  const handleMarkPriceClicked = () => {
    const value = formatAmount(markPrice, 30, 6, false);
    setTriggerPriceInput(value);
  };
  const accruedFees = parseValue(position.accruedPositionFee, 30)
    .add(parseValue(position.accruedFundingFee, 30))
    .add(parseValue(position.accruedBorrowFee, 30));

  const newTPCollateral = useMemo(() => {
    if (takeProfitQuantity && !takeProfitQuantity.eq(0)) {
      const sizeDelta = takeProfitQuantity.mul(parseValue(position.averagePrice, 30)).div(parseValue(1, 30));
      const newColateral = parseValue(position.collateral, 30).sub(
        sizeDelta.mul(parseValue(1, 30)).div(parseValue(position.leverage, 30))
      );
      return newColateral.gt(0) ? newColateral : BigNumber.from(0);
    }
    return null;
  }, [takeProfitQuantity]);
  const TpSizeDelta = useMemo(() => {
    if (takeProfitQuantity && takeProfitQuantity !== 0) {
      const sizeDelta = takeProfitQuantity.mul(parseValue(position.averagePrice, 30)).div(parseValue(1, 30));
      return sizeDelta;
    }
    return null;
  }, [takeProfitQuantity]);
  const TPPercentSizeDelta = takeProfitQuantity
    ? takeProfitQuantity.eq(position.quantity)
      ? parseValue(1, 30)
      : takeProfitQuantity.mul(parseValue(1, 30)).div(position.quantity)
    : null;

  const SLPercentSizeDelta = stopLossQuantity
    ? stopLossQuantity.eq(position.quantity, 30)
      ? parseValue(1, 30)
      : stopLossQuantity.mul(parseValue(1, 30)).div(position.quantity)
    : null;
  const newSLCollateral = useMemo(() => {
    if (stopLossQuantity && stopLossQuantity !== 0) {
      const sizeDelta = stopLossQuantity.mul(parseValue(position.averagePrice, 30)).div(parseValue(1, 30));
      const newColateral = parseValue(position.collateral, 30).sub(
        sizeDelta.mul(parseValue(1, 30)).div(parseValue(position.leverage, 30))
      );
      return newColateral;
    }
    return null;
  }, [stopLossQuantity]);
  const SLSizeDelta = useMemo(() => {
    if (stopLossQuantity && stopLossQuantity !== 0) {
      const sizeDelta = stopLossQuantity.mul(parseValue(position.averagePrice, 30)).div(parseValue(1, 30));
      return sizeDelta;
    }
    return null;
  }, [stopLossQuantity]);

  // const newLiquidPriceTP =
  //   newTPCollateral && newTPCollateral.gt(0)
  //     ? getLiqPrice(
  //         liquidateThreshold,
  //         newTPCollateral,
  //         parseValue(position.size, 30).sub(TpSizeDelta),
  //         accruedFees.sub(accruedFees.mul(TPPercentSizeDelta).div(parseValue(1, 30))),
  //         position.isLong,
  //         parseValue(position.averagePrice, 30)
  //       )
  //     : null;
  const newLiquidPriceTP =
    newTPCollateral && newTPCollateral.gt(0)
      ? parseValue(
          getLiquidPriceV2(
            newTPCollateral.mul(parseValue(position.leverage, 30)),
            newTPCollateral,
            position.isLong,
            accruedFees.sub(accruedFees.mul(TPPercentSizeDelta).div(parseValue(1, 30))),
            parseValue(position.averagePrice, 30),
            liquidateThreshold
          ),
          30
        )
      : null;
  // const newLiquidPriceSL =
  //   newSLCollateral && newSLCollateral.gt(0)
  //     ? getLiqPrice(
  //         liquidateThreshold,
  //         newSLCollateral,
  //         parseValue(position.size, 30).sub(SLSizeDelta),
  //         accruedFees.sub(accruedFees.mul(SLPercentSizeDelta).div(parseValue(1, 30))),
  //         position.isLong,
  //         parseValue(position.averagePrice, 30)
  //       )
  //     : null;
  const newLiquidPriceSL =
    newSLCollateral && newSLCollateral.gt(0)
      ? parseValue(
          getLiquidPriceV2(
            newSLCollateral.mul(parseValue(position.leverage, 30)),
            newSLCollateral,
            position.isLong,
            accruedFees.sub(accruedFees.mul(SLPercentSizeDelta).div(parseValue(1, 30))),
            parseValue(position.averagePrice, 30),
            liquidateThreshold
          ),
          30
        )
      : null;
  const handleTakeProfitLongPercent = (percentage) => {
    const value = parseValue(position.averagePrice, 30).add(
      parseValue(percentage, 30).mul(parseValue(position.averagePrice, 30)).div(parseValue(1, 30))
    );
    setTriggerPriceInput(formatAmount(value, 30, 6, false));
    setTPSLTrigerPriceValue(value);
  };
  const handleTakeProfitShortPercent = (percentage) => {
    const value = parseValue(position.averagePrice, 30).sub(
      parseValue(percentage, 30).mul(parseValue(position.averagePrice, 30)).div(parseValue(1, 30))
    );
    setTriggerPriceInput(formatAmount(value, 30, 6, false));
    setTPSLTrigerPriceValue(value);
  };
  const handleTrigerPriceInputChange = (e) => {
    const value = e.target.value;
    setTriggerPriceInput(value);
    if (value) {
      setTPSLTrigerPriceValue(parseValue(value, 30));
    } else {
      setTPSLTrigerPriceValue(0);
    }
  };
  const setCloseQuantityValue = (value) => {
    if (orderOption === TAKE_PROFIT) setTakeProfitQuantity(value);
    else setStopLossQuantity(value);
  };
  const handleSelectPercentageCloseQuantity = (percentage) => {
    const value = position.quantity.mul(parseValue(percentage, 30)).div(parseValue(1, 30));
    setCloseQuantity(formatAmount(value, 30, 30, false));
    setCloseQuantityValue(value);
  };
  const estTakeProfitPnl = () => {
    if (takeProfitPrice && takeProfitQuantity) {
      // debugger
      const markPrice = takeProfitPrice;
      const averagePrice = parseValue(position.averagePrice, 30);
      let size = 0;
      let collateral = 0;
      if (takeProfitQuantity.eq(position.quantity)) {
        size = parseValue(position.size, 30);
        collateral = parseValue(position.collateral, 30);
      } else {
        size = takeProfitQuantity.mul(averagePrice).div(parseValue(1, 30));
        collateral = size.mul(parseValue(1, 30)).div(parseValue(position.leverage, 30));
      }
      const pnl = position.isLong
        ? markPrice.sub(averagePrice).mul(size).div(averagePrice)
        : averagePrice.sub(markPrice).mul(size).div(averagePrice);
      const percentPnl = pnl.mul(parseValue(100, 30)).div(parseValue(position.collateral, 30));
      return {
        pnl,
        prefix: pnl?.gt(0) ? "+" : pnl?.lt(0) ? "-" : "",
        percentPnl,
        recieve: pnl.add(collateral),
        collateral,
        size,
      };
    }
    return null;
  };
  const estStopLossPnl = () => {
    if (stopLossPrice && stopLossQuantity) {
      // debugger
      const markPrice = stopLossPrice;
      const averagePrice = parseValue(position.averagePrice, 30);
      let size = 0;
      let collateral = 0;
      if (stopLossQuantity.eq(position.quantity)) {
        size = parseValue(position.size, 30);
        collateral = parseValue(position.collateral, 30);
      } else {
        size = stopLossQuantity.mul(averagePrice).div(parseValue(1, 30));
        collateral = size.mul(parseValue(1, 30)).div(parseValue(position.leverage, 30));
      }
      const pnl = position.isLong
        ? markPrice.sub(averagePrice).mul(size).div(averagePrice)
        : averagePrice.sub(markPrice).mul(size).div(averagePrice);
      const percentPnl = pnl.mul(parseValue(100, 30)).div(parseValue(position.collateral, 30));
      return {
        pnl,
        prefix: pnl?.gt(0) ? "+" : pnl?.lt(0) ? "-" : "",
        percentPnl,
        recieve: pnl.add(collateral),
      };
    }
    return null;
  };
  const estTakeProfit = estTakeProfitPnl();

  const estStopLoss = estStopLossPnl();
  const onHandleAddTPSL = () => {
    const isTP = !!takeProfitPrice && !!takeProfitQuantity;
    const isSL = !!stopLossPrice && !!stopLossQuantity;
    if (isTP || isSL) {
      const prices = [];
      const isTPs = [isTP];
      const amountPercents = [];
      if (isSL && isTP) isTPs.push(false);
      if (isTP && !isSL) {
        prices.push(takeProfitPrice);
      } else if (!isTP && isSL) {
        prices.push(stopLossPrice);
      } else {
        prices.push(takeProfitPrice);
        prices.push(stopLossPrice);
      }

      if (isTP && !isSL) {
        const percent = takeProfitQuantity.eq(position.quantity)
          ? 100
          : Number(formatAmount(takeProfitQuantity.mul(parseValue(100, 30)).div(position.quantity), 30, 2, false));
        amountPercents.push(percent * 1000);
      } else if (!isTP && isSL) {
        const percent = stopLossQuantity.eq(position.quantity)
          ? 100
          : Number(formatAmount(stopLossQuantity.mul(parseValue(100, 30)).div(position.quantity), 30, 2, false));
        amountPercents.push(percent * 1000);
      } else {
        const percentTakeProfit = takeProfitQuantity.eq(position.quantity)
          ? 100
          : Number(formatAmount(takeProfitQuantity.mul(parseValue(100, 30)).div(position.quantity), 30, 2, false));
        const percentStopLoss = stopLossQuantity.eq(position.quantity)
          ? 100
          : Number(formatAmount(stopLossQuantity.mul(parseValue(100, 30)).div(position.quantity), 30, 2, false));
        amountPercents.push(percentTakeProfit * 1000);
        amountPercents.push(percentStopLoss * 1000);
      }
      // console.log("???", { position, isTPs, prices, amountPercents });
      onConfirm && onConfirm(position, isTPs, prices, amountPercents);
    }
  };
  useEffect(() => {
    if (orderOption === TAKE_PROFIT) {
      if (takeProfitPrice) {
        setTriggerPriceInput(formatAmount(takeProfitPrice, 30, 6, false));
        setCloseQuantity(formatAmount(takeProfitQuantity, 30, 30, false));
      } else {
        setTriggerPriceInput("");
        setCloseQuantity("");
      }
    } else {
      if (stopLossPrice) {
        setTriggerPriceInput(formatAmount(stopLossPrice, 30, 6, false));
        setCloseQuantity(formatAmount(stopLossQuantity, 30, 30, false));
      } else {
        setTriggerPriceInput("");
        setCloseQuantity("");
      }
    }
  }, [orderOption]);
  const handleChangeQuantityInput = (e) => {
    const value = e.target.value;
    setCloseQuantity(value);
    if (value && Number(value)) {
      if (orderOption === TAKE_PROFIT) {
        setTakeProfitQuantity(parseValue(value, 30));
      } else {
        setStopLossQuantity(parseValue(value, 30));
      }
    } else {
      if (orderOption === TAKE_PROFIT) {
        setTakeProfitQuantity(0);
      } else {
        setStopLossQuantity(0);
      }
    }
  };

  const getErrorTakeProfit = (_takeProfitPrice) => {
    if (triggerPriceInput === "") return false;

    if (!Number(triggerPriceInput)) {
      return "Enter an amount";
    }

    const triggerPrice = _takeProfitPrice || parseValue(triggerPriceInput, 30);

    if (position.isLong) {
      if (triggerPrice.lte(markPrice)) {
        return "Please adjust the Trigger Price so that it is higher than the mark price";
      }
    } else {
      if (triggerPrice.gte(markPrice)) {
        return "Please adjust the Trigger Price so that it is lower than the mark price";
      }
    }

    return false;
  };

  const getErrorStopLoss = (_stopLossPrice) => {
    if (triggerPriceInput === "") return false;

    if (!Number(triggerPriceInput)) {
      return "Enter an amount";
    }

    const triggerPrice = _stopLossPrice || parseValue(triggerPriceInput, 30);

    if (position.isLong) {
      if (triggerPrice.gte(markPrice) || triggerPrice.lte(parseValue(position.liquidationPrice, 30))) {
        return "Please adjust the Trigger Price so that it is lower than the mark price and higher than liquidation price";
      }
    } else {
      if (triggerPrice.lte(markPrice) || triggerPrice.gte(parseValue(position.liquidationPrice, 30))) {
        return "Please adjust the Trigger Price so that it is higher than the mark price and lower than liquidation price";
      }
    }

    return false;
  };

  const getErrorCloseQuantity = () => {
    if (closeQuantity === "") return false;

    if (!Number(closeQuantity)) {
      return "Enter close quantity";
    }

    if (position.quantity && closeQuantity && parseValue(closeQuantity, 30).gt(position.quantity)) {
      return "Amount exceeds balance";
    }

    return false;
  };

  const isDisableButton = () => {
    if (!triggerPriceInput || !Number(triggerPriceInput) || !closeQuantity || !Number(closeQuantity)) {
      return true;
    }

    if (orderOption === TAKE_PROFIT) {
      return !!getErrorTakeProfit() || !!getErrorCloseQuantity();
    }
    return !!getErrorStopLoss() || !!getErrorCloseQuantity();
  };

  const getPrimaryText = () => {
    if (closeQuantity === "") return isConfirm ? "Adding..." : "Add TP/SL";

    if (!Number(closeQuantity)) {
      return "Enter close quantity";
    }

    if (position.quantity && closeQuantity && parseValue(closeQuantity, 30).gt(position.quantity)) {
      return "Amount exceeds balance";
    }

    return "Add TP/SL";
  };
  // estStopLoss?.recieve &&
  //                 accruedFees &&
  //                 SLPercentSizeDelta &&
  //                 estStopLoss?.recieve.sub(accruedFees.mul(TPPercentSizeDelta).div(parseValue(1, 30)))
  // console.log("????", {
  //   recieve: estStopLoss?.recieve,
  //   accruedFees,
  //   SLPercentSizeDelta,
  //   // test:
  //   //   estStopLoss?.recieve && accruedFees && SLPercentSizeDelta
  //   //     ? estStopLoss?.recieve.sub(accruedFees.mul(TPPercentSizeDelta).div(parseValue(1, 30))).lt(0)
  //   //     : "----",
  // });

  const takeProfitReceive =
    estTakeProfit?.recieve && accruedFees && TPPercentSizeDelta
      ? estTakeProfit?.recieve.sub(accruedFees.mul(TPPercentSizeDelta).div(parseValue(1, 30)))
      : undefined;
  const stopLossReceive =
    estStopLoss?.recieve && accruedFees && SLPercentSizeDelta
      ? estStopLoss?.recieve.sub(accruedFees.mul(SLPercentSizeDelta).div(parseValue(1, 30)))
      : undefined;

  const isValidTP = !getErrorTakeProfit(takeProfitPrice) && !getErrorCloseQuantity();
  const isValidSL = !getErrorStopLoss(stopLossPrice) && !getErrorCloseQuantity();

  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal confirmation-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Add Take Profit / Stop Loss"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <div className="position-info-container">
              <div className="position-info">
                <img src={importImage("ic_" + position?.symbol?.toLowerCase() + "_24.svg")} alt="" />
                <div className="title">{position.symbol}</div>
                <div className="position-id">#{position?.posId}</div>
              </div>
              <div className="position-tags">
                <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                  {position?.isLong ? "LONG" : "SHORT"}
                </div>
                <div className="side">
                  {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                </div>
                <div className="leverage-box">{formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X</div>
              </div>
              {/* <div className="position-id">#{position?.posId}</div> */}
            </div>
          </div>
          <div className="content-container">
            <Tab
              options={ORDER_OPTIONS}
              option={orderOption}
              optionLabels={ORDER_OPTION_LABELS}
              onChange={onOrderOptionChange}
            />
            <div className="Exchange-swap-section" style={{ marginBottom: "8px", marginTop: "16px" }}>
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  <div className="Exchange-swap-usd">
                    <span>
                      {/* Close: {convertedAmountFormatted} {position.collateralToken.symbol} */}
                      Trigger Price
                    </span>
                  </div>
                </div>
                <div className="muted align-right clickable" onClick={handleMarkPriceClicked}>
                  <span>Mark: ${formatAmount(markPrice, 30, currentMarket?.decimals || 2, true)}</span>
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={triggerPriceInput}
                    onChange={handleTrigerPriceInputChange}
                    onKeyDown={preventSpecialCharacters}
                  />
                </div>
                <div className="PositionEditor-token-symbol">USD</div>
              </div>
            </div>
            {orderOption === TAKE_PROFIT && <span className="validate-error">{getErrorTakeProfit()}</span>}
            {orderOption === STOP_LOSS && <span className="validate-error">{getErrorStopLoss()}</span>}
            {orderOption === TAKE_PROFIT ? (
              position.isLong ? (
                <TakeProfitLongTriggerButton
                  onChangePercentage={handleTakeProfitLongPercent}
                  balance={Number(formatAmount(parseValue(position.averagePrice, 30), 30, 30, false))}
                  value={triggerPriceInput}
                  percentArray={[0.01, 0.02, 0.03, 0.05]}
                />
              ) : (
                <TakeProfitShortTriggerButton
                  onChangePercentage={handleTakeProfitShortPercent}
                  balance={Number(formatAmount(parseValue(position.averagePrice, 30), 30, 30, false))}
                  value={triggerPriceInput}
                  percentArray={[0.01, 0.02, 0.03, 0.05]}
                />
              )
            ) : position.isLong ? (
              <TakeProfitShortTriggerButton
                onChangePercentage={handleTakeProfitShortPercent}
                balance={Number(formatAmount(parseValue(position.averagePrice, 30), 30, 30, false))}
                value={triggerPriceInput}
                percentArray={[0.01, 0.02, 0.03, 0.05]}
              />
            ) : (
              <TakeProfitLongTriggerButton
                onChangePercentage={handleTakeProfitLongPercent}
                balance={Number(formatAmount(parseValue(position.averagePrice, 30), 30, 30, false))}
                value={triggerPriceInput}
                percentArray={[0.01, 0.02, 0.03, 0.05]}
              />
            )}
            <div className="Exchange-swap-section" style={{ marginBottom: "8px", marginTop: "16px" }}>
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  <span>Close Quantity</span>
                </div>
                <div className="muted align-right">
                  <span>Max: {formatAmount(position.quantity, 30, 4, true)}</span>
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={closeQuantity}
                    onChange={handleChangeQuantityInput}
                    onKeyDown={preventSpecialCharacters}
                  />
                </div>
                <div className="PositionEditor-token-symbol">{position.symbol}</div>
              </div>
            </div>
            <PercentageButtons
              onChangePercentage={handleSelectPercentageCloseQuantity}
              balance={Number(formatAmount(position.quantity, 30, 30, false))}
              value={closeQuantity}
            />
            <div className="PositionEditor-keep-leverage-settings" style={{ marginTop: "24px" }}>
              <Checkbox isChecked={true}>
                <span className="muted font-sm">
                  <span style={{ fontWeight: 500 }}>
                    Keep leverage at {formatAmount(parseValue(position.leverage, 30), 30, 2)}x
                  </span>
                </span>
              </Checkbox>
            </div>
            <ExchangeInfoRow label={`Entry Price`}>
              ${formatAmount(parseValue(position.averagePrice, 30), 30, currentMarket?.decimals || 2, true)}
            </ExchangeInfoRow>
            <Divider />
            <div className="PositionEditor-info-box" style={{ marginTop: "16px" }}>
              <div className="square-container square-fee-container">
                <div className="add-profit-title">Take Profit</div>
                <ExchangeInfoRow label={`Trigger Price`}>
                  {takeProfitPrice ? (!position.isLong ? "≤ " : "≥ ") : ""}
                  {takeProfitPrice && isValidTP
                    ? "$" + formatAmount(takeProfitPrice, 30, currentMarket?.decimals || 2, true)
                    : "-"}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`TP Qty`}>
                  {takeProfitQuantity && !takeProfitQuantity.eq(0) && isValidTP ? (
                    <>
                      {formatAmount(takeProfitQuantity, 30, 4, true)} {position.symbol}
                    </>
                  ) : (
                    "-"
                  )}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Size`}>
                  {newTPCollateral && isValidTP ? (
                    <span>
                      <span style={{ textDecoration: "line-through", color: "#828899" }}>
                        ${formatAmount(parseValue(position.size, 30), 30, 2)}
                      </span>{" "}
                      <span style={{ position: "relative", top: "2px" }}>
                        {" "}
                        <GreenArraowIcon fill={"#828899"} />
                      </span>{" "}
                      ${formatAmount(newTPCollateral.mul(parseValue(position.leverage, 30)), 60, 2, true)}
                    </span>
                  ) : (
                    `$${formatAmount(parseValue(position.size, 30), 30, 2, true)}`
                  )}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Collateral`}>
                  {newTPCollateral && isValidTP ? (
                    <span>
                      <span style={{ textDecoration: "line-through", color: "#828899" }}>
                        ${formatAmount(parseValue(position.collateral, 30), 30, 2)}
                      </span>{" "}
                      <span style={{ position: "relative", top: "2px" }}>
                        {" "}
                        <GreenArraowIcon fill={"#828899"} />
                      </span>{" "}
                      ${formatShortNumber(formatAmount(newTPCollateral, 30, 2, false), 2)}
                    </span>
                  ) : (
                    `$${formatAmount(parseValue(position.collateral, 30), 30, 2, true)}`
                  )}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Liq. Price`}>
                  {takeProfitQuantity && takeProfitQuantity.eq(position.quantity) ? (
                    "-"
                  ) : newLiquidPriceTP && isValidTP ? (
                    <span>
                      <span style={{ textDecoration: "line-through", color: "#828899" }}>
                        $
                        {formatAmount(
                          parseValue(position.liquidationPrice, 30),
                          30,
                          currentMarket?.decimals || 2,
                          true
                        )}
                      </span>{" "}
                      <span style={{ position: "relative", top: "2px" }}>
                        {" "}
                        <GreenArraowIcon fill={"#828899"} />
                      </span>{" "}
                      $
                      {formatShortNumber(
                        formatAmount(newLiquidPriceTP, 30, currentMarket?.decimals || 2, true),
                        currentMarket?.decimals || 2
                      )}
                    </span>
                  ) : (
                    `$${formatAmount(parseValue(position.liquidationPrice, 30), 30, currentMarket?.decimals || 2)}`
                  )}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Fees`}>
                  {accruedFees && TPPercentSizeDelta ? (accruedFees.gte(0) ? "" : "-") : ""}
                  {TPPercentSizeDelta && isValidTP
                    ? "$" +
                      formatAmount(accruedFees.mul(TPPercentSizeDelta).div(parseValue(1, 30)), 30, 4, true).replace(
                        "-",
                        ""
                      )
                    : "-"}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Receive`}>
                  {takeProfitReceive?.gte(0) ? "" : "-"}
                  {takeProfitReceive && isValidTP
                    ? "$" + formatAmount(takeProfitReceive, 30, 2, true).replace("-", "")
                    : "-"}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Est. PnL`}>
                  <div
                    className={cx({
                      positive: estTakeProfit?.pnl.gt(0),
                      negative: estTakeProfit?.pnl.lt(0),
                    })}
                  >
                    {estTakeProfit && isValidTP
                      ? `${estTakeProfit?.prefix}$${formatAmount(estTakeProfit?.pnl, 30, 2, true).replace("-", "")}`
                      : "-"}
                  </div>
                </ExchangeInfoRow>
                <Divider />
                <div className="add-profit-title">Stop Loss</div>
                <ExchangeInfoRow label={`Trigger Price`}>
                  {stopLossPrice ? (position.isLong ? "≤ " : "≥ ") : ""}
                  {stopLossPrice && isValidSL
                    ? "$" + formatAmount(stopLossPrice, 30, currentMarket?.decimals || 2, true)
                    : "-"}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`SL Qty`}>
                  {" "}
                  {stopLossQuantity && isValidSL ? (
                    <>
                      {formatAmount(stopLossQuantity, 30, 4, true)} {stopLossQuantity ? position.symbol : ""}
                    </>
                  ) : (
                    "-"
                  )}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Size`}>
                  {newSLCollateral ? (
                    <span>
                      <span style={{ textDecoration: "line-through", color: "#828899" }}>
                        ${formatAmount(parseValue(position.size, 30), 30, 2)}
                      </span>{" "}
                      <span style={{ position: "relative", top: "2px" }}>
                        {" "}
                        <GreenArraowIcon fill={"#828899"} />
                      </span>{" "}
                      $
                      {formatAmount(
                        newSLCollateral.eq(1)
                          ? newSLCollateral.mul(parseValue(position.leverage, 30)).abs()
                          : newSLCollateral.mul(parseValue(position.leverage, 30)),
                        60,
                        2,
                        true
                      )}
                    </span>
                  ) : (
                    `$${formatAmount(parseValue(position.size, 30), 30, 2, true)}`
                  )}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Collateral`}>
                  {newSLCollateral && isValidSL ? (
                    <span>
                      <span style={{ textDecoration: "line-through", color: "#828899" }}>
                        ${formatAmount(parseValue(position.collateral, 30), 30, 2)}
                      </span>{" "}
                      <span style={{ position: "relative", top: "2px" }}>
                        {" "}
                        <GreenArraowIcon fill={"#828899"} />
                      </span>{" "}
                      ${formatShortNumber(formatAmount(newSLCollateral, 30, 2, false), 2)}
                    </span>
                  ) : (
                    `$${formatAmount(parseValue(position.collateral, 30), 30, 2, true)}`
                  )}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Liq. Price`}>
                  {stopLossQuantity && stopLossQuantity.eq(position.quantity) ? (
                    "-"
                  ) : newLiquidPriceSL && isValidSL ? (
                    <span>
                      <span style={{ textDecoration: "line-through", color: "#828899" }}>
                        $
                        {formatAmount(
                          parseValue(position.liquidationPrice, 30),
                          30,
                          currentMarket?.decimals || 2,
                          true
                        )}
                      </span>{" "}
                      <span style={{ position: "relative", top: "2px" }}>
                        {" "}
                        <GreenArraowIcon fill={"#828899"} />
                      </span>{" "}
                      $
                      {formatShortNumber(
                        formatAmount(newLiquidPriceSL, 30, currentMarket?.decimals || 2, true),
                        currentMarket?.decimals || 2
                      )}
                    </span>
                  ) : (
                    `$${formatAmount(parseValue(position.liquidationPrice, 30), 30, currentMarket?.decimals || 2)}`
                  )}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Fees`}>
                  {accruedFees && SLPercentSizeDelta ? (accruedFees.gte(0) ? "" : "-") : ""}
                  {SLPercentSizeDelta && isValidSL
                    ? "$" +
                      formatAmount(accruedFees.mul(SLPercentSizeDelta).div(parseValue(1, 30)), 30, 4, true).replace(
                        "-",
                        ""
                      )
                    : "-"}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Receive`}>
                  {stopLossReceive?.gte(0) ? "" : "-"}
                  {stopLossReceive && isValidSL
                    ? "$" + formatAmount(stopLossReceive, 30, 2, true).replace("-", "")
                    : "-"}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Est. PnL`}>
                  <div
                    className={cx({
                      positive: estStopLoss?.pnl.gt(0),
                      negative: estStopLoss?.pnl.lt(0),
                    })}
                  >
                    {estStopLoss && isValidSL
                      ? `${estStopLoss?.prefix}$${formatAmount(estStopLoss?.pnl, 30, 2, true).replace("-", "")}`
                      : "-"}
                  </div>
                </ExchangeInfoRow>
                {/* <Divider /> */}

                {/* <ExchangeInfoRow label={`Mark Price`}>
                  ${formatAmount(markPrice, 30, currentMarket?.decimals || 2, true)}
                </ExchangeInfoRow>
                <ExchangeInfoRow label={`Pos. Size`}>
                  ${formatAmount(parseValue(position.size, 30), 30, currentMarket?.decimals || 2, true)} (
                  {formatAmount(position.quantity, 30, 4, true)} {position.symbol})
                </ExchangeInfoRow> */}
                {/* <ExchangeInfoRow label={`Pos. Qty`}>
                  {formatAmount(position.quantity, 30, 4, true)} {position.symbol}
                </ExchangeInfoRow> */}
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button
              className="default-btn Exchange-swap-button"
              onClick={onHandleAddTPSL}
              disabled={isDisableButton() || isConfirm}
            >
              {/* Add TP/SL */}
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}
export const Divider = styled.div`
  background: var(--Border, rgba(239, 236, 235, 0.08));
  height: 1px;
  width: 100%;
  margin: 12px 0 12px 0;
`;
