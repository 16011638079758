import { ImageFantom, ImageNavi, ImageOp } from "assets";
import React, { useContext } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { itemVariants, listVariants, titleVariants } from "lib/anim";
import { contentVariants, descVariant } from "./Banner";
import { StatsContext } from "contexts/StatsProvider";
import arbitrumIcon from "img/ic_s_24.svg";
import CountUp from "react-countup";
import { formatAmount } from "lib/numbers";
import { USD_DECIMALS } from "lib/legacy";

const Network = () => {
  const {
    v2,
    totalValueLocked,
    totalFees,
    totalTradingVolume,

    totalAllVersion,
  } = useContext(StatsContext);

  const networkData = [
    {
      title: "Sonic",
      img: arbitrumIcon,
      volume: (
        <CountUp
          end={parseFloat(formatAmount(totalAllVersion?.totalTradingVolume, USD_DECIMALS, 0))}
          separator=","
          prefix="$"
        />
      ),
      locked: (
        <CountUp
          end={parseFloat(formatAmount(totalValueLocked?.["totalAll"], USD_DECIMALS, 0))}
          separator=","
          prefix="$"
        />
      ),
      fees: (
        <CountUp end={parseFloat(formatAmount(totalAllVersion?.totalFees, USD_DECIMALS, 0))} separator="," prefix="$" />
      ),
    },
  ];
  return (
    <Wrapper>
      <div className="container wrapper">
        <div className="box-title">
          <motion.div initial="hidden" whileInView="visible" variants={contentVariants} className="title">
            Available on your preferred network
          </motion.div>
          <motion.div initial="hidden" whileInView="visible" variants={descVariant} className="desc">
            Navigator is currently live on Sonic Testnet.
          </motion.div>
        </div>
        <motion.div variants={listVariants} initial="hidden" whileInView="visible" className="box-content">
          {networkData.map((item, idx) => (
            <motion.div variants={itemVariants} className="box-item" key={idx}>
              <div className="box-info">
                <img className="img-box" src={item.img} alt="" />
                <div>{item.title}</div>
              </div>
              <div className="wall"></div>

              <div className="wrap-value">
                <div className="box-value">
                  <div className="title-value">Total Volume</div>
                  <div className="price">{item.volume}</div>
                </div>
                <div className="box-value">
                  <div className="title-value">Total Value Locked</div>
                  <div className="price">{item.locked}</div>
                </div>
                <div className="box-value">
                  <div className="title-value">Total Fees</div>
                  <div className="price">{item.fees}</div>
                </div>
              </div>
              <a href="https://app.navigator.exchange/#/trade" className="default-btn btn">
                Launch App
              </a>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </Wrapper>
  );
};

export default Network;

const Wrapper = styled.div`
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 24px;
  }
  .box-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
  .title {
    color: #fff;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 50.4px */
    @media screen and (max-width: 768px) {
      font-size: 28px;
      text-align: center;
    }
  }
  .img-box {
    width: 48px;
    height: 48px;
  }
  .desc {
    color: var(--Text-Text_Secondary, #828899);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
  .box-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
  }
  .box-item {
    display: flex;
    padding: 24px;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-radius: 24px;
    background: #0c0f0c;
    width: 100%;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .wall {
    width: 1px;
    align-self: stretch;
    background: var(--Border, rgba(255, 255, 255, 0.1));
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .wrap-value {
    display: flex;
    align-items: center;
    width: 100%;
    flex: 1;
    @media screen and (max-width: 1024px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
    }
  }
  .box-info {
    display: flex;
    width: 160px;
    align-items: center;
    gap: 12px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
    @media screen and (max-width: 768px) {
      flex: 1;
    }
  }
  .box-value {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Base-Unit-XS-8, 8px);
    flex: 1 0 0;

    @media screen and (max-width: 1024px) {
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
  .title-value {
    color: var(--Text-Text_Secondary, #828899);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
  .price {
    color: Text/Text_Primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
  }
  .btn {
    display: flex;
    padding: var(--Base-Unit-XS-8, 8px) 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    color: var(--Text-Text_Button, #000);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
    height: inherit;
  }
`;
