function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#000"
        d="M20.824 5.69L22.5 4.097V3.75h-5.805l-4.137 10.22L7.852 3.75H1.765v.348l1.958 2.339c.19.173.29.425.265.68v9.19c.06.331-.049.672-.283.912L1.5 19.871v.344h6.252v-.348l-2.205-2.648a1.075 1.075 0 01-.304-.912v-7.95l5.488 11.863h.638l4.719-11.862v9.45c0 .248 0 .3-.165.463l-1.697 1.63v.349h8.236v-.348l-1.637-1.59a.481.481 0 01-.187-.464V6.153a.48.48 0 01.186-.464z"
      ></path>
    </svg>
  );
}

export default Icon;
