function Icon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? "36"}
      height={props.size ?? "36"}
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M16.5 3A7.5 7.5 0 009 10.5v1.689A6.003 6.003 0 004.5 18v9a6 6 0 006 6h15a6 6 0 006-6v-9a6.003 6.003 0 00-4.5-5.811V10.5A7.5 7.5 0 0019.5 3h-3zm7.5 9v-1.5A4.5 4.5 0 0019.5 6h-3a4.5 4.5 0 00-4.5 4.5V12h12zm-13.5 3h15a3 3 0 013 3v9a3 3 0 01-3 3h-15a3 3 0 01-3-3v-9a3 3 0 013-3z"
        clipRule="evenodd"
      ></path>
      <mask id="path-2-inside-1_17917_5803" fill="#fff">
        <path
          fillRule="evenodd"
          d="M19.5 23.599A2.999 2.999 0 0018 18a3 3 0 00-1.5 5.599v2.151c0 .69.672 1.25 1.5 1.25s1.5-.56 1.5-1.25v-2.151z"
          clipRule="evenodd"
        ></path>
      </mask>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M19.5 23.599A2.999 2.999 0 0018 18a3 3 0 00-1.5 5.599v2.151c0 .69.672 1.25 1.5 1.25s1.5-.56 1.5-1.25v-2.151z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        d="M19.5 23.599l-.5-.866-.5.289v.577h1zm-3 0h1v-.577l-.5-.289-.5.866zM20 21c0 .739-.4 1.386-1 1.733l1 1.731c1.194-.69 2-1.982 2-3.464h-2zm-2-2a2 2 0 012 2h2a4 4 0 00-4-4v2zm-2 2a2 2 0 012-2v-2a4 4 0 00-4 4h2zm1 1.733c-.6-.347-1-.994-1-1.733h-2c0 1.482.806 2.774 2 3.464l1-1.731zm.5 3.017v-2.151h-2v2.151h2zm.5.25a.662.662 0 01-.42-.134c-.08-.067-.08-.113-.08-.116h-2c0 1.406 1.298 2.25 2.5 2.25v-2zm.5-.25c0 .003 0 .049-.08.116A.662.662 0 0118 26v2c1.202 0 2.5-.844 2.5-2.25h-2zm0-2.151v2.151h2v-2.151h-2z"
        mask="url(#path-2-inside-1_17917_5803)"
      ></path>
    </svg>
  );
}

export default Icon;
