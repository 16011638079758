import { BigNumber, ethers } from "ethers";
import { gql } from "@apollo/client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Token as UniToken } from "@uniswap/sdk-core";
import { Pool } from "@uniswap/v3-sdk";
import useSWR from "swr";

import VaultNslp from "abis/VaultNslp.json";
import OrderVaultNslp from "abis/OrderVaultNslp.json";
import OrderBook from "abis/OrderBook.json";
import PositionManager from "abis/PositionManager.json";
import Vault from "abis/Vault.json";
import Router from "abis/Router.json";
import UniPool from "abis/UniPool.json";
import UniswapV2 from "abis/UniswapV2.json";
import LiquidityLocker from "abis/LiquidityLocker.json";

import Token from "abis/Token.json";
import PositionRouter from "abis/PositionRouter.json";
import ReaderV2 from "abis/ReaderV2.json";

import { getContract } from "config/contracts";
import {
  ARBITRUM,
  ARBITRUM_TESTNET,
  AVALANCHE,
  BASE,
  SONIC_TESTNET,
  FANTOM,
  OP,
  getConstant,
  getHighExecutionFee,
  SUPPORTED_CHAIN_IDS,
} from "config/chains";
import { DECREASE, getOrderKey, INCREASE, SWAP, USD_DECIMALS } from "lib/legacy";

import { groupBy } from "lodash";
import { UI_VERSION } from "config/ui";
import { getServerBaseUrl, getServerUrl } from "config/backend";
import { getNaviGraphClient, nissohGraphClient } from "lib/subgraph/clients";
import { callContract, contractFetcher } from "lib/contracts";
import { replaceNativeTokenAddress } from "./tokens";
import { getUsd } from "./tokens/utils";
import { getProvider } from "lib/rpc";
import { bigNumberify, expandDecimals, parseValue } from "lib/numbers";
import { getTokenBySymbol } from "config/tokens";
import { t } from "@lingui/macro";

export * from "./prices";

const { AddressZero } = ethers.constants;

export function useAllOrdersStats(chainId) {
  const query = gql(`{
    orderStat(id: "total") {
      openSwap
      openIncrease
      openDecrease
      executedSwap
      executedIncrease
      executedDecrease
      cancelledSwap
      cancelledIncrease
      cancelledDecrease
    }
  }`);

  const [res, setRes] = useState<any>();

  useEffect(() => {
    const graphClient = getNaviGraphClient(chainId);
    if (graphClient) {
      // eslint-disable-next-line no-console
      graphClient.query({ query }).then(setRes).catch(console.warn);
    }
  }, [setRes, query, chainId]);

  return res ? res.data.orderStat : null;
}

export function useUserStat(chainId) {
  const query = gql(`{
    userStat(id: "total") {
      id
      uniqueCount
    }
  }`);

  const [res, setRes] = useState<any>();

  useEffect(() => {
    // eslint-disable-next-line no-console
    getNaviGraphClient(chainId)?.query({ query }).then(setRes).catch(console.warn);
  }, [setRes, query, chainId]);

  return res ? res.data.userStat : null;
}

export function useLiquidationsData(chainId, account) {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (account) {
      const query = gql(`{
         liquidatedPositions(
           where: {account: "${account.toLowerCase()}"}
           first: 100
           orderBy: timestamp
           orderDirection: desc
         ) {
           key
           timestamp
           borrowFee
           loss
           collateral
           size
           markPrice
           type
         }
      }`);
      const graphClient = getNaviGraphClient(chainId);
      if (!graphClient) {
        return;
      }

      graphClient
        .query({ query })
        .then((res) => {
          const _data = res.data.liquidatedPositions.map((item) => {
            return {
              ...item,
              size: bigNumberify(item.size),
              collateral: bigNumberify(item.collateral),
              markPrice: bigNumberify(item.markPrice),
            };
          });
          setData(_data);
        })
        // eslint-disable-next-line no-console
        .catch(console.warn);
    }
  }, [setData, chainId, account]);

  return data;
}

export function useAllPositions(chainId, library) {
  const count = 1000;
  const query = gql(`{
    aggregatedTradeOpens(
      first: ${count}
    ) {
      account
      initialPosition{
        indexToken
        collateralToken
        isLong
        sizeDelta
      }
      increaseList {
        sizeDelta
      }
      decreaseList {
        sizeDelta
      }
    }
  }`);

  const [res, setRes] = useState<any>();

  useEffect(() => {
    // eslint-disable-next-line no-console
    nissohGraphClient.query({ query }).then(setRes).catch(console.warn);
  }, [setRes, query]);

  const key = res ? `allPositions${count}__` : null;

  const { data: positions = [] } = useSWR(key, async () => {
    const provider = getProvider(library, chainId);
    const vaultAddress = getContract(chainId, "Vault");
    const contract = new ethers.Contract(vaultAddress, Vault.abi, provider);
    const ret = await Promise.all(
      res.data.aggregatedTradeOpens.map(async (dataItem) => {
        try {
          const { indexToken, collateralToken, isLong } = dataItem.initialPosition;
          const positionData = await contract.getPosition(dataItem.account, collateralToken, indexToken, isLong);
          const position: any = {
            size: bigNumberify(positionData[0]),
            collateral: bigNumberify(positionData[1]),
            entryFundingRate: bigNumberify(positionData[3]),
            account: dataItem.account,
          };
          position.fundingFee = await contract.getFundingFee(collateralToken, position.size, position.entryFundingRate);
          position.marginFee = position.size.div(1000);
          position.fee = position.fundingFee.add(position.marginFee);

          const THRESHOLD = 5000;
          const collateralDiffPercent = position.fee.mul(10000).div(position.collateral);
          position.danger = collateralDiffPercent.gt(THRESHOLD);

          return position;
        } catch (ex) {
          // eslint-disable-next-line no-console
          console.error(ex);
        }
      })
    );

    return ret.filter(Boolean);
  });

  return positions;
}

export function useAllOrders(chainId, library) {
  const query = gql(`{
    orders(
      first: 1000,
      orderBy: createdTimestamp,
      orderDirection: desc,
      where: {status: "open"}
    ) {
      type
      account
      index
      status
      createdTimestamp
    }
  }`);

  const [res, setRes] = useState<any>();

  useEffect(() => {
    getNaviGraphClient(chainId)?.query({ query }).then(setRes);
  }, [setRes, query, chainId]);

  const key = res ? res.data.orders.map((order) => `${order.type}-${order.account}-${order.index}`) : null;
  const { data: orders = [] } = useSWR(key, () => {
    const provider = getProvider(library, chainId);
    const orderBookAddress = getContract(chainId, "OrderBook");
    const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, provider);
    return Promise.all(
      res.data.orders.map(async (order) => {
        try {
          const type = order.type.charAt(0).toUpperCase() + order.type.substring(1);
          const method = `get${type}Order`;
          const orderFromChain = await contract[method](order.account, order.index);
          const ret: any = {};
          for (const [key, val] of Object.entries(orderFromChain)) {
            ret[key] = val;
          }
          if (order.type === "swap") {
            ret.path = [ret.path0, ret.path1, ret.path2].filter((address) => address !== AddressZero);
          }
          ret.type = type;
          ret.index = order.index;
          ret.account = order.account;
          ret.createdTimestamp = order.createdTimestamp;
          return ret;
        } catch (ex) {
          // eslint-disable-next-line no-console
          console.error(ex);
        }
      })
    );
  });

  return orders.filter(Boolean);
}

export function usePositionsForOrders(chainId, library, orders) {
  const key = orders ? orders.map((order) => getOrderKey(order) + "____") : null;
  const { data: positions = {} } = useSWR(key, async () => {
    const provider = getProvider(library, chainId);
    const vaultAddress = getContract(chainId, "Vault");
    const contract = new ethers.Contract(vaultAddress, Vault.abi, provider);
    const data = await Promise.all(
      orders.map(async (order) => {
        try {
          const position = await contract.getPosition(
            order.account,
            order.collateralToken,
            order.indexToken,
            order.isLong
          );
          if (position[0].eq(0)) {
            return [null, order];
          }
          return [position, order];
        } catch (ex) {
          // eslint-disable-next-line no-console
          console.error(ex);
        }
      })
    );
    return data.reduce((memo, [position, order]) => {
      memo[getOrderKey(order)] = position;
      return memo;
    }, {});
  });

  return positions;
}

function invariant(condition, errorMsg) {
  if (!condition) {
    throw new Error(errorMsg);
  }
}

export function useTrades(chainId, account, forSingleAccount, afterId) {
  let url =
    account && account.length > 0
      ? `${getServerBaseUrl(chainId)}/trades?address=${account.toLowerCase()}`
      : !forSingleAccount && `${getServerBaseUrl(chainId)}/trades`;

  if (afterId && afterId.length > 0 && url) {
    const urlItem = new URL(url as string);
    urlItem.searchParams.append("after", afterId);
    url = urlItem.toString();
  }

  const { data: trades, mutate: updateTrades } = useSWR(url ? url : null, {
    dedupingInterval: 10000,
    // @ts-ignore
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });
  if (trades && !trades.error) {
    trades.sort((item0, item1) => {
      const data0 = item0;
      const data1 = item1;
      const time0 = parseInt(data0.timestamp);
      const time1 = parseInt(data1.timestamp);
      if (time1 > time0) {
        return 1;
      }
      if (time1 < time0) {
        return -1;
      }

      const block0 = parseInt(data0.blockNumber);
      const block1 = parseInt(data1.blockNumber);

      if (isNaN(block0) && isNaN(block1)) {
        return 0;
      }

      if (isNaN(block0)) {
        return 1;
      }

      if (isNaN(block1)) {
        return -1;
      }

      if (block1 > block0) {
        return 1;
      }

      if (block1 < block0) {
        return -1;
      }

      return 0;
    });
  }

  return { trades, updateTrades };
}

export function useMinExecutionFee(library, active, chainId, infoTokens) {
  const positionRouterAddress = getContract(chainId, "PositionRouter");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");

  const { data: minExecutionFee } = useSWR<BigNumber>([active, chainId, positionRouterAddress, "minExecutionFee"], {
    fetcher: contractFetcher(library, PositionRouter),
  });

  const { data: gasPrice } = useSWR<BigNumber | undefined>(["gasPrice", chainId], {
    fetcher: () => {
      return new Promise(async (resolve, reject) => {
        const provider = getProvider(library, chainId);
        if (!provider) {
          resolve(undefined);
          return;
        }

        try {
          const gasPrice = await provider.getGasPrice();
          resolve(gasPrice);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      });
    },
  });

  let multiplier;

  // if gas prices on Arbitrum are high, the main transaction costs would come from the L2 gas usage
  // for executing positions this is around 65,000 gas
  // if gas prices on Ethereum are high, than the gas usage might be higher, this calculation doesn't deal with that
  // case yet
  if (chainId === ARBITRUM || chainId === ARBITRUM_TESTNET) {
    multiplier = 65000;
  }

  // multiplier for Avalanche is just the average gas usage
  if (chainId === AVALANCHE) {
    multiplier = 700000;
  }
  if (chainId === FANTOM) {
    multiplier = 700000;
  }
  if (chainId === SONIC_TESTNET) {
    multiplier = 700000;
  }
  if (chainId === BASE) {
    multiplier = 700000;
  }

  let finalExecutionFee = minExecutionFee;

  if (gasPrice && minExecutionFee) {
    const estimatedExecutionFee = gasPrice.mul(multiplier);
    if (estimatedExecutionFee.gt(minExecutionFee)) {
      finalExecutionFee = estimatedExecutionFee;
    }
  }

  const finalExecutionFeeUSD = getUsd(finalExecutionFee, nativeTokenAddress, false, infoTokens);
  const isFeeHigh = finalExecutionFeeUSD?.gt(expandDecimals(getHighExecutionFee(chainId), USD_DECIMALS));
  const errorMessage =
    isFeeHigh &&
    `The network cost to send transactions is high at the moment, please check the "Execution Fee" value before proceeding.`;

  return {
    minExecutionFee: finalExecutionFee,
    minExecutionFeeUSD: finalExecutionFeeUSD,
    minExecutionFeeErrorMessage: errorMessage,
  };
}

export function useStakedNaviSupply(library, active) {
  const naviAddressArb = getContract(ARBITRUM, "NAVI");
  const stakedNaviTrackerAddressArb = getContract(ARBITRUM, "StakedNaviTracker");

  const { data: arbData, mutate: arbMutate } = useSWR<any>(
    [`StakeV2:stakedNaviSupply:${active}`, ARBITRUM, naviAddressArb, "balanceOf", stakedNaviTrackerAddressArb],
    {
      fetcher: contractFetcher(library, Token),
    }
  );

  const naviAddressAvax = getContract(AVALANCHE, "NAVI");
  const stakedNaviTrackerAddressAvax = getContract(AVALANCHE, "StakedNaviTracker");

  const { data: avaxData, mutate: avaxMutate } = useSWR(
    [`StakeV2:stakedNaviSupply:${active}`, AVALANCHE, naviAddressAvax, "balanceOf", stakedNaviTrackerAddressAvax],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  let data;
  if (arbData && avaxData) {
    data = arbData.add(avaxData);
  }

  const mutate = () => {
    arbMutate();
    avaxMutate();
  };

  return { data, mutate };
}

export function useHasOutdatedUi() {
  // const url = getServerUrl(ARBITRUM, "/ui_version");
  // const { data, mutate } = useSWR([url], {
  //   // @ts-ignore
  //   fetcher: (...args) => fetch(...args).then((res) => res.text()),
  // });

  let hasOutdatedUi = false;

  // if (data && parseFloat(data) > parseFloat(UI_VERSION)) {
  //   hasOutdatedUi = true;
  // }

  return { data: hasOutdatedUi };
}
export function useNAVIInfo() {
  const url = "https://api.navigator.exchange/sonic/api/tokens/info?symbols=NAVI&chain=SONIC";
  const { data: data } = useSWR([url], {
    // @ts-ignore
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });
  return {
    // totalSupply: parseValue(data?.NAVI?.totalSupply, 18) || 0,
    // totalNaviSupply: parseValue(data?.NAVI?.circulatingSupply, 18) || 0,
    naviPrice: parseValue(data?.NAVI?.price, USD_DECIMALS) || 0,
  };
}
export function useNaviPrice(chainId, libraries, active) {
  // const arbitrumLibrary = libraries && libraries.arbitrum ? libraries.arbitrum : undefined;
  // const opLibrary = libraries && libraries.op ? libraries.op : undefined;

  // const { data: naviPriceFromArbitrum, mutate: mutateFromArbitrum } = useNaviPriceFromArbitrum(arbitrumLibrary, active);
  // // const { data: naviPriceFromOP, mutate: mutateFromOP } = useNaviPriceFromOP();
  // const { data: naviPriceFromOP, mutate: mutateFromOP } = useNaviPriceFromOP2(opLibrary, active);
  const { data: naviPriceFromFantom, mutate: mutateFromFantom } = useNaviPriceFromFantomV2();
  // const { data: naviPriceFromBase, mutate: mutateFromBase } = useNaviPriceFromOP2(opLibrary, active);
  const { data: naviPriceFromSonic, mutate: mutateFromSonic } = useNaviPriceFromChainId(chainId);

  let naviPrice = naviPriceFromFantom;
  if (chainId && SUPPORTED_CHAIN_IDS.includes(chainId) && naviPriceFromSonic) {
    naviPrice = naviPriceFromSonic;
  }
  // let naviPrice = naviPriceFromFantom;
  // if (chainId === OP) {
  //   naviPrice = naviPriceFromOP;
  // }
  // if (chainId === ARBITRUM) {
  //   naviPrice = naviPriceFromArbitrum;
  // }
  // if (chainId === BASE) {
  //   naviPrice = naviPriceFromBase;
  // }
  // const mutate = useCallback(() => {
  //   mutateFromFantom();
  //   mutateFromOP();
  //   mutateFromArbitrum();
  //   mutateFromBase();
  // }, [mutateFromFantom, mutateFromOP, mutateFromArbitrum, mutateFromBase]);
  const mutate = useCallback(() => {
    mutateFromSonic();
    mutateFromFantom();
  }, [mutateFromSonic]);

  return {
    naviPrice,
    // naviPriceFromArbitrum,
    // naviPriceFromOP,
    // naviPriceFromFantom,
    // naviPriceFromBase,
    mutate,
  };
}
export function useBridgeApi(account, currentTab) {
  const url = `https://api.navigator.exchange/layerzero/bridge/histories/${account}${
    currentTab === "NAVI" ? "" : "?type=NFT"
  }`;
  const data = useSWR([url], {
    dedupingInterval: 30000,
    // @ts-ignore
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });
  if (data && data?.data) return data?.data;
  else return null;
}
export function useMigrateApi(account, chainId) {
  const url = `https://api.navigator.exchange/layerzero/migration/histories/${account}?chainId=${chainId}`;
  const data = useSWR([url], {
    dedupingInterval: 30000,
    // @ts-ignore
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });
  if (data && data?.data) return data?.data;
  else return null;
}
// use only the supply endpoint on arbitrum, it includes the supply on avalanche
export function useTotalNaviSupply() {
  const naviSupplyUrlArbitrum = getServerUrl(ARBITRUM, "/navi_supply");

  const { data: naviSupply, mutate: updateNaviSupply } = useSWR([naviSupplyUrlArbitrum], {
    // @ts-ignore
    fetcher: (...args) => fetch(...args).then((res) => res.text()),
  });

  return {
    total: naviSupply ? bigNumberify(naviSupply) : undefined,
    mutate: updateNaviSupply,
  };
}

export function useTotalNaviSupplyAll() {
  const naviAddressSONIC = getContract(SONIC_TESTNET, "NAVI");
  const nlpAddressSONIC = getContract(SONIC_TESTNET, "NLP");
  const usdgAddressSONIC = getContract(SONIC_TESTNET, "USDN");
  const readerAddressSONIC = getContract(SONIC_TESTNET, "Reader");
  const tokensForSupplyQuerySONIC = [naviAddressSONIC, nlpAddressSONIC, usdgAddressSONIC];

  // const naviAddressOP = getContract(OP, "NAVI");
  // const nlpAddressOP = getContract(OP, "NLP");
  // const usdgAddressOP = getContract(OP, "USDN");
  // const readerAddressOP = getContract(OP, "Reader");
  // const tokensForSupplyQueryOP = [naviAddressOP, nlpAddressOP, usdgAddressOP];

  // const naviAddress = getContract(FANTOM, "NAVI");
  // const nlpAddress = getContract(FANTOM, "NLP");
  // const usdgAddress = getContract(FANTOM, "USDN");
  // const readerAddressFtm = getContract(FANTOM, "Reader");
  // const tokensForSupplyQuery = [naviAddress, nlpAddress, usdgAddress];

  // const naviAddressArb = getContract(ARBITRUM, "NAVI");
  // const nlpAddressArb = getContract(ARBITRUM, "NLP");
  // const usdgAddressArb = getContract(ARBITRUM, "USDN");
  // const readerAddressArb = getContract(ARBITRUM, "Reader");
  // const tokensForSupplyQueryArb = [naviAddressArb, nlpAddressArb, usdgAddressArb];

  // const naviAddressBase = getContract(BASE, "NAVI");
  // const nlpAddressBase = getContract(BASE, "NLP");
  // const usdgAddressBase = getContract(BASE, "USDN");
  // const readerAddressBase = getContract(BASE, "Reader");
  // const tokensForSupplyQueryBase = [naviAddressBase, nlpAddressBase, usdgAddressBase];

  let totalSupplies = useRef(bigNumberify(0));

  const { data: totalSuppliesSONIC, mutate: updateTotalSuppliesSONIC } = useSWR<any>(
    [
      `Dashboard:totalSuppliesSONIC:${[SONIC_TESTNET, tokensForSupplyQuerySONIC]}`,
      SONIC_TESTNET,
      readerAddressSONIC,
      "getTokenBalancesWithSupplies",
      AddressZero,
    ],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQuerySONIC]),
      refreshInterval: 10000,
    }
  );

  // const { data: totalSuppliesBASE, mutate: updateTotalSuppliesBASE } = useSWR<any>(
  //   [
  //     `Dashboard:totalSuppliesBASE:${[BASE, tokensForSupplyQueryBase]}`,
  //     BASE,
  //     readerAddressBase,
  //     "getTokenBalancesWithSupplies",
  //     AddressZero,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQueryBase]),
  //     refreshInterval: 10000,
  //   }
  // );

  // const { data: totalSuppliesOP, mutate: updateTotalSuppliesOP } = useSWR<any>(
  //   [
  //     `Dashboard:totalSuppliesOP:${[OP, tokensForSupplyQueryOP]}`,
  //     OP,
  //     readerAddressOP,
  //     "getTokenBalancesWithSupplies",
  //     AddressZero,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQueryOP]),
  //     refreshInterval: 10000,
  //   }
  // );

  // const { data: totalSuppliesFtm, mutate: updateTotalSuppliesFtm } = useSWR<any>(
  //   [
  //     `Dashboard:totalSuppliesOP:${[FANTOM, tokensForSupplyQuery]}`,
  //     FANTOM,
  //     readerAddressFtm,
  //     "getTokenBalancesWithSupplies",
  //     AddressZero,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQuery]),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: mulSuppliesFtm } = useSWR(
  //   [
  //     `Bridge:mulSuppliesFtm:${FANTOM}`,
  //     FANTOM,
  //     "0x01e77288b38b416F972428d562454fb329350bAc",
  //     "balanceOf",
  //     "0x23a2d47Af72A9d18B756DfDDEF790cdc1d0e9005",
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: totalSuppliesArb, mutate: updateTotalSuppliesArb } = useSWR<any>(
  //   [
  //     `Dashboard:totalSuppliesArb:${[ARBITRUM, tokensForSupplyQueryArb]}`,
  //     ARBITRUM,
  //     readerAddressArb,
  //     "getTokenBalancesWithSupplies",
  //     AddressZero,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, ReaderV2, [tokensForSupplyQueryArb]),
  //     refreshInterval: 10000,
  //   }
  // );
  const mutate = useCallback(() => {
    // updateTotalSuppliesOP();
    // updateTotalSuppliesFtm();
    // updateTotalSuppliesArb();
    // updateTotalSuppliesBASE();
    updateTotalSuppliesSONIC();
  }, [updateTotalSuppliesSONIC]);

  if (
    // totalSuppliesOP &&
    // totalSuppliesFtm &&
    // totalSuppliesOP[1] &&
    // totalSuppliesFtm[1] &&
    // totalSuppliesArb &&
    // totalSuppliesArb[1] &&
    // totalSuppliesBASE &&
    // totalSuppliesBASE[1] &&
    // mulSuppliesFtm &&
    totalSuppliesSONIC &&
    totalSuppliesSONIC[1]
  ) {
    let total = totalSuppliesSONIC[1];
    totalSupplies.current = total;
  }

  return {
    // op: totalSuppliesOP && totalSuppliesOP[1] ? totalSuppliesOP[1] : bigNumberify(0),
    // fantom:
    //   mulSuppliesFtm && totalSuppliesFtm && totalSuppliesFtm[1]
    //     ? totalSuppliesFtm[1].sub(mulSuppliesFtm)
    //     : bigNumberify(0),
    // arb: totalSuppliesArb && totalSuppliesArb[1] ? totalSuppliesArb[1] : bigNumberify(0),
    sonic: totalSuppliesSONIC && totalSuppliesSONIC[1] ? totalSuppliesSONIC[1] : bigNumberify(0),
    total: totalSupplies.current,
    mutate,
  };
}

export function useTotalNaviStaked() {
  // const stakedNaviTrackerAddressArbitrum = getContract(ARBITRUM, "StakedNaviTracker");
  // const stakedNaviTrackerAddressBase = getContract(BASE, "StakedNaviTracker");
  // const stakedNaviTrackerAddressOP = getContract(OP, "StakedNaviTracker");
  // const stakedNaviTrackerAddressFtm = getContract(FANTOM, "StakedNaviTracker");
  const stakedNaviTrackerAddressSONIC = getContract(SONIC_TESTNET, "StakedNaviTracker");
  let totalStakedNavi = useRef(bigNumberify(0));

  // const { data: stakedNaviSupplyBase, mutate: updateStakedNaviSupplyBase } = useSWR<BigNumber>(
  //   [`StakeV2:stakedNaviSupplyBase:${BASE}`, BASE, getContract(BASE, "NAVI"), "balanceOf", stakedNaviTrackerAddressBase],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: stakedNaviSupplyArbitrum, mutate: updateStakedNaviSupplyArbitrum } = useSWR<BigNumber>(
  //   [
  //     `StakeV2:stakedNaviSupply:${ARBITRUM}`,
  //     ARBITRUM,
  //     getContract(ARBITRUM, "NAVI"),
  //     "balanceOf",
  //     stakedNaviTrackerAddressArbitrum,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: stakedNaviSupplyOP, mutate: updateStakedNaviSupplyOP } = useSWR<BigNumber>(
  //   [`StakeV2:stakedNaviSupplyOP:${OP}`, OP, getContract(OP, "NAVI"), "balanceOf", stakedNaviTrackerAddressOP],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //     refreshInterval: 10000,
  //   }
  // );

  // const { data: stakedNaviSupplyFtm, mutate: updateStakedNaviSupplyFtm } = useSWR<BigNumber>(
  //   [
  //     `StakeV2:stakedNaviSupplyFtm:${FANTOM}`,
  //     FANTOM,
  //     getContract(FANTOM, "NAVI"),
  //     "balanceOf",
  //     stakedNaviTrackerAddressFtm,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //     refreshInterval: 10000,
  //   }
  // );
  const { data: stakedNaviSupplySONIC, mutate: updateStakedNaviSupplySONIC } = useSWR<BigNumber>(
    [
      `StakeV2:stakedNaviSupplySONIC:${SONIC_TESTNET}`,
      SONIC_TESTNET,
      getContract(SONIC_TESTNET, "NAVI"),
      "balanceOf",
      stakedNaviTrackerAddressSONIC,
    ],
    {
      fetcher: contractFetcher(undefined, Token),
      refreshInterval: 10000,
    }
  );

  const mutate = useCallback(() => {
    // updateStakedNaviSupplyArbitrum();
    // updateStakedNaviSupplyOP();
    // updateStakedNaviSupplyOPLegacy();
    // updateStakedNaviSupplyFtm();
    // updateStakedNaviSupplyFtmLegacy();
    // updateStakedNaviSupplyBase();
    updateStakedNaviSupplySONIC();
  }, [
    // updateStakedNaviSupplyFtmLegacy,
    // updateStakedNaviSupplyFtm,
    // updateStakedNaviSupplyOP,
    // updateStakedNaviSupplyOPLegacy,
    // updateStakedNaviSupplyArbitrum,
    // updateStakedNaviSupplyBase,
    updateStakedNaviSupplySONIC,
  ]);

  if (
    // stakedNaviSupplyFtm &&
    // stakedNaviSupplyOP &&
    // stakedNaviSupplyOPLegacy &&
    // stakedNaviSupplyArbitrum &&
    // stakedNaviSupplyFtmLegacy &&
    // stakedNaviSupplyBase &&
    stakedNaviSupplySONIC
  ) {
    let total = stakedNaviSupplySONIC;
    // .add(stakedNaviSupplyOP)
    // .add(stakedNaviSupplyOPLegacy)
    // .add(stakedNaviSupplyArbitrum)
    // .add(stakedNaviSupplyFtmLegacy)
    // .add(stakedNaviSupplyBase);
    totalStakedNavi.current = total;
  }

  return {
    // op: stakedNaviSupplyOP,
    // oplegacy: stakedNaviSupplyOPLegacy,
    // fantom: stakedNaviSupplyFtm,
    // fantomlegacy: stakedNaviSupplyFtmLegacy,
    // arbitrum: stakedNaviSupplyArbitrum,
    // base: stakedNaviSupplyBase,
    total: totalStakedNavi.current,
    sonic: stakedNaviSupplySONIC,
    mutate,
  };
}

export function useTotalEsNaviStaked() {
  // const stakedNaviTrackerAddressOP = getContract(OP, "StakedNaviTracker");
  // const stakedNaviTrackerAddressFtm = getContract(FANTOM, "StakedNaviTracker");
  // const stakedNaviTrackerAddressArbitrum = getContract(ARBITRUM, "StakedNaviTracker");
  // const stakedNaviTrackerAddressBase = getContract(BASE, "StakedNaviTracker");
  const stakedNaviTrackerAddressSONIC = getContract(SONIC_TESTNET, "StakedNaviTracker");
  let totalStakedNavi = useRef(bigNumberify(0));

  const { data: stakedNaviSupplySONIC, mutate: updateStakedNaviSupplySONIC } = useSWR<BigNumber>(
    [
      `StakeV2:stakedEsNaviSupplySONIC:${SONIC_TESTNET}`,
      SONIC_TESTNET,
      getContract(SONIC_TESTNET, "ES_NAVI"),
      "balanceOf",
      stakedNaviTrackerAddressSONIC,
    ],
    {
      fetcher: contractFetcher(undefined, Token),
      refreshInterval: 10000,
    }
  );

  // const { data: stakedNaviSupplyOP, mutate: updateStakedNaviSupplyOP } = useSWR<BigNumber>(
  //   [`StakeV2:stakedEsNaviSupplyOP:${OP}`, OP, getContract(OP, "ES_NAVI"), "balanceOf", stakedNaviTrackerAddressOP],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: stakedNaviSupplyFtm, mutate: updateStakedNaviSupplyFtm } = useSWR<BigNumber>(
  //   [
  //     `StakeV2:stakedEsNaviSupplyFtm:${FANTOM}`,
  //     FANTOM,
  //     getContract(FANTOM, "ES_NAVI"),
  //     "balanceOf",
  //     stakedNaviTrackerAddressFtm,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //     refreshInterval: 10000,
  //   }
  // );
  // const { data: stakedNaviSupplyFtmLegacy, mutate: updateStakedNaviSupplyFtmLegacy } = useSWR<BigNumber>(
  //   [
  //     `StakeV2:stakedEsNaviSupplyFtmLegacy:${FANTOM}`,
  //     FANTOM,
  //     getContract(FANTOM, "ES_NAVI"),
  //     "balanceOf",
  //     "0x727dB8FA7861340d49d13ea78321D0C9a1a79cd5",
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //   }
  // );
  // const { data: stakedNaviSupplyArbitrum, mutate: updateStakedNaviSupplyArbitrum } = useSWR<BigNumber>(
  //   [
  //     `StakeV2:stakedEsNaviSupplyArbitrum:${ARBITRUM}`,
  //     ARBITRUM,
  //     getContract(ARBITRUM, "ES_NAVI"),
  //     "balanceOf",
  //     stakedNaviTrackerAddressArbitrum,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //     refreshInterval: 10000,
  //   }
  // );

  // const { data: stakedNaviSupplyBase, mutate: updateStakedNaviSupplyBase } = useSWR<BigNumber>(
  //   [
  //     `StakeV2:stakedEsNaviSupplyBase:${BASE}`,
  //     BASE,
  //     getContract(BASE, "ES_NAVI"),
  //     "balanceOf",
  //     stakedNaviTrackerAddressBase,
  //   ],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //     refreshInterval: 10000,
  //   }
  // );

  const mutate = useCallback(() => {
    // updateStakedNaviSupplyArbitrum();
    // updateStakedNaviSupplyOP();
    // updateStakedNaviSupplyFtm();
    // updateStakedNaviSupplyFtmLegacy();
    // updateStakedNaviSupplyBase();
    updateStakedNaviSupplySONIC();
  }, [
    // updateStakedNaviSupplyArbitrum,
    // updateStakedNaviSupplyFtm,
    // updateStakedNaviSupplyOP,
    // updateStakedNaviSupplyFtmLegacy,
    // updateStakedNaviSupplyBase,
    updateStakedNaviSupplySONIC,
  ]);

  if (stakedNaviSupplySONIC) {
    let total = stakedNaviSupplySONIC;
    totalStakedNavi.current = total;
  }

  return {
    // op: stakedNaviSupplyOP,
    // fantom: stakedNaviSupplyFtm,
    // fantomlegacy: stakedNaviSupplyFtmLegacy,
    // arbitrum: stakedNaviSupplyArbitrum,
    // base: stakedNaviSupplyBase,
    sonic: stakedNaviSupplySONIC,
    total: totalStakedNavi.current,
    mutate,
  };
}
export function useTotalNaviInLiquidity() {
  // let poolAddressArbitrum = getContract(ARBITRUM, "UniswapNaviEthPool");
  // let poolAddressAvax = getContract(AVALANCHE, "TraderJoeNaviAvaxPool");
  let poolAddressFtm = getContract(FANTOM, "UniswapNaviEthPool");
  let poolAddressOP = getContract(OP, "UniswapNaviEthPool");

  let totalNAVI = useRef(bigNumberify(0));

  // const { data: naviInLiquidityOnArbitrum, mutate: mutateNAVIInLiquidityOnArbitrum } = useSWR<any>(
  //   [`StakeV2:naviInLiquidity:${ARBITRUM}`, ARBITRUM, getContract(ARBITRUM, "NAVI"), "balanceOf", poolAddressArbitrum],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //   }
  // );
  // const { data: naviInLiquidityOnAvax, mutate: mutateNAVIInLiquidityOnAvax } = useSWR<any>(
  //   [`StakeV2:naviInLiquidity:${AVALANCHE}`, AVALANCHE, getContract(AVALANCHE, "NAVI"), "balanceOf", poolAddressAvax],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //   }
  // );
  const { data: naviInLiquidityOnFtm, mutate: mutateNAVIInLiquidityOnFtm } = useSWR<any>(
    [`StakeV2:naviInLiquidity:${FANTOM}`, FANTOM, getContract(FANTOM, "NAVI"), "balanceOf", poolAddressFtm],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );
  const { data: naviInLiquidityOnOP, mutate: mutateNAVIInLiquidityOnOP } = useSWR<any>(
    [`StakeV2:naviInLiquidity:${OP}`, OP, getContract(OP, "NAVI"), "balanceOf", poolAddressOP],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  const mutate = useCallback(() => {
    mutateNAVIInLiquidityOnFtm();
    mutateNAVIInLiquidityOnOP();
  }, [mutateNAVIInLiquidityOnFtm, mutateNAVIInLiquidityOnOP]);

  if (naviInLiquidityOnFtm && naviInLiquidityOnOP) {
    let total = bigNumberify(naviInLiquidityOnFtm)?.add(naviInLiquidityOnOP);
    totalNAVI.current = total;
  }
  return {
    op: naviInLiquidityOnOP,
    fantom: naviInLiquidityOnFtm,
    total: totalNAVI.current,
    mutate,
  };
}
export function useLqLocked() {
  const poolAddress = getContract(FANTOM, "UniswapNaviEthPool");
  const liquidityLockerAddress = getContract(FANTOM, "LiquidityLocker");

  const { data, mutate: updateReserves } = useSWR(["TraderJoeNaviFantomReserves", FANTOM, poolAddress, "getReserves"], {
    fetcher: contractFetcher(undefined, UniswapV2),
  });
  const { _reserve0: naviReserve, _reserve1: usdcReserve }: any = data || {};
  const { data: totalSupply, mutate: updateTotalSupply } = useSWR(["totalSupply", FANTOM, poolAddress, "totalSupply"], {
    fetcher: contractFetcher(undefined, UniswapV2),
  });

  const { data: balance } = useSWR(["balance", FANTOM, poolAddress, "balanceOf"], {
    fetcher: contractFetcher(undefined, UniswapV2, [liquidityLockerAddress]),
  });

  const { data: lockUntil } = useSWR(["lockUntil", FANTOM, liquidityLockerAddress, "lockUntil"], {
    fetcher: contractFetcher(undefined, LiquidityLocker),
  });

  if (balance && usdcReserve && naviReserve && totalSupply && lockUntil) {
    const pooledUSDC = usdcReserve.mul(balance).div(totalSupply);
    const pooledNAVI = naviReserve.mul(balance).div(totalSupply);
    const naviPrice = usdcReserve.mul(expandDecimals(1, 18)).div(naviReserve);

    const pooledNAVIDolar = naviReserve.mul(balance).div(totalSupply).mul(naviPrice).div(expandDecimals(1, 6));
    const tvl = pooledUSDC.mul(2).div(expandDecimals(1, 6));
    return {
      tvl,
      pooledUSDC,
      pooledNAVI,
      pooledNAVIDolar,
      lockUntil,
      lockTokenddress: "0x2a6538a456650cd454dcd8f0b4665183dba0bb27",
      timeLockddress: "0x8ac2cc7af1517e6f58a31a43773ca6c80bf3edb9",
      lpTokenAddress: poolAddress,
    };
  }
}

function useNaviPriceFromOP2(library, active) {
  const poolAddress = getContract(OP, "UniswapNaviEthPool");
  const { data: uniPoolSlot0, mutate: updateUniPoolSlot0 } = useSWR<any>(
    [`StakeV2:uniPoolSlot0:${active}:${poolAddress}`, OP, poolAddress, "slot0"],
    {
      fetcher: contractFetcher(library, UniPool),
      refreshInterval: 10000,
    }
  );

  const vaultAddress = getContract(OP, "Vault");
  const ethAddress = getTokenBySymbol(OP, "WETH").address;
  const { data: ethPrice, mutate: updateEthPrice } = useSWR<BigNumber>(
    [`StakeV2:ethPrice:${active}:${ethAddress}`, OP, vaultAddress, "getMinPrice", ethAddress],
    {
      fetcher: contractFetcher(library, Vault),
      refreshInterval: 10000,
    }
  );

  const naviPrice = useMemo(() => {
    if (uniPoolSlot0 && ethPrice) {
      const tokenA = new UniToken(OP, ethAddress, 18, "SYMBOL", "NAME");

      const naviAddress = getContract(OP, "NAVI");
      const tokenB = new UniToken(OP, naviAddress, 18, "SYMBOL", "NAME");

      const pool = new Pool(
        tokenA, // tokenA
        tokenB, // tokenB
        10000, // fee
        uniPoolSlot0.sqrtPriceX96, // sqrtRatioX96
        1, // liquidity
        uniPoolSlot0.tick, // tickCurrent
        []
      );

      const poolTokenPrice = pool.priceOf(tokenB).toSignificant(6);
      const poolTokenPriceAmount = parseValue(poolTokenPrice, 18);
      return poolTokenPriceAmount?.mul(ethPrice).div(expandDecimals(1, 18));
    }
  }, [ethPrice, uniPoolSlot0, ethAddress]);

  const mutate = useCallback(() => {
    updateUniPoolSlot0(undefined, true);
    updateEthPrice(undefined, true);
  }, [updateEthPrice, updateUniPoolSlot0]);

  return { data: naviPrice, mutate };
}

function useNaviPriceFromFantom() {
  const poolAddress = getContract(FANTOM, "UniswapNaviEthPool");

  const { data, mutate: updateReserves } = useSWR(["TraderJoeNaviFantomReserves", FANTOM, poolAddress, "getReserves"], {
    fetcher: contractFetcher(undefined, UniswapV2),
  });
  const { _reserve0: naviReserve, _reserve1: avaxReserve }: any = data || {};

  const vaultAddress = getContract(FANTOM, "Vault");
  const usdcAddress = getTokenBySymbol(FANTOM, "USDC").address;
  // const { data: usdcPrice, mutate: updateUsdcPrice } = useSWR(
  //   [`StakeV2:usdcPrice`, FANTOM, vaultAddress, "getMinPrice", usdcAddress],
  //   {
  //     fetcher: contractFetcher(undefined, Vault),
  //   }
  // );

  const apiPriceUSDC = `https://api.dexscreener.com/latest/dex/pairs/fantom/0x2b4C76d0dc16BE1C31D4C1DC53bF9B45987Fc75c`;

  const { data: usdcData, mutate: updateUsdcPrice } = useSWR([apiPriceUSDC], {
    // @ts-ignore
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  });
  let usdcPrice = usdcData ? bigNumberify(usdcData.pair.priceUsd * 1000000) : undefined;
  usdcPrice = usdcPrice?.mul(bigNumberify(10)!.pow(30 - 6));
  const PRECISION = bigNumberify(10)!.pow(18);
  const PRECISION_USDC = bigNumberify(10)!.pow(6);
  let naviPrice;
  if (avaxReserve && naviReserve && usdcPrice) {
    naviPrice = avaxReserve.mul(PRECISION).div(naviReserve).mul(usdcPrice)!.div(PRECISION_USDC);
  }

  const mutate = useCallback(() => {
    updateReserves(undefined, true);
    updateUsdcPrice(undefined, true);
  }, [updateReserves, updateUsdcPrice]);

  return { data: naviPrice, mutate };
}

function useNaviPriceFromFantomV2() {
  const poolAddress = getContract(FANTOM, "TraderJoeNaviAvaxPool");

  const { data, mutate: updateReserves } = useSWR(
    [`TraderJoeNaviAvaxReserves:${poolAddress}`, FANTOM, poolAddress, "getReserves"],
    {
      fetcher: contractFetcher(undefined, UniswapV2),
      refreshInterval: 10000,
    }
  );
  const { _reserve0: avaxReserve, _reserve1: naviReserve }: any = data || {};
  const vaultAddress = getContract(FANTOM, "Vault");
  const ethAddress = getTokenBySymbol(FANTOM, "WFTM").address;
  const { data: ethPrice, mutate: updateEthPrice } = useSWR(
    [`StakeV2:ethPrice:${ethAddress}`, FANTOM, vaultAddress, "getMinPrice", ethAddress],
    {
      fetcher: contractFetcher(undefined, Vault),
      refreshInterval: 10000,
    }
  );

  const PRECISION = bigNumberify(10)!.pow(30);
  let naviPrice;
  if (avaxReserve && naviReserve && ethPrice) {
    naviPrice = naviReserve.mul(PRECISION).div(avaxReserve).mul(ethPrice).div(PRECISION);
  }

  const mutate = useCallback(() => {
    updateReserves(undefined, true);
    updateEthPrice(undefined, true);
  }, [updateReserves, updateEthPrice]);

  return { data: naviPrice, mutate };
}
function useNaviPriceFromChainId(chainId = SONIC_TESTNET) {
  const poolAddress = getContract(chainId, "TraderJoeNaviAvaxPool");

  const { data, mutate: updateReserves } = useSWR(
    [`TraderJoeNaviAvaxReserves:${poolAddress}`, chainId, poolAddress, "getReserves"],
    {
      fetcher: contractFetcher(undefined, UniswapV2),
      refreshInterval: 10000,
    }
  );
  const { _reserve0: avaxReserve, _reserve1: naviReserve }: any = data || {};
  const vaultAddress = getContract(chainId, "Vault");
  const ethAddress = getTokenBySymbol(chainId, "WS").address;
  const { data: ethPrice, mutate: updateEthPrice } = useSWR(
    [`StakeV2:ethPrice:${ethAddress}`, chainId, vaultAddress, "getMinPrice", ethAddress],
    {
      fetcher: contractFetcher(undefined, Vault),
      refreshInterval: 10000,
    }
  );

  const PRECISION = bigNumberify(10)!.pow(30);
  let naviPrice;
  if (avaxReserve && naviReserve && ethPrice) {
    naviPrice = naviReserve.mul(PRECISION).div(avaxReserve).mul(ethPrice).div(PRECISION);
  }

  const mutate = useCallback(() => {
    updateReserves(undefined, true);
    updateEthPrice(undefined, true);
  }, [updateReserves, updateEthPrice]);

  return { data: naviPrice, mutate };
}

function useNaviPriceFromOP() {
  const poolAddress = getContract(OP, "TraderJoeNaviAvaxPool");

  const { data, mutate: updateReserves } = useSWR(["TraderJoeNaviAvaxReserves", OP, poolAddress, "getReserves"], {
    fetcher: contractFetcher(undefined, UniswapV2),
  });
  const { _reserve0: avaxReserve, _reserve1: naviReserve }: any = data || {};
  const vaultAddress = getContract(OP, "Vault");
  const ethAddress = getTokenBySymbol(OP, "WETH").address;
  const { data: ethPrice, mutate: updateEthPrice } = useSWR(
    [`StakeV2:ethPrice`, OP, vaultAddress, "getMinPrice", ethAddress],
    {
      fetcher: contractFetcher(undefined, Vault),
    }
  );

  const PRECISION = bigNumberify(10)!.pow(18);
  let naviPrice;
  if (avaxReserve && naviReserve && ethPrice) {
    naviPrice = avaxReserve.mul(PRECISION).div(naviReserve).mul(ethPrice).div(PRECISION);
  }

  const mutate = useCallback(() => {
    updateReserves(undefined, true);
    updateEthPrice(undefined, true);
  }, [updateReserves, updateEthPrice]);

  return { data: naviPrice, mutate };
}

function useNaviPriceFromArbitrum(library, active) {
  const poolAddress = getContract(ARBITRUM, "UniswapNaviEthPool");
  const { data: uniPoolSlot0, mutate: updateUniPoolSlot0 } = useSWR<any>(
    [`StakeV2:uniPoolSlot0:${active}:${poolAddress}`, ARBITRUM, poolAddress, "slot0"],
    {
      fetcher: contractFetcher(library, UniPool),
      refreshInterval: 10000,
    }
  );

  const vaultAddress = getContract(ARBITRUM, "Vault");
  const ethAddress = getTokenBySymbol(ARBITRUM, "WETH").address;
  const { data: ethPrice, mutate: updateEthPrice } = useSWR<BigNumber>(
    [`StakeV2:ethPrice:${active}:${ethAddress}`, ARBITRUM, vaultAddress, "getMinPrice", ethAddress],
    {
      fetcher: contractFetcher(library, Vault),
      refreshInterval: 10000,
    }
  );

  const naviPrice = useMemo(() => {
    if (uniPoolSlot0 && ethPrice) {
      const tokenA = new UniToken(ARBITRUM, ethAddress, 18, "SYMBOL", "NAME");

      const naviAddress = getContract(ARBITRUM, "NAVI");
      const tokenB = new UniToken(ARBITRUM, naviAddress, 18, "SYMBOL", "NAME");

      const pool = new Pool(
        tokenA, // tokenA
        tokenB, // tokenB
        10000, // fee
        uniPoolSlot0.sqrtPriceX96, // sqrtRatioX96
        1, // liquidity
        uniPoolSlot0.tick, // tickCurrent
        []
      );

      const poolTokenPrice = pool.priceOf(tokenB).toSignificant(6);
      const poolTokenPriceAmount = parseValue(poolTokenPrice, 18);
      return poolTokenPriceAmount?.mul(ethPrice).div(expandDecimals(1, 18));
    }
  }, [ethPrice, uniPoolSlot0, ethAddress]);

  const mutate = useCallback(() => {
    updateUniPoolSlot0(undefined, true);
    updateEthPrice(undefined, true);
  }, [updateEthPrice, updateUniPoolSlot0]);

  return { data: naviPrice, mutate };
}

export async function approvePlugin(chainId, pluginAddress, { library, setPendingTxns, sentMsg, failMsg }) {
  const routerAddress = getContract(chainId, "Router");
  const contract = new ethers.Contract(routerAddress, Router.abi, library.getSigner());
  return callContract(chainId, contract, "approvePlugin", [pluginAddress], {
    sentMsg,
    failMsg,
    setPendingTxns,
  });
}

export async function createSwapOrder(
  chainId,
  library,
  path,
  amountIn,
  minOut,
  triggerRatio,
  nativeTokenAddress,
  opts: any = {}
) {
  const executionFee = getConstant(chainId, "SWAP_ORDER_EXECUTION_GAS_FEE");
  const triggerAboveThreshold = false;
  let shouldWrap = false;
  let shouldUnwrap = false;
  opts.value = executionFee;

  if (path[0] === AddressZero) {
    shouldWrap = true;
    opts.value = opts.value.add(amountIn);
  }
  if (path[path.length - 1] === AddressZero) {
    shouldUnwrap = true;
  }
  path = replaceNativeTokenAddress(path, nativeTokenAddress);

  const params = [path, amountIn, minOut, triggerRatio, triggerAboveThreshold, executionFee, shouldWrap, shouldUnwrap];

  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, "createSwapOrder", params, opts);
}

export async function createIncreaseOrder(
  chainId,
  library,
  nativeTokenAddress,
  path,
  amountIn,
  indexTokenAddress,
  minOut,
  sizeDelta,
  collateralTokenAddress,
  isLong,
  triggerPrice,
  opts: any = {}
) {
  invariant(!isLong || indexTokenAddress === collateralTokenAddress, "invalid token addresses");
  invariant(indexTokenAddress !== AddressZero, "indexToken is 0");
  invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0");

  const fromETH = path[0] === AddressZero;

  path = replaceNativeTokenAddress(path, nativeTokenAddress);
  const shouldWrap = fromETH;
  const triggerAboveThreshold = !isLong;
  const executionFee = getConstant(chainId, "INCREASE_ORDER_EXECUTION_GAS_FEE");

  const params = [
    path,
    amountIn,
    indexTokenAddress,
    minOut,
    sizeDelta,
    collateralTokenAddress,
    isLong,
    triggerPrice,
    triggerAboveThreshold,
    executionFee,
    shouldWrap,
  ];

  if (!opts.value) {
    opts.value = fromETH ? amountIn.add(executionFee) : executionFee;
  }

  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, "createIncreaseOrder", params, opts);
}

export async function createDecreaseOrder(
  chainId,
  library,
  indexTokenAddress,
  sizeDelta,
  collateralTokenAddress,
  collateralDelta,
  isLong,
  triggerPrice,
  triggerAboveThreshold,
  opts: any = {}
) {
  invariant(!isLong || indexTokenAddress === collateralTokenAddress, "invalid token addresses");
  invariant(indexTokenAddress !== AddressZero, "indexToken is 0");
  invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0");

  const executionFee = getConstant(chainId, "DECREASE_ORDER_EXECUTION_GAS_FEE");

  const params = [
    indexTokenAddress,
    sizeDelta,
    collateralTokenAddress,
    collateralDelta,
    isLong,
    triggerPrice,
    triggerAboveThreshold,
  ];
  opts.value = executionFee;
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, "createDecreaseOrder", params, opts);
}

export async function cancelSwapOrder(chainId, library, index, opts) {
  const params = [index];
  const method = "cancelSwapOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function cancelDecreaseOrder(chainId, library, index, opts) {
  const params = [index];
  const method = "cancelDecreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function cancelIncreaseOrder(chainId, library, index, opts) {
  const params = [index];
  const method = "cancelIncreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export function handleCancelOrder(chainId, library, order, opts) {
  let func;
  if (order.type === SWAP) {
    func = cancelSwapOrder;
  } else if (order.type === INCREASE) {
    func = cancelIncreaseOrder;
  } else if (order.type === DECREASE) {
    func = cancelDecreaseOrder;
  }

  return func(chainId, library, order.index, {
    successMsg: `Order cancelled.`,
    failMsg: `Cancel failed.`,
    sentMsg: `Cancel submitted.`,
    pendingTxns: opts.pendingTxns,
    setPendingTxns: opts.setPendingTxns,
  });
}

export async function cancelMultipleOrders(chainId, library, allIndexes = [], opts) {
  const ordersWithTypes = groupBy(allIndexes, (v: any) => v.split("-")[0]);
  function getIndexes(key) {
    if (!ordersWithTypes[key]) return;
    return ordersWithTypes[key].map((d) => d.split("-")[1]);
  }
  // params order => swap, increase, decrease
  const params = ["Swap", "Increase", "Decrease"].map((key) => getIndexes(key) || []);
  const method = "cancelMultiple";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());
  return callContract(chainId, contract, method, params, opts);
}

export async function updateDecreaseOrder(
  chainId,
  library,
  index,
  collateralDelta,
  sizeDelta,
  triggerPrice,
  triggerAboveThreshold,
  opts
) {
  const params = [index, collateralDelta, sizeDelta, triggerPrice, triggerAboveThreshold];
  const method = "updateDecreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function updateIncreaseOrder(
  chainId,
  library,
  index,
  sizeDelta,
  triggerPrice,
  triggerAboveThreshold,
  opts
) {
  const params = [index, sizeDelta, triggerPrice, triggerAboveThreshold];
  const method = "updateIncreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function updateSwapOrder(chainId, library, index, minOut, triggerRatio, triggerAboveThreshold, opts) {
  const params = [index, minOut, triggerRatio, triggerAboveThreshold];
  const method = "updateSwapOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function _executeOrder(chainId, library, method, account, index, feeReceiver, opts) {
  const params = [account, index, feeReceiver];
  const positionManagerAddress = getContract(chainId, "PositionManager");
  const contract = new ethers.Contract(positionManagerAddress, PositionManager.abi, library.getSigner());
  return callContract(chainId, contract, method, params, opts);
}

export function executeSwapOrder(chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, library, "executeSwapOrder", account, index, feeReceiver, opts);
}

export function executeIncreaseOrder(chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, library, "executeIncreaseOrder", account, index, feeReceiver, opts);
}

export function executeDecreaseOrder(chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, library, "executeDecreaseOrder", account, index, feeReceiver, opts);
}

export async function newPositionOrder(
  chainId,
  library,
  tokenId,
  isLong,
  orderType,
  price,
  slippage,
  collateral,
  size,
  refer,
  opts: any = {}
) {
  const params = [
    tokenId,
    isLong,
    orderType,
    // 0 -> market order
    // 1 -> limit order
    // 2 -> stop-market order
    // 3 -> stop-limit order
    [price, slippage, collateral, size], // paramNslp
    // for market order:  _params[0] -> allowed price (revert if exceeded)
    // for limit order: _params[0] -> limit price
    //_params[1] -> slippage || 0
    // In stop-market order: _params[1] -> stop price,
    // In stop-limit order: _params[0] -> limit price, _params[1] -> stop price
    // for all orders: _params[2] -> collateral
    // for all orders: _params[3] -> size
    refer,
  ];
  const method = "newPositionOrder";
  const orderBookAddress = getContract(chainId, "VaultNslp");
  // console.log("?????", { params, opts });

  const contract = new ethers.Contract(orderBookAddress, VaultNslp.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function newPositionOrderWithTPSL(
  chainId,
  library,
  tokenId,
  isLong,
  orderType,
  allowedPrice,
  slippage,
  collateral,
  size, // paramNslp
  refer,
  isTPs,
  prices,
  amountPercents,
  opts: any = {}
) {
  const params = [
    tokenId,
    isLong,
    orderType,
    [allowedPrice, slippage, collateral, size], // paramNslp
    refer,
    isTPs,
    prices,
    amountPercents,
  ];
  const method = "newPositionOrderWithTPSL";
  const orderBookAddress = getContract(chainId, "VaultNslp");
  const contract = new ethers.Contract(orderBookAddress, VaultNslp.abi, library.getSigner());
  return callContract(chainId, contract, method, params, opts);
}

export async function addTPSL(chainId, library, _posId, _isTPs, _prices, _amountPercents, opts) {
  const params = [_posId, _isTPs, _prices, _amountPercents];
  const method = "addTPSL";
  const orderBookAddress = getContract(chainId, "VaultNslp");
  const contract = new ethers.Contract(orderBookAddress, VaultNslp.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}
export async function addOrRemoveCollateral(chainId, library, isPlus, _posId, _amount, opts) {
  const params = [_posId, _amount];
  const method = isPlus ? "addCollateral" : "removeCollateral";
  const orderBookAddress = getContract(chainId, "VaultNslp");
  const contract = new ethers.Contract(orderBookAddress, VaultNslp.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function addTrailingStop(chainId, library, _posId, _param, opts) {
  const params = [_posId, _param];
  const method = "addTrailingStop";
  const orderBookAddress = getContract(chainId, "VaultNslp");
  const contract = new ethers.Contract(orderBookAddress, VaultNslp.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function addPosition(chainId, library, _posId, _collateralDelta, _sizeDelta, _allowPrice, opts) {
  const params = [_posId, _collateralDelta, _sizeDelta, _allowPrice];
  const method = "addPosition";
  const orderBookAddress = getContract(chainId, "VaultNslp");
  const contract = new ethers.Contract(orderBookAddress, VaultNslp.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function decreasePosition(chainId, library, _sizeDelta, _allowedPrice, _posId, opts) {
  const params = [_sizeDelta, _allowedPrice, _posId];
  const method = "decreasePosition";
  const orderBookAddress = getContract(chainId, "VaultNslp");
  const contract = new ethers.Contract(orderBookAddress, VaultNslp.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function cancelPendingOrder(chainId, library, posId, opts) {
  const params = [posId];
  const method = "cancelPendingOrder";
  const orderBookAddress = getContract(chainId, "VaultNslp");
  const contract = new ethers.Contract(orderBookAddress, VaultNslp.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}
export async function cancelTriggerOrder(chainId, library, _posId, _orderId, opts) {
  const params = [_posId, _orderId];
  const method = "cancelTriggerOrder";
  const orderBookAddress = getContract(chainId, "OrderVaultNslp");
  const contract = new ethers.Contract(orderBookAddress, OrderVaultNslp.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}
export async function editTriggerOrder(chainId, library, _posId, _orderId, _isTP, _price, _amountPercent, opts) {
  const params = [_posId, _orderId, _isTP, _price, _amountPercent];
  const method = "editTriggerOrder";
  const orderBookAddress = getContract(chainId, "OrderVaultNslp");
  const contract = new ethers.Contract(orderBookAddress, OrderVaultNslp.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}
