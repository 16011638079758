import React, { useState, useCallback, useEffect, useMemo } from "react";
import { BigNumber, ethers } from "ethers";
import cx from "classnames";
import { Trans, t } from "@lingui/macro";
import { BsArrowRight } from "react-icons/bs";

import {
  DEFAULT_SLIPPAGE_AMOUNT,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  USD_DECIMALS,
  DUST_USD,
  BASIS_POINTS_DIVISOR,
  MIN_PROFIT_TIME,
  getLiquidationPrice,
  getLeverage,
  getMarginFee,
  PRECISION,
  MARKET,
  STOP,
  DECREASE,
  calculatePositionDelta,
  getDeltaStr,
  getProfitPrice,
  getNextToAmount,
  USDN_DECIMALS,
  adjustForDecimals,
  isAddressZero,
  MAX_ALLOWED_LEVERAGE,
  importImage,
  TAKE_PROFIT,
  STOP_LOSS,
  getErrorTextStopLoss,
  getErrorTextTakeProfit,
} from "lib/legacy";
import { ARBITRUM, getChainName, getConstant, IS_NETWORK_DISABLED } from "config/chains";
import { createDecreaseOrder, useHasOutdatedUi } from "domain/legacy";
import { getContract } from "config/contracts";
import PositionRouter from "abis/PositionRouter.json";
import Checkbox from "../Checkbox/Checkbox";
import Tab from "../Tab/Tab";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import Tooltip from "../Tooltip/Tooltip";
import TokenSelector from "./TokenSelector";
import "./PositionSeller.css";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { callContract } from "lib/contracts";
import { getTokenAmountFromUsd } from "domain/tokens";
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from "config/ui";
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from "lib/localStorage";
import { CLOSE_POSITION_RECEIVE_TOKEN_KEY, SLIPPAGE_BPS_KEY, THEME_KEY } from "config/localStorage";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { usePrevious } from "lib/usePrevious";
import {
  bigNumberify,
  expandDecimals,
  formatAmount,
  formatAmountFree,
  formatNumber,
  getDisplayDecimalByAmount,
  limitDecimals,
  parseValue,
} from "lib/numbers";
import { getTokens, getWrappedToken } from "config/tokens";
import { formatDateTime, getTimeRemaining } from "lib/dates";
import ExternalLink from "components/ExternalLink/ExternalLink";
import PercentageButtons from "./PercentageButtons";
import TakeProfitLongTriggerButton from "./TakeProfitLongTriggerButton";
import TakeProfitShortTriggerButton from "./TakeProfitShortTriggerButton";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { get } from "http";
import RowsDropdown, { RowsDropdownHandler } from "./RowsDropdown";
import GreenArraowIcon from "components/Icon/GreenArraowIcon";
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "grey" }} spin />;
const { AddressZero } = ethers.constants;
const ORDER_SIZE_DUST_USD = expandDecimals(1, USD_DECIMALS - 1); // $0.10

export default function EditTakeProfit(props) {
  const {
    isVisible,
    position,
    setIsVisible,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    chainId,
    onConfirm,
    marketTokensInfo,
    isConfirm,
  } = props;
  const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
  const markPrice = BigNumber.from(position?.isLong ? currentMarket?.askPrice : currentMarket?.bidPrice || 0);
  let [orderOption, setOrderOption] = useState(TAKE_PROFIT);
  const [fromValue, setFromValue] = useState("");
  const [triggerPriceInput, setTriggerPriceInput] = useState(markPrice || "");
  const [isChangeAmount, setIsChangeAmount] = useState(false);
  const [closeQuantity, setCloseQuantity] = useState(0);
  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);
  const [triggerPriceValue, setTriggerPriceValue] = useState("");
  const [keepLeverage, setKeepLeverage] = useLocalStorageSerializeKey([chainId, "Exchange-keep-leverage"], true);
  const ORDER_OPTIONS = [TAKE_PROFIT, STOP_LOSS];
  const ORDER_OPTION_LABELS = {
    [TAKE_PROFIT]: TAKE_PROFIT,
    [STOP_LOSS]: STOP_LOSS,
  };
  const onOrderOptionChange = (option) => {
    setOrderOption(option);
  };
  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }
  const { positionNetValue } = position;
  const handleSelectPercentageTriggerPrice = (percentage) => {
    setTriggerPriceInput(percentage);
  };
  const handleSelectPercentageCloseQuantity = (percentage) => {
    const value = quantity.mul(parseValue(percentage, 30)).div(parseValue(1, 30));
    setCloseQuantity(formatAmount(value, 30, 30, false));
    setIsChangeAmount(true);
  };
  const size = position.size;
  const collateral = position.collateral;
  const quantity = position.posQty;
  const defaultPercent = parseValue(Number(position.trigger.amountPercent) / 100000, 30);
  useEffect(() => {
    setFromValue(formatAmount(position.triggerPrice, 30, 6, false));
    const percent = defaultPercent;
    const closeQuantity = position.posQty.mul(percent).div(parseValue(1, 30));
    setCloseQuantity(formatAmount(closeQuantity, 30, 30, false));
    setIsChangeAmount(false);
  }, []);
  const handleTakeProfitLongPercent = (percentage) => {
    const value = position.averagePrice.add(
      parseValue(percentage, 30).mul(position.averagePrice).div(parseValue(1, 30))
    );
    setFromValue(formatAmount(value, 30, 6, false));
    // setTPSLTrigerPriceValue(value);
  };
  const handleTakeProfitShortPercent = (percentage) => {
    const value = position.averagePrice.sub(
      parseValue(percentage, 30).mul(position.averagePrice).div(parseValue(1, 30))
    );
    setFromValue(formatAmount(value, 30, 6, false));
    // setTPSLTrigerPriceValue(value);
  };
  const newSize = closeQuantity
    ? parseValue(closeQuantity, 30).mul(position.averagePrice).div(parseValue(1, 30))
    : null;
  const pnlWithoutFee =
    newSize && fromValue
      ? position.isLong
        ? parseValue(fromValue, 30).sub(position.averagePrice).mul(newSize).div(position.averagePrice)
        : position.averagePrice.sub(parseValue(fromValue, 30)).mul(newSize).div(position.averagePrice)
      : null;
  const pnlWithoutOldFee =
    newSize && fromValue
      ? position.isLong
        ? parseValue(formatAmount(position.triggerPrice, 30, 6, false), 30)
            .sub(position.averagePrice)
            .mul(newSize)
            .div(position.averagePrice)
        : position.averagePrice
            .sub(parseValue(formatAmount(position.triggerPrice, 30, 6, false), 30))
            .mul(newSize)
            .div(position.averagePrice)
      : null;
  const handleConfirm = () => {
    // debugger
    const orderId = position.trigger.id.split("-")[1];
    const _isTP = position.trigger.isTP;
    let percent = parseValue(closeQuantity, 30).eq(quantity)
      ? 100000
      : formatAmount(parseValue(closeQuantity, 30).mul(parseValue(100000, 30)).div(quantity), 30, 0, false);
    if (!isChangeAmount) percent = position?.trigger?.amountPercent;
    const price = parseValue(fromValue, 30);
    // console.log("?????", { position, orderId, _isTP, price, percent });
    onConfirm && onConfirm(position, orderId, _isTP, price, percent);
  };
  const isTp = position.trigger.isTP;

  const getErrorTakeProfit = () => {
    if (!Number(fromValue)) {
      return null;
    }

    const triggerPrice = parseValue(fromValue, 30);

    if (position.isLong) {
      if (triggerPrice.lte(markPrice)) {
        return "Trigger Price below Mark Price";
      }
    } else {
      if (triggerPrice.gte(markPrice)) {
        return "Trigger Price above Mark Price";
      }
    }

    return null;
  };

  const getErrorStopLoss = () => {
    if (!Number(fromValue)) {
      return null;
    }

    const triggerPrice = parseValue(fromValue, 30);

    if (position.isLong) {
      if (triggerPrice.gte(markPrice)) {
        return "Price above Mark Price";
      }

      if (triggerPrice.lte(position.liquidationPrice)) {
        return "Price below Liquidation Price";
      }
    } else {
      if (triggerPrice.lte(markPrice)) {
        return "Price below Mark Price";
      }

      if (triggerPrice.gte(position.liquidationPrice)) {
        return "Price above Liquidation Price";
      }
    }

    return null;
  };

  const isDisableButton = () => {
    if (isTp) {
      return !!getErrorTakeProfit();
    } else {
      return !!getErrorStopLoss();
    }
  };

  const getError = () => {
    if (!fromValue || !Number(fromValue)) {
      return "Enter an amount";
    }

    if (!closeQuantity || !Number(closeQuantity)) {
      return "Enter close amount";
    }

    if (parseValue(closeQuantity, 30).gt(quantity)) {
      return "Amount exceeds balance";
    }
    if (isTp) return getErrorTakeProfit();
    else return getErrorStopLoss();
  };

  const getPrimaryText = () => {
    return getError() ? getError() : isConfirm ? "Editing..." : isTp ? "Edit Take Profit" : "Edit Stop Loss";
  };
  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className={`PositionSeller-modal confirmation-modal edit-modal`}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={isTp ? "Edit Take Profit" : "Edit Stop Loss"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <div className="position-info-container">
              <div className="position-info">
                <img src={importImage("ic_" + position?.symbol?.toLowerCase() + "_24.svg")} alt="" />
                <div className="title">{position.symbol}</div>
                <div className="position-id">#{position?.posId}</div>
              </div>
              <div className="position-tags">
                <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
                  {position?.isLong ? "LONG" : "SHORT"}
                </div>
                <div className="side">
                  {position?.positionType === "Market Order" ? "Market" : position?.positionType}
                </div>
                <div className="leverage-box">{formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X</div>
              </div>
            </div>
          </div>
          <div className="content-container">
            <div className="Exchange-swap-section first-input" style={{ marginTop: 0 }}>
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  <div className="Exchange-swap-usd">
                    <span>
                      {/* Close: {convertedAmountFormatted} {position.collateralToken.symbol} */}
                      Trigger Price
                    </span>
                  </div>
                </div>
                <div
                  className="muted align-right clickable"
                  onClick={() => setFromValue(formatAmount(markPrice, 30, 6, false))}
                >
                  <span>Mark: ${formatAmount(markPrice, 30, currentMarket?.decimals || 2, true)}</span>
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={fromValue}
                    onChange={(e) => setFromValue(e.target.value)}
                  />
                </div>
                <div className="PositionEditor-token-symbol">USD</div>
              </div>
            </div>
            {/* {isTp ? (
              <>{getErrorTakeProfit() && <span className="validate-error">{getErrorTakeProfit()}</span>}</>
            ) : (
              <>{getErrorStopLoss() && <span className="validate-error">{getErrorStopLoss()}</span>}</>
            )} */}
            {isTp ? (
              position.isLong ? (
                <TakeProfitLongTriggerButton
                  onChangePercentage={handleTakeProfitLongPercent}
                  balance={Number(formatAmount(position.averagePrice, 30, 6, false))}
                  value={fromValue}
                  percentArray={[0.01, 0.02, 0.03, 0.05]}
                />
              ) : (
                <TakeProfitShortTriggerButton
                  onChangePercentage={handleTakeProfitShortPercent}
                  balance={Number(formatAmount(position.averagePrice, 30, 6, false))}
                  value={fromValue}
                  percentArray={[0.01, 0.02, 0.03, 0.05]}
                />
              )
            ) : position.isLong ? (
              <TakeProfitShortTriggerButton
                onChangePercentage={handleTakeProfitShortPercent}
                balance={Number(formatAmount(position.averagePrice, 30, 6, false))}
                value={fromValue}
                percentArray={[0.01, 0.02, 0.03, 0.05]}
              />
            ) : (
              <TakeProfitLongTriggerButton
                onChangePercentage={handleTakeProfitLongPercent}
                balance={Number(formatAmount(position.averagePrice, 30, 6, false))}
                value={fromValue}
                percentArray={[0.01, 0.02, 0.03, 0.05]}
              />
            )}
            {/* <TakeProfitLongTriggerButton
              onChangePercentage={handleSelectPercentageTriggerPrice}
              balance={Number(formatAmount(currentMarket.askPrice, 30, 30, false))}
              value={triggerPriceInput}
              percentArray={[0.01, 0.02, 0.03, 0.05]}
            /> */}
            <div className="Exchange-swap-section custom-input" style={{ marginBottom: "8px", marginTop: "16px" }}>
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  <span>Close Quantity</span>
                </div>
                <div className="muted align-right">
                  <span>Max: {formatAmount(quantity, 30, 4, true)}</span>
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={closeQuantity}
                    onChange={(e) => {
                      setIsChangeAmount(true);
                      setCloseQuantity(e.target.value);
                    }}
                  />
                </div>
                <div className="PositionEditor-token-symbol">{position.symbol}</div>
              </div>
            </div>
            <PercentageButtons
              onChangePercentage={handleSelectPercentageCloseQuantity}
              balance={Number(formatAmount(quantity, 30, 30, false))}
              value={closeQuantity}
            />
            <div className="PositionEditor-info-box" style={{ marginTop: "16px" }}>
              <div className="square-container square-fee-container">
                <RowsDropdown
                  show={true}
                  handler={
                    <RowsDropdownHandler>
                      <div style={{ fontSize: 14, marginBottom: 8 }}>{isTp ? "Take Profit" : "Stop Loss"}</div>
                    </RowsDropdownHandler>
                  }
                >
                  {" "}
                  <ExchangeInfoRow label={`Trigger Price`}>
                    {fromValue && !position.triggerPrice.eq(parseValue(fromValue, 30)) ? (
                      <>
                        <span style={{ textDecoration: "line-through", color: "#828899" }}>
                          {fromValue ? (position.isLong ? "≥ " : "≤ ") : ""} $
                          {formatAmount(position.triggerPrice, 30, currentMarket?.decimals || 2, false)}
                        </span>{" "}
                        <span style={{ position: "relative", top: "2px" }}>
                          {" "}
                          <GreenArraowIcon fill={"#828899"} />
                        </span>{" "}
                        <div>
                          {fromValue ? (position.isLong ? "≥ " : "≤ ") : ""}$
                          {formatAmount(parseValue(fromValue, 30), 30, currentMarket?.decimals || 2, true)}
                        </div>
                      </>
                    ) : (
                      <span>
                        {fromValue ? (position.isLong ? "≥ " : "≤ ") : ""} $
                        {formatAmount(position.triggerPrice, 30, currentMarket?.decimals || 2, false)}
                      </span>
                    )}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`${isTp ? "TP" : "SL"} Qty`}>
                    {fromValue &&
                    defaultPercent &&
                    closeQuantity &&
                    !position.posQty.mul(defaultPercent).div(parseValue(1, 30)).eq(parseValue(closeQuantity, 30)) ? (
                      <>
                        <span style={{ textDecoration: "line-through", color: "#828899" }}>
                          {" "}
                          {fromValue
                            ? formatNumber(
                                formatAmount(
                                  parseValue(
                                    formatAmount(
                                      position.posQty.mul(defaultPercent).div(parseValue(1, 30)),
                                      30,
                                      30,
                                      false
                                    ),
                                    30
                                  ),
                                  30,
                                  4,
                                  false
                                ),
                                4
                              )
                            : "--"}{" "}
                          {position.symbol}
                        </span>{" "}
                        <span style={{ position: "relative", top: "2px" }}>
                          {" "}
                          <GreenArraowIcon fill={"#828899"} />
                        </span>{" "}
                        {fromValue ? formatNumber(formatAmount(parseValue(closeQuantity, 30), 30, 4, false), 4) : "--"}{" "}
                        {position.symbol}
                      </>
                    ) : (
                      <>
                        {formatNumber(
                          formatAmount(
                            parseValue(
                              formatAmount(position.posQty.mul(defaultPercent).div(parseValue(1, 30)), 30, 30, false),
                              30
                            ),
                            30,
                            4,
                            false
                          ),
                          4
                        )}{" "}
                        {position.symbol}
                      </>
                    )}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Est. PnL`} isPositive={pnlWithoutFee && pnlWithoutFee.gt(0)}>
                    {fromValue && pnlWithoutFee && !pnlWithoutFee.eq(pnlWithoutOldFee) ? (
                      <>
                        <span style={{ textDecoration: "line-through", color: "#828899" }}>
                          {" "}
                          {pnlWithoutOldFee ? (pnlWithoutOldFee.gt(0) ? "+" : "-") : ""}$
                          {pnlWithoutOldFee ? formatAmount(pnlWithoutOldFee, 30, 2, true).replace("-", "") : "--"}
                        </span>{" "}
                        <span style={{ position: "relative", top: "2px" }}>
                          {" "}
                          <GreenArraowIcon fill={"#828899"} />
                        </span>{" "}
                        {pnlWithoutFee ? (pnlWithoutFee.gt(0) ? "+" : "-") : ""}$
                        {pnlWithoutFee ? formatAmount(pnlWithoutFee, 30, 2, true).replace("-", "") : "--"}
                      </>
                    ) : (
                      <>
                        {pnlWithoutOldFee ? (pnlWithoutOldFee.gt(0) ? "+" : "-") : ""}$
                        {pnlWithoutOldFee ? formatAmount(pnlWithoutOldFee, 30, 2, true).replace("-", "") : "--"}
                      </>
                    )}
                  </ExchangeInfoRow>
                  <div className="divider" />
                  <div style={{ margin: 4 }} />
                  <ExchangeInfoRow label={`Entry Price`}>
                    ${formatAmount(position.averagePrice, 30, currentMarket?.decimals || 2, true)}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Pos. Size`}>${formatAmount(size, 30, 2, true)}</ExchangeInfoRow>
                  <ExchangeInfoRow label={`Pos. Qty`}>
                    {formatAmount(quantity, 30, 4, true)} {position.symbol}
                  </ExchangeInfoRow>
                </RowsDropdown>
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button
              className="App-cta Exchange-swap-button"
              onClick={handleConfirm}
              disabled={!!isDisableButton() || getError() || isConfirm}
            >
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}
