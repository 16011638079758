import useSWR from "swr";
import { BASE, DEFAULT_CHAIN_ID, FANTOM, OP } from "config/chains";
import { fetcher } from "lib/fetcher";
import { InitDataAggregator } from "pages/SpotThroughArregator/config";
import { useCallback, useEffect, useRef, useState } from "react";
import { getServerBaseUrl, getServerKyberUrl, getServerUrl } from "config/backend";
import axios from "axios";
import { helperToast } from "lib/helperToast";
import { ADDRESS_ZERO } from "@uniswap/v3-sdk";
import { getTokenBySymbol } from "config/tokens";
import { getContract } from "config/contracts";
export type Result = Record<string, { value: string; type: string }>;
export type AggregatorParamType = {
  from?: string;
  to?: string;
  dest?: string;
  amount?: string;
  slippage?: string;
  source?: string;
  ref?: string;
  receiver: string;
};

export const apiKeyMicroSwap = "navigatorfinance";

function useNSLPAggregator(
  chainId: number = DEFAULT_CHAIN_ID,
  params: AggregatorParamType,
  savedSelectedDexes: string
) {
  const [dataRes, setData] = useState({});
  // const [isLoading, setIsLoading] = useState(false);
  const interval = useRef<any>(null);
  // let listTokenInfoValid = [] as any;

  let paramsValue = { ...params };
  paramsValue.from =
    params.from === ADDRESS_ZERO ? "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" : params.from?.toLowerCase();
  paramsValue.to = params?.dest?.toLowerCase() || getTokenBySymbol(chainId, "USDC").address.toLowerCase();
  paramsValue.amount = params.amount?.toString();
  paramsValue.slippage = params.slippage?.toString() || "0.005";
  paramsValue.source = "microswap";
  // paramsValue.ref = "0xae4049fb7721E683302459ee0F382EcA389736f3";
  paramsValue.receiver = getContract(chainId, "StakeHelper").toLowerCase();

  const apiUrl = `https://api.microswap.org/aggregator/v2/quote?chainId=${chainId}&dexes=${savedSelectedDexes || ""}`;

  const fetchIds = useCallback(async () => {
    try {
      // setIsLoading(true);

      const res = await axios.get(apiUrl, {
        params: paramsValue,
        headers: {
          "Content-Type": "application/json",
          "Api-Key": `${apiKeyMicroSwap}`,
        },
      });
      // console.log("????", res);
      if (res && res.data) {
        const data = res.data || {};
        // setIsLoading(false);
        setData(data);
      } else {
        // helperToast.error("error ");
      }
    } catch (e) {
      // setIsLoading(true);
      // helperToast.error(e);
      setData([]);
    }
  }, [chainId, params, savedSelectedDexes]);

  useEffect(() => {
    if (params.amount && Number(params.amount)) {
      fetchIds();
      if (interval?.current) {
        clearInterval(interval.current);
      }
      interval.current = setInterval(fetchIds, 15000);
      return () => clearInterval(interval.current);
    } else {
      if (interval?.current) {
        clearInterval(interval.current);
      }
      setData({});
    }
  }, [params, savedSelectedDexes]);

  return {
    data: dataRes,
    isLoading: false,
  };
}

export default useNSLPAggregator;
