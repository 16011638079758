import { useWeb3React } from "@web3-react/core";
import cx from "classnames";
import {
  CLOSED_POSITION,
  OPEN_ORDER,
  OPEN_POSITION,
  SECONDS_PER_DAY,
  TRADE_ACTIVITY,
  getCompletePosition,
  getMarketTokensInfo,
  getPageTitle,
} from "lib/legacy";
import { cloneElement, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { useChainId } from "lib/chains";
import { formatAmount, formatNumber, parseValue } from "lib/numbers";

import SEO from "components/Common/SEO";

import FooterV2 from "components/Footer/Footer";
import { getContract } from "config/contracts";
import no_data_icon from "img/analytics/no-data-icon.svg";
import no_data_iconLight from "img/analytics/no-data-iconLight.svg";
import icClock from "img/clock-bg.svg";
import icClockLight from "img/ic_clockLight.svg";
import { contractFetcher } from "lib/contracts";

import PositionsList from "components/ExchangeV2/PositionsList";
import useAssetsPrices from "hooks/useAssetsPrices";
import useMarketAssets from "hooks/useMarketAssets";
import useSWR from "swr";

import ReaderNslp from "abis/ReaderNslp.json";
import bg from "img/portfolio/bg.png";
import { Select } from "antd";
import TransactionDepositModal from "components/DepositModal";
import ClosedPositionsList from "components/ExchangeV2/ClosedPositionsList";
import OrderListNew from "components/ExchangeV2/OrderListNew";
import TradeActivityList from "components/ExchangeV2/TradeActivityList";
import ExternalLink from "components/ExternalLink/ExternalLink";
import LinkIcon from "components/Icon/LinkIcon";
import Tooltip from "components/Tooltip/Tooltip";
import WithdrawModal from "components/WithdrawModal";
import { getExplorerUrl } from "config/chains";
import { useThemeContext } from "contexts/ThemeProvider";
import { usePortfolio } from "hooks/usePortfolio";
import { usePortfolio1 } from "hooks/usePortfolio1";
import useUserOrder from "hooks/useUserOrder";
import useUserPosition from "hooks/useUserPosition";
import useUserStatsWithParams from "hooks/useUserStatsWithParams";
import useUserTradeInfo from "hooks/useUserTradeInfo";
import downTriangleIc from "img/downTriangleIc.svg";
import downTriangleIcLight from "img/downTriangleIcLight.svg";
import moment from "moment";
import "styles/AccountOverview.scss";
import PortfolioChart from "./PortfolioChart";
import { commify } from "@ethersproject/units";
import { ExchangeMergePortfolio } from "pages/ExchangeMerged/ExchangeMergePortfolio";
import iconVolumn from "img/earn/ic-volumn.svg";
import iconTrade from "img/earn/ic-trade.svg";
import iconProfit from "img/earn/ic-profit.svg";
export const TAB_OPTIONS = [
  OPEN_POSITION,
  OPEN_ORDER,
  CLOSED_POSITION,
  TRADE_ACTIVITY,
  // DEPOSITS,
  // WITHDRAWS,
  // CASH_CASHOUT,
];

const FILTER_BY_OPTIONS = [
  {
    label: "All time",
    value: null,
  },
  {
    label: "24 Hours",
    value: moment().unix() - SECONDS_PER_DAY,
  },
  {
    label: "7 Days",
    value: moment().unix() - SECONDS_PER_DAY * 7,
  },
  {
    label: "1 Month",
    value: moment().unix() - SECONDS_PER_DAY * 30,
  },
];

let closePositionTempt = [];
export default function Portfolio({
  setPendingTxns,
  connectWallet,
  savedSlippageAmount,
  savedSelectedDexes,
  savedIsPnlInLeverage,
  savedShowPnlAfterFees,
  pendingTxns,
}) {
  const { active, library, account } = useWeb3React();

  //----------
  const { chainId } = useChainId();
  const { positions: myOrders, isLoading } = useUserOrder(account);
  const { data: dataUserStats, isLoading: isLoadingDataStats, setFrom } = useUserStatsWithParams(account, chainId);
  const { positions: positionsOnChain, loading } = useUserTradeInfo(account);
  const { positions: positionsApi } = useUserPosition(account);
  let positions = getCompletePosition(positionsOnChain, positionsApi);
  if (closePositionTempt && closePositionTempt.length > 0) {
    positions = positions.filter((x) => !closePositionTempt.find((y) => y.posId === x.posId));
  }
  const { isLightTheme, lightThemeClassName } = useThemeContext();

  const readerNslpAddress = getContract(chainId, "ReaderNslp");

  const { data: userTokenBalances } = useSWR(
    active && [active, chainId, readerNslpAddress, "getUserBalances", account],
    {
      fetcher: contractFetcher(library, ReaderNslp),
    }
  );

  // const { data: usdcBalance } = useSWR(
  //   [`User:usdcBalance:${chainId}`, chainId, getContract(chainId, "USDC"), "balanceOf", account],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //   }
  // );

  // const { data: gusdBalance } = useSWR(
  //   [`StakeV2:gusdBalance:${chainId}`, chainId, getContract(chainId, "NSUSD"), "balanceOf", account],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //   }
  // );

  const TABS = [
    {
      label: "Open Positions ",
      key: "openPositions",
      count: positions?.length || 0,
    },
    {
      label: "Open Orders ",
      key: "orders",
      count: myOrders.length || 0,
    },
    {
      label: "Closed Positions ",
      key: "closedPositions",
      // count: dataUserStats?.closedCount || 0,
    },
    {
      label: "Trade History ",
      key: "tradeHistories",
      // count: dataUserStats?.trades || 0,
    },
  ];
  const [activeTab, setActiveTab] = useState(TABS[0]);
  const [isWithdrawModal, setIsWithdrawModal] = useState(false);
  const [isDepositModal, setIsDepositModal] = useState(false);
  const [modalType, setModalType] = useState("withdraw");

  const [depositValue, setDepositValue] = useState("");
  const [withdrawValue, setWithdrawValue] = useState("");

  const marketAsssets = useMarketAssets();
  const marketAsssetList = Object.keys(marketAsssets).map((key) => marketAsssets[key]);

  const marketPrices = useAssetsPrices();
  const marketTokensInfo = getMarketTokensInfo(marketAsssetList, marketPrices);
  const { data: dataPortfolio1, isLoading: isLoadingPortfolio1 } = usePortfolio1(chainId, account);

  const [posOrders, setPosOrders] = useState();

  useEffect(() => {
    if (activeTab.key !== "orders") {
      setPosOrders(undefined);
    }
  }, [activeTab]);

  const handleShowModal = (modalType) => {
    if (modalType === "deposit") {
      setIsDepositModal(true);
      setModalType("deposit");
    }
    if (modalType === "withdraw") {
      setIsWithdrawModal(true);
      setModalType("withdraw");
    }
  };

  const renderTabSection = () => {
    return {
      openPositions: (
        <PositionsList
          setListSection={() => setActiveTab(TABS[1])}
          setPendingTxns={setPendingTxns}
          userTokenBalances={userTokenBalances}
          myOrders={myOrders}
          setPosOrders={setPosOrders}
          positions={positions}
          loading={loading}
          closePositionTempt={closePositionTempt}
        />
      ),
      orders: <OrderListNew myOrders={posOrders || myOrders} />,
      closedPositions: <ClosedPositionsList />,
      tradeHistories: <TradeActivityList />,
    }[activeTab.key];
  };

  // let portfolioValue = 0;
  // if (dataPortfolio.totalSize && dataPortfolio.marginUsage && dataPortfolio.freeCollateral) {
  //   if (dataPortfolio.marginUsage.eq(0)) {
  //     portfolioValue = dataPortfolio.freeCollateral;
  //   } else {
  //     portfolioValue = dataPortfolio.totalSize
  //       .mul(parseValue(1, 30))
  //       .div(dataPortfolio.marginUsage)
  //       .add(dataPortfolio.freeCollateral);
  //   }
  // }

  // let leverage = 0;
  // if (dataPortfolio.totalSize && dataPortfolio.totalCollateral && !dataPortfolio.totalCollateral.eq(0)) {
  //   leverage = dataPortfolio.totalSize.mul(parseValue(1, 30)).div(dataPortfolio.totalCollateral);
  // }

  const styleTick = useMemo(() => {
    if (dataPortfolio1.marginUsage) {
      const marginUsage = parseFloat(formatAmount(parseValue(dataPortfolio1.marginUsage, 30), 30, 30));
      const goal = (marginUsage * 180) / 250;

      return {
        transform: `translateX(calc(-50% - 8px)) rotate(${Math.floor(goal)}deg)`,
      };
    }
    return {};
  }, [dataPortfolio1.marginUsage]);

  // const exchangeBallance =
  //   dataPortfolio?.totalCollateral && dataPortfolio?.totalCollateral
  //     ? parseFloat(formatAmount(dataPortfolio.totalCollateral, 30, 2, false)) +
  //       parseFloat(formatAmount(dataPortfolio.freeCollateral, 30, 2, false))
  //     : 0;

  let walletBalance = dataPortfolio1?.walletValue || 0;

  const portfolioValue1 = dataPortfolio1?.portfolioValue || 0;
  const collateralUsed = dataPortfolio1?.totalCollateral || 0;

  const exchangeBallance =
    dataPortfolio1?.freeCollateral && dataPortfolio1?.walletValue
      ? dataPortfolio1?.freeCollateral - dataPortfolio1?.walletValue
      : 0;
  const marginUsage = dataPortfolio1?.marginUsage || 0;

  return (
    <SEO title={getPageTitle("Portfolio")}>
      {isDepositModal && (
        <TransactionDepositModal
          chainId={chainId}
          isVisible={isDepositModal}
          setIsVisible={setIsDepositModal}
          library={library}
          isDepositModal={modalType === "deposit" ? true : false}
          savedSlippageAmount={savedSlippageAmount}
          savedSelectedDexes={savedSelectedDexes}
        />
      )}
      {isWithdrawModal && (
        <WithdrawModal
          chainId={chainId}
          isVisible={isWithdrawModal}
          setIsVisible={setIsWithdrawModal}
          library={library}
        />
      )}

      <Wrapper className={`${lightThemeClassName} `}>
        <div className="Portfolio default-container page-layout">
          {/* <input type="text" onChange={(e) => setAccount(e.target.value)} placeholder="Custom Account" /> */}
          <div className="Portfolio-heading">
            <div className="Portfolio-heading-text">Portfolio</div>
            {active && (
              <ExternalLink className="Portfolio-heading-desc" href={`${getExplorerUrl(chainId)}address/${account}`}>
                {account} <LinkIcon width={24} height={24} />
              </ExternalLink>
            )}
          </div>
          <div className="Portfolio-summary">
            <div className="portfolio-card">
              <div className="portfolio-card-top1">
                <div className="portfolio-card-box-title">
                  <div className="portfolio-card-title-content">
                    <Tooltip
                      renderContent={() => (
                        <div>
                          Wallet balance + Exchange balance + Collateral used + unrealized PnL after accrued fees
                        </div>
                      )}
                      handle={<>Portfolio Value</>}
                      position="left-top"
                      className="excep"
                    />
                    <div className="portfolio-card-total">
                      {/* {isLoadingPortfolio && <div className="skeleton-box" style={{ height: 33.59, width: 100 }} />}
                  {!isLoadingPortfolio && formatAmountInDollar(portfolioValue, 30, 2, true)} */}
                      {isLoadingPortfolio1 ? (
                        <div className="skeleton-box" style={{ height: 33.59, width: 100 }} />
                      ) : (
                        "$" + commify(portfolioValue1?.toFixed(2) || 0)
                      )}
                    </div>
                  </div>
                  <div className="portfolio-card-title-actions">
                    <div className={`portfolio-card-bottom ${!active ? "inactive" : ""}`}>
                      {active && (
                        <>
                          <button className="default-btn bold" onClick={() => handleShowModal("deposit")}>
                            Deposit
                          </button>
                          <button className="border-btn bold" onClick={() => handleShowModal("withdraw")}>
                            Withdraw
                          </button>
                        </>
                      )}
                      {!active && (
                        <button className="default-btn bold" onClick={connectWallet}>
                          Connect Wallet
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="portfolio-card-rows-container">
                  <div className="portfolio-card-rows">
                    <Tooltip
                      renderContent={() => <div>Total collateral value of all open positions</div>}
                      handle={<>Collateral used</>}
                      position="left-top"
                      className="excep"
                    />{" "}
                    <div className="wallet-amount">${commify(collateralUsed?.toFixed(2) || 0)}</div>
                  </div>
                  <div className="portfolio-card-rows portfolio-card-rows1  ">
                    <Tooltip
                      renderContent={() => <div>Free collateral for trading with the Crypto pool</div>}
                      handle={<>Wallet balance</>}
                      position="left-top"
                      className="excep"
                    />{" "}
                    <div className="wallet-amount">${commify(walletBalance?.toFixed(2) || 0)}</div>
                  </div>
                  <div className="portfolio-card-rows portfolio-card-rows2 ">
                    <Tooltip
                      renderContent={() => <div>Free collateral for trading with the Stable pool</div>}
                      handle={<>Exchange balance</>}
                      position="left-top"
                      className="excep"
                    />{" "}
                    <div className="wallet-amount">${commify(exchangeBallance?.toFixed(2) || 0)}</div>
                  </div>{" "}
                </div>

                {/* <div className={`portfolio-card-bottom ${!active ? "inactive" : ""}`}>
                {active && (
                  <>
                    <button className="default-btn bold" onClick={() => handleShowModal("deposit")}>
                      Deposit
                    </button>
                    <button className="border-btn bold" onClick={() => handleShowModal("withdraw")}>
                      Withdraw
                    </button>
                  </>
                )}
                {!active && (
                  <button className="default-btn bold" onClick={connectWallet}>
                    Connect Wallet
                  </button>
                )}
              </div> */}
                {/* <div className="portfolio-card-rows">
                <div className="portfolio-card-row">
                  <div className="portfolio-card-key">
                    <Tooltip
                      renderContent={() => <div>Total collateral value of all open positions</div>}
                      handle={"Your Collateral"}
                      position="left-bottom"
                      className="fit-content nowrap"
                    />
                  </div>
                  <div className="portfolio-card-value">
                    {isLoadingPortfolio && <div className="skeleton-box" style={{ height: 19.59, width: 30 }} />}
                    {!isLoadingPortfolio && formatAmountInDollar(dataPortfolio.totalCollateral, 30, 2, true)}
                  </div>
                </div>
                <div className="portfolio-card-row">
                  <div className="portfolio-card-key">Free Collateral</div>
                  <div className="portfolio-card-value">
                    {isLoadingPortfolio && <div className="skeleton-box" style={{ height: 19.59, width: 30 }} />}
                    {!isLoadingPortfolio && formatAmountInDollar(dataPortfolio.freeCollateral, 30, 2, true)}
                  </div>
                </div>
              </div> */}
              </div>
              {/* <div className="portfolio-card-middle">
              <div className="portfolio-card-heading">
                <Tooltip
                  renderContent={() => (
                    <div>Total open position size / Total collateral after unrealized PnL and accrued fees</div>
                  )}
                  handle={<>Margin Usage / Leverage</>}
                  position="left-top"
                  className="excep"
                />{" "}
              </div>
              <div className="portfolio-card-clock">
                <div className="clock">
                  <img src={isLightTheme ? icClockLight : icClock} alt="icClock" />
                  <div className="clock-tick" style={styleTick}>
                    <div className="clock-tick-main" />
                    <div className="clock-tick-dot" />
                  </div>
                </div>
                <div className="portfolio-card-clock-value">
                  {isLoadingPortfolio1 && <div className="skeleton-box" style={{ height: 33.59, width: 160 }} />}
                  {!isLoadingPortfolio1 && (
                    <>
                      {marginUsage?.toFixed(2)}% / {marginUsage?.toFixed(2)}x
                    </>
                  )}
                </div>
              </div>
            </div> */}
            </div>
            <div className="portfolio-chart">
              <div className="portfolio-card-middle" style={{ background: "#fff" }}>
                <div className="margin-block">
                  <div className="clock">
                    <img src={icClock} alt="icClock" />
                  </div>
                  <div>
                    <div className="portfolio-card-heading">
                      <Tooltip
                        renderContent={() => (
                          <div>Total open position size / Total collateral after unrealized PnL and accrued fees</div>
                        )}
                        handle={<>Margin Usage/Leverage</>}
                        position="left-top"
                        className="excep"
                      />{" "}
                    </div>
                    <div className="portfolio-card-clock">
                      <div className="portfolio-card-clock-value">
                        {isLoadingPortfolio1 && <div className="skeleton-box" style={{ height: 33.59, width: 160 }} />}
                        {!isLoadingPortfolio1 && (
                          <>
                            {marginUsage?.toFixed(2)}% / {marginUsage?.toFixed(2)}x
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="portfolio-card-middle" style={{ background: "#FFE177" }}>
                <div className="margin-block">
                  <img src={iconVolumn} alt="ic-volumn" />
                  <div className="Portfolio-history-item-info">
                    <div className="portfolio-card-heading">Volume</div>
                    <div className="Portfolio-history-item-value">
                      {dataUserStats?.tradingVolume
                        ? "$" + commify(dataUserStats?.tradingVolume?.toFixed(2), 2)
                        : "$0.00"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="portfolio-card-middle" style={{ background: "#D1A9FF" }}>
                <div className="margin-block">
                  <img src={iconTrade} alt="ic-trade" />
                  <div className="Portfolio-history-item-info">
                    <div className="portfolio-card-heading">Trades</div>
                    <div className="Portfolio-history-item-value">{commify(dataUserStats?.trades || "0")}</div>
                  </div>
                </div>
              </div>
              <div className="portfolio-card-middle" style={{ background: "#86CCFF" }}>
                <div className="margin-block">
                  <img src={iconProfit} alt="ic-profit" />
                  <div className="Portfolio-history-item-info">
                    <div className="portfolio-card-heading">Profit/Loss</div>
                    <div className="Portfolio-history-item-value">
                      {dataUserStats?.pnl && dataUserStats?.pnl < 0 ? "-" : ""}$
                      {dataUserStats?.pnl ? commify(Math.abs(Number(dataUserStats?.pnl || 0))?.toFixed(2)) : "0.00"}
                    </div>
                  </div>
                </div>
              </div>

              {/* {active && <PortfolioChart account={account} chainId={chainId} />}
            {!active && (
              <div className="no-record-chart">
                <img
                  src={isLightTheme ? no_data_iconLight : no_data_icon}
                  alt="no_data_icon"
                  className="no-record-icon-styled"
                />
                <span>No Data</span>
              </div>
            )} */}
            </div>
          </div>
          {/* <div className="Portfolio-collateral">
          <div className="Portfolio-collateral-title">Your Collateral</div>
          <DataTable $noIndex $autoHeight $rowHeightSkeleton="278.75px" className={lightThemeClassName}>
            <div className="head">
              <div className="col-name">Asset</div>
              <div className="col-name">Wallet</div>
              <div className="col-name">Your Collateral</div>
              <div className="col-name">Free Collateral</div>
              <div className="col-name" style={{ minWidth: 300 }}></div>
            </div>
            <div className="body">
              {!isLoading &&
                Array(2)
                  .fill(null)
                  ?.map((row, i) => {
                    return (
                      <TableRow noScaleOnHover key={i}>
                        <div d-label="Asset" className="col-body">
                          <TokenHead isComing={i === 2} />
                        </div>
                        <div d-label="Wallet" className="col-body">
                          <div className="col-body-content">
                            {formatAmount(usdcBalance || 0, 6, 2, true)}
                            <div className="muted not-bold">{formatAmountInDollar(usdcBalance || 0, 6, 2, true)}</div>
                          </div>
                        </div>
                        <div d-label="Your Collateral" className="col-body">
                          <div className="col-body-content">
                            {!isLoadingPortfolio && formatAmount(dataPortfolio.totalCollateral, 30, 2, true)}
                            <div className="muted not-bold">
                              {formatAmountInDollar(dataPortfolio.totalCollateral, 30, 2, true)}
                            </div>
                          </div>
                        </div>
                        <div d-label="Free Collateral" className="col-body">
                          <div className="col-body-content">
                            {!isLoadingPortfolio && formatAmount(dataPortfolio.freeCollateral, 30, 2, true)}
                            <div className="muted not-bold">
                              {!isLoadingPortfolio && formatAmountInDollar(dataPortfolio.freeCollateral, 30, 2, true)}
                            </div>
                          </div>
                        </div>
                        <div d-label="" className="col-body col-btns" style={{ minWidth: 300 }}>
                          {active && (
                            <div className="btns">
                              <button className="default-btn bold" onClick={() => handleShowModal("deposit")}>
                                Deposit
                              </button>
                              <button className="border-btn bold" onClick={() => handleShowModal("withdraw")}>
                                Withdraw
                              </button>
                            </div>
                          )}
                          {!active && (
                            <div className="btns">
                              <button className="default-btn bold" onClick={connectWallet}>
                                Connect Wallet
                              </button>
                            </div>
                          )}
                        </div>
                      </TableRow>
                    );
                  })}
            </div>
            {isLoading && <SkeletonLoadingTable rowNumber={1} noHeading />}
          </DataTable>
        </div> */}
          <div className="Portfolio-history">
            <div className="Portfolio-history-heading">
              <div className="Portfolio-history-title">History</div>{" "}
              <Select1
                showArrow
                suffixIcon={<img src={isLightTheme ? downTriangleIcLight : downTriangleIc} alt="" />}
                onChange={setFrom}
                defaultValue={FILTER_BY_OPTIONS[0]}
                bordered={false}
                options={FILTER_BY_OPTIONS}
                popupClassName={lightThemeClassName}
              />
            </div>
            {/* <div className="Portfolio-history-container">
              <div className="Portfolio-history-item">
                <div className="Portfolio-history-item-info">
                  <div className="Portfolio-history-item-title">Ranking</div>
                  <div className="Portfolio-history-item-value">
                    {account ? commify(dataUserStats?.topPnl || "0") : "--"}
                  </div>
                </div>
              </div>
              <div className="Portfolio-history-item">
                <div className="Portfolio-history-item-info">
                  <div className="Portfolio-history-item-title">Trades</div>
                  <div className="Portfolio-history-item-value">{commify(dataUserStats?.trades || "0")}</div>
                </div>
              </div>
              <div className="Portfolio-history-item">
                <div className="Portfolio-history-item-info">
                  <div className="Portfolio-history-item-title">Profit / Loss</div>
                  <div className="Portfolio-history-item-value">
                    {dataUserStats?.pnl && dataUserStats?.pnl < 0 ? "-" : ""}$
                    {dataUserStats?.pnl ? commify(Math.abs(Number(dataUserStats?.pnl || 0))?.toFixed(2)) : "0.00"}
                  </div>
                </div>
              </div>
              <div className="Portfolio-history-item">
                <div className="Portfolio-history-item-info">
                  <div className="Portfolio-history-item-title">Volume</div>
                  <div className="Portfolio-history-item-value">
                    {dataUserStats?.tradingVolume
                      ? "$" + commify(dataUserStats?.tradingVolume?.toFixed(2), 2)
                      : "$0.00"}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <ExchangeMergePortfolio
            savedIsPnlInLeverage={savedIsPnlInLeverage}
            savedShowPnlAfterFees={savedShowPnlAfterFees}
            savedSlippageAmount={savedSlippageAmount}
            pendingTxns={pendingTxns}
            setPendingTxns={setPendingTxns}
          />
          {/* <div className={`Portfolio-positions exchange-v2 ${lightThemeClassName}`}>
          <div className="Portfolio-tabs">
            {TABS.map((tab) => {
              return (
                <div
                  className={cx("tab", {
                    active: activeTab.key === tab.key,
                  })}
                  onClick={() => setActiveTab(tab)}
                  key={tab.key}
                >
                  {tab.label}{" "}
                  {tab.hasOwnProperty("count") ? (
                    <Styledcount
                      className={cx("", {
                        styleActive: activeTab.key === tab.key,
                      })}
                    >
                      {tab.count}
                    </Styledcount>
                  ) : null}
                </div>
              );
            })}
          </div>
          <div className="Portfolio-tab-content">
            {cloneElement(renderTabSection(), {
              marketAsssets,
              account,
              chainId,
              marketTokensInfo,
              library,
              active,
            })}
          </div>
        </div> */}
        </div>
      </Wrapper>
      <FooterV2 />
    </SEO>
  );
}

const Select1 = styled(Select)`
  &:not(.ant-select-customize-input) .ant-select-selector {
    width: fit-content;
    display: flex;
    height: 40px !important;
    padding: 0px var(--Base-Unit-S-12, 12px) !important;
    justify-content: center;
    align-items: center;
    gap: var(--Base-Unit-XS-8, 8px);
    border-radius: 100px;
    border: 1px solid rgba(239, 236, 235, 0.08) !important;
    background-color: #12141a !important;
  }
`;

const Wrapper = styled.div`
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 48px;
  @media screen and (max-width: 1350px) {
    padding-top: 0;
  }
  .portfolio-card-middle .Tooltip-handle {
    color: rgba(0, 0, 0, 0.6);
  }
  .page-layout {
    padding-top: 48px;
    @media screen and (max-width: 700px) {
      padding-top: 16px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .wallet-amount {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }

  .Portfolio-heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 24px;

    .Portfolio-heading-text {
      font-size: 36px;
      font-weight: 700;
      line-height: 140%; /* 50.4px */
    }

    .Portfolio-heading-desc {
      display: flex;
      align-items: center;
      text-decoration: none;

      color: #828899;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */

      @media screen and (max-width: 700px) {
        font-size: 12px;
      }
      cursor: pointer;

      &:hover {
        color: #90fb75;

        svg {
          g {
            opacity: 1;
          }

          path {
            fill: #90fb75;
          }
        }
      }
    }
  }

  .Portfolio-summary {
    display: flex;
    gap: 24px;
    width: 100%;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    .portfolio-card-box-title {
      @media screen and (max-width: 700px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
      }
      min-height: 118px;
      background: linear-gradient(90deg, #2c4d24 0%, rgba(0, 0, 0, 0) 100%);
      border-radius: 16px 16px 0 0;
      padding: 24px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      line-height: 140%;
      .portfolio-card-total {
        font-size: 24px;
        margin-top: 8px;
        font-weight: 700;
        line-height: 150%;
      }
      button {
        width: 138px !important;
      }
    }
    .portfolio-card-rows-container {
      @media screen and (max-width: 700px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        .portfolio-card-rows {
          width: 100%;
          display: flex;
          flex-direction: row !important;
          padding: 0 !important;
        }
      }
      min-height: 118px;
      display: flex;
      align-items: center;
      .portfolio-card-rows {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
        border: none;
        margin: 0;
      }
    }
    .portfolio-card {
      flex: 1;
      .portfolio-card-top1 {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-radius: 16px;
        background: #12141a;
      }

      /*
      & > * {
        display: flex;
        padding: 24px;
      } */
      .portfolio-card-rows {
        .Tooltip-handle {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          color: #828899;
          /* color: #828899; */
        }
        display: flex;
        padding: 16px 24px;
        &.portfolio-card-rows2 {
          padding: 16px 24px 16px 24px;
          /* border-top: 1px solid var(--Border, rgba(239, 236, 235, 0.08)); */
        }
        &.portfolio-card-rows1 {
          /* border-top: 1px solid var(--Border, rgba(239, 236, 235, 0.08)); */
          padding: 16px 24px;
        }
        justify-content: space-between;

        .portfolio-card-row {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          align-self: stretch;

          .portfolio-card-key {
            color: #828899;
            font-size: 14px;
            font-weight: 400;
            line-height: 140%; /* 19.6px */

            .Tooltip-handle {
              color: #828899;
              font-size: 14px;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
            }
          }

          .portfolio-card-heading {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            align-self: stretch;

            .Tooltip-handle {
              color: #828899;
              font-size: 14px;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
            }

            .portfolio-card-connected,
            .portfolio-card-disconnected {
              color: var(--Primary, #c1ff99);
              font-size: 14px;
              font-weight: 500;
              line-height: 120%; /* 16.8px */
              display: flex;
              align-items: center;
              gap: var(--Base-Unit-XS-8, 8px);

              .portfolio-card-dot {
                width: var(--Base-Unit-XS-8, 8px);
                height: var(--Base-Unit-XS-8, 8px);
                background-color: #c1ff99;
                border-radius: 50%;
              }
            }

            .portfolio-card-disconnected {
              color: #828899;

              .portfolio-card-dot {
                background-color: #828899;
              }
            }
          }

          .portfolio-card-top {
            flex-direction: column;
            align-items: stretch;
            gap: 16px;
            border-bottom: 1px solid var(--Border, rgba(255, 255, 255, 0.1));

            .portfolio-card-box {
              display: flex;
              flex-direction: column;
              align-items: stretch;
              gap: 8px;
            }

            .portfolio-card-total {
              color: var(--Primary, #c1ff99);
              font-size: 24px;
              font-weight: 700;
              line-height: 140%; /* 33.6px */
            }

            .portfolio-card-value {
              font-size: 14px;
              font-weight: 700;
              line-height: 140%; /* 19.6px */
            }
          }
        }
      }

      .portfolio-card-middle {
        display: flex;
        padding: 24px;

        /* margin-top: 16px; */
        border-radius: 16px;

        .portfolio-card-heading {
          font-size: 14px;
          font-style: normal;
          color: var(--Black, #000);
          font-weight: 500;
          line-height: 140%;
        }
        .portfolio-card-clock {
          display: flex;
          align-items: center;

          .clock {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              bottom: -4px;
              left: 50%;
              transform: translateX(-50%);
              width: 30px;
              height: 19px;
              background-color: #172152;
              border-radius: 10rem 10rem 0 0;
            }

            .clock-tick {
              display: flex;
              align-items: center;
              transform-origin: right;

              width: fit-content;
              position: absolute;
              bottom: -2px;
              left: 50%;
              transform: translateX(calc(-50% - 8px)) rotate(0deg);
              z-index: 1;

              .clock-tick-main {
                height: 2px;
                width: 12px;
                background-color: white;
              }

              .clock-tick-dot {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: white;
              }
            }
          }
        }
      }

      .portfolio-card-bottom {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;

        &.inactive {
          grid-template-columns: 1fr;
        }
      }
    }

    .portfolio-chart {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .margin-block {
        display: flex;
        gap: 16px;
        align-items: center;
        .portfolio-card-clock {
          margin-top: 8px;
        }
      }
      .no-record-chart {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;
        border: 1px solid var(--Border, rgba(255, 255, 255, 0.1));
        border-radius: 16px;

        @media screen and (max-width: 767px) {
          padding: 16px;
        }
      }
    }
    .portfolio-card-middle {
      @media screen and (max-width: 700px) {
        width: 100%;
      }
      max-height: 110px;
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      /* margin-top: 16px; */
      border-radius: 16px;
      background: var(--Nature-1, #18191a);
      width: calc(50% - 8px);
      .portfolio-card-heading {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        color: #000;
        opacity: 0.6;
      }
      .Portfolio-history-item-value {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        color: #000;
        margin-top: 8px;
      }
      .portfolio-card-clock {
        display: flex;
        align-items: center;
        gap: var(--Base-Unit-S-12, 12px);

        .clock {
          position: relative;

          &::after {
            content: "";
            position: absolute;
            bottom: -4px;
            left: 50%;
            transform: translateX(-50%);
            width: 30px;
            height: 19px;
            background-color: #172152;
            border-radius: 10rem 10rem 0 0;
          }

          .clock-tick {
            display: flex;
            align-items: center;
            transform-origin: right;

            width: fit-content;
            position: absolute;
            bottom: -2px;
            left: 50%;
            transform: translateX(calc(-50% - 8px)) rotate(0deg);
            z-index: 1;

            .clock-tick-main {
              height: 2px;
              width: 12px;
              background-color: white;
            }

            .clock-tick-dot {
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: white;
            }
          }
        }

        .portfolio-card-clock-value {
          font-size: 24px;
          font-weight: 700;
          line-height: 150%; /* 33.6px */
          color: var(--Black, #000);
        }
      }
    }
  }

  .Portfolio-collateral {
    margin-top: 72px;

    .Portfolio-collateral-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 140%; /* 33.6px */
      margin-bottom: 16px;
    }
  }

  .Portfolio-history {
    margin: 48px 0 0 0;

    .Portfolio-history-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      margin-bottom: 24px;

      .Portfolio-history-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 150%; /* 33.6px */
      }
    }

    .Portfolio-history-container {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;

      .Portfolio-history-item {
        display: flex;
        padding: 24px;
        align-items: center;
        gap: var(--Base-Unit-S-12, 12px);
        flex: 1 0 0;
        border-radius: 16px;
        background: var(--Nature-1, #18191a);

        .Portfolio-history-item-info {
          display: flex;
          /* flex-direction: column;
          align-items: flex-start; */
          gap: var(--Base-Unit-XS-8, 8px);
          flex: 1 0 0;

          .Portfolio-history-item-title {
            color: #828899;
            font-size: 14px;
            font-weight: 500;
            line-height: 140%; /* 19.6px */
          }

          .Portfolio-history-item-value {
            font-size: 14px;
            font-weight: 700;
            line-height: 140%; /* 28px */
          }
        }
      }
    }
  }

  .Portfolio-positions {
    border-radius: 16px;
    background: var(--Nature-1, #12141a);
    padding: 24px;

    .Portfolio-tabs {
      @media screen and (min-width: 900px) {
        width: fit-content;
      }
      background: #1a1d26;
      border: 1px solid var(--Border, rgba(255, 255, 255, 0.1));
      display: flex;
      align-items: flex-start;
      margin: 0 0 16px;
      overflow-y: auto;
      border-radius: 100px;
      position: relative;
      z-index: 1;

      .tab {
        display: flex;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 100px;
        color: #828899;
        font-size: 14px;
        font-weight: 700;
        line-height: 140%; /* 19.6px */
        text-wrap: nowrap;
        white-space: nowrap;
        cursor: pointer;
        border: 1px solid transparent;

        &:hover {
          color: #fff;
        }

        &.active {
          background: #262933;
          color: #90fb75;
          background: #262933;
          pointer-events: none;
        }
      }
    }

    .Portfolio-tab-content {
      position: relative;
      z-index: 1;

      .PositionsList table {
        margin-top: -16px;
        border-collapse: separate;
        .table-content {
          background: #212224;
        }
        .Exchange-list-header {
          top: 0;
          margin-bottom: 12px;
        }

        .Exchange-list-header th span {
          font-size: 14px !important;
          font-style: normal;
          font-weight: 500 !important;
          line-height: 140%;
          color: var(--Text-Text_Secondary, #828899) !important;
        }

        .no-data-record {
          border-color: var(--Border, rgba(255, 255, 255, 0.1));
        }
      }
    }
  }

  @media screen and (max-width: 1023px) {
    .Portfolio-heading {
    }

    .Portfolio-summary {
      grid-template-columns: 1fr 1fr;
    }

    .Portfolio-collateral {
      margin-top: 64px;
    }

    .Portfolio-history {
      margin-top: 64px;

      .Portfolio-history-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .Portfolio-heading {
      flex-direction: column;
      align-items: flex-start;

      .Portfolio-heading-text {
        font-size: 24px;
      }
    }

    .Portfolio-summary {
      grid-template-columns: 1fr;

      .portfolio-card {
        .portfolio-card-top,
        .portfolio-card-middle,
        .portfolio-card-bottom {
          padding: 12px 0 0 0;
          gap: 14px;
        }
      }
    }

    .Portfolio-collateral {
      margin-top: 40px;
    }

    .Portfolio-history {
      margin-top: 40px;

      .Portfolio-history-container {
        grid-template-columns: 1fr;
        gap: 16px;
      }
    }
  }
`;
const Styledcount = styled.div`
  border-radius: 8px;
  border: 1px solid #41454d;
  background: var(--Nature-2, #212224);
  color: #828899;
  height: 20px;
  padding: 0 6px;
  font-size: 10px;

  &.styleActive {
    color: #fff;
  }
`;
