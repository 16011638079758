import jazzicon from "jazzicon";
import styled from "styled-components";

const StyledAvatar = styled.img`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: 55%;
`;

const Avatar = ({ address, size = "30px", chainId, NFTid = null }) => {
  const srcImg = `https://cdn.navigator.exchange/nfts/${NFTid}.png`;

  let xml;
  if (address) {
    const cnt = parseInt(address.slice(2, 10), 16);
    const el = jazzicon(100, cnt);
    const svg = el.querySelector("svg");
    xml = btoa(unescape(encodeURIComponent(new XMLSerializer().serializeToString(svg))));
  }

  return (
    <StyledAvatar size={size} src={!NFTid || chainId === 10 ? "data:image/svg+xml;base64," + xml : srcImg} alt="" />
  );
};

export default Avatar;
