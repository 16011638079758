import React, { useMemo, useState } from "react";
import cx from "classnames";
import searchIc from "img/icons/search.svg";
import styled, { css } from "styled-components";
import { importImage } from "lib/legacy";
import { formatAmount, limitDecimals, parseValue } from "lib/numbers";
import { CRYPTO, FOREX, METALS } from "config/tokens";
import { useDebounce } from "lib/useDebounce";
import { Menu } from "@headlessui/react";
import { useHistory } from "react-router-dom";

const SelectTokenChart = ({ tokens, onSelect, current, theme }) => {
  const isLoading = !tokens.length;
  const [activeTab, setActiveTab] = useState("All");
  const [searchInput, setSearchInput] = useState("");
  const debounceSearch = useDebounce(searchInput, 500);
  const history = useHistory();
  const numberAll = Object.values(tokens)?.length;
  const numberCrypto = Object.values(tokens)?.filter((item) => CRYPTO.includes(item?.tokenSymbol))?.length;
  const numberForex = Object.values(tokens)?.filter((item) => FOREX.includes(item?.tokenSymbol))?.length;
  const numberMetals = Object.values(tokens)?.filter((item) => METALS.includes(item?.tokenSymbol))?.length;
  const LIST = [
    {
      label: "All",
      number: numberAll,
    },
    {
      label: "Crypto",
      number: numberCrypto,
    },
    {
      label: "Forex",
      number: numberForex,
    },
    {
      label: "Metals",
      number: numberMetals,
    },
  ];

  const filter = useMemo(() => {
    let result = [];

    if (activeTab === "All") {
      result = Object.values(tokens);
    }
    if (activeTab === "Crypto") {
      result = Object.values(tokens)?.filter((item) => CRYPTO.includes(item?.tokenSymbol));
    }
    if (activeTab === "Forex") {
      result = Object.values(tokens)?.filter((item) => FOREX.includes(item?.tokenSymbol));
    }
    if (activeTab === "Metals") {
      result = Object.values(tokens)?.filter((item) => METALS.includes(item?.tokenSymbol));
    }

    if (debounceSearch) {
      return result?.filter((item) => item?.symbol.toLowerCase().includes(debounceSearch.toLowerCase()));
    }
    return result;
  }, [activeTab, debounceSearch, tokens]);
  const onHandleSelectMarket = (token) => {
    onSelect(token);
  };
  return (
    <Wrapper $height={filter.length > 0 ? filter.length * 64 : 64} className="select-market-container">
      <div className="top">
        <div className="input-section">
          <img src={searchIc} alt="" />
          <input
            placeholder="Search"
            value={searchInput}
            onChange={(event) => {
              setSearchInput(event.target.value);
            }}
          />
        </div>
        <div className="filter">
          {isLoading ? (
            Array(4)
              .fill(null)
              .map((i, k) => <div key={k} className="skeleton-box" style={{ height: "33px", borderRadius: 8 }} />)
          ) : (
            <>
              {LIST.map((item) => (
                <button
                  onClick={() => {
                    setActiveTab(item.label);
                  }}
                  className={`${activeTab === item.label ? "active" : ""}`}
                  key={item.label}
                >
                  {item.label} {item.number ? `(${item.number})` : "(0)"}
                </button>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="bottom list-tokens-chart">
        {isLoading ? (
          Array(4)
            .fill(null)
            .map((t, k) => (
              <div className="row" key={k}>
                <div className="left">
                  <div className="skeleton-box" style={{ height: "24px", width: "24px", borderRadius: 100 }} />
                  <div className="skeleton-box" style={{ height: "19px", width: "68px", borderRadius: 4 }} />
                </div>
                <div className="right">
                  <div className="skeleton-box" style={{ height: "19px", width: "73px", borderRadius: 4 }} />
                  <div className="skeleton-box" style={{ height: "19px", width: "46px", borderRadius: 4 }} />
                </div>
              </div>
            ))
        ) : filter.length > 0 ? (
          filter.map((t, i) => {
            return (
              <Menu.Item
                className={`row ${current === t.symbol ? "active" : ""}`}
                key={i}
                onClick={() => {
                  onHandleSelectMarket(t);
                }}
              >
                <div>
                  <div className="left">
                    <img src={importImage("ic_" + t.tokenSymbol?.toLowerCase() + "_40.svg")} alt="symbol" />
                    <div className="select-symbol-container">
                      <div className="symbol">{t?.symbol}</div>
                      <div className="supported-version">
                        {/* <div className="title">Supported</div>
                        {t?.isV1 && <div className="ver">V1</div>}
                        {t?.isV2 && <div className="ver">V2</div>} */}
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="price">{formatAmount(t?.askPrice, 30, t?.decimals, true)}</div>
                    <div
                      className={cx("change", {
                        positive: t?.price24HChanged > 0,
                        negative: t?.price24HChanged < 0,
                      })}
                    >
                      {/* {t?.price24HChanged === 0 ? null : (
                        <img src={t?.price24HChanged < 0 ? ArrowDownIcon : ArrowUpIcon} alt="up arrow" />
                      )} */}
                      {/* {t?.price24HChanged ? (t?.price24HChanged < 0 ? "-" : "+") : ""} */}
                      {t ? limitDecimals(t.price24HChanged || 0, 2) : "--"}%
                    </div>
                  </div>
                </div>
              </Menu.Item>
            );
          })
        ) : (
          <div className={`row no-pair`}>{searchInput ? "No results" : "No pairs available"}</div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 424px;
  flex-direction: column;
  align-items: flex-start;
  /* border-radius: 16px; */
  /* background: var(--Natural-2, #302f2f); */
  overflow: hidden;

  @media screen and (max-width: 1020px) {
    width: calc(50vw - 32px);
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  .top {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 13px;
    align-self: stretch;
    border-bottom: 1px solid var(--white-10, rgba(255, 255, 255, 0.1));
    cursor: default;

    .input-section {
      display: flex;
      height: 44px;
      padding: 7px 12px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 12px;
      position: relative;
      background: var(--Background-Input_Background, rgba(0, 0, 0, 0.8));
      input {
        padding: 0;
        color: var(--white-100, rgba(255, 255, 255, 1));
        font-size: 14px;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
        position: absolute;
        inset: 0;
        padding-left: 36px;
      }
    }

    .filter {
      display: flex;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;

      button {
        display: flex;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: var(--Border-Radius-Medium, 12px);
        background: var(--Nature-1, #18191a);

        color: #828899;
        font-size: 14px;
        border: none;
        height: 33px;

        font-weight: 500;
        line-height: 140%; /* 19.6px */
        border: 2px solid transparent;

        &.active {
          border-radius: var(--Border-Radius-Medium, 12px);
          border: 2px solid #41454d;
          background: var(--Nature-3, #2e3033);
          color: #fff;
        }
      }

      @media screen and (max-width: 1023px) {
      }

      @media screen and (max-width: 1024px) {
        /* flex-wrap: wrap; */
        width: 100%;
        justify-content: space-between;
        overflow-x: auto;
        button {
          flex: unset;
          white-space: nowrap;
        }
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    height: ${({ $height }) => $height + "px"};
    max-height: 256px;
    width: 100%;

    ${({ $height }) =>
      $height > 256 &&
      css`
        min-height: 256px;
        overflow-y: auto;
      `}

    &::-webkit-scrollbar {
      width: 8px !important;
    }

    ::-webkit-scrollbar-track {
      /* background: transparent; */
    }

    &::-webkit-scrollbar-thumb {
      /* background: #ffffff99; */
      width: 6px !important;
      border-radius: 50px;
      /* border: none; */
    }
    .row {
      display: flex;
      padding: 12px 16px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      cursor: pointer;

      &.no-pair {
        font-size: 14px;
        font-weight: 400;
        line-height: 40px;
        justify-content: center;
        cursor: default;
      }

      &:hover {
        background: var(--white-10, rgba(255, 255, 255, 0.1));
      }

      &.active {
        background: var(--white-10, rgba(255, 255, 255, 0.1));
      }

      .left {
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          width: 24px;
          height: 24px;
        }
        .symbol {
          color: var(--white-100, #fff);
          font-size: 14px;
          font-weight: 700;
          line-height: 120%; /* 19.2px */
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 4px;

        .price {
          color: var(--white-100, #fff);
          font-size: 14px;
          font-weight: 700;
          line-height: 120%; /* 19.2px */
        }

        .change {
          font-size: 14px;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
          display: flex;
        }
      }
    }
  }
`;

export default SelectTokenChart;
