function Icon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ?? "30"}
      height={props.height ?? "22"}
      fill="none"
      viewBox="0 0 30 22"
    >
      <path
        fill="#000"
        d="M15.001.621a5.591 5.591 0 100 11.182A5.591 5.591 0 0015 .621zM7.83 3.052c-2.796.219-4.936 2.723-4.571 5.616.292 2.188 2.042 3.962 4.23 4.278a5.07 5.07 0 002.966-.486c.292-.146.34-.583.097-.802a6.957 6.957 0 01-2.6-5.446c0-.875.17-1.726.461-2.504a.489.489 0 00-.462-.656H7.83zm14.221 0c-.34 0-.583.34-.462.656.292.778.462 1.63.462 2.504a7.011 7.011 0 01-2.577 5.446c-.267.219-.218.632.098.802.875.438 1.871.656 2.965.486 2.213-.316 3.963-2.09 4.23-4.303.365-2.917-1.848-5.445-4.668-5.59h-.048zm-7.05 10.697c-3.646 0-7.074 1.798-9.116 4.789a3.594 3.594 0 00-.608 2.042c0 .389.34.705.73.705h17.989c.389 0 .73-.316.73-.705a3.369 3.369 0 00-.609-2.042c-2.042-2.99-5.47-4.79-9.116-4.79zm-8.833.982a.53.53 0 00-.089.014 9.815 9.815 0 00-5.956 3.987.716.716 0 00.608 1.094H3.26a.701.701 0 00.68-.534c.098-.438.268-.852.511-1.24v-.025c.584-.924 1.289-1.726 2.067-2.455.342-.32.085-.873-.349-.841zm17.642.004c-.434-.012-.69.54-.349.837a12.944 12.944 0 011.92 2.212c.049.073.122.17.195.292.219.389.389.778.51 1.216.073.316.365.51.681.51h2.529c.559 0 .899-.608.583-1.07a9.937 9.937 0 00-5.98-3.987.522.522 0 00-.09-.01z"
      ></path>
    </svg>
  );
}

export default Icon;
