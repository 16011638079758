import ExternalLink from "components/ExternalLink/ExternalLink";
import { useState } from "react";

const Partner = ({ item, idx, setPauseId, pauseId }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <ExternalLink href={item.link} className="wrap-coin" key={idx}>
      <div
        className="item-partner"
        key={idx}
        onMouseEnter={() => {
          setPauseId(pauseId);
          setShowTooltip(true);
        }}
        onMouseLeave={() => {
          setPauseId(undefined);
          setShowTooltip(false);
        }}
        style={{ position: "relative" }}
      >
        <img className="icon-partner" src={item.img} alt="" />
        {showTooltip && (
          <div
            style={{
              position: "absolute",
              top: "30%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#333",
              color: "#fff",
              padding: "5px 10px",
              borderRadius: "4px",
              whiteSpace: "nowrap",
              pointerEvents: "none",
            }}
          >
            {item.name}
          </div>
        )}
      </div>
    </ExternalLink>
  );
};
export default Partner;
