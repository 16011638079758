import React, { useState, useCallback, useEffect, useMemo } from "react";
import { ethers } from "ethers";
import cx from "classnames";
import { Trans, t } from "@lingui/macro";
import { BsArrowRight } from "react-icons/bs";

import {
  DEFAULT_SLIPPAGE_AMOUNT,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  USD_DECIMALS,
  DUST_USD,
  BASIS_POINTS_DIVISOR,
  MIN_PROFIT_TIME,
  getLiquidationPrice,
  getLeverage,
  getMarginFee,
  PRECISION,
  MARKET,
  STOP,
  DECREASE,
  calculatePositionDelta,
  getDeltaStr,
  getProfitPrice,
  getNextToAmount,
  USDN_DECIMALS,
  adjustForDecimals,
  isAddressZero,
  MAX_ALLOWED_LEVERAGE,
  importImage,
} from "lib/legacy";
import { ARBITRUM, getChainName, getConstant, IS_NETWORK_DISABLED } from "config/chains";
import { createDecreaseOrder, useHasOutdatedUi } from "domain/legacy";
import { getContract } from "config/contracts";
import PositionRouter from "abis/PositionRouter.json";
import Checkbox from "../Checkbox/Checkbox";
import Tab from "../Tab/Tab";
import Modal from "../Modal/Modal";
import ExchangeInfoRow from "./ExchangeInfoRow";
import Tooltip from "../Tooltip/Tooltip";
import TokenSelector from "./TokenSelector";
import "./PositionSeller.css";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { callContract } from "lib/contracts";
import { getTokenAmountFromUsd } from "domain/tokens";
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from "config/ui";
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from "lib/localStorage";
import { CLOSE_POSITION_RECEIVE_TOKEN_KEY, SLIPPAGE_BPS_KEY } from "config/localStorage";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { usePrevious } from "lib/usePrevious";
import {
  bigNumberify,
  expandDecimals,
  formatAmount,
  formatAmountFree,
  getDisplayDecimalByAmount,
  limitDecimals,
  parseValue,
} from "lib/numbers";
import { getTokens, getWrappedToken } from "config/tokens";
import { formatDateTime, getTimeRemaining } from "lib/dates";
import ExternalLink from "components/ExternalLink/ExternalLink";

const { AddressZero } = ethers.constants;
const ORDER_SIZE_DUST_USD = expandDecimals(1, USD_DECIMALS - 1); // $0.10

export default function PositionSeller(props) {
  const { isVisible, position, setIsVisible, isHigherSlippageAllowed, setIsHigherSlippageAllowed, chainId } = props;
  let [orderOption, setOrderOption] = useState(MARKET);
  const [fromValue, setFromValue] = useState("");
  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);
  const [triggerPriceValue, setTriggerPriceValue] = useState("");
  const [keepLeverage, setKeepLeverage] = useLocalStorageSerializeKey([chainId, "Exchange-keep-leverage"], true);
  const ORDER_OPTIONS = [MARKET, STOP];
  const ORDER_OPTION_LABELS = {
    [MARKET]: `Market`,
    [STOP]: `Trigger`,
  };
  const onOrderOptionChange = (option) => {
    setOrderOption(option);
  };
  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }
  const { positionNetValue } = position;
  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className="PositionSeller-modal confirmation-modal"
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={"Add Take Profit / Stop Loss"}
          allowContentTouchMove
        >
          <div className="order-title-line order-title-line-details">
            <img src={importImage("ic_" + position?.symbol?.toLowerCase() + "_24.svg")} alt="" />
            <div className="title">{position.symbol}</div>
            <div className={`side ${position?.isLong ? "side-long" : "side-short"}`}>
              {position?.isLong ? "LONG" : "SHORT"}
            </div>
            <div className="side">{position?.positionType === "Market Order" ? "Market" : position?.positionType}</div>
          </div>
          <div className="content-container">
            <Tab
              options={ORDER_OPTIONS}
              option={orderOption}
              optionLabels={ORDER_OPTION_LABELS}
              onChange={onOrderOptionChange}
            />
            <div className="Exchange-swap-section">
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  <div className="Exchange-swap-usd">
                    <span>
                      {/* Close: {convertedAmountFormatted} {position.collateralToken.symbol} */}
                      Close:
                    </span>
                  </div>
                </div>
                {/* {maxAmount && (
                <div className="muted align-right clickable" onClick={() => setFromValue(maxAmountFormattedFree)}>
                  <span>Max: {maxAmountFormatted}</span>
                </div>
              )} */}
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={fromValue}
                    // onChange={(e) => setFromValue(e.target.value)}
                  />
                  <div
                    className="Exchange-swap-max"
                    // onClick={() => {
                    //   setFromValue(maxAmountFormattedFree);
                    // }}
                  >
                    <span>MAX</span>
                  </div>
                </div>
                <div className="PositionEditor-token-symbol">USD</div>
              </div>
            </div>
            {orderOption === STOP && (
              <div className="Exchange-swap-section">
                <div className="Exchange-swap-section-top">
                  <div className="muted">
                    <span>Price</span>
                  </div>
                  <div
                    className="muted align-right clickable"
                    // onClick={() => {
                    //   setTriggerPriceValue(
                    //     formatAmountFree(
                    //       position.markPrice,
                    //       USD_DECIMALS,
                    //       getDisplayDecimalByAmount(position.markPrice, USD_DECIMALS)
                    //     )
                    //   );
                    // }}
                  >
                    <span>
                      Mark:{" "}
                      {/* {formatAmount(
                      position.markPrice,
                      USD_DECIMALS,
                      getDisplayDecimalByAmount(position.markPrice, USD_DECIMALS),
                      true
                    )} */}
                    </span>
                  </div>
                </div>
                <div className="Exchange-swap-section-bottom">
                  <div className="Exchange-swap-input-container">
                    <input
                      type="number"
                      min="0"
                      placeholder="0.0"
                      className="Exchange-swap-input"
                      value={triggerPriceValue}
                      // onChange={onTriggerPriceChange}
                    />
                  </div>
                  <div className="PositionEditor-token-symbol">USD</div>
                </div>
              </div>
            )}
            <div className="PositionEditor-info-box">
              <div className="PositionEditor-keep-leverage-settings">
                <Checkbox isChecked={keepLeverage} setIsChecked={setKeepLeverage}>
                  <span className="muted font-sm">
                    <span>Keep leverage at {formatAmount(position.leverage, 4, 2)}x</span>
                  </span>
                </Checkbox>
              </div>
              {orderOption === MARKET && (
                <div className="PositionEditor-allow-higher-slippage">
                  <Checkbox isChecked={isHigherSlippageAllowed} setIsChecked={setIsHigherSlippageAllowed}>
                    <span className="muted font-sm">
                      <span>Allow up to 1% slippage</span>
                    </span>
                  </Checkbox>
                </div>
              )}
              {orderOption === MARKET && (
                <div>
                  <ExchangeInfoRow label={`Allowed Slippage`}>
                    <Tooltip
                      handle={`${formatAmount(allowedSlippage, 2, 2)}%`}
                      position="right-bottom"
                      renderContent={() => {
                        return (
                          <div>
                            You can change this in the settings menu on the top right of the page.
                            <div style={{ marginTop: "8px" }}>
                              Note that a low allowed slippage, e.g. less than{" "}
                              <span style={{ color: "white", fontWeight: 700 }}>0.5%</span>, may result in failed orders
                              if prices are volatile.
                            </div>
                          </div>
                        );
                      }}
                    />
                  </ExchangeInfoRow>
                </div>
              )}
              {orderOption === STOP && (
                <div className="Exchange-info-row">
                  <div className="Exchange-info-label">
                    <span>Trigger Price</span>
                  </div>
                  <div className="align-right">$--</div>
                </div>
              )}
              <div className="Exchange-info-row top-line">
                <div className="Exchange-info-label">
                  <span>Mark Price</span>
                </div>
                <div className="align-right">${limitDecimals(position.markPrice, 4) || "--"}</div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <span>Entry Price</span>
                </div>
                <div className="align-right">${limitDecimals(position.averagePrice, 4) || "--"}</div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <span>Liq. Price</span>
                </div>
                <div className="align-right">${limitDecimals(position.liquidationPrice, 4) || "--"}</div>
              </div>
              <div className="Exchange-info-row top-line">
                <div className="Exchange-info-label">
                  <span>Size</span>
                </div>
                <div className="align-right">
                  <div className="align-right">${position.size || "--"}</div>
                </div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <span>Collateral</span>
                </div>
                <div className="align-right">
                  <div className="align-right">${position.collateral || "--"}</div>
                </div>
              </div>
              {!keepLeverage && (
                <div className="Exchange-info-row">
                  <div className="Exchange-info-label">
                    <span>Leverage</span>
                  </div>
                  <div className="align-right">${position.leverage || "--"}</div>
                </div>
              )}
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <span>PnL</span>
                </div>
                <div className="align-right">
                  <div
                    className={cx("Exchange-list-info-labell", {
                      positive: positionNetValue?.pnl > 0,
                      negative: positionNetValue?.pnl < 0,
                      muted: positionNetValue?.pnl === 0,
                    })}
                  >
                    {positionNetValue.prefix}${limitDecimals(positionNetValue?.pnl, 2).replace("-", "")} (
                    {positionNetValue.prefix}
                    {formatAmount(positionNetValue?.percentPnl, 30, 2, true).replace("-", "")}%)
                  </div>
                </div>
              </div>

              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  <span>Fees</span>
                </div>
                <div className="align-right">$--</div>
              </div>
              <div className="Exchange-info-row PositionSeller-receive-row top-line">
                <div className="Exchange-info-label">
                  <span>Receive</span>
                </div>
                <div className="align-right PositionSelector-selected-receive-token">USD ($--)</div>
              </div>
            </div>
          </div>
          <div className="Exchange-swap-button-container">
            <button className="App-cta Exchange-swap-button">Confirm</button>
          </div>
        </Modal>
      )}
    </div>
  );
}
