function Icon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ?? "30"}
      height={props.height ?? "28"}
      fill="none"
      viewBox="0 0 30 28"
    >
      <path
        fill="#000"
        d="M22.105 17.947h-15V14L0 20.71l7.105 6.711v-3.947h15v-5.527zM30 7.29L22.895.58v3.946h-15v5.527h15V14L30 7.29zM4.737 4.526H1.579v5.527h3.158V4.526zM28.42 17.948h-3.157v5.526h3.158v-5.526z"
      ></path>
    </svg>
  );
}

export default Icon;
