import oneInch from "img/landing_page/1inch.svg";
import Chainlink from "img/landing_page/Chainlink.svg";
import Coingecko from "img/landing_page/Coingecko.svg";
import Coinmarketcap from "img/landing_page/Coinmarketcap.svg";
import dAppRadar from "img/landing_page/dAppRadar.svg";
import Debank from "img/landing_page/Debank.svg";
import Defillama from "img/landing_page/Defillama.svg";
import Equalizer from "img/landing_page/Equalizer.svg";
import Fantom from "img/landing_page/Fantom.svg";
import LayerZero from "img/landing_page/LayerZero.svg";
import MEXC from "img/landing_page/MEXC.svg";
import Microswap from "img/landing_page/Microswap.svg";
import O3Swap from "img/landing_page/O3Swap.svg";
import ODOS from "img/landing_page/ODOS.svg";
import OpenOcean from "img/landing_page/OpenOcean.svg";
import Pyth from "img/landing_page/Pyth.svg";
import Terminal from "img/landing_page/Terminal.svg";
import TheVoyager from "img/landing_page/TheVoyager.svg";
import Velodrom from "img/landing_page/Velodrom.svg";
import Wonderland from "img/landing_page/Wonderland.svg";

export const PARTNERS = [
  {
    img: oneInch,
    link: "https://1inch.io",
    name: "1Inch",
  },
  // {
  //   img: Chainlink,
  //   link: "https://chain.link/",
  //   name: "Chainlink",
  // },
  {
    img: Coingecko,
    link: "https://www.coingecko.com",
    name: "Coingecko",
  },
  {
    img: Coinmarketcap,
    link: "https://coinmarketcap.com/",
    name: "Coinmarketcap",
  },
  {
    img: dAppRadar,
    link: "https://dappradar.com/",
    name: "dAppRadar",
  },
  {
    img: Debank,
    link: "https://debank.com/",
    name: "Debank",
  },
  {
    img: Defillama,
    link: "https://defillama.com/",
    name: "Defillama",
  },
  {
    img: Equalizer,
    link: "https://equalizer.exchange",
    name: "Equalizer",
  },
  {
    img: Fantom,
    link: "https://fantom.foundation/",
    name: "Fantom",
  },
  {
    img: LayerZero,
    link: "https://layerzero.network/",
    name: "LayerZero",
  },
  {
    img: MEXC,
    link: "https://www.mexc.com/",
    name: "MEXC",
  },
  {
    img: Microswap,
    link: "https://www.microswap.org/",
    name: "Microswap",
  },
  {
    img: O3Swap,
    link: "https://o3swap.com/",
    name: "O3Swap",
  },
  {
    img: ODOS,
    link: "https://www.odos.xyz/",
    name: "ODOS",
  },
  {
    img: OpenOcean,
    link: "https://openocean.finance/",
    name: "OpenOcean",
  },
  {
    img: Pyth,
    link: "https://www.pyth.network/",
    name: "Pyth",
  },
  {
    img: Terminal,
    link: "https://tokenterminal.com/",
    name: "Terminal",
  },
  {
    img: TheVoyager,
    link: "https://app.thevoyager.io/",
    name: "TheVoyager",
  },
  {
    img: Velodrom,
    link: "https://velodrome.finance/",
    name: "Velodrom",
  },
  {
    img: Wonderland,
    link: "https://volta.club/",
    name: "Wonderland",
  },
];
