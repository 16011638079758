import React, { useState, useCallback, useEffect, useMemo } from "react";
import { BigNumber, ethers } from "ethers";
import cx from "classnames";
import { Trans, select, t } from "@lingui/macro";
import { BsArrowRight } from "react-icons/bs";
import styled from "styled-components";
import {
  DEFAULT_SLIPPAGE_AMOUNT,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  USD_DECIMALS,
  DUST_USD,
  BASIS_POINTS_DIVISOR,
  MIN_PROFIT_TIME,
  getLiquidationPrice,
  getLeverage,
  getMarginFee,
  PRECISION,
  MARKET,
  STOP,
  DECREASE,
  calculatePositionDelta,
  getDeltaStr,
  getProfitPrice,
  getNextToAmount,
  USDN_DECIMALS,
  adjustForDecimals,
  isAddressZero,
  MAX_ALLOWED_LEVERAGE,
  getMarginFeeV1,
  TAKE_PROFIT,
  STOP_LOSS,
} from "lib/legacy";
import { ARBITRUM, getChainName, getConstant, IS_NETWORK_DISABLED } from "config/chains";
import { createDecreaseOrder, useHasOutdatedUi } from "domain/legacy";
import { getContract } from "config/contracts";
import PositionRouter from "abis/PositionRouter.json";
import Checkbox from "../Checkbox/Checkbox";
import Tab from "../Tab/Tab";
import Modal from "../Modal/Modal";
import Tooltip from "../Tooltip/Tooltip";
import TokenSelector from "./TokenSelector";
import "./PositionSeller.css";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { callContract } from "lib/contracts";
import { getTokenAmountFromUsd } from "domain/tokens";
import { TRIGGER_PREFIX_ABOVE, TRIGGER_PREFIX_BELOW } from "config/ui";
import { useLocalStorageByChainId, useLocalStorageSerializeKey } from "lib/localStorage";
import { CLOSE_POSITION_RECEIVE_TOKEN_KEY, SLIPPAGE_BPS_KEY } from "config/localStorage";
import { getTokenInfo, getUsd } from "domain/tokens/utils";
import { usePrevious } from "lib/usePrevious";
import {
  bigNumberify,
  expandDecimals,
  formatAmount,
  formatAmountFree,
  getDisplayDecimalByAmount,
  parseValue,
} from "lib/numbers";
import { getTokens, getWrappedToken } from "config/tokens";
import { formatDateTime, getTimeRemaining } from "lib/dates";
import ExternalLink from "components/ExternalLink/ExternalLink";
import TakeProfitLongTriggerButton from "components/ExchangeV2/TakeProfitLongTriggerButton";
import TakeProfitShortTriggerButton from "components/ExchangeV2/TakeProfitShortTriggerButton";
import PercentageButtons from "components/ExchangeV2/PercentageButtons";
import { preventSpecialCharacters } from "utils/helpers";
import GreenArraowIcon from "components/Icon/GreenArraowIcon";
import { formatShortNumber } from "utils/formatPrice";
import ExchangeInfoRow from "components/ExchangeV2/ExchangeInfoRow";
import TokenTopInfo from "./TokenTopInfo";

const { AddressZero } = ethers.constants;
const ORDER_SIZE_DUST_USD = expandDecimals(1, USD_DECIMALS - 1); // $0.10

function shouldSwap(collateralToken, receiveToken) {
  // If position collateral is WETH in contract, then position.collateralToken is { symbol: “ETH”, isNative: true, … }
  // @see https://github.com/navi-io/navi-interface/blob/master/src/pages/Exchange/Exchange.js#L162
  // meaning if collateralToken.isNative === true in reality position has WETH as a collateral
  // and if collateralToken.isNative === true and receiveToken.isNative === true then position’s WETH will be unwrapped and user will receive native ETH
  const isCollateralWrapped = collateralToken.isNative;

  const isSameToken =
    collateralToken.address === receiveToken.address || (isCollateralWrapped && receiveToken.isWrapped);

  const isUnwrap = isCollateralWrapped && receiveToken.isNative;

  return !isSameToken && !isUnwrap;
}

function getSwapLimits(infoTokens, fromTokenAddress, toTokenAddress) {
  const fromInfo = getTokenInfo(infoTokens, fromTokenAddress);
  const toInfo = getTokenInfo(infoTokens, toTokenAddress);

  let maxInUsd;
  let maxIn;
  let maxOut;
  let maxOutUsd;

  if (!fromInfo?.maxUsdgAmount) {
    maxInUsd = bigNumberify(0);
    maxIn = bigNumberify(0);
  } else {
    maxInUsd = fromInfo.maxUsdgAmount
      .sub(fromInfo.usdgAmount)
      .mul(expandDecimals(1, USD_DECIMALS))
      .div(expandDecimals(1, USDN_DECIMALS));

    maxIn = maxInUsd.mul(expandDecimals(1, fromInfo.decimals)).div(fromInfo.maxPrice).toString();
  }

  if (!toInfo?.poolAmount || !toInfo?.bufferAmount) {
    maxOut = bigNumberify(0);
    maxOutUsd = bigNumberify(0);
  } else {
    maxOut = toInfo.availableAmount.gt(toInfo.poolAmount.sub(toInfo.bufferAmount))
      ? toInfo.poolAmount.sub(toInfo.bufferAmount)
      : toInfo.availableAmount;

    maxOutUsd = getUsd(maxOut, toInfo.address, false, infoTokens);
  }

  return {
    maxIn,
    maxInUsd,
    maxOut,
    maxOutUsd,
  };
}

export default function AddTPSLV1(props) {
  const {
    pendingPositions,
    setPendingPositions,
    positionsMap,
    positionKey,
    isVisible,
    setIsVisible,
    account,
    library,
    infoTokens,
    setPendingTxns,
    flagOrdersEnabled,
    savedIsPnlInLeverage,
    chainId,
    nativeTokenAddress,
    orders,
    isWaitingForPluginApproval,
    isPluginApproving,
    orderBookApproved,
    setOrdersToaOpen,
    positionRouterApproved,
    isWaitingForPositionRouterApproval,
    isPositionRouterApproving,
    approvePositionRouter,
    isHigherSlippageAllowed,
    setIsHigherSlippageAllowed,
    minExecutionFee,
    minExecutionFeeErrorMessage,
    usdgSupply,
    totalTokenWeights,
    isContractAccount,
    selectedPosition,
  } = props;
  const [savedSlippageAmount] = useLocalStorageSerializeKey([chainId, SLIPPAGE_BPS_KEY], DEFAULT_SLIPPAGE_AMOUNT);
  const [keepLeverage, setKeepLeverage] = useLocalStorageSerializeKey([chainId, "Exchange-keep-leverage"], true);
  const position = positionsMap && positionKey ? positionsMap[positionKey] : undefined;
  const [fromValue, setFromValue] = useState("");
  const [isProfitWarningAccepted, setIsProfitWarningAccepted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const prevIsVisible = usePrevious(isVisible);
  const positionRouterAddress = getContract(chainId, "PositionRouter");
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const longOrShortText = position?.isLong ? `Long` : `Short`;

  const toTokens = isContractAccount ? getTokens(chainId).filter((t) => !t.isNative) : getTokens(chainId);
  const wrappedToken = getWrappedToken(chainId);

  const [savedRecieveTokenAddress, setSavedRecieveTokenAddress] = useLocalStorageByChainId(
    chainId,
    `${CLOSE_POSITION_RECEIVE_TOKEN_KEY}-${position.indexToken.symbol}-${position?.isLong ? "long" : "short"}`
  );

  const [swapToToken, setSwapToToken] = useState(() =>
    savedRecieveTokenAddress ? toTokens.find((token) => token.address === savedRecieveTokenAddress) : undefined
  );

  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }

  const ORDER_OPTIONS = [MARKET, STOP];
  const TPSL_OPTION = [TAKE_PROFIT, STOP_LOSS];
  const TPSL_OPTION_LABELS = {
    [TAKE_PROFIT]: TAKE_PROFIT,
    [STOP_LOSS]: STOP_LOSS,
  };
  const ORDER_OPTION_LABELS = {
    [MARKET]: `Market`,
    [STOP]: `Trigger`,
  };
  let [orderOption, setOrderOption] = useState(STOP);
  let [tpslOption, setTpslOption] = useState(TAKE_PROFIT);

  if (!flagOrdersEnabled) {
    orderOption = MARKET;
  }

  const needPositionRouterApproval = !positionRouterApproved && orderOption === MARKET;
  const [takeProfitPrice, setTakeProfitPrice] = useState(0);
  const [takeProfitQuantity, setTakeProfitQuantity] = useState(0);
  const [stopLossPrice, setStopLossPrice] = useState(0);
  const [stopLossQuantity, setStopLossQuantity] = useState(0);
  const [triggerPriceInput, setTriggerPriceInput] = useState("");
  const [closeQuantity, setCloseQuantity] = useState("");
  const setTPSLTrigerPriceValue = (value) => {
    if (tpslOption === TAKE_PROFIT) {
      setTakeProfitPrice(value);
    } else {
      setStopLossPrice(value);
    }
  };
  const handleTakeProfitLongPercent = (percentage) => {
    const value = position.averagePrice.add(
      parseValue(percentage, 30).mul(position.averagePrice).div(parseValue(1, 30))
    );
    setTriggerPriceInput(formatAmount(value, 30, 6, false));
    setTPSLTrigerPriceValue(value);
  };
  const handleTakeProfitShortPercent = (percentage) => {
    const value = position.averagePrice.sub(
      parseValue(percentage, 30).mul(position.averagePrice).div(parseValue(1, 30))
    );
    setTriggerPriceInput(formatAmount(value, 30, 6, false));
    setTPSLTrigerPriceValue(value);
  };

  const handleTrigerPriceInputChange = (e) => {
    const value = e.target.value;
    setTriggerPriceInput(value);
    if (value) {
      setTPSLTrigerPriceValue(parseValue(value, 30));
    } else {
      setTPSLTrigerPriceValue(0);
    }
  };

  const posQty = position?.averagePrice
    ? position.size.mul(parseValue(1, USD_DECIMALS)).div(position?.averagePrice)
    : 0;
  const setCloseQuantityValue = (value) => {
    if (tpslOption === TAKE_PROFIT) setTakeProfitQuantity(value);
    else setStopLossQuantity(value);
  };
  const handleSelectPercentageCloseQuantity = (percentage) => {
    const value = posQty.mul(parseValue(percentage, 30)).div(parseValue(1, 30));
    setCloseQuantity(formatAmount(value, 30, position.indexToken.decimals, false));
    setCloseQuantityValue(value);
  };
  const handleChangeQuantityInput = (e) => {
    const value = e.target.value;
    setCloseQuantity(value);
    if (value && Number(value)) {
      if (tpslOption === TAKE_PROFIT) {
        setTakeProfitQuantity(parseValue(value, 30));
      } else {
        setStopLossQuantity(parseValue(value, 30));
      }
    } else {
      if (tpslOption === TAKE_PROFIT) {
        setTakeProfitQuantity(0);
      } else {
        setStopLossQuantity(0);
      }
    }
  };
  const newTPCollateral = useMemo(() => {
    if (takeProfitQuantity && !takeProfitQuantity.eq(0)) {
      const sizeDelta = takeProfitQuantity.mul(position.averagePrice).div(parseValue(1, 30));
      const newColateral = position.collateral.sub(
        sizeDelta.mul(parseValue(1, 30)).div(parseValue(position.leverage, 30))
      );
      return newColateral.gt(0) ? newColateral : BigNumber.from(0);
    }
    return null;
  }, [takeProfitQuantity]);
  const TpSizeDelta = useMemo(() => {
    if (takeProfitQuantity && takeProfitQuantity !== 0) {
      const sizeDelta = takeProfitQuantity.mul(position.averagePrice).div(parseValue(1, 30));
      return sizeDelta;
    }
    return null;
  }, [takeProfitQuantity]);
  const TPPercentSizeDelta = takeProfitQuantity
    ? takeProfitQuantity.eq(posQty)
      ? parseValue(1, 30)
      : takeProfitQuantity.mul(parseValue(1, 30)).div(posQty)
    : null;
  const newSLCollateral = useMemo(() => {
    if (stopLossQuantity && stopLossQuantity !== 0) {
      const sizeDelta = stopLossQuantity.mul(position.averagePrice).div(parseValue(1, 30));
      const newColateral = position.collateral.sub(
        sizeDelta.mul(parseValue(1, 30)).div(parseValue(position.leverage, 30))
      );
      return newColateral;
    }
    return null;
  }, [stopLossQuantity]);
  const newLiquidPriceTP = null;
  const newLiquidPriceSL = null;
  useEffect(() => {
    if (tpslOption === TAKE_PROFIT) {
      if (takeProfitPrice) {
        setTriggerPriceInput(formatAmount(takeProfitPrice, 30, 6, false));
        setCloseQuantity(formatAmount(takeProfitQuantity, 30, position.indexToken.decimals, false));
      } else {
        setTriggerPriceInput("");
        setCloseQuantity("");
      }
    } else {
      if (stopLossPrice) {
        setTriggerPriceInput(formatAmount(stopLossPrice, 30, 6, false));
        setCloseQuantity(formatAmount(stopLossQuantity, 30, position.indexToken.decimals, false));
      } else {
        setTriggerPriceInput("");
        setCloseQuantity("");
      }
    }
  }, [tpslOption]);
  const onOrderOptionChange = (option) => {
    setOrderOption(option);
  };
  const onTPSLOptionChange = (option) => {
    setTpslOption(option);
  };

  const onTriggerPriceChange = (evt) => {
    setTriggerPriceValue(evt.target.value || "");
  };

  const [triggerPriceValue, setTriggerPriceValue] = useState("");
  const triggerPriceUsd = orderOption === MARKET ? 0 : parseValue(triggerPriceValue, USD_DECIMALS);

  const [nextDelta, nextHasProfit = bigNumberify(0)] = useMemo(() => {
    if (!position) {
      return [bigNumberify(0), false];
    }

    if (orderOption !== STOP) {
      return [position.delta, position.hasProfit, position.deltaPercentage];
    }

    if (!triggerPriceUsd) {
      return [bigNumberify(0), false];
    }

    const { delta, hasProfit, deltaPercentage } = calculatePositionDelta(triggerPriceUsd, position);
    return [delta, hasProfit, deltaPercentage];
  }, [position, orderOption, triggerPriceUsd]);

  const [nextDeltaTp, nextHasProfitTP = bigNumberify(0)] = useMemo(() => {
    if (!position) {
      return [bigNumberify(0), false];
    }
    if (!takeProfitPrice || takeProfitPrice.eq(0)) {
      return [bigNumberify(0), false];
    }
    const { delta, hasProfit, deltaPercentage } = calculatePositionDelta(takeProfitPrice, position);
    return [delta, hasProfit, deltaPercentage];
  }, [position, takeProfitPrice]);

  const [nextDeltaSL, nextHasProfitSL = bigNumberify(0)] = useMemo(() => {
    if (!position) {
      return [bigNumberify(0), false];
    }
    if (!stopLossPrice || stopLossPrice.eq(0)) {
      return [bigNumberify(0), false];
    }
    const { delta, hasProfit, deltaPercentage } = calculatePositionDelta(stopLossPrice, position);
    return [delta, hasProfit, deltaPercentage];
  }, [position, stopLossPrice]);

  const existingOrders = useMemo(() => {
    if (orderOption === STOP && (!triggerPriceUsd || triggerPriceUsd.eq(0))) {
      return [];
    }
    if (!orders || !position) {
      return [];
    }

    const ret = [];
    for (const order of orders) {
      // only Stop orders can't be executed without corresponding opened position
      if (order.type !== DECREASE) continue;

      // if user creates Stop-Loss we need only Stop-Loss orders and vice versa
      if (orderOption === STOP) {
        const triggerAboveThreshold = triggerPriceUsd.gt(position.markPrice);
        if (triggerAboveThreshold !== order.triggerAboveThreshold) continue;
      }

      const sameToken =
        order.indexToken === nativeTokenAddress
          ? position.indexToken.isNative
          : order.indexToken === position.indexToken.address;
      if (order.isLong === position.isLong && sameToken) {
        ret.push(order);
      }
    }
    return ret;
  }, [position, orders, triggerPriceUsd, orderOption, nativeTokenAddress]);

  const existingOrder = existingOrders[0];

  const needOrderBookApproval = orderOption === STOP && !orderBookApproved;

  const isSwapAllowed = orderOption === MARKET;

  const { data: hasOutdatedUi } = useHasOutdatedUi();

  let collateralToken;
  let receiveToken;
  let maxAmount;
  let maxAmountFormatted;
  let maxAmountFormattedFree;
  let fromAmount;

  let convertedAmount;
  let convertedAmountTP;
  let convertedAmountSL;
  let convertedAmountFormatted;

  let nextLeverage;
  let nextLeverageTP;
  let nextLeverageSL;
  let liquidationPrice;
  let nextLiquidationPrice;
  let nextLiquidationPriceTP;
  let nextLiquidationPriceSL;
  let isClosing;
  let isClosingTP;
  let isClosingSL;
  let sizeDelta;
  let sizeDeltaTP;
  let sizeDeltaSL;

  let nextCollateral;
  let nextCollateralTP;
  let nextCollateralSL;
  let collateralDelta = bigNumberify(0);
  let collateralDeltaTP = bigNumberify(0);
  let collateralDeltaSL = bigNumberify(0);

  let receiveAmount = bigNumberify(0);
  let receiveAmountTP = bigNumberify(0);
  let receiveAmountSL = bigNumberify(0);

  let convertedReceiveAmount = bigNumberify(0);
  let convertedReceiveAmountTP = bigNumberify(0);
  let convertedReceiveAmountSL = bigNumberify(0);

  let adjustedDelta = bigNumberify(0);
  let adjustedDeltaTP = bigNumberify(0);
  let adjustedDeltaSL = bigNumberify(0);

  let isNotEnoughReceiveTokenLiquidity;
  let isCollateralPoolCapacityExceeded;
  let isNotEnoughReceiveTokenLiquidityTP;
  let isCollateralPoolCapacityExceededTP;
  let isNotEnoughReceiveTokenLiquiditySL;
  let isCollateralPoolCapacityExceededSl;

  let title;
  let fundingFee;
  let positionFee;
  let positionFeeTP;
  let positionFeeSL;
  let swapFeeToken;
  let swapFee;
  let swapFeeTokenTP;
  let swapFeeTP;
  let swapFeeTokenSL;
  let swapFeeSL;
  let totalFees = bigNumberify(0);
  let totalFeesTP = bigNumberify(0);
  let totalFeesSL = bigNumberify(0);

  useEffect(() => {
    if (isSwapAllowed && isContractAccount && isAddressZero(receiveToken.address)) {
      setSwapToToken(wrappedToken);
      setSavedRecieveTokenAddress(wrappedToken.address);
    }
  }, [
    isContractAccount,
    isSwapAllowed,
    nativeTokenSymbol,
    receiveToken?.address,
    wrappedToken,
    setSavedRecieveTokenAddress,
  ]);

  let executionFee = orderOption === STOP ? getConstant(chainId, "DECREASE_ORDER_EXECUTION_GAS_FEE") : minExecutionFee;

  let executionFeeUsd = getUsd(executionFee, nativeTokenAddress, false, infoTokens) || bigNumberify(0);

  if (position) {
    fundingFee = position.fundingFee;
    fromAmount = parseValue(fromValue, USD_DECIMALS);
    sizeDelta = fromAmount;
    sizeDeltaTP = takeProfitQuantity
      ? takeProfitQuantity.eq(posQty)
        ? position.size
        : takeProfitQuantity.mul(position.averagePrice).div(parseValue(1, 30))
      : BigNumber.from(0);
    sizeDeltaSL = stopLossQuantity
      ? stopLossQuantity.eq(posQty)
        ? position.size
        : stopLossQuantity.mul(position.averagePrice).div(parseValue(1, 30))
      : BigNumber.from(0);

    title = `Close ${longOrShortText} ${position.indexToken.symbol}`;
    collateralToken = position.collateralToken;
    liquidationPrice = getLiquidationPrice(position);

    if (fromAmount) {
      isClosing = position.size.sub(fromAmount).lt(DUST_USD);
      positionFee = getMarginFeeV1(chainId, fromAmount);
    }
    if (takeProfitQuantity && takeProfitQuantity.gt(0)) {
      isClosingTP = position.size.sub(sizeDeltaTP).lt(DUST_USD);
      positionFeeTP = getMarginFeeV1(chainId, sizeDeltaTP);
    }
    if (stopLossQuantity && stopLossQuantity.gt(0)) {
      isClosingSL = position.size.sub(sizeDeltaSL).lt(DUST_USD);
      positionFeeSL = getMarginFeeV1(chainId, sizeDeltaSL);
    }

    if (isClosing) {
      sizeDelta = position.size;
      receiveAmount = position.collateral;
    } else if (orderOption === STOP && sizeDelta && existingOrders.length > 0) {
      let residualSize = position.size;
      for (const order of existingOrders) {
        residualSize = residualSize.sub(order.sizeDelta);
      }
      if (residualSize.sub(sizeDelta).abs().lt(ORDER_SIZE_DUST_USD)) {
        sizeDelta = residualSize;
      }
    }

    if (isClosingTP) {
      sizeDeltaTP = position.size;
      receiveAmountTP = position.collateral;
    } else if (orderOption === STOP && sizeDeltaTP && existingOrders.length > 0) {
      let residualSize = position.size;
      for (const order of existingOrders) {
        residualSize = residualSize.sub(order.sizeDelta);
      }
      if (residualSize.sub(sizeDeltaTP).abs().lt(ORDER_SIZE_DUST_USD)) {
        sizeDeltaTP = residualSize;
      }
    }
    if (isClosingSL) {
      sizeDeltaSL = position.size;
      receiveAmountSL = position.collateral;
    } else if (orderOption === STOP && sizeDeltaSL && existingOrders.length > 0) {
      let residualSize = position.size;
      for (const order of existingOrders) {
        residualSize = residualSize.sub(order.sizeDelta);
      }
      if (residualSize.sub(sizeDeltaSL).abs().lt(ORDER_SIZE_DUST_USD)) {
        sizeDeltaSL = residualSize;
      }
    }

    if (sizeDelta && position.size.gt(0)) {
      adjustedDelta = nextDelta.mul(sizeDelta).div(position.size);
    }
    if (sizeDeltaTP && position.size.gt(0)) {
      adjustedDeltaTP = nextDeltaTp.mul(sizeDeltaTP).div(position.size);
    }
    if (sizeDeltaSL && position.size.gt(0)) {
      adjustedDeltaSL = nextDeltaSL.mul(sizeDeltaSL).div(position.size);
    }

    if (nextHasProfit) {
      receiveAmount = receiveAmount.add(adjustedDelta);
    } else {
      if (receiveAmount.gt(adjustedDelta)) {
        receiveAmount = receiveAmount.sub(adjustedDelta);
      } else {
        receiveAmount = bigNumberify(0);
      }
    }
    if (nextHasProfitTP) {
      receiveAmountTP = receiveAmountTP.add(adjustedDeltaTP);
    } else {
      if (receiveAmountTP.gt(adjustedDeltaTP)) {
        receiveAmountTP = receiveAmountTP.sub(adjustedDeltaTP);
      } else {
        receiveAmountTP = bigNumberify(0);
      }
    }
    if (nextHasProfitSL) {
      receiveAmountSL = receiveAmountSL.add(adjustedDeltaSL);
    } else {
      if (receiveAmountSL.gt(adjustedDeltaSL)) {
        receiveAmountSL = receiveAmountSL.sub(adjustedDeltaSL);
      } else {
        receiveAmountSL = bigNumberify(0);
      }
    }

    if (keepLeverage && sizeDelta && !isClosing) {
      collateralDelta = sizeDelta.mul(position.collateral).div(position.size);
      // if the position will be realising a loss then reduce collateralDelta by the realised loss
      if (!nextHasProfit) {
        const deductions = adjustedDelta.add(positionFee).add(fundingFee);
        if (collateralDelta.gt(deductions)) {
          collateralDelta = collateralDelta = collateralDelta.sub(deductions);
        } else {
          collateralDelta = bigNumberify(0);
        }
      }
    }

    if (keepLeverage && sizeDeltaTP && !isClosingTP) {
      collateralDeltaTP = sizeDeltaTP.mul(position.collateral).div(position.size);
      // if the position will be realising a loss then reduce collateralDelta by the realised loss
      if (!nextHasProfitTP) {
        const deductions = adjustedDeltaTP.add(positionFeeTP || 0).add(fundingFee);
        if (collateralDeltaTP.gt(deductions)) {
          collateralDeltaTP = collateralDeltaTP.sub(deductions);
        } else {
          collateralDeltaTP = bigNumberify(0);
        }
      }
    }
    if (keepLeverage && sizeDeltaSL && !isClosingSL) {
      collateralDeltaSL = sizeDeltaSL.mul(position.collateral).div(position.size);
      // if the position will be realising a loss then reduce collateralDelta by the realised loss
      if (!nextHasProfitSL) {
        const deductions = adjustedDeltaSL.add(positionFeeSL || 0).add(fundingFee);
        if (collateralDeltaSL.gt(deductions)) {
          collateralDeltaSL = collateralDeltaSL.sub(deductions);
        } else {
          collateralDeltaSL = bigNumberify(0);
        }
      }
    }

    maxAmount = position.size;
    maxAmountFormatted = formatAmount(maxAmount, USD_DECIMALS, 2, true);
    maxAmountFormattedFree = formatAmountFree(maxAmount, USD_DECIMALS, 2);

    if (fromAmount && collateralToken.maxPrice) {
      convertedAmount = fromAmount.mul(expandDecimals(1, collateralToken.decimals)).div(collateralToken.maxPrice);
      convertedAmountFormatted = formatAmount(convertedAmount, collateralToken.decimals, 4, true);
    }
    if (fromAmount && collateralToken.maxPrice) {
      convertedAmount = fromAmount.mul(expandDecimals(1, collateralToken.decimals)).div(collateralToken.maxPrice);
      convertedAmountFormatted = formatAmount(convertedAmount, collateralToken.decimals, 4, true);
    }

    if (stopLossQuantity && stopLossQuantity.gt(0) && collateralToken.maxPrice) {
      convertedAmountSL = stopLossQuantity
        .mul(expandDecimals(1, collateralToken.decimals))
        .div(collateralToken.maxPrice);
    }
    if (takeProfitQuantity && takeProfitQuantity.gt(0) && collateralToken.maxPrice) {
      convertedAmountTP = takeProfitQuantity
        .mul(expandDecimals(1, collateralToken.decimals))
        .div(collateralToken.maxPrice);
    }

    totalFees = totalFees.add(positionFee || bigNumberify(0)).add(fundingFee || bigNumberify(0));
    totalFeesTP = totalFeesTP.add(positionFeeTP || bigNumberify(0)).add(fundingFee || bigNumberify(0));
    totalFeesSL = totalFeesSL.add(positionFeeSL || bigNumberify(0)).add(fundingFee || bigNumberify(0));

    receiveAmount = receiveAmount.add(collateralDelta);
    receiveAmountTP = receiveAmountTP.add(collateralDeltaTP);
    receiveAmountSL = receiveAmountSL.add(collateralDeltaSL);

    if (sizeDelta) {
      if (receiveAmount.gt(totalFees)) {
        receiveAmount = receiveAmount.sub(totalFees);
      } else {
        receiveAmount = bigNumberify(0);
      }
    }
    if (sizeDeltaTP) {
      if (receiveAmountTP.gt(totalFeesTP)) {
        receiveAmountTP = receiveAmountTP.sub(totalFeesTP);
      } else {
        receiveAmountTP = bigNumberify(0);
      }
    }
    if (sizeDeltaSL) {
      if (receiveAmountSL.gt(totalFeesSL)) {
        receiveAmountSL = receiveAmountSL.sub(totalFeesSL);
      } else {
        receiveAmountSL = bigNumberify(0);
      }
    }

    receiveToken = isSwapAllowed && swapToToken ? swapToToken : collateralToken;

    if (isSwapAllowed && isContractAccount && isAddressZero(receiveToken.address)) {
      receiveToken = wrappedToken;
    }

    // Calculate swap fees
    if (isSwapAllowed && swapToToken) {
      const { feeBasisPoints } = getNextToAmount(
        chainId,
        convertedAmount,
        collateralToken.address,
        receiveToken.address,
        infoTokens,
        undefined,
        undefined,
        usdgSupply,
        totalTokenWeights,
        true
      );
      const { feeBasisPoints: feeBasisPointsTP } = getNextToAmount(
        chainId,
        convertedAmountTP,
        collateralToken.address,
        receiveToken.address,
        infoTokens,
        undefined,
        undefined,
        usdgSupply,
        totalTokenWeights,
        true
      );
      const { feeBasisPoints: feeBasisPointsTSL } = getNextToAmount(
        chainId,
        convertedAmountSL,
        collateralToken.address,
        receiveToken.address,
        infoTokens,
        undefined,
        undefined,
        usdgSupply,
        totalTokenWeights,
        true
      );

      if (feeBasisPoints) {
        swapFee = receiveAmount.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR);
        swapFeeToken = getTokenAmountFromUsd(infoTokens, collateralToken.address, swapFee);
        totalFees = totalFees.add(swapFee || bigNumberify(0));
        receiveAmount = receiveAmount.sub(swapFee);
      }

      if (feeBasisPointsTP) {
        swapFeeTP = receiveAmountTP.mul(feeBasisPointsTP).div(BASIS_POINTS_DIVISOR);
        swapFeeTokenTP = getTokenAmountFromUsd(infoTokens, collateralToken.address, swapFeeTP);
        totalFeesTP = totalFeesTP.add(swapFeeTP || bigNumberify(0));
        receiveAmountTP = receiveAmountTP.sub(swapFeeTP);
      }
      if (feeBasisPointsTSL) {
        swapFeeSL = receiveAmountSL.mul(feeBasisPointsTSL).div(BASIS_POINTS_DIVISOR);
        swapFeeTokenSL = getTokenAmountFromUsd(infoTokens, collateralToken.address, swapFeeSL);
        totalFeesSL = totalFeesSL.add(swapFeeSL || bigNumberify(0));
        receiveAmountSL = receiveAmountSL.sub(swapFeeSL);
      }
    }
    if (orderOption === STOP && (!takeProfitPrice || takeProfitPrice.lte(0))) {
      receiveAmountTP = bigNumberify(0);
      convertedReceiveAmountTP = bigNumberify(0);
    }
    if (orderOption === STOP && (!stopLossPrice || stopLossPrice.lte(0))) {
      receiveAmountSL = bigNumberify(0);
      convertedReceiveAmountSL = bigNumberify(0);
    }
    // For Shorts trigger orders the collateral is a stable coin, it should not depend on the triggerPrice
    if (orderOption === STOP && position.isLong) {
      convertedReceiveAmount = getTokenAmountFromUsd(infoTokens, receiveToken.address, receiveAmount, {
        overridePrice: triggerPriceUsd,
      });
      convertedReceiveAmountTP = getTokenAmountFromUsd(infoTokens, receiveToken.address, receiveAmountTP, {
        overridePrice: takeProfitPrice,
      });
      // console.log("????", { receiveAmountTP: receiveAmountTP / 1e30, takeProfitPrice: takeProfitPrice / 1e30 });

      convertedReceiveAmountSL = getTokenAmountFromUsd(infoTokens, receiveToken.address, receiveAmountSL, {
        overridePrice: stopLossPrice,
      });
    } else {
      convertedReceiveAmount = getTokenAmountFromUsd(infoTokens, receiveToken.address, receiveAmount);
      convertedReceiveAmountTP = getTokenAmountFromUsd(infoTokens, receiveToken.address, receiveAmountTP);
      convertedReceiveAmountSL = getTokenAmountFromUsd(infoTokens, receiveToken.address, receiveAmountSL);
    }

    // Check swap limits (max in / max out)
    if (isSwapAllowed && shouldSwap(collateralToken, receiveToken)) {
      const collateralInfo = getTokenInfo(infoTokens, collateralToken.address);
      const receiveTokenInfo = getTokenInfo(infoTokens, receiveToken.address);

      isNotEnoughReceiveTokenLiquidity =
        receiveTokenInfo.availableAmount.lt(convertedReceiveAmount) ||
        receiveTokenInfo.bufferAmount.gt(receiveTokenInfo.poolAmount.sub(convertedReceiveAmount));

      isNotEnoughReceiveTokenLiquidityTP =
        receiveTokenInfo.availableAmount.lt(convertedReceiveAmountTP) ||
        receiveTokenInfo.bufferAmount.gt(receiveTokenInfo.poolAmount.sub(convertedReceiveAmountTP));
      isNotEnoughReceiveTokenLiquiditySL =
        receiveTokenInfo.availableAmount.lt(convertedReceiveAmountSL) ||
        receiveTokenInfo.bufferAmount.gt(receiveTokenInfo.poolAmount.sub(convertedReceiveAmountSL));

      if (
        collateralInfo.maxUsdgAmount &&
        collateralInfo.maxUsdgAmount.gt(0) &&
        collateralInfo.usdgAmount &&
        collateralInfo.maxPrice
      ) {
        const usdgFromAmount = adjustForDecimals(receiveAmount, USD_DECIMALS, USDN_DECIMALS);
        const nextUsdgAmount = collateralInfo.usdgAmount.add(usdgFromAmount);
        const usdgFromAmountTP = adjustForDecimals(receiveAmountTP, USD_DECIMALS, USDN_DECIMALS);
        const nextUsdgAmountTP = collateralInfo.usdgAmount.add(usdgFromAmountTP);
        const usdgFromAmountSL = adjustForDecimals(receiveAmountSL, USD_DECIMALS, USDN_DECIMALS);
        const nextUsdgAmountSL = collateralInfo.usdgAmount.add(usdgFromAmountSL);

        if (nextUsdgAmount.gt(collateralInfo.maxUsdgAmount)) {
          isCollateralPoolCapacityExceeded = true;
        }
        if (nextUsdgAmountTP.gt(collateralInfo.maxUsdgAmount)) {
          isCollateralPoolCapacityExceededTP = true;
        }
        if (nextUsdgAmountSL.gt(collateralInfo.maxUsdgAmount)) {
          isCollateralPoolCapacityExceededSl = true;
        }
      }
    }

    if (isClosing) {
      nextCollateral = bigNumberify(0);
    } else {
      if (position.collateral) {
        nextCollateral = position.collateral;
        if (collateralDelta && collateralDelta.gt(0)) {
          nextCollateral = position.collateral.sub(collateralDelta);
        } else if (position.delta && position.delta.gt(0) && sizeDelta) {
          if (!position.hasProfit) {
            nextCollateral = nextCollateral.sub(adjustedDelta);
          }
        }
      }
    }
    if (isClosingTP) {
      nextCollateralTP = bigNumberify(0);
    } else {
      if (position.collateral) {
        nextCollateralTP = position.collateral;
      }
      if (collateralDeltaTP && collateralDeltaTP.gt(0)) {
        nextCollateralTP = position.collateral.sub(collateralDeltaTP);
      } else if (position.delta && position.delta.gt(0) && sizeDeltaTP) {
        if (!position.hasProfit) {
          nextCollateralTP = nextCollateralTP.sub(adjustedDeltaTP);
        }
      }
    }
    if (isClosingSL) {
      nextCollateralSL = bigNumberify(0);
    } else {
      if (position.collateral) {
        nextCollateralSL = position.collateral;
      }
      if (collateralDeltaSL && collateralDeltaSL.gt(0)) {
        nextCollateralSL = position.collateral.sub(collateralDeltaSL);
      } else if (position.delta && position.delta.gt(0) && sizeDeltaSL) {
        if (!position.hasProfit) {
          nextCollateralSL = nextCollateralTP.sub(adjustedDeltaSL);
        }
      }
    }

    if (fromAmount) {
      if (!isClosing) {
        if (!keepLeverage) {
          nextLeverage = getLeverage({
            size: position.size,
            sizeDelta,
            collateral: position.collateral,
            entryFundingRate: position.entryFundingRate,
            cumulativeFundingRate: position.cumulativeFundingRate,
            hasProfit: nextHasProfit,
            delta: nextDelta,
            includeDelta: savedIsPnlInLeverage,
          });
        }
        nextLiquidationPrice = getLiquidationPrice({
          isLong: position.isLong,
          size: position.size,
          sizeDelta,
          collateral: position.collateral,
          averagePrice: position.averagePrice,
          entryFundingRate: position.entryFundingRate,
          cumulativeFundingRate: position.cumulativeFundingRate,
          delta: nextDelta,
          hasProfit: nextHasProfit,
          includeDelta: true,
        });
      }
    }
    if (takeProfitQuantity && takeProfitQuantity.gt(0)) {
      if (!keepLeverage) {
        nextLeverageTP = getLeverage({
          size: position.size,
          sizeDelta: sizeDeltaTP,
          collateral: position.collateral,
          entryFundingRate: position.entryFundingRate,
          cumulativeFundingRate: position.cumulativeFundingRate,
          hasProfit: nextHasProfitTP,
          delta: nextDeltaTp,
          includeDelta: savedIsPnlInLeverage,
        });
      }
      nextLiquidationPriceTP = getLiquidationPrice({
        isLong: position.isLong,
        size: position.size,
        sizeDelta: sizeDeltaTP,
        collateral: position.collateral,
        averagePrice: position.averagePrice,
        entryFundingRate: position.entryFundingRate,
        cumulativeFundingRate: position.cumulativeFundingRate,
        delta: nextDeltaTp,
        hasProfit: nextHasProfitTP,
        includeDelta: true,
      });
    }
  }
  // console.log("????", { isClosingTP, isClosingSL });

  if (stopLossQuantity && stopLossQuantity.gt(0)) {
    if (!keepLeverage) {
      nextLeverageSL = getLeverage({
        size: position.size,
        sizeDelta: sizeDeltaSL,
        collateral: position.collateral,
        entryFundingRate: position.entryFundingRate,
        cumulativeFundingRate: position.cumulativeFundingRate,
        hasProfit: nextHasProfitSL,
        delta: nextDeltaSL,
        includeDelta: savedIsPnlInLeverage,
      });
    }
    nextLiquidationPriceSL = getLiquidationPrice({
      isLong: position.isLong,
      size: position.size,
      sizeDelta: sizeDeltaSL,
      collateral: position.collateral,
      averagePrice: position.averagePrice,
      entryFundingRate: position.entryFundingRate,
      cumulativeFundingRate: position.cumulativeFundingRate,
      delta: nextDeltaSL,
      hasProfit: nextHasProfitSL,
      includeDelta: true,
    });
  }

  const [deltaStr, deltaPercentageStr] = useMemo(() => {
    if (!position || !position.markPrice || position.collateral.eq(0)) {
      return ["-", "-"];
    }
    if (orderOption !== STOP) {
      const { pendingDelta, pendingDeltaPercentage, hasProfit } = calculatePositionDelta(
        position.markPrice,
        position,
        fromAmount
      );
      const { deltaStr, deltaPercentageStr } = getDeltaStr({
        delta: pendingDelta,
        deltaPercentage: pendingDeltaPercentage,
        hasProfit,
      });
      return [deltaStr, deltaPercentageStr];
    }
    if (!triggerPriceUsd || triggerPriceUsd.eq(0)) {
      return ["-", "-"];
    }

    const { pendingDelta, pendingDeltaPercentage, hasProfit } = calculatePositionDelta(
      triggerPriceUsd,
      position,
      fromAmount
    );

    const { deltaStr, deltaPercentageStr } = getDeltaStr({
      delta: pendingDelta,
      deltaPercentage: pendingDeltaPercentage,
      hasProfit,
    });
    return [deltaStr, deltaPercentageStr];
  }, [position, triggerPriceUsd, orderOption, fromAmount]);
  const [deltaStrTP, deltaPercentageStrTP] = useMemo(() => {
    if (!position || !position.markPrice || position.collateral.eq(0)) {
      return ["-", "-"];
    }
    if (!takeProfitPrice || takeProfitPrice.eq(0)) {
      return ["-", "-"];
    }

    const { pendingDelta, pendingDeltaPercentage, hasProfit } = calculatePositionDelta(
      takeProfitPrice,
      position,
      sizeDeltaTP
    );

    const { deltaStr, deltaPercentageStr } = getDeltaStr({
      delta: pendingDelta,
      deltaPercentage: pendingDeltaPercentage,
      hasProfit,
    });
    return [deltaStr, deltaPercentageStr];
  }, [position, takeProfitPrice, tpslOption, takeProfitQuantity]);

  const [deltaStrSL, deltaPercentageStrSL] = useMemo(() => {
    if (!position || !position.markPrice || position.collateral.eq(0)) {
      return ["-", "-"];
    }
    if (!stopLossPrice || stopLossPrice.eq(0)) {
      return ["-", "-"];
    }

    const { pendingDelta, pendingDeltaPercentage, hasProfit } = calculatePositionDelta(
      stopLossPrice,
      position,
      sizeDeltaSL
    );

    const { deltaStr, deltaPercentageStr } = getDeltaStr({
      delta: pendingDelta,
      deltaPercentage: pendingDeltaPercentage,
      hasProfit,
    });
    return [deltaStr, deltaPercentageStr];
  }, [position, stopLossPrice, tpslOption, stopLossQuantity]);

  const getError = () => {
    if (isSwapAllowed && isContractAccount && isAddressZero(receiveToken?.address)) {
      return `${nativeTokenSymbol} can not be sent to smart contract addresses. Select another token.`;
    }
    if (IS_NETWORK_DISABLED[chainId]) {
      if (orderOption === STOP) return [`Trigger order disabled, pending ${getChainName(chainId)} upgrade`];
      return [`Position close disabled, pending ${getChainName(chainId)} upgrade`];
    }
    if (hasOutdatedUi) {
      return `Page outdated, please refresh`;
    }
    if (
      (!takeProfitPrice && !stopLossPrice) ||
      (takeProfitPrice && takeProfitPrice.lte(0)) ||
      (stopLossPrice && stopLossPrice.lte(0))
    ) {
      return `Enter trigger price`;
    }
    if (
      (!takeProfitQuantity && !stopLossQuantity) ||
      (takeProfitQuantity && takeProfitQuantity.lte(0)) ||
      (stopLossQuantity && stopLossQuantity.lte(0))
    ) {
      return `Enter quantity`;
    }

    if (takeProfitQuantity && takeProfitQuantity.gt(posQty)) {
      return `TP Amount exceeds balance`;
    }
    if (stopLossQuantity && stopLossQuantity.gt(posQty)) {
      return `SL Amount exceeds balance`;
    }

    // if (nextLeverage && nextLeverage.eq(0)) {
    //   return `Enter an amount`;
    // }
    if (orderOption === STOP) {
      // if (!triggerPriceUsd || triggerPriceUsd.eq(0)) {
      //   return `Enter Price`;
      // }
      if (
        (position.isLong && takeProfitPrice && takeProfitPrice.lte(liquidationPrice)) ||
        (!position.isLong && takeProfitPrice && takeProfitPrice.gte(liquidationPrice))
      ) {
        return `TP Trigger Price below Liq. Price`;
      }
      if (
        (!position.isLong && stopLossPrice && stopLossPrice.gte(liquidationPrice)) ||
        (position.isLong && stopLossPrice && stopLossPrice.lte(liquidationPrice))
      ) {
        return `SL Trigger Price ${position.isLong ? "below" : "above"} Liq. Price`;
      }
      if (position.isLong && takeProfitPrice && takeProfitPrice.lt(position.markPrice)) {
        return `TP Trigger Price below Mark Price`;
      }
      if (position.isLong && stopLossPrice && stopLossPrice.gt(position.markPrice)) {
        return `SL Trigger Price below Mark Price`;
      }
      if (!position.isLong && takeProfitPrice && takeProfitPrice.gt(position.markPrice)) {
        return `TP Trigger Price above Mark Price`;
      }
      if (!position.isLong && stopLossPrice && stopLossPrice.lt(position.markPrice)) {
        return `SL Trigger Price above Mark Price`;
      }

      // if (profitPrice && nextDelta.eq(0) && nextHasProfit) {
      //   return `Invalid price, see warning`;
      // }
    }

    if (isNotEnoughReceiveTokenLiquidityTP || isNotEnoughReceiveTokenLiquiditySL) {
      return `Insufficient receive token liquidity`;
    }

    if (isCollateralPoolCapacityExceededTP || isCollateralPoolCapacityExceededSl) {
      return `${collateralToken.symbol} pool exceeded, can only Receive ${collateralToken.symbol}`;
    }
    if (isCollateralPoolCapacityExceededTP) {
      return `Take profit ${collateralToken.symbol} pool exceeded, can only Receive ${collateralToken.symbol}`;
    }
    if (isCollateralPoolCapacityExceededSl) {
      return `Stop loss ${collateralToken.symbol} pool exceeded, can only Receive ${collateralToken.symbol}`;
    }
    //TODO
    if (!isClosingTP && position && position.size && sizeDeltaTP && sizeDeltaTP.gt(0)) {
      if (position.size.sub(sizeDeltaTP).lt(expandDecimals(10, USD_DECIMALS))) {
        return `Leftover position below 10 USD`;
      }
      if (nextCollateralTP && nextCollateralTP.lt(expandDecimals(5, USD_DECIMALS))) {
        return `Take profit leftover collateral below 5 USD`;
      }
    }
    if (!isClosingSL && position && position.size && sizeDeltaSL && sizeDeltaSL.gt(0)) {
      if (position.size.sub(sizeDeltaSL).lt(expandDecimals(10, USD_DECIMALS))) {
        return `Leftover position below 10 USD`;
      }
      if (nextCollateralSL && nextCollateralSL.lt(expandDecimals(5, USD_DECIMALS))) {
        return `Stop loss leftover collateral below 5 USD`;
      }
    }

    if (position && position.size && position.size.lt(sizeDeltaTP)) {
      return `Max close amount exceeded`;
    }
    if (position && position.size && position.size.lt(sizeDeltaSL)) {
      return `Max close amount exceeded`;
    }

    if (nextLeverageTP && nextLeverageTP.lt(1.1 * BASIS_POINTS_DIVISOR)) {
      return `Min leverage: 1.1x`;
    }
    if (nextLeverageSL && nextLeverageSL.lt(1.1 * BASIS_POINTS_DIVISOR)) {
      return `Min leverage: 1.1x`;
    }

    if (nextLeverageTP && nextLeverageTP.gt(MAX_ALLOWED_LEVERAGE(chainId))) {
      return `Max leverage: ${(MAX_ALLOWED_LEVERAGE(chainId) / BASIS_POINTS_DIVISOR).toFixed(1)}x`;
    }
    if (nextLeverageSL && nextLeverageSL.gt(MAX_ALLOWED_LEVERAGE(chainId))) {
      return `Max leverage: ${(MAX_ALLOWED_LEVERAGE(chainId) / BASIS_POINTS_DIVISOR).toFixed(1)}x`;
    }

    if (hasPendingProfit && orderOption !== STOP && !isProfitWarningAccepted) {
      return `Forfeit profit not checked`;
    }
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }
    if (needOrderBookApproval && isWaitingForPluginApproval) {
      return false;
    }
    if (isPluginApproving) {
      return false;
    }
    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return false;
    }
    if (isPositionRouterApproving) {
      return false;
    }

    return true;
  };

  const hasPendingProfit = MIN_PROFIT_TIME > 0 && position.delta.eq(0) && position.pendingDelta.gt(0);

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }

    if (orderOption === STOP) {
      if (isSubmitting) return `Creating Order...`;

      if (needOrderBookApproval && isWaitingForPluginApproval) {
        return `Enabling Orders...`;
      }
      if (isPluginApproving) {
        return `Enabling Orders...`;
      }
      if (needOrderBookApproval) {
        return `Enable Orders`;
      }

      return `Add TP/SL`;
    }

    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return `Enabling Leverage...`;
    }

    if (isPositionRouterApproving) {
      return `Enabling Leverage...`;
    }

    if (needPositionRouterApproval) {
      return `Enable Leverage`;
    }

    if (hasPendingProfit) {
      return `Close without profit`;
    }
    return isSubmitting ? `Closing...` : `Close`;
  };

  const resetForm = () => {
    setFromValue("");
    setIsProfitWarningAccepted(false);
  };

  useEffect(() => {
    if (prevIsVisible !== isVisible) {
      resetForm();
    }
  }, [prevIsVisible, isVisible]);

  const onClickPrimary = async () => {
    if (needOrderBookApproval) {
      setOrdersToaOpen(true);
      return;
    }

    if (needPositionRouterApproval) {
      approvePositionRouter({
        sentMsg: `Enable leverage sent.`,
        failMsg: `Enable leverage failed.`,
      });
      return;
    }

    setIsSubmitting(true);

    const collateralTokenAddress = position.collateralToken.isNative
      ? nativeTokenAddress
      : position.collateralToken.address;
    const indexTokenAddress = position.indexToken.isNative ? nativeTokenAddress : position.indexToken.address;

    if (orderOption === STOP) {
      if (takeProfitPrice && takeProfitPrice.gt(0) && takeProfitQuantity && takeProfitQuantity.gt(0)) {
        const triggerAboveThreshold = takeProfitPrice.gt(position.markPrice);
        createDecreaseOrder(
          chainId,
          library,
          indexTokenAddress,
          sizeDeltaTP,
          collateralTokenAddress,
          collateralDeltaTP,
          position.isLong,
          takeProfitPrice,
          triggerAboveThreshold,
          {
            sentMsg: `Take profit submitted!`,
            successMsg: `Take profit created!`,
            failMsg: `Take profit creation failed.`,
            setPendingTxns,
          }
        )
          .then(() => {
            setFromValue("");
            setIsVisible(false);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }
      if (stopLossPrice && stopLossPrice.gt(0) && stopLossQuantity && stopLossQuantity.gt(0)) {
        const triggerAboveThreshold = stopLossPrice.gt(position.markPrice);
        createDecreaseOrder(
          chainId,
          library,
          indexTokenAddress,
          sizeDeltaSL,
          collateralTokenAddress,
          collateralDeltaSL,
          position.isLong,
          stopLossPrice,
          triggerAboveThreshold,
          {
            sentMsg: `Stop loss submitted!`,
            successMsg: `Stop loss created!`,
            failMsg: `Stop loss creation failed.`,
            setPendingTxns,
          }
        )
          .then(() => {
            setFromValue("");
            setIsVisible(false);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }

      return;
    }

    const priceBasisPoints = position.isLong
      ? BASIS_POINTS_DIVISOR - allowedSlippage
      : BASIS_POINTS_DIVISOR + allowedSlippage;
    const refPrice = position.isLong ? position.indexToken.minPrice : position.indexToken.maxPrice;
    let priceLimit = refPrice.mul(priceBasisPoints).div(BASIS_POINTS_DIVISOR);
    const minProfitExpiration = position.lastIncreasedTime + MIN_PROFIT_TIME;
    const minProfitTimeExpired = parseInt(Date.now() / 1000) > minProfitExpiration;

    if (nextHasProfit && !minProfitTimeExpired && !isProfitWarningAccepted) {
      if ((position.isLong && priceLimit.lt(profitPrice)) || (!position.isLong && priceLimit.gt(profitPrice))) {
        priceLimit = profitPrice;
      }
    }

    const tokenAddress0 = collateralTokenAddress === AddressZero ? nativeTokenAddress : collateralTokenAddress;

    const path = [tokenAddress0];

    const isUnwrap = receiveToken.address === AddressZero;
    const isSwap = receiveToken.address !== tokenAddress0;

    if (isSwap) {
      if (isUnwrap && tokenAddress0 !== nativeTokenAddress) {
        path.push(nativeTokenAddress);
      } else if (!isUnwrap) {
        path.push(receiveToken.address);
      }
    }

    const withdrawETH = isUnwrap && !isContractAccount;

    const params = [
      path, // _path
      indexTokenAddress, // _indexToken
      collateralDelta, // _collateralDelta
      sizeDelta, // _sizeDelta
      position.isLong, // _isLong
      account, // _receiver
      priceLimit, // _acceptablePrice
      0, // _minOut
      minExecutionFee, // _executionFee
      withdrawETH, // _withdrawETH
      AddressZero, // _callbackTarget
    ];
    const sizeDeltaUsd = formatAmount(sizeDelta, USD_DECIMALS, 2);
    const successMsg = `Requested decrease of ${position.indexToken.symbol} ${longOrShortText} by ${sizeDeltaUsd} USD.`;

    const contract = new ethers.Contract(positionRouterAddress, PositionRouter.abi, library.getSigner());

    callContract(chainId, contract, "createDecreasePosition", params, {
      value: minExecutionFee,
      sentMsg: `Close submitted!`,
      successMsg,
      failMsg: `Close failed.`,
      setPendingTxns,
      // for Arbitrum, sometimes the successMsg shows after the position has already been executed
      // hide the success message for Arbitrum as a workaround
      hideSuccessMsg: chainId === ARBITRUM,
    })
      .then(async (res) => {
        setFromValue("");
        setIsVisible(false);

        let nextSize = position.size.sub(sizeDelta);

        pendingPositions[position.key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            size: nextSize,
          },
        };

        setPendingPositions({ ...pendingPositions });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  // console.log("???", { takeProfitPrice, takeProfitQuantity, stopLossPrice, stopLossQuantity });

  const renderExistingOrderWarning = useCallback(() => {
    if (!existingOrder) {
      return;
    }
    const indexToken = getTokenInfo(infoTokens, existingOrder.indexToken);
    const sizeInToken = formatAmount(
      existingOrder.sizeDelta.mul(PRECISION).div(existingOrder.triggerPrice),
      USD_DECIMALS,
      4,
      true
    );
    const prefix = existingOrder.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
    return (
      <div className="Confirmation-box-warning">
        <span>
          You have an active order to decrease {longOrShortText} {sizeInToken} {indexToken.symbol} ($
          {formatAmount(existingOrder.sizeDelta, USD_DECIMALS, 2, true)}) at {prefix}{" "}
          {formatAmount(existingOrder.triggerPrice, USD_DECIMALS, 2, true)}
        </span>
      </div>
    );
  }, [existingOrder, infoTokens, longOrShortText]);

  function renderMinProfitWarning() {
    if (MIN_PROFIT_TIME === 0) {
      return null;
    }

    if (profitPrice && nextDelta.eq(0) && nextHasProfit) {
      const minProfitExpiration = position.lastIncreasedTime + MIN_PROFIT_TIME;

      if (orderOption === MARKET) {
        return (
          <div className="Confirmation-box-warning">
            <span>
              Reducing the position at the current price will forfeit a&nbsp;
              <ExternalLink href="https://docs.mummy.finance/trading#minimum-price-change">
                pending profit
              </ExternalLink>{" "}
              of {deltaStr}. <br />
            </span>
            <span>
              <br />
              Profit price: {position.isLong ? ">" : "<"} ${formatAmount(profitPrice, USD_DECIMALS, 2, true)}. This rule
              applies for the next {getTimeRemaining(minProfitExpiration)}, until {formatDateTime(minProfitExpiration)}.
            </span>
          </div>
        );
      }
      return (
        <div className="Confirmation-box-warning">
          <span>
            This order will forfeit a&nbsp;
            <ExternalLink href="https://docs.mummy.finance/trading#minimum-price-change">profit</ExternalLink> of{" "}
            {deltaStr}. <br />
          </span>
          <span>
            Profit price: {position.isLong ? ">" : "<"} ${formatAmount(profitPrice, USD_DECIMALS, 2, true)}. This rule
            applies for the next {getTimeRemaining(minProfitExpiration)}, until {formatDateTime(minProfitExpiration)}.
          </span>
        </div>
      );
    }
  }

  const profitPrice = getProfitPrice(orderOption === MARKET ? position.markPrice : triggerPriceUsd, position);

  let triggerPricePrefix;
  if (triggerPriceUsd) {
    triggerPricePrefix = triggerPriceUsd.gt(position.markPrice) ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
  }

  const shouldShowExistingOrderWarning = false;

  if (orderOption === STOP && !triggerPriceUsd) {
    receiveAmount = bigNumberify(0);
    convertedReceiveAmount = bigNumberify(0);
  }
  // console.log("????", { sizeDeltaTP, nextDeltaTp, nextLiquidationPriceTP, nextHasProfitTP });
  const handleMax = () => {
    setTriggerPriceInput(formatAmount(position.markPrice, 30, 6, false));
  };
  return (
    <div className="PositionEditor">
      {position && (
        <Modal
          className="PositionSeller-modal confirmation-modal details-position-modal"
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          label={`Add Take Profit / Stop Loss`}
          allowContentTouchMove
        >
          {" "}
          <div className="order-title-line order-title-line-details">
            <TokenTopInfo
              symbol={position.indexToken.symbol}
              isLong={position.isLong}
              leverageOption={formatAmount(position.leverage, 4, 2)}
              orderOption="Market"
              type="m8"
              posId={selectedPosition?.posId}
            />
          </div>
          <div className="content-container">
            {" "}
            {flagOrdersEnabled && (
              <Tab
                options={TPSL_OPTION}
                option={tpslOption}
                optionLabels={TPSL_OPTION_LABELS}
                onChange={onTPSLOptionChange}
              />
            )}
            <div className="Exchange-swap-section">
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  <span>Trigger Price</span>
                </div>
                <div className="muted align-right clickable" onClick={handleMax}>
                  <span>
                    Mark: {position.markPrice && "$"}
                    {formatAmount(
                      position.markPrice,
                      USD_DECIMALS,
                      getDisplayDecimalByAmount(position.markPrice, USD_DECIMALS),
                      true
                    )}
                  </span>
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={triggerPriceInput}
                    onChange={handleTrigerPriceInputChange}
                  />
                </div>
                <div className="PositionEditor-token-symbol">USD</div>
              </div>
            </div>
            {tpslOption === TAKE_PROFIT ? (
              position.isLong ? (
                <TakeProfitLongTriggerButton
                  onChangePercentage={handleTakeProfitLongPercent}
                  balance={Number(formatAmount(position.averagePrice, 30, 30, false))}
                  value={triggerPriceInput}
                  percentArray={[0.01, 0.02, 0.03, 0.05]}
                />
              ) : (
                <TakeProfitShortTriggerButton
                  onChangePercentage={handleTakeProfitShortPercent}
                  balance={Number(formatAmount(position.averagePrice, 30, 30, false))}
                  value={triggerPriceValue}
                  percentArray={[0.01, 0.02, 0.03, 0.05]}
                />
              )
            ) : position.isLong ? (
              <TakeProfitShortTriggerButton
                onChangePercentage={handleTakeProfitShortPercent}
                balance={Number(formatAmount(position.averagePrice, 30, 30, false))}
                value={triggerPriceInput}
                percentArray={[0.01, 0.02, 0.03, 0.05]}
              />
            ) : (
              <TakeProfitLongTriggerButton
                onChangePercentage={handleTakeProfitLongPercent}
                balance={Number(formatAmount(position.averagePrice, 30, 30, false))}
                value={triggerPriceInput}
                percentArray={[0.01, 0.02, 0.03, 0.05]}
              />
            )}
            <div className="Exchange-swap-section" style={{ marginBottom: "8px", marginTop: "16px" }}>
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  <span>Close Quantity</span>
                </div>
                <div
                  className="muted align-right clickable "
                  onClick={() => {
                    handleSelectPercentageCloseQuantity(1);
                  }}
                >
                  <span>Max: {formatAmount(posQty, 30, 4, true)}</span>
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={closeQuantity}
                    onChange={handleChangeQuantityInput}
                    onKeyDown={preventSpecialCharacters}
                  />
                </div>
                <div className="PositionEditor-token-symbol">{position.indexToken.symbol}</div>
              </div>
            </div>
            <PercentageButtons
              onChangePercentage={handleSelectPercentageCloseQuantity}
              balance={Number(formatAmount(posQty, 30, position.indexToken.decimals, false))}
              value={closeQuantity}
            />
            {renderMinProfitWarning()}
            {shouldShowExistingOrderWarning && renderExistingOrderWarning()}
            <div className="PositionEditor-info-box">
              {minExecutionFeeErrorMessage && (
                <div className="Confirmation-box-warning">{minExecutionFeeErrorMessage}</div>
              )}

              <div className="PositionEditor-keep-leverage-settings" style={{ marginTop: "24px" }}>
                <Checkbox isChecked={keepLeverage} setIsChecked={setKeepLeverage}>
                  <span className="muted font-sm" style={{ fontWeight: 500 }}>
                    <span>Keep leverage at {formatAmount(position.leverage, 4, 2)}x</span>
                  </span>
                </Checkbox>
              </div>
              <div className="square-container square-fee-container">
                <ExchangeInfoRow label={`Entry Price`}>
                  ${formatAmount(position.averagePrice, 30, 2, true)}
                </ExchangeInfoRow>{" "}
                <div className="divider" />
              </div>

              {/* {orderOption === MARKET && (
              <div className="PositionEditor-allow-higher-slippage">
                <Checkbox isChecked={isHigherSlippageAllowed} setIsChecked={setIsHigherSlippageAllowed}>
                  <span className="muted font-sm">
                    <span>Allow up to 1% slippage</span>
                  </span>
                </Checkbox>
              </div>
            )} */}
              {/* {orderOption === MARKET && (
              <div>
                <ExchangeInfoRow label={`Allowed Slippage`}>
                  <Tooltip
                    handle={`${formatAmount(allowedSlippage, 2, 2)}%`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <div>
                          You can change this in the settings menu on the top right of the page.
                          <div style={{ marginTop: "8px" }}>
                            Note that a low allowed slippage, e.g. less than{" "}
                            <span style={{ color: "white", fontWeight: 700 }}>0.5%</span>, may result in failed orders
                            if prices are volatile.
                          </div>
                        </div>
                      );
                    }}
                  />
                </ExchangeInfoRow>
              </div>
            )} */}
              <div className="PositionEditor-info-box" style={{ marginTop: "16px" }}>
                <div className="square-container square-fee-container">
                  <div className="add-profit-title">Take Profit</div>
                  <ExchangeInfoRow label={`Trigger Price`}>
                    {takeProfitPrice ? (!position.isLong ? "≤ " : "≥ ") : ""}
                    {takeProfitPrice ? "$" + formatAmount(takeProfitPrice, 30, 2, true) : "-"}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`TP Qty`}>
                    {takeProfitQuantity && !takeProfitQuantity.eq(0)
                      ? formatAmount(takeProfitQuantity, 30, 4, true) + ` ${position.indexToken.symbol}`
                      : "-"}{" "}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Pos. Size`}>
                    {takeProfitPrice && takeProfitPrice.gt(0) && takeProfitQuantity && takeProfitQuantity.gt(0) ? (
                      <span>
                        <span style={{ textDecoration: "line-through", color: "#828899" }}>
                          ${formatAmount(position.size, 30, 2, true)}
                        </span>{" "}
                        <span style={{ position: "relative", top: "2px" }}>
                          {" "}
                          <GreenArraowIcon fill={"#828899"} />
                        </span>{" "}
                        ${formatAmount(nextCollateralTP.mul(nextLeverageTP || position.leverage), 34, 2, true)}
                      </span>
                    ) : (
                      `$${formatAmount(position.size, 30, 2, true)}`
                    )}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Collateral`}>
                    {nextCollateralTP &&
                    takeProfitPrice &&
                    takeProfitPrice.gt(0) &&
                    takeProfitQuantity &&
                    takeProfitQuantity.gt(0) ? (
                      <span>
                        <span style={{ textDecoration: "line-through", color: "#828899" }}>
                          ${formatAmount(position.collateral, 30, 2)}
                        </span>{" "}
                        <span style={{ position: "relative", top: "2px" }}>
                          {" "}
                          <GreenArraowIcon fill={"#828899"} />
                        </span>{" "}
                        ${formatShortNumber(formatAmount(nextCollateralTP, 30, 2, false), 2)}
                      </span>
                    ) : (
                      `$${formatAmount(position.collateral, 30, 2, true)}`
                    )}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Liq. Price`}>
                    {nextLiquidationPriceTP && !nextLiquidationPriceTP.eq(liquidationPrice) ? (
                      <span>
                        <span style={{ textDecoration: "line-through", color: "#828899" }}>
                          $
                          {formatAmount(
                            liquidationPrice,
                            USD_DECIMALS,
                            position?.indexToken?.displayDecimals || 2,
                            true
                          )}
                        </span>{" "}
                        <span style={{ position: "relative", top: "2px" }}>
                          {" "}
                          <GreenArraowIcon fill={"#828899"} />
                        </span>{" "}
                        $
                        {formatAmount(
                          nextLiquidationPriceTP,
                          USD_DECIMALS,
                          position?.indexToken?.displayDecimals || 2,
                          true
                        )}
                      </span>
                    ) : isClosingTP ? (
                      "-"
                    ) : (
                      `$${formatAmount(
                        liquidationPrice,
                        USD_DECIMALS,
                        position?.indexToken?.displayDecimals || 2,
                        true
                      )}`
                    )}
                  </ExchangeInfoRow>
                  {!keepLeverage ? (
                    <ExchangeInfoRow label={`Leverage`}>
                      {!isClosingTP ? (
                        nextLeverageTP ? (
                          <span>
                            <span style={{ textDecoration: "line-through", color: "#828899" }}>
                              {formatAmount(position.leverage, 4, 2)}x
                            </span>{" "}
                            <span style={{ position: "relative", top: "2px" }}>
                              {" "}
                              <GreenArraowIcon fill={"#828899"} />
                            </span>{" "}
                            {formatAmount(nextLeverageTP, 4, 2)}x
                          </span>
                        ) : (
                          `${formatAmount(position.leverage, 4, 2)}x`
                        )
                      ) : (
                        "-"
                      )}
                    </ExchangeInfoRow>
                  ) : null}

                  <ExchangeInfoRow label={`Fees`}>
                    <Tooltip
                      position="right-top"
                      className="PositionSeller-fees-tooltip"
                      handle={
                        <div>
                          {totalFeesTP
                            ? `$${formatAmount(totalFeesTP.add(executionFeeUsd), USD_DECIMALS, 2, true)}`
                            : "-"}
                        </div>
                      }
                      renderContent={() => (
                        <div>
                          {fundingFee && (
                            <StatsTooltipRow
                              label={`Borrow Fee`}
                              value={formatAmount(fundingFee, USD_DECIMALS, 2, true)}
                            />
                          )}

                          {positionFeeTP && (
                            <StatsTooltipRow
                              label={`Closing Fee`}
                              value={formatAmount(positionFeeTP, USD_DECIMALS, 2, true)}
                            />
                          )}

                          {swapFeeTP && (
                            <StatsTooltipRow
                              label={`Swap Fee`}
                              showDollar={false}
                              value={`${formatAmount(swapFeeTokenTP, collateralToken.decimals, 5)} ${
                                collateralToken.symbol
                              }
                           ($${formatAmount(swapFeeTP, USD_DECIMALS, 2, true)})`}
                            />
                          )}

                          <StatsTooltipRow
                            label={`Execution fee`}
                            showDollar={false}
                            value={`${formatAmount(executionFee, 18, 4, true)} ${nativeTokenSymbol} ($${formatAmount(
                              executionFeeUsd,
                              USD_DECIMALS,
                              2
                            )})`}
                          />
                        </div>
                      )}
                    />
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Receive`}>
                    {!isSwapAllowed && receiveToken && (
                      <div className="align-right bold PositionSelector-selected-receive-token">
                        {formatAmount(convertedReceiveAmountTP, receiveToken.decimals, 4, true)}
                        &nbsp;{receiveToken.symbol} ($
                        {formatAmount(receiveAmountTP, USD_DECIMALS, 2, true)})
                      </div>
                    )}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Est. PnL`}>
                    <div
                      className={`bold align-right ${
                        deltaStrTP.includes("-") && deltaStrTP.includes("$") ? "negative" : "positive"
                      }`}
                    >
                      {/* {deltaStrTP} ({deltaPercentageStrTP}) */}
                      {deltaStrTP}
                    </div>
                  </ExchangeInfoRow>
                  <div className="divider" />
                  <div className="add-profit-title">Stop Loss</div>
                  <ExchangeInfoRow label={`Trigger Price`}>
                    {stopLossPrice ? (position.isLong ? "≤ " : "≥ ") : ""}
                    {stopLossPrice ? "$" + formatAmount(stopLossPrice, 30, 2, true) : "-"}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`SL Qty`}>
                    {" "}
                    {stopLossQuantity
                      ? formatAmount(stopLossQuantity, 30, 4, true) + ` ${position.indexToken.symbol}`
                      : "-"}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Pos. Size`}>
                    {stopLossQuantity && stopLossPrice && stopLossQuantity.gt(0) && stopLossPrice.gt(0) ? (
                      <span>
                        <span style={{ textDecoration: "line-through", color: "#828899" }}>
                          ${formatAmount(position.size, 30, 2)}
                        </span>{" "}
                        <span style={{ position: "relative", top: "2px" }}>
                          {" "}
                          <GreenArraowIcon fill={"#828899"} />
                        </span>{" "}
                        ${formatAmount(nextCollateralSL.mul(nextLeverageSL || position.leverage), 34, 2, true)}
                      </span>
                    ) : (
                      `$${formatAmount(position.size, 30, 2, true)}`
                    )}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Collateral`}>
                    {stopLossQuantity && stopLossPrice && stopLossQuantity.gt(0) && stopLossPrice.gt(0) ? (
                      <span>
                        <span style={{ textDecoration: "line-through", color: "#828899" }}>
                          ${formatAmount(position.collateral, 30, 2)}
                        </span>{" "}
                        <span style={{ position: "relative", top: "2px" }}>
                          {" "}
                          <GreenArraowIcon fill={"#828899"} />
                        </span>{" "}
                        ${formatShortNumber(formatAmount(nextCollateralSL, 30, 2, false), 2)}
                      </span>
                    ) : (
                      `$${formatAmount(position.collateral, 30, 2, true)}`
                    )}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Liq. Price`}>
                    {nextLiquidationPriceSL && !nextLiquidationPriceSL.eq(liquidationPrice) ? (
                      <span>
                        <span style={{ textDecoration: "line-through", color: "#828899" }}>
                          $
                          {formatAmount(
                            liquidationPrice,
                            USD_DECIMALS,
                            position?.indexToken?.displayDecimals || 2,
                            true
                          )}
                        </span>{" "}
                        <span style={{ position: "relative", top: "2px" }}>
                          {" "}
                          <GreenArraowIcon fill={"#828899"} />
                        </span>{" "}
                        $
                        {formatAmount(
                          nextLiquidationPriceSL,
                          USD_DECIMALS,
                          position?.indexToken?.displayDecimals || 2,
                          true
                        )}
                      </span>
                    ) : isClosingSL ? (
                      "-"
                    ) : (
                      `$${formatAmount(
                        liquidationPrice,
                        USD_DECIMALS,
                        position?.indexToken?.displayDecimals || 2,
                        true
                      )}`
                    )}
                  </ExchangeInfoRow>
                  {!keepLeverage ? (
                    <ExchangeInfoRow label={`Leverage`}>
                      {!isClosingSL ? (
                        nextLeverageSL ? (
                          <span>
                            <span style={{ textDecoration: "line-through", color: "#828899" }}>
                              {formatAmount(position.leverage, 4, 2)}x
                            </span>{" "}
                            <span style={{ position: "relative", top: "2px" }}>
                              {" "}
                              <GreenArraowIcon fill={"#828899"} />
                            </span>{" "}
                            {formatAmount(nextLeverageSL, 4, 2)}x
                          </span>
                        ) : (
                          `${formatAmount(position.leverage, 4, 2)}x`
                        )
                      ) : (
                        "-"
                      )}
                    </ExchangeInfoRow>
                  ) : null}

                  <ExchangeInfoRow label={`Fees`}>
                    <Tooltip
                      position="right-top"
                      className="PositionSeller-fees-tooltip"
                      handle={
                        <div>
                          {totalFeesSL
                            ? `$${formatAmount(totalFeesTP.add(executionFeeUsd), USD_DECIMALS, 2, true)}`
                            : "-"}
                        </div>
                      }
                      renderContent={() => (
                        <div>
                          {fundingFee && (
                            <StatsTooltipRow
                              label={`Borrow Fee`}
                              value={formatAmount(fundingFee, USD_DECIMALS, 2, true)}
                            />
                          )}

                          {positionFeeTP && (
                            <StatsTooltipRow
                              label={`Closing Fee`}
                              value={formatAmount(positionFeeSL, USD_DECIMALS, 2, true)}
                            />
                          )}

                          {swapFeeTP && (
                            <StatsTooltipRow
                              label={`Swap Fee`}
                              showDollar={false}
                              value={`${formatAmount(swapFeeTokenSL, collateralToken.decimals, 5)} ${
                                collateralToken.symbol
                              }
                           ($${formatAmount(swapFeeTP, USD_DECIMALS, 2, true)})`}
                            />
                          )}

                          <StatsTooltipRow
                            label={`Execution Fee`}
                            showDollar={false}
                            value={`${formatAmount(executionFee, 18, 4, true)} ${nativeTokenSymbol} ($${formatAmount(
                              executionFeeUsd,
                              USD_DECIMALS,
                              2
                            )})`}
                          />
                        </div>
                      )}
                    />
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Receive`}>
                    {!isSwapAllowed && receiveToken && (
                      <div className="align-right bold PositionSelector-selected-receive-token">
                        {formatAmount(convertedReceiveAmountSL, receiveToken.decimals, 4, true)}
                        &nbsp;{receiveToken.symbol} ($
                        {formatAmount(receiveAmountSL, USD_DECIMALS, 2, true)})
                      </div>
                    )}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Est. PnL`} noMargin={true}>
                    <div className={`bold align-right ${deltaStrSL.includes("-") ? "negative" : "positive"}`}>
                      {/* {deltaStrSL} ({deltaPercentageStrSL}) */}
                      {deltaStrSL}
                    </div>
                  </ExchangeInfoRow>
                  {/* <div className="divider" /> */}

                  {/* <ExchangeInfoRow label={`Mark Price`}>
                    ${formatAmount(position.markPrice, 30, 2, true)}
                  </ExchangeInfoRow>
                  <ExchangeInfoRow label={`Pos. Size`}>
                    ${formatAmount(position.size, 30, 2, true)} ({formatAmount(posQty, 30, 4, true)}{" "}
                    {position.indexToken.symbol})
                  </ExchangeInfoRow> */}
                  {/* <ExchangeInfoRow label={`Pos. Qty`}>
                  {formatAmount(posQty, 30, 4, true)} {position.symbol}
                </ExchangeInfoRow> */}
                </div>
              </div>
            </div>
          </div>{" "}
          <div style={{ marginTop: "-8px" }} className="Exchange-swap-button-container ">
            <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}

const Wrapper = styled.div``;
