import Tooltip from "components/Tooltip/Tooltip";
import { FANTOM } from "config/chains";
import { StatsContext } from "contexts/StatsProviderV2";
import { useThemeContext } from "contexts/ThemeProvider";
import useDailyInfoLast24hCharts from "hooks/useDailyInfoLast24h";
import OpenInterestIcon from "img/analytics/openInterest.svg";
import OpenInterestIconLight from "img/analytics/openInterestLight.svg";
import TotalRealizedIcon from "img/analytics/realized-icon.svg";
import TotalRealizedIconLight from "img/analytics/realizedIconLight.svg";
import TotalFeeIcon from "img/analytics/totalFee.svg";
import TotalFeeIconLight from "img/analytics/totalFeeLight.svg";
import TotalTradesIcon from "img/analytics/totalTradesV2.svg";
import TotalTradesIconLight from "img/analytics/totalTradesV2Light.svg";
import TotalUserIcon from "img/analytics/totalUser.svg";
import TotalUserIconLight from "img/analytics/totalUserLight.svg";
import TotalValueLogNSLPIcon from "img/analytics/totalValueLogNSLP.svg";
import TotalValueLogNSLPIconLight from "img/analytics/totalValueLogNSLPLight.svg";
import TotalVolumnIcon from "img/analytics/totalVolumn.svg";
import TotalVolumnIconLight from "img/analytics/totalVolumnLight.svg";
import { formatNumber } from "lib/numbers";
import { FC, useContext, useMemo } from "react";
import { useMedia } from "react-use";
import styled from "styled-components";
import SparklinesChart from "./SparklinesChart";
import { ACTIVE_CHAIN_IDS_V2 } from "lib/legacy";

interface Props {
  chainId: number;
  dailyInfoData: any;
  isLoadingDailyInfoData: boolean;
  dailyInfoChartData: any;
  isLoadingDailyInfoChart: boolean;
  theme?: string;
}

const LinearGradientFill = ({ stopColor = "#296eff" }) => {
  return (
    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
      <stop offset="0%" stopColor={stopColor} stopOpacity="1" />
      <stop offset="100%" stopColor={stopColor} stopOpacity="0" />
    </linearGradient>
  );
};

const Stats: FC<Props> = ({
  chainId,
  dailyInfoData,
  isLoadingDailyInfoData,
  dailyInfoChartData,
  isLoadingDailyInfoChart,
}) => {
  // const { data } = useAppStats(chainId);
  const { isLightTheme } = useThemeContext();
  const isMobile = useMedia("(max-width: 767px)");

  const data = useContext(StatsContext);

  const parseNumberOrDefault = (value: string, defaultValue: number): number => {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? defaultValue : parsedValue;
  };

  const statsDataWithChart = useMemo(() => {
    let volumes24h: any = [];
    let trades24h: any = [];
    let fees24h: any = [];
    let nslpPrice24h: any = [];
    let nslpTvlValue;
    let totalVolumeValue;
    let totalTradesValue;

    if (dailyInfoChartData && dailyInfoData && ACTIVE_CHAIN_IDS_V2.includes(chainId)) {
      dailyInfoChartData.forEach(({ volume, fees, trades, nslpPrice, timestamp }) => {
        volumes24h.push([+volume, timestamp]);
        trades24h.push([+trades, timestamp]);
        fees24h.push([+fees, timestamp]);
        nslpPrice24h.push([+nslpPrice, timestamp]);
      });

      nslpTvlValue = data?.nslpTvl?.[chainId] || 0;

      totalVolumeValue =
        dailyInfoData?.reduce(
          (val, currentValue) => {
            val.volume += parseNumberOrDefault(currentValue.volume, 0);
            return val;
          },
          { volume: 0 }
        ).volume || 0;
      totalTradesValue =
        dailyInfoData?.reduce(
          (val, currentValue) => {
            val.trades += parseNumberOrDefault(currentValue.trades, 0);
            return val;
          },
          { trades: 0 }
        ).trades || 0;
      const result = [
        {
          label: "24H volume",
          totalLabel: "Total volume",
          value: "$" + formatNumber(data?.volume24H?.[chainId], 2),
          totalValue: "$" + formatNumber(totalVolumeValue, 2),
          chartData: volumes24h,
          urlIcon: isLightTheme ? TotalVolumnIconLight : TotalVolumnIcon,
        },
        {
          label: "24H trades",
          totalLabel: "Total trades",
          value: formatNumber(data?.trades24H?.[chainId], 2),
          totalValue: formatNumber(totalTradesValue, 2),
          chartData: trades24h,
          urlIcon: isLightTheme ? TotalTradesIconLight : TotalTradesIcon,
        },
        {
          label: "NSLP price",
          totalLabel: "NSLP TVL",
          value: "$" + formatNumber(data?.nslpPrice?.[chainId], 2),
          totalValue: "$" + formatNumber(nslpTvlValue, 2),
          chartData: nslpPrice24h,
          urlIcon: isLightTheme ? TotalValueLogNSLPIconLight : TotalValueLogNSLPIcon,
        },
      ];
      return result;
    } else {
      const result = [
        {
          label: "24H volume",
          totalLabel: "Total volume",
          value: "$--",
          totalValue: "$--",
          chartData: [],
          urlIcon: isLightTheme ? TotalVolumnIconLight : TotalVolumnIcon,
        },
        {
          label: "24H trades",
          totalLabel: "Total trades",
          value: "--",
          totalValue: "--",
          chartData: [],
          urlIcon: isLightTheme ? TotalTradesIconLight : TotalTradesIcon,
        },
        {
          label: "NSLP price",
          totalLabel: "NSLP TVL",
          value: "$--",
          totalValue: "$--",
          chartData: [],
          urlIcon: isLightTheme ? TotalValueLogNSLPIconLight : TotalValueLogNSLPIcon,
        },
      ];
      return result;
    }
  }, [
    chainId,
    dailyInfoChartData,
    dailyInfoData,
    data?.nslpPrice,
    data?.nslpTvl,
    data?.trades24H,
    data?.volume24H,
    isLightTheme,
  ]);

  const statsData = useMemo(() => {
    let openInterestValue;
    let usersValue = 0;
    if (dailyInfoData && data && ACTIVE_CHAIN_IDS_V2.includes(chainId)) {
      const openInterestReduce = dailyInfoData?.reduce(
        (val, currentValue) => {
          val.longOpenInterest += parseNumberOrDefault(currentValue.longOpenInterest, 0);
          val.shortOpenInterest += parseNumberOrDefault(currentValue.shortOpenInterest, 0);
          return val;
        },
        { longOpenInterest: 0, shortOpenInterest: 0 }
      );
      openInterestValue = Number(openInterestReduce.longOpenInterest) + Number(openInterestReduce.shortOpenInterest);
      usersValue =
        dailyInfoData?.reduce(
          (val, currentValue) => {
            val.newUsers += parseNumberOrDefault(currentValue.newUsers, 0);
            return val;
          },
          { newUsers: 0 }
        ).newUsers || 0;

      return [
        {
          icon: isLightTheme ? TotalUserIconLight : TotalUserIcon,
          label: "Total users",
          value: formatNumber(usersValue, 0),
        },
        {
          icon: isLightTheme ? OpenInterestIconLight : OpenInterestIcon,
          label: "Open interest",
          value: "$" + formatNumber(openInterestValue, 2),
        },
        {
          icon: isLightTheme ? TotalFeeIconLight : TotalFeeIcon,
          label: "24H fees",
          value: "$" + formatNumber(data?.fees24H?.[chainId], 2),
        },
        {
          icon: isLightTheme ? TotalRealizedIconLight : TotalRealizedIcon,
          label: "24H realized PnL",
          tooltip: "Realized PnL of closed positions",
          value:
            data?.realizedPnl24H?.[chainId] &&
            (Number(data?.realizedPnl24H?.[chainId]) >= 0 ? "" : "-") +
              "$" +
              formatNumber(Math.abs(data?.realizedPnl24H?.[chainId]), 2),
        },
        // {
        //   label: "BLP price",
        //   value: "$" + formatNumber(nslpPriceValue, 4),
        // },
        // {
        //   label: "BLP TVL",
        //   value: "$" + formatNumber(nslpTvlValue, 2),
        // },
      ];
    }

    return [
      {
        icon: isLightTheme ? TotalUserIconLight : TotalUserIcon,
        label: "Total users",
        value: "--",
      },
      {
        icon: isLightTheme ? OpenInterestIconLight : OpenInterestIcon,
        label: "Open interest",
        value: "$--",
      },
      {
        icon: isLightTheme ? TotalFeeIconLight : TotalFeeIcon,
        label: "24H fees",
        value: "$--",
      },
      {
        icon: isLightTheme ? TotalRealizedIconLight : TotalRealizedIcon,
        label: "24H realized PnL",
        value: "$--",
      },
      // {
      //   label: "BLP price",
      //   value: "$--",
      // },
      // {
      //   label: "BLP TVL",
      //   value: "$--",
      // },
    ];
  }, [chainId, data, dailyInfoData, isLightTheme]);
  const LIST_STATS_WAITING = [
    {
      icon: TotalUserIcon,
      label: "Total users",
    },
    {
      icon: OpenInterestIcon,
      label: "Open interest",
    },
    {
      icon: TotalFeeIcon,
      label: "24H fees",
    },
    {
      icon: TotalRealizedIcon,
      label: "24H realized PnL",
      tooltip: "Realized PnL of closed positions",
    },
  ];

  return (
    <StatsWrap>
      <GridRowLeft>
        {!isLoadingDailyInfoChart && dailyInfoChartData
          ? statsDataWithChart.map((item, index) => {
              const isSwapToUsd = item.label !== "24H trades";
              return (
                <div className="stats-box-group" key={index}>
                  <div className="stats-img-group">
                    <img src={item.urlIcon} className="stats-img-styled" />
                  </div>
                  <div className="chart-cell-box">
                    <div className="total-stat-label">{item.totalLabel}</div>
                    <div className="total-stat-value">{item.totalValue}</div>
                  </div>
                  <div className="chart-cell-box">
                    <div className="total-stat-label">{item.label}</div>
                    <div className="total-stat-value">{item.value}</div>
                    <SparklinesChart data={item.chartData} isSwapToUsd={isSwapToUsd} />
                  </div>
                </div>
              );
            })
          : statsDataWithChart.map((item, index) => (
              <div className="stats-box-group" key={index}>
                <div className="stats-img-group">
                  <img src={TotalVolumnIcon} className="stats-img-styled" />
                </div>
                <div className="chart-cell-box">
                  <div className="total-stat-label">{item.totalLabel}</div>
                  <div className="skeleton-box" style={{ height: "30px", width: "80px" }} />
                </div>
                <div className="chart-cell-box">
                  <div className="total-stat-label">{item.label}</div>
                  <div className="skeleton-box" style={{ height: "30px", width: "80px" }} />
                </div>
                <div className="skeleton-box" style={{ height: "100%", width: "100%" }} />
              </div>
            ))}
      </GridRowLeft>

      <GridRowRight>
        {!isLoadingDailyInfoData
          ? statsData.map((item, index) => {
              return (
                <div className="stats-box-group" key={index}>
                  {true ? (
                    <>
                      <div className="stats-img-group">
                        <img src={item.icon} className="stats-img-styled" />
                      </div>
                      <div className="chart-cell-box">
                        <div className="total-stat-label">
                          {item.tooltip ? (
                            <Tooltip
                              className="fit-content nowrap"
                              position={isMobile ? "right-top" : "left-top"}
                              handle={item.label}
                              renderContent={() => item.tooltip}
                            />
                          ) : (
                            item.label
                          )}
                        </div>
                        <div className="total-stat-value">{item.value}</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="stats-img-group">
                        <img src={item.icon} className="stats-img-styled" />
                      </div>
                      <div className="chart-cell-box">
                        <div className="skeleton-box" style={{ height: "30px", width: "80px" }} />
                      </div>
                    </>
                  )}
                </div>
              );
            })
          : LIST_STATS_WAITING.map((item, index) => (
              <div className="stats-box-group" key={index}>
                <div className="stats-img-group">
                  <img src={item.icon} className="stats-img-styled" />
                </div>
                <div className="chart-cell-box">
                  <div className="total-stat-label">
                    {item.tooltip ? (
                      <Tooltip
                        className="fit-content nowrap"
                        position={isMobile ? "right-top" : "left-top"}
                        handle={item.label}
                        renderContent={() => item.tooltip}
                      />
                    ) : (
                      item.label
                    )}
                  </div>
                  <div className="skeleton-box" style={{ height: "30px", width: "80px" }} />
                </div>
              </div>
            ))}
      </GridRowRight>
    </StatsWrap>
  );
};

const GridRowLeft = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  .skeleton-box {
    margin-top: 8px;
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
`;

const GridRowRight = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  .skeleton-box {
    margin-top: 8px;
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    .chart-cell {
      padding: 16px;
    }
  }
`;

const StatsWrap = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
  .stats-box-group {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-radius: 16px;
    background: #1a1919;
  }

  .stats-img-styled {
  }

  .total-stat-label {
    color: var(--white-70, rgba(255, 255, 255, 0.7));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
  .total-stat-value {
    font-size: 24px;
    line-height: 140%;
    font-weight: bold;
    margin-top: 8px;
  }
  .chart-cell-box {
    width: 100%;
  }
  @media (max-width: 1024px) {
    margin-bottom: 64px;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 767px) {
    gap: 16px;
    margin-bottom: 48px;
    .stats-box-group {
      gap: 16px;
      padding: 16px;
    }
  }
`;

export default Stats;
