function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#000"
        d="M9.633 14.863l-.364 5.026c.52 0 .746-.22 1.016-.483l2.441-2.291 5.059 3.637c.927.508 1.581.24 1.831-.838l3.32-15.275h.001c.295-1.347-.495-1.874-1.4-1.543L2.022 10.432c-1.332.507-1.311 1.236-.226 1.566l4.99 1.524 11.59-7.12c.545-.354 1.04-.158.633.197l-9.375 8.264z"
      ></path>
    </svg>
  );
}

export default Icon;
