function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#000"
        d="M3.321 2.88l6.824 9.949-7.156 8.29h1.526l6.306-7.306 5.012 7.307h4.807l-7.141-10.41 6.758-7.83h-1.524l-5.91 6.846L8.129 2.88H3.32z"
      ></path>
    </svg>
  );
}

export default Icon;
