import styled from "styled-components";

import gum_ic from "img/gum.svg";
import arrow_ic from "img/arrow.svg";
import spooky from "img/ic_spooky.svg";
import oneInch from "img/ic_1inch.svg";
import kyber from "img/ic_kyber.svg";
import paraswap from "img/ic_paraswap.svg";
import openOcean from "img/ic_openocean.svg";
import binance from "img/ic_binance.svg";
import bybit from "img/ic_bybit.svg";
import kucoin from "img/ic_kucoin.svg";
import uniswap from "img/ic_uni_40.svg";

import green_arrow_ic from "img/green-arrow.svg";

const configs = {
  decentralizedExchanges: [
    {
      label: "Uniswap",
      link: "#",
      icon: uniswap,
    },
  ],
  decentralizedExchangeAggregators: [
    {
      label: "1inch",
      link: "#",
      icon: oneInch,
    },
    {
      label: "KyberSwap",
      link: "#",
      icon: kyber,
    },
    {
      label: "Paraswap",
      link: "#",
      icon: paraswap,
    },
    {
      label: "OpenOcean",
      link: "#",
      icon: openOcean,
    },
  ],
  centralizedServices: [
    {
      label: "Binance",
      link: "#",
      icon: binance,
    },
    {
      label: "Bybit",
      link: "#",
      icon: bybit,
    },
    {
      label: "Kucoin",
      link: "#",
      icon: kucoin,
    },
  ],
};

const BuyGum = () => {
  return (
    <Wrapper>
      <div className="Buy-Head">
        <div className="Buy-HeadDetails">
          <img src={gum_ic} alt="gum_ic" />
          <div>
            <h1>Buy NAVI</h1>
            <p>Choose to buy from decentralized or centralized exchanges.</p>
          </div>
        </div>
        <div className="Buy-Links">
          <button className="Buy-Link">Staking page</button>
          <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
            <circle cx="2" cy="2" r="2" fill="#807E7C" />
          </svg>
          <button className="Buy-Link">
            Docs <img src={green_arrow_ic} alt="green_arrow_ic" />
          </button>
        </div>
      </div>
      <div className="Buy-Papers">
        <div className="Buy-Paper">
          <h3>Decentralized Exchanges</h3>
          <div className="Buy-PaperDex">
            {configs.decentralizedExchanges.map((dex) => (
              <a href={dex.link} key={dex.label} className="Buy-PaperDexItem">
                <img src={dex.icon} alt="dex-icon" />
                <span>{dex.label}</span>
                <img src={arrow_ic} alt="arrow_ic" />
              </a>
            ))}
          </div>
        </div>
        <div className="Buy-Paper">
          <h3>Decentralized Exchange Aggregators</h3>
          <div className="Buy-PaperDex">
            {configs.decentralizedExchangeAggregators.map((dex) => (
              <a href={dex.link} key={dex.label} className="Buy-PaperDexItem">
                <img src={dex.icon} alt="dex-icon" />
                <span>{dex.label}</span>
                <img src={arrow_ic} alt="arrow_ic" />
              </a>
            ))}
          </div>
        </div>
        <div className="Buy-Paper">
          <h3>Centralized Services</h3>
          <div className="Buy-PaperDex">
            {configs.centralizedServices.map((dex) => (
              <a href={dex.link} key={dex.label} className="Buy-PaperDexItem">
                <img src={dex.icon} alt="dex-icon" />
                <span>{dex.label}</span>
                <img src={arrow_ic} alt="arrow_ic" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: calc(1320px + 48px);
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 283.59px;
  margin: 0 auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  .Buy-Head {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
    margin: 48px 0;
  }

  .Buy-HeadDetails {
    display: flex;
    align-items: center;
    gap: 8px;

    & > img {
      width: 48px;
      height: 48px;
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      h1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 150%; /* 36px */
      }

      p {
        color: var(--Text-Text_Secondary, #828899);
        font-size: 14px;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
      }
    }
    @media screen and (max-width: 1023px) {
    }

    @media screen and (max-width: 767px) {
      align-items: flex-start;

      & > img {
        margin-top: 8px;
      }
    }
  }

  .Buy-Links {
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 1023px) {
    }

    @media screen and (max-width: 767px) {
      align-self: flex-start;
      margin-left: 56px;
    }
  }

  .Buy-Link {
    color: var(--Functional-Green, #90fb75);
    font-size: 14px;
    font-weight: 700;
    line-height: 140%; /* 19.6px */

    display: flex;
    align-items: center;
    gap: 4px;

    background-color: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;

    &:hover {
      color: #2ecf06;
    }
  }

  .Buy-Papers {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    margin-top: 48px;
  }

  .Buy-Paper {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
    flex: 1 0 0;

    border-radius: 16px;
    background: var(--Nature-1, #18191a);

    h3 {
      font-size: 16px;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
    }
  }

  .Buy-PaperDex {
    border-radius: 16px;
    border: 1px solid var(--Border, rgba(239, 236, 235, 0.08));
    overflow: hidden;
  }

  .Buy-PaperDexItem {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    text-decoration: none;

    &:hover {
      background-color: #ffffff1a;
    }

    img[alt="dex-icon"] {
      width: 36px;
      height: 36px;
    }

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      margin-left: 12px;
      margin-right: auto;
    }

    & + .Buy-PaperDexItem {
      border-top: 1px solid var(--Border, rgba(239, 236, 235, 0.08));
    }
  }

  @media screen and (max-width: 1023px) {
  }

  @media screen and (max-width: 767px) {
    .Buy-Head {
      flex-direction: column;
      gap: 16px;
    }

    .Buy-Papers {
      flex-direction: column;
      align-items: stretch;
    }
  }
`;
export default BuyGum;
