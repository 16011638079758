import TwitterXIcon from "components/Icon/TwitterXIcon";
import {
  getReferralCodeTradeUrl,
  getTwitterShareUrl,
  isRecentReferralCodeNotExpired,
} from "components/Referrals/referralsHelper";
import { FANTOM, SUPPORTED_V2_CHAINS } from "config/chains";
import { useThemeContext } from "contexts/ThemeProvider";
import IcCoppyLight from "img/coppy-light.svg";
import IcCoppy from "img/ic_copy.svg";
import { helperToast } from "lib/helperToast";
import { USD_DECIMALS } from "lib/legacy";
import { bigNumberify, formatAmount, parseValue } from "lib/numbers";
import { useMemo } from "react";
import { useCopyToClipboard, useMedia } from "react-use";
import styled from "styled-components";
const MyRefCodes = (props) => {
  const { referrerTotalStats, recentlyAddedCodes, userReferralStats, firstCode, chainId } = props;
  const allReferralCodes = referrerTotalStats?.map((c) => c.referralCode.trim()) || [];
  const theme = useThemeContext();
  const [, copyToClipboard] = useCopyToClipboard();
  const finalAffiliatesTotalStats = useMemo(
    () =>
      recentlyAddedCodes?.filter(isRecentReferralCodeNotExpired).reduce((acc, cv) => {
        if (!allReferralCodes.includes(cv.referralCode)) {
          acc = acc.concat(cv);
        }
        return acc;
      }, referrerTotalStats),
    [allReferralCodes, referrerTotalStats, recentlyAddedCodes]
  );
  const isMobile = useMedia("(max-width: 700px)");
  return (
    <div className="payout-content-container">
      {!isMobile && (
        <div className="table-content table-header">
          <div className="line-content">Referral Code</div>
          <div className="line-content">Total Volume</div>
          <div className="line-content">Traders Referred</div>
          <div className="line-content">Total Earned</div>
          <div className="table-actions" />
        </div>
      )}
      {!isMobile && (
        <div className="table-body">
          {finalAffiliatesTotalStats &&
            finalAffiliatesTotalStats.length > 0 &&
            finalAffiliatesTotalStats.map((item, index) => {
              let referrerRebate = bigNumberify(0);
              // console.log("????", firstCode, item.referralCode);
              const isLast = firstCode && firstCode === item.referralCode && SUPPORTED_V2_CHAINS.includes(chainId);
              if (item && item.totalRebateUsd && item.discountUsd) {
                referrerRebate = item.totalRebateUsd.sub(item.discountUsd);
              }
              const earn = isLast
                ? +(Number(userReferralStats?.amount) + Number(formatAmount(referrerRebate, USD_DECIMALS, 6, false)))
                : formatAmount(referrerRebate, USD_DECIMALS, 6, false);
              console.log({ totalEarnRefCodetable: earn });
              const traderReferred = isLast
                ? item.registeredReferralsCount + userReferralStats?.totalReferrals
                : item.registeredReferralsCount;
              const totalVolume = isLast
                ? +(
                    Number(userReferralStats?.volume) + Number(formatAmount(item.volume, USD_DECIMALS, 6, false))
                  ).toFixed(2)
                : formatAmount(item.volume, USD_DECIMALS, 6, false);
              return (
                <div className="table-content" key={item.referralCode}>
                  <div className="line-content">{item.referralCode}</div>
                  <div className="line-content">${formatAmount(parseValue(totalVolume, 30), 30, 2, true)}</div>
                  <div className="line-content">{traderReferred}</div>
                  <div className="line-content">${formatAmount(parseValue(earn, 30), 30, 4, true)}</div>
                  <div className="table-actions">
                    <div
                      className="ic_container"
                      onClick={() => {
                        copyToClipboard(getReferralCodeTradeUrl(item.referralCode));
                        helperToast.success("Referral link copied to your clipboard");
                      }}
                    >
                      <img alt="" src={IcCoppy} />
                    </div>
                    <a
                      className="ic_container"
                      href={getTwitterShareUrl(item.referralCode)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TwitterXIcon color={theme?.isLightTheme} />
                      {/* <img alt="" src={theme?.isLightTheme ? IcXLightGreen : IcX} /> */}
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
      )}

      {isMobile && (
        <div className="mobile-ref-container">
          {finalAffiliatesTotalStats &&
            finalAffiliatesTotalStats.length > 0 &&
            finalAffiliatesTotalStats.map((item, index) => {
              let referrerRebate = bigNumberify(0);
              const isLast = firstCode && firstCode === item.referralCode && chainId === FANTOM;
              if (item && item.totalRebateUsd && item.discountUsd) {
                referrerRebate = item.totalRebateUsd.sub(item.discountUsd);
              }
              const earn = isLast
                ? +(
                    Number(userReferralStats?.amount) + Number(formatAmount(referrerRebate, USD_DECIMALS, 6, false))
                  ).toFixed(4)
                : formatAmount(referrerRebate, USD_DECIMALS, 6, false);
              const traderReferred = isLast
                ? item.registeredReferralsCount + userReferralStats?.totalReferrals
                : item.registeredReferralsCount;
              const totalVolume = isLast
                ? +(
                    Number(userReferralStats?.volume) + Number(formatAmount(item.volume, USD_DECIMALS, 6, false))
                  ).toFixed(2)
                : formatAmount(item.volume, USD_DECIMALS, 6, false);
              return (
                <div className="ref-as-container" key={item.referralCode}>
                  <Wrap>
                    <div>
                      <div>{item.referralCode}</div>
                      <div className="icons">
                        <div
                          className="ic_container"
                          onClick={() => {
                            copyToClipboard(getReferralCodeTradeUrl(item.referralCode));
                            helperToast.success("Referral link copied to your clipboard");
                          }}
                        >
                          <img alt="" src={theme?.isLightTheme ? IcCoppyLight : IcCoppy} />
                        </div>
                        <a
                          className="ic_container"
                          href={getTwitterShareUrl(item.referralCode)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TwitterXIcon color={theme?.isLightTheme ? "#fff" : "#fff"} />
                        </a>
                      </div>{" "}
                    </div>

                    <div className="divider" />
                  </Wrap>
                  <div className="ref-as-line">
                    <div className="line-title">Referral Code</div>
                    <div className="line-value">{item.referralCode}</div>
                  </div>

                  <div className="ref-as-line">
                    <div className="line-title">Total Volume</div>
                    <div className="line-value">${formatAmount(parseValue(totalVolume, 30), 30, 2, true)}</div>
                  </div>
                  <div className="ref-as-line">
                    <div className="line-title">Traders Referred</div>
                    <div className="line-value">{traderReferred}</div>
                  </div>
                  <div className="ref-as-line">
                    <div className="line-title">Total Earned</div>
                    <div className="line-value">${formatAmount(parseValue(earn, 30), 30, 4, true)}</div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {(!finalAffiliatesTotalStats || (finalAffiliatesTotalStats && finalAffiliatesTotalStats.length == 0)) && (
        <div className="no-data-record">No referral codes</div>
      )}
    </div>
  );
};
const Wrap = styled.div`
  > div:first-child {
    display: flex;
    justify-content: space-between;
  }

  .icons {
    display: flex;
    gap: 8px;
  }
  .divider {
    background: rgba(55, 63, 92, 0.5);
    margin: 12px 0 8px 0;
    height: 1px;
    width: calc(100% + 32px);
    margin-left: -16px;
  }
`;
export default MyRefCodes;
