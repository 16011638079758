import React, { useEffect, useState, useMemo } from "react";
import cx from "classnames";
import { Trans, t, select } from "@lingui/macro";
import Tooltip from "../Tooltip/Tooltip";
import PositionSeller from "./PositionSeller";
import PositionEditor from "./PositionEditor";
import OrdersToa from "./OrdersToa";
import SettingsManager from "abis/SettingsManager.json";
import { ImSpinner2 } from "react-icons/im";
import ic_chain from "img/trade/chains.svg";
import ic_chainLight from "img/trade/chains_light.svg";
import { getTokenSymbolFromString } from "domain/tokens";
import styled, { css } from "styled-components";

import {
  getLiquidationPrice,
  getLeverage,
  getOrderError,
  USD_DECIMALS,
  FUNDING_RATE_PRECISION,
  SWAP,
  LONG,
  SHORT,
  INCREASE,
  DECREASE,
  getUnrealizedPnl,
  getLiqRisk,
  importImage,
  getCompletePosition,
} from "lib/legacy";
import PositionShare from "./PositionShare";
import PositionDropdown from "./PositionDropdown";
import StatsTooltipRow from "../StatsTooltip/StatsTooltipRow";
import { helperToast } from "lib/helperToast";
import { getUsd } from "domain/tokens/utils";
import { bigNumberify, expandDecimals, formatAmount, limitDecimals, parseValue } from "lib/numbers";
import { AiOutlineEdit } from "react-icons/ai";
import useAccountType, { AccountType } from "lib/wallets/useAccountType";
import useUserPosition, { DEFAULT_PAGE_SIZE_POSITIONS } from "hooks/useUserPosition";
import useUserOrder from "hooks/useUserOrder";
import { BigNumber } from "ethers";
import useMarketAssets from "hooks/useMarketAssets";
import PositionDetails from "./PositionDetails";
import AddTPSL from "./AddTPSL";
import EditLeverage from "./EditLeverage";
import AddToPosition from "./AddToPosition";
import AddCollateral from "./AddCollateral";
import AddTrailingStop from "./AddTrailingStop";
import PositionClosed from "./PositionClosed";
import ClosePosition from "./ClosePosition";
import { contractFetcher } from "lib/contracts";
import useSWR from "swr";
import { addOrRemoveCollateral, addPosition, addTPSL, addTrailingStop, decreasePosition } from "domain/legacy";
import { getContract } from "config/contracts";
import { ARBITRUM } from "config/chains";
import { commify } from "@ethersproject/units";
import moment from "moment";
import EditTakeProfit from "./EditTakeProfit";
import EditStopLoss from "./EditStopLoss";
import PaginationV2 from "pages/Analytics/components/PaginationV2";
import useUserTradeInfo from "hooks/useUserTradeInfo";
import { useWeb3React } from "@web3-react/core";
import { renderRisk } from "lib/helper";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useThemeContext } from "contexts/ThemeProvider";
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "#FFA722" }} spin />;
// let lengthInPositionNew = 0;
// let isSetLength = false
export default function PositionsList(props) {
  const {
    active,
    account,
    library,
    setPendingTxns,
    setListSection,
    chainId,
    marketAsssets,
    marketTokensInfo,
    userTokenBalances,
    myOrders,
    setPosOrders,
    positionsOnChain,
    loading,
    positionsDataIsLoading,
    positionsApi,
    justOpenPositions,
    closePositionTempt,
    positions,
    savedSlippageAmount,
  } = props;

  // console.log(positions,'--positions---');
  const [positionToEditKey, setPositionToEditKey] = useState(undefined);
  const [positionToSellKey, setPositionToSellKey] = useState(undefined);
  const [selectedPosition, setSelectedPosition] = useState(undefined);
  const [positionToShare, setPositionToShare] = useState(null);
  const [isPositionEditorVisible, setIsPositionEditorVisible] = useState(undefined);
  const [isTPSLModalOPen, setIsTPSLModalOPen] = useState(false);
  const [isAddToPosition, setIsAddToPosition] = useState(false);
  const [isAddCollateralModalOpen, setIsAddCollateralModalOpen] = useState(false);
  const [isEditLeverageModalOPen, setIsEditLeverageModalOPen] = useState(false);
  const [isPositionSellerVisible, setIsPositionSellerVisible] = useState(undefined);
  const [isPositionDetailsVisible, setIsPositionDetailsVisible] = useState(undefined);
  const [collateralTokenAddress, setCollateralTokenAddress] = useState(undefined);
  const [isPositionShareModalOpen, setIsPositionShareModalOpen] = useState(false);
  const [isTrailingStopModalOpen, setIsTrailingStopModalOpen] = useState(false);
  const [isEditTakeProfitModalOpen, setIsEditTakeProfitModalOpen] = useState(false);
  const [isEditStopLossModalOpen, setIsEditStopLossModalOpen] = useState(false);
  const [ordersToaOpen, setOrdersToaOpen] = useState(false);
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false);
  // console.log("????", savedSlippageAmount);
  //Handle pagination
  const theme = useThemeContext();
  const [page, setPage] = useState(1);

  const filterOrders = useMemo(() => {
    const startIndex = (page - 1) * DEFAULT_PAGE_SIZE_POSITIONS;
    const endIndex = page * DEFAULT_PAGE_SIZE_POSITIONS;
    return positions?.slice(startIndex, endIndex) || [];
  }, [positions, page]);

  const accountType = useAccountType();
  const [isSectionConfirming, setIsSectionConfirming] = useState(false);

  const handleOpenTPSL = (position) => {
    setSelectedPosition(position);
    setIsTPSLModalOPen(true);
  };

  const editPosition = (position) => {
    setCollateralTokenAddress(position);
    setPositionToEditKey(position);
    setIsPositionEditorVisible(true);
  };
  const settingsManagerAddress = getContract(chainId, "SettingsManager");
  const { data: marketOrderGasFee } = useSWR(
    ["ExChange:marketOrderGasFee", chainId, settingsManagerAddress, "marketOrderGasFee"],
    {
      fetcher: contractFetcher(library, SettingsManager),
    }
  );
  const { data: triggerGasFee } = useSWR(["ExChange:triggerGasFee", chainId, settingsManagerAddress, "triggerGasFee"], {
    fetcher: contractFetcher(library, SettingsManager),
  });
  const sellPosition = (position) => {
    setPositionToSellKey(position);
    setIsPositionSellerVisible(true);
    setIsHigherSlippageAllowed(false);
  };
  const getPositionNetValue = (position, markPrice) => {
    const { averagePrice, isLong, size } = position;

    const pnlWithoutFee = isLong
      ? markPrice.sub(parseValue(averagePrice, 30)).mul(parseValue(size, 30)).div(parseValue(averagePrice, 30))
      : parseValue(averagePrice, 30).sub(markPrice).mul(parseValue(size, 30)).div(parseValue(averagePrice, 30));

    const accruedFees =
      parseValue(position.accruedPositionFee, 30)
        .add(parseValue(position.accruedFundingFee, 30))
        .add(parseValue(position.accruedBorrowFee, 30)) || BigNumber.from(0);
    const accruedFeesWithoutPosition = parseValue(position.accruedFundingFee, 30).add(
      parseValue(position.accruedBorrowFee, 30)
    );
    const pnlWithFee = pnlWithoutFee.sub(accruedFees);
    const percentPnl = pnlWithFee.mul(parseValue(100, 30)).div(parseValue(position.collateral, 30));
    const percentPnlWithoutFee = pnlWithoutFee.mul(parseValue(100, 30)).div(parseValue(position.collateral, 30));
    const pnlAfterPosition = pnlWithoutFee.sub(accruedFeesWithoutPosition);
    const pnl = pnlWithFee;
    return {
      percentPnl,
      pnl,
      pnlWithoutFee,
      pnlAfterPosition,
      percentPnlWithoutFee,
      netValue: pnlWithFee.add(parseValue(position.collateral, 30)),
      netValueWithoutFee: pnlWithoutFee.add(parseValue(position.collateral, 30)),
      prefix: pnl?.gt(0) ? "+" : pnl?.lt(0) ? "-" : "",
      prefixWithoutFee: pnlWithoutFee?.gt(0) ? "+" : pnlWithoutFee?.lt(0) ? "-" : "",
    };
  };
  const handleSelectedPosition = (position) => {
    setSelectedPosition(position);
    setIsPositionDetailsVisible(true);
  };
  const handleClosePosition = (position, size) => {
    const sizeAmount = size;
    const isLong = position.isLong;
    const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
    const markPrice = BigNumber.from((isLong ? currentMarket?.askPrice : currentMarket?.bidPrice) || 0);

    const slippageStorage = window.localStorage.getItem(`[${chainId},"Exchange-swap-slippage-basis-points-v3"]`);
    const allowPrice = position.isLong
      ? markPrice.sub(markPrice.mul(parseValue(slippageStorage || 50, 30)).div(parseValue(10000, 30)))
      : markPrice.add(markPrice.mul(parseValue(slippageStorage || 50, 30)).div(parseValue(10000, 30)));
    const posId = position.posId;
    setIsSectionConfirming(true);
    decreasePosition(chainId, library, sizeAmount, allowPrice, posId, {
      value: marketOrderGasFee,
      setPendingTxns,
      sentMsg: `Close position submitted!`,
      failMsg: `Closed position failed.`,
      successMsg: "Closed position successfully!",
      // for Arbitrum, sometimes the successMsg shows after the position has already been executed
      // hide the success message for Arbitrum as a workaround
      hideSuccessMsg: true,
    })
      .then(async (res) => {
        // setIsConfirming(false);
        setIsPositionSellerVisible(false);
        setIsPositionDetailsVisible(false);
        await res.wait();
        if (sizeAmount.eq(parseValue(position.size, 30))) {
          closePositionTempt.push({ ...position });
        }
      })
      .finally(() => {
        // setIsSubmitting(false);
        // setIsPendingConfirmation(false);
        setIsSectionConfirming(false);
      });
  };
  const handleEditCollateral = (position, collateral) => {
    const posId = position.posId;
    setIsSectionConfirming(true);
    addOrRemoveCollateral(chainId, library, false, posId, collateral, {
      value: marketOrderGasFee,
      setPendingTxns,
      sentMsg: `Edit leverage submitted.`,
      failMsg: `Edit leverage failed.`,
      successMsg: "Edited leverage successfully",
      // for Arbitrum, sometimes the successMsg shows after the position has already been executed
      // hide the success message for Arbitrum as a workaround
      hideSuccessMsg: chainId === ARBITRUM,
    })
      .then(async () => {
        // setIsConfirming(false);
        setIsEditLeverageModalOPen(false);
      })
      .finally(() => {
        // setIsSubmitting(false);
        // setIsPendingConfirmation(false);
        setIsSectionConfirming(false);
      });
  };
  const handleAddToPostion = (position, collateralDelta, sizeDelta) => {
    const posId = position.posId;
    const isLong = position.isLong;
    const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
    const markPrice = BigNumber.from((isLong ? currentMarket?.askPrice : currentMarket.bidPrice) || 0);
    const slippageStorage = window.localStorage.getItem(`[${chainId},"Exchange-swap-slippage-basis-points-v3"]`);
    const allowPrice = position.isLong
      ? markPrice.add(markPrice.mul(parseValue(slippageStorage || 50, 30)).div(parseValue(10000, 30)))
      : markPrice.sub(markPrice.mul(parseValue(slippageStorage || 50, 30)).div(parseValue(10000, 30)));
    // console.log("??????",{chainId, library, posId, collateralDelta, sizeDelta, allowPrice});
    setIsSectionConfirming(true);
    addPosition(chainId, library, posId, collateralDelta, sizeDelta, allowPrice, {
      value: marketOrderGasFee,
      setPendingTxns,
      sentMsg: `Add to position submitted!`,
      failMsg: `Add to positon failed.`,
      successMsg: "Added to position successfully!",
      // for Arbitrum, sometimes the successMsg shows after the position has already been executed
      // hide the success message for Arbitrum as a workaround
      hideSuccessMsg: chainId === ARBITRUM,
    })
      .then(async () => {
        // setIsConfirming(false);
        setIsAddToPosition(false);
      })
      .finally(() => {
        // setIsSubmitting(false);
        // setIsPendingConfirmation(false);
        setIsSectionConfirming(false);
      });
  };
  const handleEditPosition = (position, amount) => {
    const sizeAmount = parseValue(amount, 30);
    const posId = position.posId;
    setIsSectionConfirming(true);
    addOrRemoveCollateral(chainId, library, true, posId, sizeAmount, {
      value: marketOrderGasFee,
      setPendingTxns,
      sentMsg: `Edit collateral submitted.`,
      failMsg: `Edit collateral failed.`,
      successMsg: "Edited collateral successfully",
      // for Arbitrum, sometimes the successMsg shows after the position has already been executed
      // hide the success message for Arbitrum as a workaround
      hideSuccessMsg: chainId === ARBITRUM,
    })
      .then(async () => {
        // setIsConfirming(false);
        setIsAddCollateralModalOpen(false);
      })
      .finally(() => {
        // setIsSubmitting(false);
        // setIsPendingConfirmation(false);
        setIsSectionConfirming(false);
      });
  };
  const handleAddTPSLPosition = (position, isTPs, prices, amountPercents) => {
    const posId = position.posId;
    setIsSectionConfirming(true);
    addTPSL(chainId, library, posId, isTPs, prices, amountPercents, {
      value: BigNumber.from(isTPs.length).mul(triggerGasFee),
      setPendingTxns,
      sentMsg: `Add TP/SL submitted.`,
      failMsg: `Add TP/SL failed`,
      successMsg: "Added TP/SL successfully",
      hideSuccessMsg: chainId === ARBITRUM,
    })
      .then(async () => {
        // setIsConfirming(false);
        setIsTPSLModalOPen(false);
      })
      .finally(() => {
        // setIsSubmitting(false);
        // setIsPendingConfirmation(false);
        setIsSectionConfirming(false);
      });
  };
  const handleAddTrailingStop = (posId, params) => {
    // console.log("?????", { posId, params });
    setIsSectionConfirming(true);
    addTrailingStop(chainId, library, posId, params, {
      value: triggerGasFee,
      setPendingTxns,
      sentMsg: `Add trailing stop submitted.`,
      failMsg: `Add trailing stop failed`,
      successMsg: "Added trailing stop successfully",
      hideSuccessMsg: chainId === ARBITRUM,
    })
      .then(async () => {
        // setIsConfirming(false);
        setIsTrailingStopModalOpen(false);
      })
      .finally(() => {
        // setIsSubmitting(false);
        // setIsPendingConfirmation(false);
        setIsSectionConfirming(false);
      });
  };

  // console.log("????", { positions, loading, positionsDataIsLoading });
  return (
    <div className="PositionsList">
      {isPositionShareModalOpen && (
        <PositionShare
          setIsPositionShareModalOpen={setIsPositionShareModalOpen}
          isPositionShareModalOpen={isPositionShareModalOpen}
          selectedPosition={positionToShare}
          chainId={chainId}
          account={account}
          marketTokensInfo={marketTokensInfo}
          positions={positions}
        />
      )}
      {isTPSLModalOPen && (
        <AddTPSL
          setIsVisible={setIsTPSLModalOPen}
          isVisible={isTPSLModalOPen}
          position={selectedPosition}
          chainId={chainId}
          library={library}
          onConfirm={handleAddTPSLPosition}
          marketTokensInfo={marketTokensInfo}
          isConfirm={isSectionConfirming}
        />
      )}
      {isTrailingStopModalOpen && (
        <AddTrailingStop
          setIsVisible={setIsTrailingStopModalOpen}
          isVisible={isTrailingStopModalOpen}
          position={selectedPosition}
          chainId={chainId}
          marketTokensInfo={marketTokensInfo}
          onConfirm={handleAddTrailingStop}
          isConfirm={isSectionConfirming}
        />
      )}
      {isEditLeverageModalOPen && (
        <EditLeverage
          setIsVisible={setIsEditLeverageModalOPen}
          isVisible={isEditLeverageModalOPen}
          position={selectedPosition}
          chainId={chainId}
          library={library}
          onConfirm={handleEditCollateral}
          marketTokensInfo={marketTokensInfo}
          isConfirm={isSectionConfirming}
        />
      )}
      {isPositionSellerVisible && (
        <ClosePosition
          isVisible={isPositionSellerVisible}
          setIsVisible={setIsPositionSellerVisible}
          selectedPosition={positionToSellKey}
          chainId={chainId}
          library={library}
          handleClosePosition={handleClosePosition}
          marketTokensInfo={marketTokensInfo}
          positions={positions}
          isConfirm={isSectionConfirming}
        />
      )}
      {isPositionDetailsVisible && (
        <PositionDetails
          isVisible={isPositionDetailsVisible}
          setIsVisible={setIsPositionDetailsVisible}
          selectedPosition={selectedPosition}
          positions={positions}
          chainId={chainId}
          onConfirm={handleClosePosition}
          marketTokensInfo={marketTokensInfo}
          isConfirm={isSectionConfirming}
        />
      )}
      {isAddToPosition && (
        <AddToPosition
          isVisible={isAddToPosition}
          setIsVisible={setIsAddToPosition}
          position={selectedPosition}
          chainId={chainId}
          userTokenBalances={userTokenBalances}
          onConfirm={handleAddToPostion}
          marketTokensInfo={marketTokensInfo}
          isConfirm={isSectionConfirming}
        />
      )}
      {isAddCollateralModalOpen && (
        <AddCollateral
          isVisible={isAddCollateralModalOpen}
          setIsVisible={setIsAddCollateralModalOpen}
          position={selectedPosition}
          chainId={chainId}
          userTokenBalances={userTokenBalances}
          handleEditPosition={handleEditPosition}
          marketTokensInfo={marketTokensInfo}
          isConfirm={isSectionConfirming}
        />
      )}
      {isEditTakeProfitModalOpen && (
        <EditTakeProfit
          isVisible={isEditTakeProfitModalOpen}
          position={selectedPosition}
          setIsVisible={setIsEditTakeProfitModalOpen}
          chainId={chainId}
          marketTokensInfo={marketTokensInfo}
          isConfirm={isSectionConfirming}
        />
      )}
      {isEditStopLossModalOpen && (
        <EditStopLoss
          isVisible={isEditStopLossModalOpen}
          position={selectedPosition}
          setIsVisible={setIsEditStopLossModalOpen}
          chainId={chainId}
          marketTokensInfo={marketTokensInfo}
          isConfirm={isSectionConfirming}
        />
      )}
      {positions && (!loading || !positionsDataIsLoading) && (
        <div className="Exchange-list small">
          <div>
            {positions.length === 0 && loading && positionsDataIsLoading && (
              <div className="Exchange-empty-positions-list-note App-card">
                <div className="skeleton-box" style={{ height: "25px" }} />
              </div>
            )}
            {positions.length === 0 && <div className="no-data-record">No open positions</div>}
            {filterOrders.map((position, index) => {
              const dateFormat = "YYYY-MM-DD";
              const timeFormat = "HH:mm:ss";
              const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
              const accruedFees =
                parseValue(position.accruedPositionFee, 30)
                  .add(parseValue(position.accruedFundingFee, 30))
                  .add(parseValue(position.accruedBorrowFee, 30)) || BigNumber.from(0);
              const avgEntryPrice = parseValue(position.averagePrice, 30);
              const markPrice = BigNumber.from(
                position?.isLong ? currentMarket?.askPrice || 0 : currentMarket?.bidPrice || 0
              );
              const posOrders = myOrders.filter((x) => x.posId === position.posId);
              const paidFees =
                parseValue(position.paidPositionFee, 30)
                  .add(parseValue(position.paidBorrowFee, 30))
                  .add(parseValue(position.paidFundingFee, 30)) || BigNumber.from(0);
              const posQty = parseValue(position.size, 30)
                .mul(parseValue(1, 30))
                .div(parseValue(position.averagePrice, 30));
              const positionNetValue = getPositionNetValue(position, markPrice);
              const uPnL =
                getUnrealizedPnl(accruedFees, markPrice, avgEntryPrice, position.isLong, posQty)?.div(
                  parseValue(1, 30)
                ) || BigNumber.from(0);
              const collateral = parseValue(position.collateral, 30);
              const LiqRisk = getLiqRisk(positionNetValue?.pnl, collateral);
              const rPnL = positionNetValue?.pnlWithoutFee.sub(paidFees || 0);
              const completePosition = {
                positionNetValue,
                markPrice: markPrice,
                ...position,
                symbol: getTokenSymbolFromString(marketAsssets?.[position.tokenId]?.symbol),
                quantity: posQty,
                currentMarket,
                accruedFees,
                uPnL,
                LiqRisk,
                paidFees,
                rPnL,
                posOrders,
              };
              return (
                <div key={position.key} className="App-card">
                  <div className="App-card-title" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
                    <span className="Exchange-list-title">
                      <img src={importImage("ic_" + completePosition?.symbol?.toLowerCase() + "_24.svg")} alt="" />{" "}
                      {completePosition.symbol}/USD <span className="muted">#{position.posId}</span>
                    </span>
                  </div>
                  <div className="App-card-divider" />
                  <div className="App-card-content app-card-position">
                    <div className="leverage-pad" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
                      <div>
                        <span
                          className={cx({ positive: position.isLong, negative: !position.isLong })}
                          style={{ color: position.isLong ? "#90FB75" : "#F6475D" }}
                        >
                          {position.isLong ? `LONG` : `SHORT`}
                        </span>
                      </div>
                      {position.leverage && (
                        <div
                          className="leverage-box"
                          style={{
                            color: "#FFC700",
                            backgroundColor: "rgba(255, 199, 0, 0.20)",
                            height: 16,
                            display: "inline-block",
                            opacity: 1,
                            borderRadius: "100px",
                            padding: "0 4px",
                            fontSize: "12px",
                            lineHeight: "15.6px",
                            fontWeight: 400,
                            marginLeft: "4px",
                          }}
                        >
                          {formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X
                        </div>
                      )}
                    </div>
                    <div className="App-card-row" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
                      <div className="label">
                        <span>Net/PnL</span>
                      </div>
                      {position.liquidationPrice ? (
                        <div>
                          <div>
                            {positionNetValue.netValue.lt(0) ? "-" : ""}$
                            {positionNetValue.netValue
                              ? formatAmount(positionNetValue.netValue, 30, 2, true).replace("-", "")
                              : "--"}
                          </div>
                          <div
                            className={cx("Exchange-list-info-label bold", {
                              positive: positionNetValue?.pnl?.gt(0),
                              negative: positionNetValue?.pnl.lt(0),
                              muted: positionNetValue?.pnl.eq(0),
                            })}
                          >
                            <span
                              className={cx("", {
                                positive: positionNetValue?.pnl?.gt(0),
                                negative: positionNetValue?.pnl.lt(0),
                                muted: positionNetValue?.pnl.eq(0),
                              })}
                            >
                              {/* {positionNetValue.prefix ? (
                                <img
                                  src={positionNetValue.prefix === "-" ? ic_ArrowDown : ic_ArrowUp}
                                  alt="tk"
                                  style={{ width: "16px", height: "16px" }}
                                />
                              ) : (
                                ""
                              )} */}
                              {`$${formatAmount(positionNetValue?.pnl, 30, 2, true)}`}{" "}
                              <span
                                className={cx("", {
                                  positive: positionNetValue?.pnl?.gt(0),
                                  negative: positionNetValue?.pnl.lt(0),
                                  muted: positionNetValue?.pnl.eq(0),
                                })}
                              >
                                ({formatAmount(positionNetValue?.percentPnl, 30, 2, true).replace("-", "")}
                                %)
                              </span>
                            </span>
                            <Tooltip
                              handle={``}
                              position={"right-bottom"}
                              styled={{ position: "relative", top: "3px" }}
                              handleClassName={cx("plain", { negative: position.hasLowCollateral })}
                              renderContent={() => {
                                return (
                                  <>
                                    <StatsTooltipRow
                                      label={`PnL without fees`}
                                      value={`${positionNetValue.prefixWithoutFee}$${formatAmount(
                                        positionNetValue.pnlWithoutFee,
                                        USD_DECIMALS,
                                        2,
                                        true
                                      ).replace("-", "")}`}
                                      showDollar={false}
                                    />
                                    <StatsTooltipRow
                                      label={`Accrued fees`}
                                      showDollar={false}
                                      value={`${accruedFees.lt(0) ? "-" : ""}$${formatAmount(
                                        accruedFees,
                                        USD_DECIMALS,
                                        2,
                                        true
                                      ).replace("-", "")}`}
                                    />
                                  </>
                                );
                              }}
                            />
                            {/* <span
                            style={{ marginLeft: "4px" }}
                            className={cx("Exchange-list-info-label", {
                              positive: positionNetValue?.pnl?.gt(0),
                              negative: positionNetValue?.pnl.lt(0),
                              muted: positionNetValue?.pnl.eq(0),
                            })}
                          >
                            ({positionNetValue.prefix}
                            {formatAmount(positionNetValue?.percentPnl, 30, 2, true).replace(
                              "-",
                              "",
                            )}
                            %)
                          </span> */}
                          </div>
                        </div>
                      ) : (
                        <div>--</div>
                      )}
                    </div>
                    <div className="App-card-row" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
                      <div className="label">
                        <span>Pos.Size</span>
                      </div>
                      <div className="position-list-collateral">
                        <div>
                          ${formatAmount(parseValue(position?.size, 30), 30, 2, true)}
                          <div className="muted fz-12 bold">
                            {formatAmount(posQty, 30, 4, true)}{" "}
                            {getTokenSymbolFromString(marketAsssets?.[position.tokenId]?.symbol)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="App-card-row" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
                      <div className="label">
                        <span>Liq.Risk</span>
                      </div>
                      <div>
                        <div>{renderRisk(LiqRisk, false)}</div>
                        {/* <div className="position-list-risk">
                          {formatAmount(LiqRisk, 30, LiqRisk.eq(0) ? 0 : 2, true).replace("-", "")}%
                        </div> */}
                      </div>
                    </div>
                    <div className="App-card-row" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
                      <div className="label">
                        <span>Liq./Mark Price</span>
                      </div>
                      <div>
                        <div>
                          $
                          {formatAmount(
                            parseValue(position.liquidationPrice, 30),
                            30,
                            currentMarket?.decimals || 2,
                            true
                          )}
                        </div>
                        <div className="muted fz-12 bold">
                          ${formatAmount(markPrice, 30, currentMarket?.decimals || 2, true)}
                        </div>
                      </div>
                    </div>
                    <div className="App-card-row" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
                      <div className="label">
                        <span>Avg. Entry Price</span>
                      </div>
                      <div>
                        ${formatAmount(parseValue(position.averagePrice, 30), 30, currentMarket?.decimals || 2, true)}
                      </div>
                    </div>
                    <div className="App-card-row" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
                      <div className="label">
                        <span>Created</span>
                      </div>
                      <div>
                        {moment(new Date(position.createdAt * 1000)).format(dateFormat)}
                        <div className="muted fz-12 bold">
                          {moment(new Date(position.createdAt * 1000)).format(timeFormat)}
                        </div>
                      </div>
                    </div>
                    {posOrders.length > 0 && (
                      <div className="App-card-row">
                        <div className="label">
                          <span>Linked Orders</span>
                        </div>
                        <div
                          className="chains-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            setListSection("Orders");
                            setPosOrders(posOrders);
                          }}
                        >
                          <span>{posOrders.length}</span>
                          <img src={theme?.isLightTheme ? ic_chainLight : ic_chain} alt="" />
                        </div>
                      </div>
                    )}
                  </div>
                  <div style={{ marginTop: "1.3rem" }} />
                  {position.liquidationPrice ? (
                    <div className="App-card-options">
                      <button
                        className="App-button-option App-card-option"
                        onClick={() => handleSelectedPosition(completePosition)}
                        // disabled={position.size == 0}
                      >
                        <span>View</span>
                      </button>
                      <button
                        className="App-button-option App-card-option border-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          sellPosition(completePosition);
                        }}
                        disabled={position.size == 0 || (position && !position.liquidationPrice)}
                      >
                        <span>Close</span>
                      </button>
                      <PositionDropdown
                        position={position}
                        handleEditCollateral={() => {
                          editPosition(completePosition);
                        }}
                        handleEditLeverage={() => {
                          setSelectedPosition(completePosition);
                          setIsEditLeverageModalOPen(true);
                        }}
                        handleShare={() => {
                          setPositionToShare(completePosition);
                          setIsPositionShareModalOpen(true);
                        }}
                        handleAddTPSL={() => {
                          setSelectedPosition(completePosition);
                          setIsTPSLModalOPen(true);
                        }}
                        handlAddToPosition={() => {
                          setSelectedPosition(completePosition);
                          setIsAddToPosition(true);
                        }}
                        handlAddCollateral={() => {
                          setSelectedPosition(completePosition);
                          setIsAddCollateralModalOpen(true);
                        }}
                        handlAddTrailingStop={() => {
                          setSelectedPosition(completePosition);
                          setIsTrailingStopModalOpen(true);
                        }}
                        handleMarketSelect={() => {
                          // onPositionClick(position);
                        }}
                        handleEditTakeProfit={() => {
                          setSelectedPosition(completePosition);
                          setIsEditTakeProfitModalOpen(true);
                        }}
                        handleEditStopLoss={() => {
                          setSelectedPosition(completePosition);
                          setIsEditStopLossModalOpen(true);
                        }}
                      />
                    </div>
                  ) : (
                    <div className="processing">
                      Processing <Spin indicator={antIcon} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {loading && positionsDataIsLoading && account && (
        <>
          <div className="skeleton-box" style={{ height: "56.19px" }} />
          <div className="skeleton-box" style={{ height: "56.19px", marginTop: 8 }} />
          <div className="skeleton-box" style={{ height: "56.19px", marginTop: 8 }} />
        </>
      )}
      {(!loading || !positionsDataIsLoading) && (
        <table className={`Exchange-list large App-box ${positions.length === 0 ? "no-array-length" : ""}`}>
          <tbody>
            {positions.length > 0 && (
              <tr className="Exchange-list-header">
                <th>
                  <span>Position</span>
                </th>
                <th>
                  <span>Net/PnL</span>
                </th>
                <th>
                  <span>Pos.Size</span>
                </th>
                <th>
                  <span>Liq.Risk</span>
                </th>
                <th>
                  <span>Liq./Mark Price</span>
                </th>
                <th>
                  <span>Avg. Entry Price</span>
                </th>
                <th>
                  <span>Created</span>
                </th>
                <th></th>
              </tr>
            )}

            {positions.length === 0 && (!loading || !positionsDataIsLoading) && (
              <tr className="no-position">
                <td colSpan="15">
                  <div className="no-data-record">No open positions</div>
                </td>
              </tr>
            )}
            {filterOrders &&
              account &&
              marketTokensInfo &&
              filterOrders.length > 0 &&
              filterOrders.map((position, index) => {
                const dateFormat = "YYYY-MM-DD";
                const timeFormat = "HH:mm:ss";
                const currentMarket = marketTokensInfo?.find((x) => x.id === position.tokenId);
                const accruedFees =
                  parseValue(position.accruedPositionFee, 30)
                    .add(parseValue(position.accruedFundingFee, 30))
                    .add(parseValue(position.accruedBorrowFee, 30)) || BigNumber.from(0);
                const avgEntryPrice = parseValue(position.averagePrice, 30);
                const markPrice = BigNumber.from(
                  position?.isLong ? currentMarket?.askPrice || 0 : currentMarket?.bidPrice || 0
                );
                const posOrders = myOrders.filter((x) => x.posId === position.posId);
                const paidFees =
                  parseValue(position.paidPositionFee, 30)
                    .add(parseValue(position.paidBorrowFee, 30))
                    .add(parseValue(position.paidFundingFee, 30)) || BigNumber.from(0);
                const posQty = parseValue(position.size, 30)
                  .mul(parseValue(1, 30))
                  .div(parseValue(position.averagePrice, 30));
                const positionNetValue = getPositionNetValue(position, markPrice);
                const uPnL =
                  getUnrealizedPnl(accruedFees, markPrice, avgEntryPrice, position.isLong, posQty)?.div(
                    parseValue(1, 30)
                  ) || BigNumber.from(0);
                const collateral = parseValue(position.collateral, 30);
                const LiqRisk = getLiqRisk(positionNetValue?.pnl, collateral);
                const rPnL = positionNetValue?.pnlWithoutFee.sub(paidFees || 0);
                const completePosition = {
                  positionNetValue,
                  markPrice: markPrice,
                  ...position,
                  symbol: getTokenSymbolFromString(marketAsssets?.[position.tokenId]?.symbol),
                  quantity: posQty,
                  currentMarket,
                  accruedFees,
                  uPnL,
                  LiqRisk,
                  paidFees,
                  rPnL,
                  posOrders,
                };
                // console.log("????", {marketAsssets, completePosition});
                return (
                  <tr
                    key={position.key}
                    className="table-content clickable"
                    onClick={() => position.liquidationPrice && handleSelectedPosition(completePosition)}
                  >
                    <td className="clickable" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
                      <div>
                        <img src={importImage("ic_" + completePosition?.symbol?.toLowerCase() + "_24.svg")} alt="tk" />{" "}
                        <div className="tk_info">
                          <div className="Exchange-list-title">
                            {marketAsssets?.[position.tokenId]?.symbol}{" "}
                            <StyledPostId className="muted">#{position.posId}</StyledPostId>
                          </div>
                          <div className="Exchange-list-info-label">
                            <span
                              className={cx({ positive: position.isLong, negative: !position.isLong })}
                              style={{ color: position.isLong ? "#90FB75" : "#F6475D", fontWeight: 700 }}
                            >
                              {position.isLong ? `LONG` : `SHORT`}
                            </span>
                            {position.leverage && (
                              <span
                                className="leverage-box"
                                style={{
                                  color: "#FFC700",
                                  backgroundColor: "rgba(255, 199, 0, 0.20)",
                                  height: 16,
                                  display: "inline-block",
                                  opacity: 1,
                                  borderRadius: "100px",
                                  padding: "0 4px",
                                  fontSize: "12px",
                                  lineHeight: "15.6px",
                                  fontWeight: 700,
                                  marginLeft: "4px",
                                }}
                              >
                                {formatAmount(parseValue(position.leverage, 30), 30, 2, true)}X
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <div className={`flag ${position.isLong ? "isLong" : "isShort"}`}></div> */}
                    </td>
                    {position.liquidationPrice ? (
                      <td style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
                        <div>
                          {positionNetValue.netValue.lt(0) ? "-" : ""}$
                          {positionNetValue.netValue
                            ? formatAmount(positionNetValue.netValue, 30, 2, true).replace("-", "")
                            : "--"}
                        </div>
                        {positionNetValue?.pnlWithoutFee && (
                          <div
                            className={cx("Exchange-list-info-label profit-loss-label", {
                              positive: positionNetValue?.pnl?.gt(0),
                              negative: positionNetValue?.pnl.lt(0),
                              muted: positionNetValue?.pnl.eq(0),
                            })}
                          >
                            <span
                              className={cx("", {
                                positive: positionNetValue?.pnl?.gt(0),
                                negative: positionNetValue?.pnl.lt(0),
                                muted: positionNetValue?.pnl.eq(0),
                              })}
                            >
                              {/* {positionNetValue.prefix ? (
                                <img
                                  src={positionNetValue.prefix === "-" ? ic_ArrowDown : ic_ArrowUp}
                                  alt="tk"
                                  style={{ width: "16px", height: "16px" }}
                                />
                              ) : (
                                ""
                              )} */}
                              {`$${formatAmount(positionNetValue?.pnl, 30, 2, true)}`}{" "}
                              <span
                                className={cx("", {
                                  positive: positionNetValue?.pnl?.gt(0),
                                  negative: positionNetValue?.pnl.lt(0),
                                  muted: positionNetValue?.pnl.eq(0),
                                })}
                              >
                                ({formatAmount(positionNetValue?.percentPnl, 30, 2, true).replace("-", "")}
                                %)
                              </span>
                            </span>
                            <Tooltip
                              handle={``}
                              position={"left-bottom"}
                              handleClassName={cx("plain", { negative: position.hasLowCollateral })}
                              renderContent={() => {
                                return (
                                  <>
                                    <StatsTooltipRow
                                      label={`PnL without fees`}
                                      value={`${positionNetValue.prefixWithoutFee}$${formatAmount(
                                        positionNetValue.pnlWithoutFee,
                                        USD_DECIMALS,
                                        2,
                                        true
                                      ).replace("-", "")}`}
                                      showDollar={false}
                                    />
                                    <StatsTooltipRow
                                      label={`Accrued fees`}
                                      showDollar={false}
                                      value={`${accruedFees.lt(0) ? "-" : ""}$${formatAmount(
                                        accruedFees,
                                        USD_DECIMALS,
                                        2,
                                        true
                                      ).replace("-", "")}`}
                                    />
                                  </>
                                );
                              }}
                            />
                          </div>
                        )}
                      </td>
                    ) : (
                      <td style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
                        <div>--</div>
                      </td>
                    )}

                    <td style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
                      <div>${formatAmount(parseValue(position?.size, 30), 30, 2, true)}</div>
                      <div className="muted fz-12 bold">
                        {formatAmount(posQty, 30, 4, true)}{" "}
                        {getTokenSymbolFromString(marketAsssets?.[position.tokenId]?.symbol)}
                      </div>
                    </td>
                    <td style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
                      <div>
                        {renderRisk(LiqRisk, false)}

                        {/* {formatAmount(LiqRisk, 30, LiqRisk.eq(0) ? 0 : 2, true).replace("-", "")}% */}
                      </div>
                    </td>
                    <td className="clickable" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
                      <div>
                        $
                        {formatAmount(
                          parseValue(position.liquidationPrice, 30),
                          30,
                          currentMarket?.decimals || 2,
                          true
                        )}
                      </div>
                      <div className="muted fz-12 bold">
                        ${formatAmount(markPrice, 30, currentMarket?.decimals || 2, true)}
                      </div>
                    </td>
                    <td className="clickable" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
                      ${formatAmount(parseValue(position.averagePrice, 30), 30, currentMarket?.decimals || 2, true)}
                    </td>
                    {/* <td className="clickable">${limitDecimals(position.liquidationPrice, 3)}</td> */}
                    <td className="clickable" style={{ opacity: position.liquidationPrice ? 1 : 0.6 }}>
                      {moment(new Date(position.createdAt * 1000)).format(dateFormat)}
                      <div className="muted fz-12 bold">
                        {moment(new Date(position.createdAt * 1000)).format(timeFormat)}
                      </div>
                    </td>

                    <td style={{ width: "30px" }}>
                      {position.liquidationPrice ? (
                        <div className="close-container">
                          <button
                            className="Exchange-list-action"
                            onClick={(e) => {
                              e.stopPropagation();
                              sellPosition(completePosition);
                            }}
                            disabled={position.size == 0 || (position && !position.liquidationPrice)}
                          >
                            Close
                          </button>
                          {/* {posOrders.length > 0 && (
                            <div
                              className="chains-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                setListSection("Orders");
                                setPosOrders(posOrders);
                              }}
                            >
                              <span>{posOrders.length}</span>
                              <img src={theme?.isLightTheme ? ic_chainLight : ic_chain} alt="" />
                            </div>
                          )} */}

                          {/* <div className="order-count">5 Orders</div> */}
                          <PositionDropdown
                            position={position}
                            handleEditCollateral={() => {
                              editPosition(completePosition);
                            }}
                            handleEditLeverage={() => {
                              setSelectedPosition(completePosition);
                              setIsEditLeverageModalOPen(true);
                            }}
                            handleShare={() => {
                              setPositionToShare(completePosition);
                              setIsPositionShareModalOpen(true);
                            }}
                            handleAddTPSL={() => {
                              setSelectedPosition(completePosition);
                              setIsTPSLModalOPen(true);
                            }}
                            handlAddToPosition={() => {
                              setSelectedPosition(completePosition);
                              setIsAddToPosition(true);
                            }}
                            handlAddCollateral={() => {
                              setSelectedPosition(completePosition);
                              setIsAddCollateralModalOpen(true);
                            }}
                            handlAddTrailingStop={() => {
                              setSelectedPosition(completePosition);
                              setIsTrailingStopModalOpen(true);
                            }}
                            handleMarketSelect={() => {
                              // onPositionClick(position);
                            }}
                            handleEditTakeProfit={() => {
                              setSelectedPosition(completePosition);
                              setIsEditTakeProfitModalOpen(true);
                            }}
                            handleEditStopLoss={() => {
                              setSelectedPosition(completePosition);
                              setIsEditStopLossModalOpen(true);
                            }}
                          />
                        </div>
                      ) : (
                        <div className="processing">
                          Processing <Spin indicator={antIcon} />
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}

      {/* {count > DEFAULT_PAGE_SIZE_POSITIONS && (
        <PaginationV2
          rowsPerPage={DEFAULT_PAGE_SIZE_POSITIONS}
          rows={count}
          currentPage={currentPage}
          setCurrentPage={setCurrenPage}
        />
      )} */}

      {positions && positions.length > DEFAULT_PAGE_SIZE_POSITIONS && (
        <PaginationV2
          rowsPerPage={DEFAULT_PAGE_SIZE_POSITIONS}
          rows={positions.length}
          currentPage={page}
          setCurrentPage={setPage}
        />
      )}
    </div>
  );
}

const StyledPostId = styled.div`
  &.muted {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: #828899;
  }
`;
